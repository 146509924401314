import {
  DepartmentOptionsHandlerProps,
  DepartmentOptionsHandlerReturn,
} from '../types';

export const departmentOptionsHandler = ({
  departmentCurrentName,
  endPointData,
  setTaskState,
  taskState,
}: DepartmentOptionsHandlerProps): DepartmentOptionsHandlerReturn => {
  const selectOptions = endPointData.map((department) => ({
    id: department.id ?? 0,
    label: department.name ?? '',
    value: department.name ?? '',
  }));

  const handleChange = (selectedOption: { id: number; value: string }) =>
    setTaskState((prevState) => ({
      ...prevState,
      departmentId: selectedOption.id,
      departmentName: selectedOption.value,
    }));

  const selectValue = {
    id: taskState?.departmentId,
    label: departmentCurrentName?.name || taskState?.departmentName,
    value: departmentCurrentName?.name || taskState?.departmentName,
  };

  return {
    handleChange,
    selectOptions,
    selectValue,
  };
};

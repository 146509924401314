import React from 'react';

type FileInputProps = {
  fileInputWrapper: string;
  fileInputId?: string;
  fileInputIcon: string | React.ReactNode;
  fileInputLabel: string;
  fileInputClassName: string;
  validationWarning: string;
  fileName: string;
  onChange: JSX.IntrinsicElements['input']['onChange'];
};

const FileInput = ({
  fileInputWrapper,
  fileInputId,
  fileInputIcon,
  fileInputLabel,
  fileInputClassName,
  validationWarning,
  fileName,
  onChange,
}: FileInputProps) => (
  <div className='file-input__wrapper'>
    <div className={fileInputWrapper}>
      <label htmlFor={fileInputId}>
        &nbsp;
        {fileInputLabel}
      </label>
      <input
        type='file'
        id={fileInputId}
        name={fileInputId}
        className={fileInputClassName}
        onChange={onChange}
      />
      {fileInputIcon}
    </div>
    {!!validationWarning?.length && (
      <div className={fileName ? 'file-input__txt' : 'file-input__warning'}>
        {validationWarning}
      </div>
    )}
  </div>
);

export default FileInput;

import { exportProjectEmployeeReport } from '../../../xls/projectEmployeeReportXLS';

import ProjectEmployeeReportPDFLink from '../../../pdf/ProjectEmployeeReportPDF';

import { SubHeaderBlockProps } from './types';
import {
  projectEmployeeAllTaskRegEx,
  projectEmployeeTaskRegEx,
} from './utils/regex';
import { useSubHeaderBlock } from './useSubHeaderBlock';
import { ClientReportHeader } from './components/ClientReportHeader';
import { projectReportHeaders } from './components/projectReportHeaders';
import { TotalReportHeader } from './components/TotalReportHeader';
import { PMReportHeader } from './components/PMReportHeader';

const SubHeaderBlock = ({
  reportClient,
  reportProject,
  reportProjectEmployee,
  reportPMEmployee,
  reportTotal,
}: SubHeaderBlockProps) => {
  const {
    billable,
    clientId,
    decodedLocation,
    employeeId,
    employeeReportTitle,
    employeeTaskName,
    endDate,
    isEmployeeTimeLogs,
    match,
    pmId,
    projectId,
    reportTitle,
    startDate,
    t,
    departmentName,
  } = useSubHeaderBlock({
    reportProjectEmployee,
    reportProject,
  });

  const {
    ProjectEmployeeTaskSubHeader,
    ProjectEmployeeAllTasksSubHeader,
    ProjectSubHeader,
  } = projectReportHeaders({
    reportProjectEmployee,
    employeeReportTitle,
    exportProjectEmployeeReport,
    t,
    ProjectEmployeeReportPDFLink,
    employeeTaskName,
    reportTitle,
    reportProject,
    isEmployeeTimeLogs,
  });

  const renderSubHeader = () => {
    // Reports by projects
    // if-statement handling for taskNames with slash in url
    if (match(projectEmployeeTaskRegEx)) {
      return <ProjectEmployeeTaskSubHeader />;
    } else if (match(projectEmployeeAllTaskRegEx)) {
      return <ProjectEmployeeAllTasksSubHeader />;
    }

    switch (decodedLocation) {
      // Reports by projects
      case `/reports/by-projects/project${projectId}/start${startDate}/end${endDate}/accrual${billable}`:
      case `/reports/by-projects/project${projectId}/start${startDate}/end${endDate}/accrual${billable}/tasksAll`:
        return <ProjectSubHeader />;

      // Reports by client
      case `/reports/by-client/start${startDate}/accrual${billable}/end${endDate}`:
      case `/reports/by-client/client${clientId}/start${startDate}/accrual${billable}/end${endDate}`:
      case `/reports/by-client/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`:
      case `/reports/by-client/client${clientId}/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`:
        return (
          <ClientReportHeader
            {...{
              t,
              reportClient,
              startDate,
              endDate,
            }}
          />
        );

      // Reports by total
      case `/reports/by-total/start${startDate}/accrual${billable}/end${endDate}`:
      case `/reports/by-total/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`:
      case `/reports/by-total/departmentAll/start${startDate}/accrual${billable}/end${endDate}`:
      case `/reports/by-total/departmentAll/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`:
      case `/reports/by-total/department${departmentName}/start${startDate}/accrual${billable}/end${endDate}`:
        case `/reports/by-total/department${departmentName}/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`:
        return (
          <TotalReportHeader
            {...{
              t,
              reportTotal,
              startDate,
              endDate,
            }}
          />
        );

      // Reports by project manager
      case `/reports/by-pm/pm${pmId}/employee${employeeId}/start${startDate}/end${endDate}/accrual${billable}`:
        return (
          <PMReportHeader
            {...{
              endDate,
              reportPMEmployee,
              startDate,
              t,
            }}
          />
        );
      default:
        return null;
    }
  };

  return renderSubHeader();
};

export default SubHeaderBlock;

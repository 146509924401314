import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { CustomNavLinkProps } from '../types';

const CustomNavLink = ({
  childElement,
  config,
  editMode,
  handleNavigateUrl,
  handleWarningPopUp,
}: CustomNavLinkProps) => {
  const linkPath = `/${config.title.toLowerCase()}`;

  const className = useMemo(
    () => ({
      className: ({ isActive }: { isActive: boolean }) =>
        isActive ? 'icon_pm_container_active' : 'icon_pm_container',
    }),
    [],
  );

  const onClick = useMemo(
    () => ({
      onClick: editMode
        ? (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            handleWarningPopUp(true);
            handleNavigateUrl(linkPath);
          }
        : () => {
            handleNavigateUrl(linkPath);
          },
    }),
    [editMode, handleNavigateUrl, handleWarningPopUp, linkPath],
  );

  return (
    <NavLink ref={childElement} to={linkPath} {...className} {...onClick}>
      <div className='icon_pm_type'>{config.icon}</div>
      <span className='icon_pm_title'>{config.title}</span>
    </NavLink>
  );
};

export default CustomNavLink;

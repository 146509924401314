import { formatDateYYYYMMDD } from '../../../../utils/date-formatting';
import {
  UseOvertimeStartEndDateProps,
  UseOvertimeStartEndDateReturn,
} from '../types';

export const useOvertimeStartEndDate = ({
  setTeamMemberState,
  teamMemberState,
}: UseOvertimeStartEndDateProps): UseOvertimeStartEndDateReturn => {
  const overtimeStartDate = teamMemberState.overtime?.startDate
    ? new Date(
        new Date(teamMemberState.overtime.startDate).setMinutes(
          new Date(teamMemberState.overtime.startDate).getTimezoneOffset(),
        ),
      )
    : undefined;

  const overtimeMinEndDate = new Date(teamMemberState.overtime?.startDate ?? 0);

  const overtimeEndDate = teamMemberState.overtime?.endDate
    ? new Date(
        new Date(teamMemberState.overtime.endDate).setMinutes(
          new Date(teamMemberState.overtime.endDate).getTimezoneOffset(),
        ),
      )
    : undefined;

  const handleOvertimeStartDateChange = (selected: Date) =>
    setTeamMemberState((state) => ({
      ...teamMemberState,
      overtime: {
        ...state.overtime,
        startDate: selected
          ? formatDateYYYYMMDD(selected)
          : state.overtime?.startDate,
      },
    }));

  const handleOvertimeEndDateChange = (selected: Date) =>
    setTeamMemberState((state) => ({
      ...teamMemberState,
      overtime: {
        ...state.overtime,
        endDate: selected
          ? formatDateYYYYMMDD(selected)
          : state.overtime?.endDate,
      },
    }));

  return {
    overtimeStartDate,
    overtimeEndDate,
    overtimeMinEndDate,
    handleOvertimeStartDateChange,
    handleOvertimeEndDateChange,
  };
};

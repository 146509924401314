import { UnitDto, UserFullDto, UserNameIdDto } from '../api/api-types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const filterEmptyLogs = <TLog extends { countHours?: string }>(
  arr: TLog[],
) => arr.filter((el) => el.countHours !== '00:00');

export const findRegisteredPM = <TPerson extends UserNameIdDto>(
  arr: TPerson[],
  employeeId: UserFullDto['id'],
) => {
  let filteredPMList: TPerson[] = [];
  if (arr && employeeId) {
    filteredPMList = arr.filter((person) => person.id !== employeeId);
  }
  return filteredPMList;
};

export const findRoles = <
  TArr extends readonly unknown[],
  TValues extends readonly unknown[],
>(
  arr: TArr,
  values: TValues,
) => values.some((value) => arr.includes(value));

export const removeDuplicatedEmployees = <
  T extends {
    employeeId?: string | number;
  },
>(
  array: T[],
) =>
  array.reduce<T[]>((accumulator, current) => {
    const x = accumulator.find(
      (item) => item?.employeeId === current?.employeeId,
    );
    if (!x) {
      return accumulator.concat([current]);
    }
    return accumulator;
  }, []);

export const groupBy = <TObj>(
  list: TObj[],
  keyGetter: (obj: TObj) => string,
) => {
  const map = new Map<string, TObj[]>();

  list?.forEach((item: TObj) => {
    const key = keyGetter(item);

    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
};

export const sortByDefault = <
  TElement extends Record<string, any>,
  TKey extends keyof TElement,
>(
  a: TElement,
  b: TElement,
  key: TKey,
) => {
  switch (true) {
    case a[key]?.toUpperCase() > b[key]?.toUpperCase():
      return 1;
    case a[key]?.toUpperCase() < b[key]?.toUpperCase():
      return -1;
    default:
      return 0;
  }
};

export const sortObjectsByDate = <
  TElement extends Record<string, any>,
  TKey extends keyof TElement,
>(
  objectArray: TElement[],
  dateKey: TKey,
) => {
  objectArray
    ?.sort((a, b) => {
      const dateA = new Date(a[dateKey].split(' - ')[0]);
      const dateB = new Date(b[dateKey].split(' - ')[0]);
      return dateA.getTime() - dateB.getTime();
    })
    .reverse();

  return objectArray;
};

export const getUnitName = (arr: UnitDto[], id: number | undefined) => {
  if (Array.isArray(arr)) {
    return arr?.find((unit) => unit.id === id);
  }

  return {
    id,
    name: '',
  };
};

import { toast } from 'react-toastify';
import {
  CUSTOMERS,
  CONTACT_PERSONS,
  PROJECTS,
  EMAILS,
  CUSTOMER_LOCATIONS,
  makeRequest,
  handleError,
  LOCATIONS,
} from './config';
import {
  CustomerWebDto,
  CustomerLocationWebDto,
  CustomerLocationCreateDto,
  CustomerLocationRequestDto,
  CcpEmailRequestDto,
  CcpEmailDto,
  CustomerGetDto,
  CustomerRequestDto,
  CustomerRequestUpdateDto,
  CustomerWebUpdateDto,
  CustomerContactPersonRequestDto,
  CcpWebDto,
  CustomerProjectsDto,
  PageNameIdDto,
  CustomerLocationDto,
} from './api-types';
import { store } from '../store';

type GetCustomerProps = {
  pageNumber?: number;
  pageSize?: number;
  sortParam?: string;
  status?: string;
  unpaged?: boolean;
  paged?: boolean;
};

export const getCustomer = async ({
  status,
  pageNumber,
  pageSize,
  sortParam,
  paged,
  unpaged,
}: GetCustomerProps) => {
  const pagedParam = paged ?? true;
  const pageNumberParam = pageNumber ?? 0;
  const unpagedParam = unpaged ?? false;
  try {
    const query = `?paged=${pagedParam}&page=${pageNumberParam}&size=${pageSize}&sort=${sortParam}${
      status ? `&status=${status}` : ''
    }&unpaged=${unpagedParam}`;
    const url = `${CUSTOMERS}${query}`;
    const response = await makeRequest.get<PageNameIdDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetClientPageData = {
  id: number;
};

export const getClientPageData = async ({ id }: GetClientPageData) => {
  try {
    const url = `${CUSTOMERS}/${id}`;
    const response = await makeRequest.get<CustomerGetDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetProjectByClientId = {
  id: number;
};

export const getProjectByClientId = async ({ id }: GetProjectByClientId) => {
  try {
    const url = `${CUSTOMERS}/${id}${PROJECTS}`;
    const response = await makeRequest.get<CustomerProjectsDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type CreateBody = {
  body: CustomerRequestDto;
};

export const createClient = async ({ body }: CreateBody) => {
  try {
    const response = await makeRequest.post<CustomerWebDto>(
      `${CUSTOMERS}`,
      body,
    );
    toast.success('New client was added successfully');
    return response.data;
  } catch (error) {
    handleError(error);
    return null;
  }
};

type UpdateClient = {
  id: number;
  body: CustomerRequestUpdateDto;
};

export const updateClient = async ({ id, body }: UpdateClient) => {
  try {
    const url = `${CUSTOMERS}/${id}`;

    const response = await makeRequest.put<CustomerWebUpdateDto>(url, body);

    store.dispatch({ type: 'UPDATE_CLIENT_PAGE', payload: true });

    toast.success('Client edited successfully');

    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type AddContactPerson = {
  customerId: number;
  body: CustomerContactPersonRequestDto;
};

export const addContactPerson = async ({
  customerId,
  body,
}: AddContactPerson) => {
  try {
    const url = `${CUSTOMERS}/${customerId}${CONTACT_PERSONS}`;
    const response = await makeRequest.post<CcpWebDto>(url, body);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PutContactPerson = {
  customerId: number;
  contactPersonId: number;
  body: CustomerContactPersonRequestDto;
};

export const putContactPerson = async ({
  customerId,
  contactPersonId,
  body,
}: PutContactPerson) => {
  try {
    const url = `${CUSTOMERS}/${customerId}${CONTACT_PERSONS}/${contactPersonId}`;
    const response = await makeRequest.put<CcpWebDto>(url, body);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PostContactPersonEmail = {
  customerId: number;
  contactPersonId: number;
  body: CcpEmailRequestDto;
};

export const postContactPersonEmail = async ({
  customerId,
  contactPersonId,
  body,
}: PostContactPersonEmail) => {
  try {
    const url = `${CUSTOMERS}/${customerId}${CONTACT_PERSONS}/${contactPersonId}${EMAILS}`;
    const response = await makeRequest.post<CcpEmailDto>(url, body);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PutContactPersonEmail = {
  customerId: number;
  contactPersonId: number;
  emailId: number;
  body: CcpEmailRequestDto;
};

export const putContactPersonEmail = async ({
  customerId,
  contactPersonId,
  emailId,
  body,
}: PutContactPersonEmail) => {
  try {
    const url = `${CUSTOMERS}/${customerId}${CONTACT_PERSONS}/${contactPersonId}${EMAILS}/${emailId}`;
    const response = await makeRequest.put<CcpEmailDto>(url, body);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export type DeleteContactPersonEmail = {
  customerId: number;
  contactPersonId: number;
  emailId: number;
};

export const deleteContactPersonEmail = async ({
  customerId,
  contactPersonId,
  emailId,
}: DeleteContactPersonEmail) => {
  try {
    const url = `${CUSTOMERS}/${customerId}${CONTACT_PERSONS}/${contactPersonId}${EMAILS}/${emailId}`;
    await makeRequest.delete(url);
  } catch (e) {
    handleError(e);
  }
};

export const getAllCustomersLocations = async () => {
  try {
    const url = `${CUSTOMERS}${LOCATIONS}`;
    const response = await makeRequest.get<CustomerLocationDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type CreateCustomerLocation = {
  customerId: number;
  body: CustomerLocationCreateDto;
};

export const createCustomerLocation = async ({
  customerId,
  body,
}: CreateCustomerLocation) => {
  try {
    const url = `${CUSTOMERS}/${customerId}${CUSTOMER_LOCATIONS}`;
    const response = await makeRequest.post<CustomerLocationWebDto>(url, body);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type UpdateCustomerLocation = {
  customerId: number;
  locationId: number;
  body: CustomerLocationRequestDto;
};

export const updateCustomerLocation = async ({
  customerId,
  locationId,
  body,
}: UpdateCustomerLocation) => {
  try {
    const url = `${CUSTOMERS}/${customerId}${CUSTOMER_LOCATIONS}/${locationId}`;
    const response = await makeRequest.put<CustomerLocationWebDto>(url, body);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

import { TimeLogDto } from '../../../../api/api-types';
import { deleteTimeLogRange } from '../../../../api/tracking';

export const deleteTimeLogHandler = (
  updateTimeLogList: () => void,
  closeModal: () => void,
) => {
  const deleteEditedTimelog = async (timeLogState: TimeLogDto) => {
    if (timeLogState.id) {
      try {
        await deleteTimeLogRange([`${timeLogState.id}`]);

        updateTimeLogList();
      } catch (error) {
        console.error('Failed to delete time log:', error);
      } finally {
        closeModal();
      }
    }
  };

  return deleteEditedTimelog;
};

import DataPair from '../../../../DataPair/DataPair';
import SimpleSelect from '../../../../Select/SimpleSelect';
import dataPairStyles from '../../../../DataPair/data-pair.module.scss';
import { ReportDataProps, SelectOption } from './types';

export const ReportData = <T extends SelectOption>({
  editMode,
  onChange,
  options,
  readOnlyValue,
  styles,
  title,
  value,
}: ReportDataProps<T>) => (
  <>
    <DataPair dataPairTitle={dataPairStyles.data_pair_title} title={title} />
    <div className={styles.data_input}>
      {editMode ? (
        <SimpleSelect
          options={options}
          value={value}
          onChange={onChange}
          styles={styles}
        />
      ) : (
        <div className={styles.data_input_readonly}>{readOnlyValue}</div>
      )}
    </div>
  </>
);

import { ReportHeaderProps } from '../types';

const getTotalReportHeader = ({
  billable,
  employeeId,
  endDate,
  location,
  reportTotal,
  startDate,
  t,
}: ReportHeaderProps) => {
  const employee =
    reportTotal?.projectCountHoursDtoList?.[0]?.employeeFullName?.name;

  const basePath = '/reports/by-total';
  const path = location.pathname;

  const conditions: { condition: boolean; result: string }[] = [
    {
      condition:
        path ===
        `${basePath}/start${startDate}/accrual${billable}/end${endDate}`,
      result: ` > ${t('reports.subtitle.report')}/${t(
        'reports.subtitle.only_total',
      )}/${t('reports.subtitle.all')}`,
    },
    {
      condition:
        path ===
        `${basePath}/departmentAll/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`,
      result: ` > ${t('reports.subtitle.report')}/${t(
        'reports.subtitle.only_total',
      )}${employee ? `/${employee}` : ''}`,
    },
  ];

  return conditions.find((cond) => cond.condition)?.result || null;
};

export default getTotalReportHeader;

import React from 'react';
import classNames from 'classnames';

export type ButtonProps = {
  id?: string;
  styleClass?: string;
  label?: string;
  active?: boolean;
  icon?: string | React.ReactNode;
  children?: React.ReactNode;
} & JSX.IntrinsicElements['button'];

const Button = ({
  id = '',
  styleClass = '',
  label = '',
  active = undefined,
  icon = null,
  children,
  ...rest
}: ButtonProps) => {
  const classes = classNames('btn', styleClass, { active });
  return (
    <button data-tooltip-id={id} className={classes} {...rest}>
      {icon && <span>{icon}</span>}
      {children ?? label}
    </button>
  );
};

export default Button;

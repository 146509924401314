import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styled-calendar.scss';

type StyledCalendarDatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> = {
  titleClassName?: string;
  startDate?: Date;
} & ReactDatePickerProps<CustomModifierNames, WithRange>;

function StyledCalendarDatePicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(props: StyledCalendarDatePickerProps<CustomModifierNames, WithRange>) {
  return (
    <div className={props.wrapperClassName}>
      <div className={props.titleClassName}>{props.title}</div>
      <DatePicker
        {...props}
        selected={props.startDate}
        onChange={props.onChange}
        onMonthChange={props.onMonthChange}
        calendarStartDay={1}
        openToDate={props.startDate}
        adjustDateOnChange
        disabledKeyboardNavigation
        inline
      />
    </div>
  );
}

export default StyledCalendarDatePicker;

import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getRegisteredEmployee } from '../../api/projects';

import useEditMode from '../../hooks/useEditMode';
import useCloseByClickOutside from '../../hooks/useCloseByClickOutside';
import { getRegisteredEmployeeAction } from '../../store/actionCreators/employeeActionCreators';
import { createEmployeeConfigs } from './configs/employeeConfigs';
import { createManagerConfigs } from './configs/managerConfigs';
import { UseMenuBarReturnType } from './types';
import { findRoles } from '../../utils/filtering';
import { namespace } from '../../constants/text-values';
import { headRoles } from '../../constants/role-values';

export const useMenuBar = (): UseMenuBarReturnType => {
  const { t } = useTranslation();
  const { user, logout } = useAuth0();

  const [isExtendedMenu, setIsExtendedMenu] = useState(false);
  const { registeredEmployee } = useAppSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const childElement = useRef<HTMLAnchorElement | null>(null);
  const { ref } = useCloseByClickOutside<HTMLDivElement>(
    isExtendedMenu,
    setIsExtendedMenu,
  );

  const isEmployee = registeredEmployee?.employmentForm === 'EMPLOYEE';

  const managerConfigs = createManagerConfigs({ isEmployee, t });
  const employeeConfigs = createEmployeeConfigs({ isEmployee, t });

  const userRole = findRoles(user?.[`${namespace}/roles`], headRoles) === true;
  const configs = userRole ? managerConfigs : employeeConfigs;

  const toggleMenu = useCallback(() => {
    setIsExtendedMenu(!isExtendedMenu);
  }, [isExtendedMenu, setIsExtendedMenu]);

  const handleLogout = useCallback(() => {
    sessionStorage.clear();
    localStorage.clear();
    logout({ returnTo: document.location.origin });
  }, [logout]);

  const {
    confirmPopUp,
    editMode,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    handleGlobalEditMode,
    handleNavigateUrl,
    handleWarningPopUp,
    navigateUrl,
    warningPopUp,
  } = useEditMode();

  useEffect(() => {
    const fetchRegisteredEmployee = async () => {
      const data = await getRegisteredEmployee();
      if (!data) return;

      dispatch(getRegisteredEmployeeAction(data));
    };

    fetchRegisteredEmployee();
  }, []);

  return {
    childElement,
    configs,
    confirmPopUp,
    editMode,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    handleGlobalEditMode,
    handleLogout,
    handleNavigateUrl,
    handleWarningPopUp,
    isExtendedMenu,
    navigate,
    navigateUrl,
    ref,
    registeredEmployee,
    t,
    toggleMenu,
    warningPopUp,
  };
};

import Button from '../../../../Button/Button';
import TextField from '../../../../TextField/TextField';
import {
  nameAndEmailInputLength,
  phoneInputLength,
} from '../../../../../constants/input-restrictions';
import { ReactComponent as TrashBinIcon } from '../../../../../icons/TrashBinIcon.svg';

import styles from '../../../../../components/Modal/styles/client-modal.module.scss';
import { useContactPersonBlock } from './useContactPersonBlock';
import { ContactPersonBlockProps } from './types';

const ContactPersonBlock = ({
  emails: initialEmails,
  handleClientState,
  idx,
  name,
  phoneNumber,
}: ContactPersonBlockProps) => {
  const { t, emails, onNamePhoneChange, onEmailChange, addEmail, removeEmail } =
    useContactPersonBlock({
      handleClientState,
      idx,
      initialEmails,
    });

  return (
    <div className={styles.cp_item}>
      <div className={styles.cp_data}>
        <TextField
          value={name}
          onChange={(e) => onNamePhoneChange('name', e.target.value)}
          labelclassname={styles.common_input_reverse}
          title={t('modals.fields.name') ?? ''}
          type='text'
          maxLength={nameAndEmailInputLength}
        />
        <TextField
          value={phoneNumber}
          onChange={(e) => onNamePhoneChange('phoneNumber', e.target.value)}
          labelclassname={styles.common_input_reverse}
          title={t('modals.fields.phone_number') ?? ''}
          type='tel'
          maxLength={phoneInputLength}
        />
      </div>
      <div className={styles.cp_emails}>
        {emails.map(({ email, id }, i) => (
          <div key={id} className={styles.cp_email}>
            <TextField
              value={email}
              onChange={(e) => onEmailChange(i, e.target.value)}
              labelclassname={styles.common_input_reverse}
              title={`Email${i ? ` ${i + 1}` : ''}`}
              type='email'
              maxLength={nameAndEmailInputLength}
            />
            {i ? (
              <Button
                icon={<TrashBinIcon />}
                styleClass='btn__icon'
                type='button'
                onClick={() => removeEmail(id)}
              />
            ) : (
              <Button
                label='+'
                styleClass='btn__icon-blue'
                type='button'
                onClick={addEmail}
                // disabled={!name && !phoneNumber}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactPersonBlock;

import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonBlockProps } from './types';
import { submitHandler } from './helpers/submitHandler';
import { removeEditMode } from './helpers/removeEditMode';
import { saveEditsHandler } from './helpers/saveEditsHandler';

export const useButtonBlock = ({
  disabled,
  editValuesMode,
  getTimeLogsList,
  handleGlobalEditMode,
  setActiveRow,
  setHandleValues,
  timeLogEditRequest,
  timeLogId,
  timeLogRequestsArray,
}: ButtonBlockProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return {
    disabled,
    editValuesMode,
    isLoading,
    removeEditMode: () =>
      removeEditMode({ setHandleValues, setActiveRow, handleGlobalEditMode }),
    saveEditsHandler: () =>
      saveEditsHandler({
        getTimeLogsList,
        handleGlobalEditMode,
        removeEditMode,
        setActiveRow,
        setHandleValues,
        timeLogEditRequest,
        timeLogId,
      }),
    submitHandler: () =>
      submitHandler({
        getTimeLogsList,
        setHandleValues,
        setIsLoading,
        timeLogRequestsArray,
      }),
    t,
  };
};

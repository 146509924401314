import { HoursByProjectAndEmployee } from '../../../api/api-types';

export const mapProjectDetails = (project: HoursByProjectAndEmployee) => {
  return {
    billableHours: project.billableHours,
    client: project.client,
    employeeFullName: { id: project.employeeFullName?.id },
    name: project.projectNameAndId?.name,
    notBillableHours: project.notBillableHours,
    projectNameAndId: { id: project.projectNameAndId?.id },
  };
};

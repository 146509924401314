import TotalItems from '../../../components/ControlsPanel/TotalItems';
import SimpleSelect from '../../../components/Select/SimpleSelect';
import { useTranslation } from 'react-i18next';
import { statusOptions } from '../../../constants/select-values';
import { controlsStatusStyle } from '../../../components/Select/selectStyles';
import PMFilter from '../../../modules/Projects/PMFilter/PMFilter';
import React, { useMemo } from 'react';
import Button from '../../../components/Button/Button';
import { ReactComponent as PlusIcon } from '../../../icons/PlusIcon.svg';
import { exportProjectList } from '../../../xls/projectListXLS';
import {
  ProjectWebDto,
  UserFullDto,
  UserNameIdDto,
} from '../../../api/api-types';
import { Msg } from '../../../store/actions';

type UseProjectControlsProps = {
  clearSelectedProject: () => void;
  pmList: UserNameIdDto[];
  projectId: number | null;
  projectListAllSorted: ProjectWebDto[];
  registeredEmployee: UserFullDto;
  selectedPMId: string;
  selectedPMName: string;
  selectedStatus: string;
  setCurrentPage: (page: number) => void;
  setHandleValues: React.Dispatch<Msg>;
  toggleAddProject: boolean;
  totalAmount: number;
};

type UseProjectControlsReturn = {
  controlsProjectLeft: JSX.Element[];
  controlsProjectRight: (boolean | JSX.Element)[];
};

export const useProjectControls = ({
  clearSelectedProject,
  pmList,
  projectId,
  projectListAllSorted,
  registeredEmployee,
  selectedPMId,
  selectedPMName,
  selectedStatus,
  setCurrentPage,
  setHandleValues,
  toggleAddProject,
  totalAmount,
}: UseProjectControlsProps): UseProjectControlsReturn => {
  const { t } = useTranslation();

  const controlsProjectLeft = [
    <TotalItems
      key='key-project-1'
      textLabel={`${t('projects.total')}: `}
      data={totalAmount}
    />,
    <SimpleSelect
      key='key-project-2'
      selectWrapperClass='select_horizontal_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('status.title') as string}
      options={statusOptions(t)}
      value={{
        label: selectedStatus,
        value: selectedStatus,
      }}
      styles={controlsStatusStyle}
      onChange={(e) => {
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { selectedStatus: e.value },
        });
        if (projectId) {
          clearSelectedProject();
        }
        setCurrentPage(0);
      }}
    />,
    <PMFilter
      key='key-project-3'
      pmList={pmList}
      registeredEmployee={registeredEmployee}
      selectedPMId={selectedPMId}
      selectedPMName={selectedPMName}
      clearSelectedProject={clearSelectedProject}
      setCurrentPage={setCurrentPage}
      setHandleValues={setHandleValues}
      t={t}
    />,
  ];

  const controlsProjectRight = useMemo(
    () => [
      <Button
        key='key-project-5'
        styleClass='btn__rounded-blue'
        label={t('buttons.new_project') as string}
        icon={<PlusIcon />}
        onClick={() =>
          setHandleValues({
            type: 'ON_TOGGLE_VIEW',
            payload: {
              toggleAddProject: !toggleAddProject,
            },
          })
        }
      />,
      projectListAllSorted.length > 0 && (
        <Button
          key='key-project-6'
          label={t('generate_report.title') as string}
          onClick={() => exportProjectList(projectListAllSorted)}
          styleClass='btn__string--underlined--grey'
        />
      ),
    ],
    [
      t,
      setHandleValues,
      toggleAddProject,
      exportProjectList,
      projectListAllSorted.length,
    ],
  );
  return { controlsProjectLeft, controlsProjectRight };
};

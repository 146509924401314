import styles from '../../../styles/vacation.module.scss';
import { DatePickerBlockProps } from './types';
import { useDatePickerBlock } from './useDatePickerBlock';

const DatePickerBlock = (props: DatePickerBlockProps) => {
  const { choosePickerType, t } = useDatePickerBlock(props);

  return (
    <div className={styles.datePicker}>
      {choosePickerType()}
      <div className={styles.datePickerWarning}>
        {t('warning.day_already_logged_selected')}
      </div>
    </div>
  );
};

export default DatePickerBlock;

import { useMemo } from 'react';
import { ProjectLastUsedDto, TaskDto } from '../../../api/api-types';

export const useVacationTasksList = (employeeData: ProjectLastUsedDto) => {
  const vacationTaskList = useMemo(
    () =>
      employeeData.projectDTOList.reduce<TaskDto[]>((acc, el) => {
        if (el.name !== 'Internal Administration') return acc;

        const vacationTasksList = el?.tasks?.filter(
          (elem) => elem.type === 'Vacation',
        );

        return [...acc, ...(vacationTasksList ?? [])];
      }, []),
    [employeeData],
  );

  return vacationTaskList;
};

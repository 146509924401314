import { useNavigate, useParams } from 'react-router-dom';
import useValues from '../../hooks/useValues';
import { useAppDispatch } from '../../hooks/redux';
import { getBackendReportByPM } from '../../api/reports';

const usePMReportList = () => {
  const { billable, startDate, endDate } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { handleValues, setHandleValues } = useValues('reportPMData');

  const { isLoading } = handleValues;

  const transformTypes = (types: string, mapping: { [key: string]: string }) =>
    types
      ? types.split(',').map((type) => mapping[type.trim()] || type.trim())
      : [];

  const billableMapping = {
    BILLABLE: 'Billable',
    INTERNAL_BILLABLE: 'Internal Billable',
    NON_BILLABLE: 'Non-Billable',
  };

  const billableTypes = transformTypes(billable || '', billableMapping);

  const pmSelectHandler = (pmId: number) => {
    setHandleValues({ type: 'SET_LOADER', payload: { isLoading: true } });

    getBackendReportByPM({
      managerId: pmId,
      startDate: startDate || '',
      endDate: endDate || '',
      billableTypes: billable || '',
    })
      .then((data) => {
        setHandleValues({ type: 'SET_LOADER', payload: { isLoading: false } });

        if (!data) return;

        dispatch({ type: 'GET_REPORT_PM', payload: data });
      })
      .catch(() => {
        setHandleValues({ type: 'SET_LOADER', payload: { isLoading: false } });
      });
    navigate(location.pathname.replace('/by-pm/', `/by-pm/pm${pmId}/`));

    return () => {
      dispatch({ type: 'CLEAR_REPORT_PM', payload: [] });
    };
  };

  return { billableTypes, pmSelectHandler, isLoading };
};

export default usePMReportList;

import { clearedTimeLogRequest } from '../../../constants/cleared-objects';
import {
  CLEAR_TEXT,
  ON_TOGGLE_VIEW,
  SET_ID,
  SET_TEXT,
} from '../../../store/actions';
import { ColumnEditBlockProps } from './types';

export const useColumnEditBlock = ({
  editDraftSickness,
  editValuesMode,
  handleGlobalEditMode,
  row,
  setEditDraftSickness,
  setEditedRowId,
  setHandleValues,
  setIsOpenEditModal,
}: ColumnEditBlockProps) => {
  const setDraftToEdit = () => {
    setEditedRowId(row.id);
    
    setHandleValues(CLEAR_TEXT({ payload: clearedTimeLogRequest }));
    setHandleValues(
      ON_TOGGLE_VIEW({
        payload: {
          editValuesMode: !editValuesMode,
        },
      }),
    );
    setHandleValues(SET_ID({ payload: { timeLogId: row.original.ids } }));
    setHandleValues(
      SET_TEXT({ payload: { comment: row.original.note || '' } }),
    );

    setEditDraftSickness({
      ...editDraftSickness,
      eptId: row.original.eptId ?? null,
      date: row.original.startPeriod ?? '',
      note: row.original.note ?? '',
      fileName: row.original.attachmentName ?? '',
    });

    setIsOpenEditModal(true);
    handleGlobalEditMode(true);
  };

  return { setDraftToEdit };
};

import { useEffect } from 'react';
import { SET_SELECTABLES } from '../../../store/actions';
import { UseAutoSetCurrentMinuteProps } from '../types';

export const useAutoSetCurrentMinute = ({
  currentHour,
  currentMinute,
  setHandleValues,
}: UseAutoSetCurrentMinuteProps) => {
  useEffect(() => {
    if (currentHour) {
      if (currentHour === '00' && !currentMinute) {
        setHandleValues(
          SET_SELECTABLES({
            payload: { currentMinute: '15' },
          }),
        );
      }
      if (Number(currentHour) > 0 && !currentMinute) {
        setHandleValues(
          SET_SELECTABLES({
            payload: { currentMinute: '00' },
          }),
        );
      }
    }
  }, [currentHour, currentMinute, setHandleValues]);
};

import { useTranslation } from 'react-i18next';
import { patchSicknessLog, postSicknessFile } from '../../../api/sickness';
import { ButtonBlockReturn, UseButtonBlockProps } from './types';
import { CLEAR_TEXT, EDIT_MODE, SET_LOADER } from '../../../store/actions';

const DEFAULT_SICKNESS_VALUE = {
  duration: {
    hours: 8,
    minutes: 0,
  },
  eptId: null,
  note: '',
  date: '',
  fileName: '',
  isPartDay: false,
};

export const useButtonBlock = ({
  clearedTimeLogRequest,
  fileItem,
  handleGlobalEditMode,
  setEditDraftSickness,
  setEditedRowId,
  setHandleValues,
  timeLogId,
  timeLogRequest,
}: UseButtonBlockProps): ButtonBlockReturn => {
  const { t } = useTranslation();

  const setDraftToEdit = () => setEditDraftSickness(DEFAULT_SICKNESS_VALUE);

  const endingRequest = () => {
    setHandleValues(CLEAR_TEXT({ payload: clearedTimeLogRequest }));
    setHandleValues(
      EDIT_MODE({
        payload: {
          editValuesMode: false,
        },
      }),
    );

    setDraftToEdit();
    setEditedRowId(null);
    setHandleValues(SET_LOADER({ payload: { isLoading: false } }));

    handleGlobalEditMode(false);
  };

  const updateLog = async () => {
    await patchSicknessLog({
      timeLogId: timeLogId[0],
      data: {
        note: timeLogRequest.note,
      },
    });

    if (!fileItem) return;

    await Promise.all(
      timeLogId.map((id) =>
        postSicknessFile({
          timeLogId: id,
          fileItem,
        }),
      ),
    );
  };

  return {
    endingRequest,
    setDraftToEdit,
    t,
    updateLog,
  };
};

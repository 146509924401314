import { ReportHeaderProps } from '../types';

const getPMReportHeader = ({
  billable,
  employeeId,
  endDate,
  location,
  pmId,
  reportPM,
  reportPMEmployee,
  startDate,
  t,
}: ReportHeaderProps) => {
  const pmName = reportPM?.[0]?.pmName;
  const pmEmployeeName = reportPMEmployee?.projectManagerFullName?.name;
  const employeeName = reportPMEmployee?.fullEmployeeName?.name;

  const basePath = '/reports/by-pm';
  const path = location.pathname;

  const conditions: { condition: boolean; result: string }[] = [
    {
      condition:
        path ===
        `${basePath}/pm${pmId}/start${startDate}/end${endDate}/accrual${billable}`,
      result: pmName ? ` > ${pmName}` : '',
    },
    {
      condition:
        path ===
        `${basePath}/start${startDate}/end${endDate}/accrual${billable}`,
      result: ' > All',
    },
    {
      condition:
        path ===
        `${basePath}/pm${pmId}/employee${employeeId}/start${startDate}/end${endDate}/accrual${billable}`,
      result: ` > ${t('reports.subtitle.report')}${
        pmEmployeeName ? `/${pmEmployeeName}` : ''
      }${employeeName ? `/${employeeName}` : ''}`,
    },
  ];

  return conditions.find((cond) => cond.condition)?.result || null;
};

export default getPMReportHeader;

import { TaskType } from './types';

export const clearedSelectedTask: TaskType = {
  name: '',
  departmentId: 0,
  departmentName: '',
  description: '',
  rate: 0,
  taskStatus: '',
};

import React from 'react';

interface ProjectEmployeeReportNameProps {
  employeeName?: string;
  projectName?: string;
}

const ProjectEmployeeReportName: React.FC<ProjectEmployeeReportNameProps> = ({
  employeeName,
  projectName,
}) => {
  if (!employeeName || !projectName) return null;

  return (
    <div className='project_report_name'>
      {`${employeeName} - ${projectName}`}
    </div>
  );
};

export default ProjectEmployeeReportName;

import { useMemo } from 'react';
import Button from '../../../components/Button/Button';
import { ReactComponent as TrashBinIcon } from '../../../icons/TrashBinIcon.svg';
import styles from '../../../styles/vacation.module.scss';
import { HandleConfirmPopUpProps } from '../types';

const useVacationColumns = ({
  t,
  handleConfirmPopUp,
}: HandleConfirmPopUpProps) => {
  const vacationColumns = useMemo(
    () => [
      {
        Header: t('columns.vacation'),
        accessor: 'vacation',
      },
      {
        Header: t('columns.dates'),
        Cell: ({ row }: any) => `${row.original.start} - ${row.original.end}`, // remove any
      },
      {
        Header: t('columns.days'),
        accessor: 'days',
      },
      {
        Header: t('columns.vacation_type'),
        accessor: 'type',
      },
      {
        Header: ' ',
        Cell: ({ row }: any) => {
          // remove any
          const [d, m, y] = row.original.start.split('/');
          const formattedStartDate = new Date(y, m - 1, d);

          if (
            row.original.vacation === t('columns.upcoming') ||
            formattedStartDate.getTime() === new Date().setHours(0, 0, 0, 0)
          ) {
            return (
              <div className={styles.buttonArea}>
                <Button
                  icon={<TrashBinIcon />}
                  onClick={async () => {
                    handleConfirmPopUp(true, row.original.id);
                  }}
                />
              </div>
            );
          }

          return null;
        },
      },
    ],
    [t, handleConfirmPopUp],
  );

  return vacationColumns;
};

export default useVacationColumns;

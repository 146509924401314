import { HoursByProjectAndEmployee } from '../../../api/api-types';
import { timeToDecimal } from '../../../utils/date-formatting';

export const calculateTotalHours = (
  projects: HoursByProjectAndEmployee[],
): number => {
  return projects.reduce(
    (total, project) => total + timeToDecimal(project.countHours ?? ''),
    0,
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns';
import { eptIdChecker } from './eptIdChecker';
import { textInputXSSRemoval } from './text-handling';
import {
  EmployeeProjectTaskRequest,
  ProjectAssignedClientDto,
  ProjectData,
  ProjectDto,
  TaskRequest,
  TrackFormRange,
} from '../api/api-types';
import { formatDateYYYYMMDD } from './date-formatting';
import { CreateProjectModalData } from '../components/Modal/CreateProjectModal/types';

export const createdProjectRequest = (
  state: CreateProjectModalData,
): ProjectAssignedClientDto => ({
  description: textInputXSSRemoval(state.description),
  endDate: state.endDate
    ? formatDateYYYYMMDD(
        new Date(
          new Date(state.endDate).setMinutes(
            new Date(state.endDate).getTimezoneOffset(),
          ),
        ),
      )
    : undefined,
  billableType: state.billableType,
  isIncludedInPlanning: state.isIncludedInPlanning,
  projectManagerId: state.projectManagerId ?? undefined,
  projectName: textInputXSSRemoval(state.projectName ?? ''),
  startDate: state.startDate
    ? formatDateYYYYMMDD(
        new Date(
          new Date(state.startDate).setMinutes(
            new Date(state.startDate).getTimezoneOffset(),
          ),
        ),
      )
    : undefined,
  customerId: state.customerId,
  customerName: state.customerName,
});

export const createdTaskRequest = (state: TaskRequest): TaskRequest => ({
  departmentId: state?.departmentId || 0,
  description: textInputXSSRemoval(state?.description ?? ''),
  name: textInputXSSRemoval(state?.name) || '',
  taskStatus: state?.taskStatus.toUpperCase() || '',
});

export const createdVacationRequest = (
  state: any,
  employeeData: ProjectDto[],
) => ({
  date: state.currentDate && format(new Date(state.currentDate), 'dd.MM.yyyy'),
  duration: { hours: 8, minutes: 0 },
  eptId: eptIdChecker(employeeData, state.dayOffType),
  note: '',
});

export const createdVacationHalfDayRequest = (
  state: any,
  employeeData: ProjectDto[],
) => ({
  endDate:
    state.currentDate && format(new Date(state.currentDate), 'dd.MM.yyyy'),
  isPartDay: true,
  eptId: eptIdChecker(employeeData, state.dayOffType),
  note: '',
  startDate:
    state.currentDate && format(new Date(state.currentDate), 'dd.MM.yyyy'),
});

export const createdVacationRangeRequest = (
  state: any,
  checkboxState: number,
  employeeData: ProjectDto[],
): TrackFormRange => ({
  endDate: state.endDate && format(new Date(state.endDate), 'dd.MM.yyyy'),
  eptId: eptIdChecker(employeeData, state.dayOffType),
  isPartDay: checkboxState !== 0,
  note: '',
  startDate: state.startDate && format(new Date(state.startDate), 'dd.MM.yyyy'),
});

export const editedProjectRequest = (state: ProjectData) => ({
  description: textInputXSSRemoval(state.description ?? ''),
  endDate:
    state.endDate &&
    formatDateYYYYMMDD(
      new Date(
        new Date(state.endDate).setMinutes(
          new Date(state.endDate).getTimezoneOffset(),
        ),
      ),
    ),
  billableType: state.billableType ?? '',
  projectManagerId: state.projectManagerId,
  projectName: textInputXSSRemoval(state.projectName ?? ''),
  startDate:
    state.startDate &&
    formatDateYYYYMMDD(
      new Date(
        new Date(state.startDate).setMinutes(
          new Date(state.startDate).getTimezoneOffset(),
        ),
      ),
    ),
  customerId: state.customerId,
});

export const editedTaskRequest = (state: TaskRequest): TaskRequest => ({
  departmentId: state.departmentId,
  description: textInputXSSRemoval(state.description ?? ''),
  name: textInputXSSRemoval(state.name),
  taskStatus: state.taskStatus.toUpperCase(),
});

export const teamMemberRequest = (
  state: EmployeeProjectTaskRequest,
): EmployeeProjectTaskRequest => {
  const overtime = state.overtime
    ? {
        comment: state.overtime.comment || '',
        endDate: state.overtime.endDate || null,
        startDate: state.overtime.startDate || null,
        overtimeRate: state.overtime.overtimeRate || 0,
        fte: state.overtime.fte || 0,
      }
    : null;

  return {
    employeeId: state.employeeId,
    endDate: state.endDate
      ? formatDateYYYYMMDD(
          new Date(
            new Date(state.endDate).setMinutes(
              new Date(state.endDate).getTimezoneOffset(),
            ),
          ),
        )
      : undefined,
    billableType: state.billableType,
    overtime,
    role: state.role,
    startDate:
      state.startDate &&
      formatDateYYYYMMDD(
        new Date(
          new Date(state.startDate).setMinutes(
            new Date(state.startDate).getTimezoneOffset(),
          ),
        ),
      ),
    taskId: Number(state?.taskId) || Number(state?.task?.id) || 0,
  };
};

import { SicknessesDto } from '../../../api/api-types';
import { formatTodayToISO } from '../../../utils/date-formatting';

export const checkIfDayAlreadyLogged = (sicknessData: SicknessesDto) => {
  return (
    sicknessData?.dateAndProgressPercentage &&
    sicknessData.dateAndProgressPercentage.find(
      (log) => log.date === formatTodayToISO(),
    )
  );
};

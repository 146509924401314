import FAQTile from './components/FAQTitle';
import { frequentQuestions } from './constants';
import { useBCoinFAQContent } from './useBCoinFAQContent';

const BCoinFAQContent = () => {
  const { isActiveTile, handleActiveTab } = useBCoinFAQContent();

  return (
    <div className='bcoin-faq-container'>
      <div className='bcoin-faq-column'>
        {frequentQuestions.slice(0, 4).map((item, index) => (
          <FAQTile
            key={item.id}
            item={item}
            index={index}
            isActiveTile={isActiveTile}
            handleActiveTab={handleActiveTab}
          />
        ))}
      </div>
      <div className='bcoin-faq-column'>
        {frequentQuestions.slice(4, 9).map((item, index) => (
          <FAQTile
            key={item.id}
            item={item}
            index={index + 4}
            isActiveTile={isActiveTile}
            handleActiveTab={handleActiveTab}
          />
        ))}
      </div>
    </div>
  );
};

export default BCoinFAQContent;

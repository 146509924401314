import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import styles from '../../styles/vacation.module.scss';

import DatePickerBlock from '../../modules/Vacation/DatePickerBlock/DatePickerBlock';
import StyledRowTable from '../../components/Tables/StyledRowTable';
import Loader from '../../components/Loader/Loader';
import HeaderBlock from '../../modules/Vacation/HeaderBlock';
import FormBlock from '../../modules/Vacation/FormBlock';
import { useVacationPage } from './useVacationPage';

const VacationPage = () => {
  const {
    checkboxState,
    constPartValue,
    currentDate,
    currentSelectedMonth,
    dayAlreadyLogged,
    dayOffType,
    employeeData,
    endDate,
    handleValues,
    isLoading,
    onMonthChange,
    registeredEmployee,
    setCheckboxState,
    setHandleValues,
    startDate,
    t,
    tableData,
    tableRef,
    updateVacationList,
    vacationColumns,
    vacationData,
    vacationTaskList,
  } = useVacationPage();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>{t('vacation.title')}</h2>
          <HeaderBlock
            checkboxState={checkboxState}
            currentDate={currentDate}
            dayOffType={dayOffType}
            endDate={endDate}
            setHandleValues={setHandleValues}
            startDate={startDate}
          />
        </div>
        <div className={styles.data}>
          {vacationData ? (
            <>
              <FormBlock
                {...{
                  checkboxState,
                  constPartValue,
                  currentDate,
                  currentSelectedMonth,
                  dayAlreadyLogged,
                  dayOffType,
                  employeeData: employeeData.projectDTOList,
                  endDate,
                  handleValues,
                  registeredEmployee,
                  setCheckboxState,
                  setHandleValues,
                  startDate,
                  updateVacationList,
                  vacationData,
                  vacationTaskList,
                }}
              />
              <DatePickerBlock
                {...{
                  checkboxState,
                  currentDate,
                  dayOffType,
                  endDate,
                  onMonthChange,
                  registeredEmployee,
                  setHandleValues,
                  startDate,
                  vacationData,
                }}
              />
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.container}>
        {!!tableData.length && !isLoading && (
          <StyledRowTable
            changeRowColor={() => {}}
            onRowClick={() => {}}
            ref={tableRef}
            columns={vacationColumns}
            data={tableData}
            tableName='vacations-list'
          />
        )}
        {!tableData.length && !isLoading && (
          <p>{t('warning.no_available_data')}</p>
        )}
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default VacationPage;

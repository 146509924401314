import { FC } from 'react';
import Button from '../../../../components/Button/Button';
import { formatToDDMMYYYY } from '../../../../utils/date-formatting';
import { exportPmEmployeeReport } from '../../../../xls/pmEmployeeReportXLS';
import { PMReportHeaderProps } from './types';

export const PMReportHeader: FC<PMReportHeaderProps> = ({
  endDate,
  reportPMEmployee,
  startDate,
  t,
}) => (
  <>
    <h3>
      {reportPMEmployee?.fullEmployeeName?.name
        ? `${reportPMEmployee.fullEmployeeName.name} ${formatToDDMMYYYY(
            startDate,
          )} - ${formatToDDMMYYYY(endDate)}`
        : ''}
    </h3>
    {!!reportPMEmployee?.timeLogsPerProjectAndEmployee?.length && (
      <Button
        label={t('generate_report.title') as string}
        onClick={() =>
          exportPmEmployeeReport(
            reportPMEmployee,
            startDate ?? '',
            endDate ?? '',
          )
        }
        styleClass='btn__string--underlined--grey'
      />
    )}
  </>
);

import React, { forwardRef } from 'react';
import { formatDateInput } from '../../../utils/date-formatting';

type CalendarInputProps = {
  value?: string;
  inputClassName?: string;
  onClick?: () => void;
}

const CalendarInput = forwardRef<HTMLButtonElement, CalendarInputProps>((props, ref) => (
  <button type="button" className={props.inputClassName} onClick={props.onClick} ref={ref}>
    {props.value && formatDateInput(props.value)}
  </button>
));

export default CalendarInput;

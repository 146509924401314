import { format } from 'date-fns';
import { eptIdChecker } from '../../../utils/eptIdChecker';
import { textInputXSSRemoval } from '../../../utils/text-handling';
import { CreateSicknessRequestProps } from '../types';

export const createSicknessRequest = ({
  endDate,
  startDate,
  eptId,
  isPartDay,
  note,
}: CreateSicknessRequestProps) => {
  return {
    endDate: endDate && format(new Date(endDate), 'dd.MM.yyyy'),
    eptId: eptIdChecker(eptId, 'Sickness'),
    isPartDay: isPartDay !== 0,
    note: textInputXSSRemoval(note) || '',
    startDate: startDate && format(new Date(startDate), 'dd.MM.yyyy'),
  };
};

import React, { useEffect, useState } from 'react';

import './client-info.scss';
import { getUnitName } from '../../utils/filtering';
import { useTranslation } from 'react-i18next';
import { getUnits } from '../../api/unit';
import { CustomerGetDto, UnitDto } from '../../api/api-types';

const ClientInfo = ({ clientPage }: { clientPage: CustomerGetDto }) => {
  const { t } = useTranslation();
  const [units, setUnits] = useState<UnitDto[]>([]);

  useEffect(() => {
    getUnits().then((data) => {
      if (!data) return;
      setUnits(data);
    });
  }, []);

  const currentUnit = getUnitName(units, clientPage?.unitId);

  return (
    <div className='item-info_container'>
      <div className='item-info_row'>
        <div className='item-info'>
          <div className='item-info_label'>
            <span>{t('item_info.unit')}</span>
          </div>
          <div className='item-info_item'>{currentUnit?.name || '-'}</div>
        </div>
      </div>
      {clientPage?.customerContactPersons?.map((person) => (
        <div className='item-info_row' key={person.id}>
          <div className='item-info'>
            <div className='item-info_label'>
              <span>{t('item_info.contact_name')}</span>
            </div>
            <div className='item-info_item'>{person.name || '-'}</div>
          </div>
          <div className='item-info'>
            <div className='item-info_label'>
              <span>{t('item_info.email')}</span>
            </div>
            <div className='item-info_list'>
              {person.emails?.length ? (
                person.emails?.map(({ email }) => (
                  <div className='item-info_item' key={email}>
                    {email}
                  </div>
                ))
              ) : (
                <div className='item-info_item'>---</div>
              )}
            </div>
          </div>
          <div className='item-info'>
            <div className='item-info_label'>
              <span>{t('item_info.phone')}</span>
            </div>
            <div className='item-info_item'>{person.phoneNumber || '---'}</div>
          </div>
        </div>
      ))}
      <div className='item-info_row'>
        <div className='item-info'>
          <div className='item-info_label'>{t('item_info.location')}</div>
          <div className='item-info_list'>
            {clientPage?.locationDtoSet?.map((item) => (
              <div className='item-info_item' key={item.id}>
                {item.name !== '' && item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;

import React from 'react';

type ProgressBarProps = {
  completed: number;
};

type ProgressBarStyles = Record<string, React.CSSProperties>;

const ProgressBar = (props: ProgressBarProps) => {
  const { completed } = props;
  const fillInAnimation = `
  @keyframes {
    0% {
      width: 0%;
      opacity: 0;
    }
    50% {
      width: 0%;
      opacity: 0;
    }
    100% {
      width: ${completed}%;
      opacity: 1;
    }
  }
  `;

  const progressBarStyles: ProgressBarStyles = {
    containerStyles: {
      height: 3,
      width: 22,
      backgroundColor: '#E0E0DE',
      borderRadius: 50,
      marginTop: '-3px',
    },
    fillerStyles: {
      height: 3,
      width: `${completed}%`,
      maxWidth: 22,
      backgroundColor: completed > 100 ? '#FF0000' : '#2979FF',
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width .35s ease-out',
      animationName: `${fillInAnimation}`,
      animationDuration: '0.35s',
      animationFillMode: 'forwards',
    },
  };

  return (
    <div style={progressBarStyles.containerStyles}>
      <div style={progressBarStyles.fillerStyles} />
    </div>
  );
};

export default ProgressBar;

import BCoinBalanceItem from './BCoinBalanceItem';
import { BCoinEmployeeDto } from '../../../api/api-types';

type BCoinBalanceContentProps = {
  items: BCoinEmployeeDto[];
  topItems: BCoinEmployeeDto[];
  currentEmloyee?: BCoinEmployeeDto | null;
};

const BCoinBalanceContent = (props: BCoinBalanceContentProps) => (
  <div className='bcoin-balance-container'>
    <div className='bcoin-balance-list'>
      {props.currentEmloyee && (
        <div>
          <BCoinBalanceItem
            key={props.currentEmloyee.id}
            item={props.currentEmloyee}
            index={3}
            currentEmloyeeStyle
          />
          <div className='b-coin-divider' />
        </div>
      )}
      {props.items.map((item) => {
        const topIndex = props.topItems.findIndex(
          (tItem) => tItem.id === item.id,
        );
        const currentEmployeeIndex = props.currentEmloyee?.id === item.id;
        // ? This is tied up to logic in `BCoinBalanceItem.jsx`
        const index = topIndex !== -1 ? topIndex : 3;
        return <BCoinBalanceItem key={item.id} item={item} index={index} currentEmployeeIndex={currentEmployeeIndex}/>;
      })}
      <div className='bcoin-balance-list-empty-space' />
    </div>
  </div>
);

export default BCoinBalanceContent;

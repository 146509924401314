export const settings = {
  arrows: false,
  autoplay: true,
  dots: false,
  draggable: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
  swipe: true,
  swipeToSlide: true,
  touchMove: true,
  variableWidth: true,
  verticalSwiping: false,
};

import React, { useContext, useState, useEffect } from 'react';
import {
  BaseModalComponentType,
  ModalNameToModalMap,
} from '../components/Modal/types';

export type ModalNames = keyof ModalNameToModalMap;

type ModalsType = {
  [ModalName in keyof ModalNameToModalMap]: {
    shown: boolean;
    payload: ModalNameToModalMap[ModalName];
  };
};

type ModalProviderType = {
  hideModal: <ModalName extends ModalNames>(modalName: ModalName) => void;

  getModal: <ModalName extends ModalNames>(
    modalName: ModalName,
  ) => ModalsType[ModalName] | undefined;

  showModal: <ModalName extends ModalNames>(
    modalName: ModalName,
    payload?: ModalNameToModalMap[ModalName],
  ) => void;
};

const ModalContext = React.createContext<ModalProviderType>({
  getModal: () => undefined,
  hideModal: () => undefined,
  showModal: () => undefined,
});

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modals, setModals] = useState<Partial<ModalsType>>({});

  const showModal = <ModalName extends ModalNames>(
    modalName: ModalName,
    payload: ModalNameToModalMap[ModalName] | undefined,
  ) => {
    setModals((modals) => ({
      ...modals,
      [modalName]: {
        shown: true,
        payload,
      },
    }));
  };

  const hideModal = <ModalName extends ModalNames>(modalName: ModalName) => {
    setModals((modals) => ({
      ...modals,
      [modalName]: {
        shown: false,
        payload: modals[modalName]?.payload,
      },
    }));
  };

  const getModal = <ModalName extends ModalNames>(
    modalName: ModalName,
  ): ModalsType[ModalName] | undefined => {
    return modals[modalName];
  };

  return (
    <ModalContext.Provider value={{ showModal, getModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export const withModal = <ModalName extends ModalNames>(
  modalName: ModalName,
) => {
  return (
    Modal: React.FC<
      BaseModalComponentType & { payload: ModalNameToModalMap[ModalName] }
    >,
  ) => {
    return () => {
      const [isModalOpened, setIsModalOpened] = useState(false);

      const { getModal, hideModal } = useModal();
      const modal = getModal(modalName);
      const closeModal = () => hideModal(modalName);

      useEffect(() => {
        if (modal?.shown) {
          return setIsModalOpened(true);
        }

        const timeout = setTimeout(() => {
          setIsModalOpened(false);
        }, 300);

        return () => {
          clearTimeout(timeout);
        };
      }, [modal]);

      if (!isModalOpened || !modal) return null;

      return <Modal closeModal={closeModal} payload={modal.payload} />;
    };
  };
};

export default ModalProvider;

import { FC } from 'react';
import Checkbox from '../../../../Checkbox/Checkbox';
import DataPair from '../../../../DataPair/DataPair';
import styles from './../../../styles/report-modal.module.scss';
import { BillableTypeCheckboxesProps } from './types';
import { useBillableTypeCheckboxes } from './useBillableTypeCheckboxes';

const BillableTypeCheckboxes: FC<BillableTypeCheckboxesProps> = ({
  data,
  dataPairStyles,
  editMode,
  selectedTask,
  setTimeLogState,
  timeLogState,
}: BillableTypeCheckboxesProps): JSX.Element => {
  const billableTypeCheckboxes = useBillableTypeCheckboxes({
    timeLogState,
    setTimeLogState,
  });

  return (
    <>
      {billableTypeCheckboxes.map((item) => (
        <div key={item.id} className={styles.report_data}>
          <DataPair
            dataPairTitle={dataPairStyles.data_pair_title}
            title={item.id}
          />
          <div className={styles.data_input}>
            <Checkbox
              checked={
                editMode
                  ? selectedTask?.billableType !== undefined &&
                    timeLogState.billableType === item.checked
                  : data.billableType === item.checked
              }
              className={item.className}
              disabled={!editMode || item.disabled}
              onChange={editMode ? () => item.onChange() : undefined}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default BillableTypeCheckboxes;

export const requiredKeys = [
  'clientName',
  'customerCountry',
  'departmentName',
  'employeeId',
  'name',
  'projectName',
  'projectStatus',
  'role',
  'taskId',
  'taskStatus',
  'unitId',
] as const;

import { clearedTimeLogRequest } from '../../../constants/cleared-objects';
import Button from '../../../components/Button/Button';
import { ButtonBlockProps } from './types';
import { useButtonBlock } from './useButtonBlock';
import { handleSubmitEdit, handleSubmit } from './utils';

const ButtonBlock = ({
  editValuesMode,
  endDate,
  fileItem,
  handleGlobalEditMode,
  isLoading,
  setEditDraftSickness,
  setEditedRowId,
  setHandleValues,
  startDate,
  timeLogId,
  timeLogRequest,
  updateSicknessList,
}: ButtonBlockProps) => {
  const { t, setDraftToEdit, endingRequest, updateLog } = useButtonBlock({
    clearedTimeLogRequest,
    fileItem,
    handleGlobalEditMode,
    setEditDraftSickness,
    setEditedRowId,
    setHandleValues,
    timeLogId,
    timeLogRequest,
  });

  return !editValuesMode ? (
    <Button
      label={t('buttons.submit') as string}
      styleClass='btn__rounded-blue'
      disabled={!startDate || !endDate || isLoading}
      onClick={() =>
        handleSubmit({
          clearedTimeLogRequest,
          fileItem,
          setDraftToEdit,
          setHandleValues,
          timeLogRequest,
          updateSicknessList,
        })
      }
    />
  ) : (
    <Button
      label={t('buttons.submit') as string}
      styleClass='btn__rounded-blue'
      disabled={isLoading}
      onClick={() =>
        handleSubmitEdit({
          endingRequest,
          fileItem,
          setHandleValues,
          timeLogId,
          updateLog,
          updateSicknessList,
        })
      }
    />
  );
};

export default ButtonBlock;

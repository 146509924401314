import RobotGreetsImage from '../../../images/RobotGreetsImage.png';
import BCoinBodyLeftCol from '../components/BCoinBodyLeftCol';

const BCoinKudosSystem = () => (
  <div className='bcoin-body'>
    <BCoinBodyLeftCol
      description='You may praise your colleagues for their cultural contribution and showing of value within the company, in particular, help, mentoring, proactivity, and so on, and reward them and yourself with one b-coin.'
      imageAsset={RobotGreetsImage}
      imageClassName='bcoin-body-left-col-img-xl-offset'
      title='Kudos system'
    />
    <div className='bcoin-body-right-col bcoin-body-kudos'>
      <div className='bcoin-kudos'>
        <div className='bcoin-kudos-divider' />
        <div className='bcoin-kudos-row'>
          <div className='bcoin-kudos-bordered-item'>
            <div className='bcoin-kudos-counter'>1</div>
            <div className='bcoin-kudos-step'>Step 1</div>
            <div className='bcoin-kudos-action'>Put a tag</div>
          </div>
          <div className='bcoin-kudos-item'>
            <div className='bcoin-kudos-item-txt'>
              Tag a colleague in{' '}
              <a
                href='https://mail.google.com/chat/u/0/#chat/space/AAAAA2io2S0/cpQNCmpUnNc'
                target='_blank'
                rel='noreferrer'
              >
                a special
              </a>{' '}
              chat thread
            </div>
          </div>
        </div>
        <div className='bcoin-kudos-row'>
          <div className='bcoin-kudos-item'>
            <div className='bcoin-kudos-counter'>2</div>
            <div className='bcoin-kudos-item-txt'>
              Share what you want to express your gratitude for
            </div>
          </div>
          <div className='bcoin-kudos-bordered-item'>
            <div className='bcoin-kudos-step'>Step 2</div>
            <div className='bcoin-kudos-action'>Write</div>
          </div>
        </div>
        <div className='bcoin-kudos-row'>
          <div className='bcoin-kudos-bordered-item'>
            <div className='bcoin-kudos-counter'>3</div>
            <div className='bcoin-kudos-step'>Step 3</div>
            <div className='bcoin-kudos-action'>Earn</div>
          </div>
          <div className='bcoin-kudos-item'>
            <div className='bcoin-kudos-item-txt'>Get your b-coin</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BCoinKudosSystem;

import { SicknessesDto } from '../../api/api-types';
import styles from '../../styles/sickness.module.scss';
import { useTranslation } from 'react-i18next';

type FormInfoProps = {
  sicknessData: SicknessesDto;
};

const FormInfo = (props: FormInfoProps) => {
  const { sicknessData } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.circularProgressBarInfo}>
      <div className={styles.circularProgressBarTitle}>
        {t('form.paid_sickness_days')}
      </div>
      <div className={styles.circularProgressBarValue}>
        {`${t('form.left')} ${
          sicknessData?.daysLeft ?? 0
        }/${sicknessData?.maximumDays}`}
      </div>
    </div>
  );
};

export default FormInfo;

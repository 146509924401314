import { ProjectDto } from '../api/api-types';

export const eptIdChecker = (
  employeeData: ProjectDto[],
  currentSubType: string,
  type = 'Internal Administration',
) =>
  employeeData?.reduce((a, { name, tasks }) => {
    let id: number | undefined = undefined;
    if (name === type) {
      tasks?.forEach((el) => {
        if (el.name === currentSubType) {
          id = el.eptId;
        }
      });
    }
    return id || a;
  }, 0);

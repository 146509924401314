import { ModalButton } from '../../Modal';
import { GetButtonsProps } from '../types';

export const getButtons = ({
  t,
  onSave,
  onClose,
  taskState,
}: GetButtonsProps): ModalButton[] => {
  const taskModalButtons = [
    {
      id: '1',
      label: t('buttons.save') as string,
      className: 'btn__rounded-blue',
      save: () => onSave(taskState),
      type: 'submit' as const,
    },
    {
      id: '2',
      label: t('buttons.cancel') as string,
      className: 'btn__string--normal--grey',
      close: onClose,
    },
  ];

  return taskModalButtons;
};

import { TrackFormRange, VacationList } from './api-types';
import { handleError, makeRequest, TIME, VACATIONS } from './config';

type GetVacationData = {
  yearMonth: string;
  employeeId?: number;
};

export const getVacationData = async ({
  yearMonth,
  employeeId,
}: GetVacationData) => {
  try {
    const query = `?yearMonth=${yearMonth}${
      employeeId ? `?employeeId=${employeeId}` : ''
    }`;
    const url = `${TIME}${VACATIONS}${query}`;
    const response = await makeRequest.get<VacationList>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PostVacationData = {
  data: TrackFormRange;
};

export const postVacationData = async ({ data }: PostVacationData) => {
  try {
    const url = `${TIME}${VACATIONS}`;
    const response = await makeRequest.post<number[]>(url, data);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useParams } from 'react-router-dom';
import useValues from '../../hooks/useValues';
import { getBackendReportByPM } from '../../api/reports';
import {
  clearReportPMAction,
  getReportPMAction,
} from '../../store/actionCreators/reportsActionCreators';
import { handleError } from '../../api/config';
import { UseReportPMReturn } from './types';
import { RootState } from '../../store';
import { setLoaderAction } from '../../store/actionCreators/loaderActionCreator';
import { parseId } from '../../utils/idParser';
import { useTranslation } from 'react-i18next';

export const useReportPM = (): UseReportPMReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pmId, startDate, endDate, billable } = useParams();

  const parsedPmId = parseId(pmId);

  const { reportPM, key } = useAppSelector((state: RootState) => state); // key is used for re-fetching data on every submit

  const { handleValues, setHandleValues } = useValues('reportPMData');
  const { isLoading } = handleValues;

  useEffect(() => {
    const fetchData = async () => {
      if (!parsedPmId || !startDate || !endDate || !billable) return;

      setHandleValues(setLoaderAction(true));

      try {
        const data = await getBackendReportByPM({
          billableTypes: billable,
          endDate,
          managerId: parsedPmId,
          startDate,
        });

        if (!data) throw new Error(t('error.no_data').toString());

        dispatch(getReportPMAction(data));
      } catch (error) {
        handleError(error);
      } finally {
        setHandleValues(setLoaderAction(false));
      }
    };

    fetchData();

    return () => {
      dispatch(clearReportPMAction());
    };
  }, [dispatch, parsedPmId, startDate, endDate, billable, key]);

  return {
    isLoading,
    reportPM,
  };
};

export const initialCustomerData = {
  id: 0,
  emails: [
    {
      email: '',
      id: 0,
    },
  ],
  name: '',
  phoneNumber: '',
};

export const initialLocation = {
  customerId: 0,
  id: 0,
  name: '',
};

import React from 'react';
import SimpleSelect from '../../Select/SimpleSelect';
import Modal from '../Modal';
import TextField from '../../TextField/TextField';
import TextAreaField from '../../TextAreaField/TextAreaField';
import DataPair from '../../DataPair/DataPair';
import { ReactComponent as CancelIcon } from '../../../icons/CancelIcon.svg';
import { statusOptions } from '../../../constants/select-values';
import {
  selectPMModalMain,
  selectProjectStatus,
} from '../../Select/selectStyles';
import styles from './../styles/task-modal.module.scss';
import dataPairStyles from '../../DataPair/data-pair.module.scss';
import {
  nameAndEmailInputLength,
  textareaLength,
} from '../../../constants/input-restrictions';
import { TaskModalProps } from './types';
import { useTaskModal } from './useTaskModal';
import { getButtons } from './helpers/getButtons';
import { departmentOptionsHandler } from './helpers/departmentOptionsHandler';
import { taskStatusOptionsHandler } from './helpers/taskStatusOptionsHandler';

const TaskModal = ({
  modalTitle,
  data,
  endPointData,
  onSave,
  onClose,
}: TaskModalProps) => {
  const {
    departmentCurrentName,
    setTaskState,
    t,
    taskOnChangeHandler,
    descriptionOnChangeHandler,
    taskState,
  } = useTaskModal(data, endPointData);

  const taskModalButtons = getButtons({ t, onSave, onClose, taskState });

  const { handleChange, selectOptions, selectValue } = departmentOptionsHandler(
    {
      departmentCurrentName,
      endPointData,
      setTaskState,
      taskState,
    },
  );

  const { taskStatusOnChangeHandler, taskValue } = taskStatusOptionsHandler({
    taskState,
    setTaskState,
  });

  return (
    <div className={styles.container}>
      <Modal
        buttons={taskModalButtons}
        closeRight
        onClose={onClose}
        onTopRight={onClose}
        styles={styles}
        title={modalTitle}
        topRightButton={<CancelIcon />}
      >
        <form className={styles.form}>
          <div className={styles.form_col_wrapper}>
            <div className={styles.form_col}>
              <div className={styles.task_data}>
                <DataPair
                  dataPairTitle={dataPairStyles.data_pair_title}
                  title={`${t('modals.fields.task_name')}*`}
                />
                <div className={styles.data_input}>
                  <TextField
                    field='name'
                    value={taskState?.name}
                    onChange={taskOnChangeHandler}
                    className={styles.task_name_input}
                    type='text'
                    maxLength={nameAndEmailInputLength}
                  />
                </div>
              </div>
              <div className={styles.task_data}>
                <DataPair
                  dataPairTitle={dataPairStyles.data_pair_title}
                  title={`${t('modals.fields.department')}*`}
                />
                <div className={styles.data_input}>
                  <SimpleSelect
                    options={selectOptions}
                    onChange={handleChange}
                    value={selectValue}
                    styles={selectPMModalMain}
                  />
                </div>
              </div>
            </div>
            <div className={styles.form_col}>
              <div className={styles.task_data}>
                <DataPair
                  dataPairTitle={dataPairStyles.data_pair_title}
                  title={`${t('modals.fields.status')}*`}
                />
                <div className={styles.data_input}>
                  <SimpleSelect
                    options={statusOptions(t)}
                    onChange={taskStatusOnChangeHandler}
                    value={taskValue}
                    styles={selectProjectStatus}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.form_col_wrapper}>
            <div className={styles.task_description}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={t('modals.fields.description')}
              />
              <div className={styles.data_input}>
                <TextAreaField
                  field='description'
                  value={taskState?.description}
                  onChange={descriptionOnChangeHandler}
                  fieldClassName={styles.task_description_input}
                  maxLength={textareaLength}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default TaskModal;

import { format } from 'date-fns';
import { TranslationFunction } from '../components/type-helpers/translation';
import { monthName } from '../constants/select-values';

export const formatDateYYYYMMDD = (date: Date) => format(date, 'yyyy-MM-dd');

export const findPreviousMonthDate = (
  givenDate: string,
  amountOfMonths: number,
) =>
  new Date(givenDate).setMonth(new Date(givenDate).getMonth() - amountOfMonths);
//TODO: check whether this cannot be done via date-fns and removed
export const formatDate =
  (date: string | number | Date) => (t: TranslationFunction) => {
    const dateObject = new Date(date);
    return `${dateObject.getDate()} ${
      monthName(t)[dateObject.getMonth()]
    } ${dateObject.getFullYear()}`;
  };

export const formatDateInput = (value: string) => {
  const tmpDate = value.split('/');
  const swapElements = Object.assign([], tmpDate, {
    0: tmpDate[1],
    1: tmpDate[0],
  });
  const formattedDateInput = swapElements.reverse().join('-');
  return formattedDateInput;
};

export const formatCurrentToISO = (date: string | number | Date) =>
  new Date(date).toISOString().split('T')[0];

export const formatTodayToISO = () => new Date().toISOString().split('T')[0];

export const formatToDDMMYYYY = (date?: string) =>
  date?.split('-').reverse().join('.');

export const formatSelectedMonth = (date?: string | number | Date) =>
  date
    ? formatCurrentToISO(date).split('-').slice(0, 2).join('-')
    : formatTodayToISO().split('-').slice(0, 2).join('-');

export const setFormatDDMMYYYYtoMMDDYYYY = (
  date: string,
  separator: string = '.',
) => {
  if (date) {
    const [day, month, year] = date.split(separator);
    return `${month}.${day}.${year}`;
  }
  return '';
};

export const timeToDecimal = (t: string) => {
  const timeToNumber = t.split(':');

  return parseInt(timeToNumber[0], 10) * 1 + parseInt(timeToNumber[1], 10) / 60;
};

export const decimalToTime = (t: number) => {
  const hours = Math.floor(t);

  const minutes = (t - hours) * 60;

  const minutesString =
    minutes < 10 ? `0${minutes.toFixed(0)}` : `${minutes.toFixed(0)}`;

  return `${hours}:${minutesString}`;
};

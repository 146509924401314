export const sortByNameFn =
  () =>
  <TObj extends { name?: string | undefined }>(a: TObj, b: TObj) => {
    const nameA = a?.name?.toUpperCase();
    const nameB = b?.name?.toUpperCase();
    if (!nameA || !nameB) return 0;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

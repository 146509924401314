import RobotFAQImage from '../../../images/RobotFAQImage.png';
import BCoinBodyLeftCol from '../components/BCoinBodyLeftCol';
import BCoinFAQContent from '../components/BCoinFAQContent/BCoinFAQContent';

const BCoinFAQ = () => (
  <div className='bcoin-body'>
    <BCoinBodyLeftCol
      description='If you have any questions or suggestions about b-coin, please contact Marvin'
      imageAsset={RobotFAQImage}
      imageClassName='bcoin-body-left-col-img-offset'
      title='FAQ'
    />
    <div className='bcoin-body-right-col bcoin-faq-right-col'>
      <BCoinFAQContent />
    </div>
  </div>
);

export default BCoinFAQ;

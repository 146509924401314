import {
  EmployeeNameOptionsHandlerProps,
  EmployeeNameOptionsHandlerReturn,
  TeamMemberState,
} from '../types';

export const employeeNameOptionsHandler = ({
  setTeamMemberState,
  employeeOptions,
  teamMemberState,
}: EmployeeNameOptionsHandlerProps): EmployeeNameOptionsHandlerReturn => {
  const onEmployeeNameChange = (e: { value: number }) =>
    setTeamMemberState((prevState: TeamMemberState) => ({
      ...prevState,
      employeeId: e.value,
    }));

  const employeeValue = employeeOptions.find(
    ({ value }) => value === teamMemberState.employeeId,
  ) || { value: null, label: '' };

  return {
    onEmployeeNameChange,
    employeeValue,
  };
};

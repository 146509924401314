/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useModal } from '../../providers/ModalProvider';
import modals from '../../constants/modals';
import { NameIdDto } from '../../api/api-types';
import { parseId } from '../../utils/idParser';
import { handleError } from '../../api/config';
import {
  clearReportProjectAction,
  getReportProjectAction,
} from '../../store/actionCreators/reportsActionCreators';
import { UseReportProjectReturn } from './types';
import { RootState } from '../../store';
import { getBackendReportByProject } from '../../api/reports';
import { getTimeLogDetails } from '../../api/tracking';

export const useReportProject = (): UseReportProjectReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { reportProject, key } = useAppSelector((state: RootState) => state);

  const { showModal } = useModal();

  const tableRef = useRef<HTMLTableElement>(null);

  const { projectId, startDate, endDate, billable, employeeTaskName } =
    useParams();
  const parsedProjectId = parseId(projectId);

  const displayProjectTotal =
    Boolean(reportProject.employeeTaskTimeLogsList?.length) && !isLoading;

  const fetchProjectReport = async () => {
    if (!parsedProjectId || !startDate || !endDate || !billable) return;

    const taskName =
      employeeTaskName === t('projects.listing.value.0')
        ? ''
        : employeeTaskName;

    setIsLoading(true);

    try {
      const data = await getBackendReportByProject({
        billableTypes: billable,
        employeeTaskName: taskName,
        endDate,
        projectId: parsedProjectId,
        startDate,
      });

      if (!data) throw new Error(t('error.no_data').toString());

      dispatch(getReportProjectAction(data));
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const editTimeLogDetails = async (row: any, employee?: NameIdDto) => {
    try {
      const data = await getTimeLogDetails(row.original.timeLogId);

      if (!data) throw new Error(t('error.no_data').toString());

      showModal(modals.editProjectReportModal, {
        data,
        employeeId: employee?.id,
        modalTitle: `${employee?.name}${t(
          'modals.titles.employee_report_on',
        )} ${data.project}`,
        updateTimeLogList: fetchProjectReport,
      });
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchProjectReport();

    return () => {
      dispatch(clearReportProjectAction(reportProject));
    };
  }, [projectId, startDate, endDate, billable, employeeTaskName, key]);

  return {
    editTimeLogDetails,
    isLoading,
    reportProject,
    displayProjectTotal,
    t,
    tableRef,
  };
};

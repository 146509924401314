import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ButtonBlock from './ButtonBlock';
import TextBlock from './TextBlock';
import { checkboxes } from './halfDayBooking';
import styles from '../../styles/vacation.module.scss';
import FormInfo from './FormInfo';
import { ReactComponent as VectorDownBlue } from '../../icons/VectorDownBlue.svg';
import useWindowResizeListener from '../../hooks/useWindowResizeListener';
import SimpleSelect from '../../components/Select/SimpleSelect';
import Checkbox from '../../components/Checkbox/Checkbox';
import { controlsDayOffStyle } from '../../components/Select/selectStyles';
import { Msg } from '../../store/actions';
import {
  ProjectDto,
  TaskDto,
  UserFullDto,
  VacationList,
} from '../../api/api-types';

type FormBlockProps = {
  checkboxState: number;
  constPartValue: number | undefined;
  currentDate: string;
  currentSelectedMonth: React.MutableRefObject<string | undefined>;
  dayAlreadyLogged?: boolean;
  dayOffType: string;
  employeeData: ProjectDto[];
  handleValues: any;
  registeredEmployee: UserFullDto;
  setCheckboxState: (value: number) => void;
  setHandleValues: React.Dispatch<Msg>;
  startDate: string;
  endDate: string;
  updateVacationList: (value: string | undefined) => void;
  vacationData: VacationList;
  vacationTaskList: TaskDto[];
};

const FormBlock = (props: FormBlockProps) => {
  const {
    checkboxState,
    constPartValue,
    currentDate,
    currentSelectedMonth,
    dayAlreadyLogged,
    dayOffType,
    employeeData,
    endDate,
    handleValues,
    registeredEmployee,
    setCheckboxState,
    setHandleValues,
    startDate,
    updateVacationList,
    vacationData,
    vacationTaskList,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const { t } = useTranslation();

  const windowSize = useWindowResizeListener();

  const vacationDataProps = {
    vacationData,
  };

  const shouldShowHalfDayBtn =
    (dayOffType === t('vacation.vacation_data.unpaid_day_offs') ||
      dayOffType === t('vacation.vacation_data.paid_vacations')) &&
    constPartValue === 1;

  return (
    <div className={styles.form}>
      <div className={styles.formContent}>
        <div className={styles.formContentCol}>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar}>
              <CircularProgressbarWithChildren
                value={vacationData?.daysLeft || 0}
                minValue={0}
                maxValue={Math.floor(vacationData?.maximumDays ?? 0)}
                styles={buildStyles({
                  pathColor: 'rgba(53, 129, 255, 1)',
                  trailColor: 'rgba(231, 231, 231, 1)',
                })}
              >
                <div className={styles.circularProgressBarContent}>
                  {(windowSize?.width ?? 0) > 1024 ? (
                    <FormInfo vacationData={vacationData} />
                  ) : null}
                </div>
              </CircularProgressbarWithChildren>
            </div>
            {(windowSize?.width ?? 0) <= 1024 ? (
              <FormInfo vacationData={vacationData} />
            ) : null}
          </div>
        </div>
        <div className={styles.formContentCol}>
          {(windowSize?.width ?? 0) < 960 ? (
            <div
              className={styles.expandDetails}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <div className={styles.expandDetailsTitle}>
                Show vacation details
              </div>
              <div
                style={isExpanded ? { transform: 'rotate(180deg)' } : undefined}
              >
                <VectorDownBlue />
              </div>
            </div>
          ) : null}
          {(windowSize?.width ?? 0) < 960 && isExpanded ? (
            <TextBlock {...vacationDataProps} />
          ) : null}
          {(windowSize?.width ?? 0) >= 960 && (
            <TextBlock {...vacationDataProps} />
          )}
          <div className={styles.checkBoxWrapper}>
            {shouldShowHalfDayBtn &&
              checkboxes.map((item, index) => (
                <Checkbox
                  key={item.id}
                  checked={checkboxState === index}
                  className='custom_checkbox'
                  onChange={() => setCheckboxState(index)}
                  title={item.title}
                />
              ))}
          </div>
          <div className={styles.formFooter}>
            <SimpleSelect
              selectWrapperClass='select_vertical_wrapper'
              selectTitleClassName={
                dayOffType === '' ? 'select_title__empty' : 'select_title'
              }
              selectTitle={`${t('vacation.vacation_data.day_off_type')}*`}
              options={vacationTaskList?.map(({ name }) => ({
                label: name ?? '',
                value: name ?? '',
              }))}
              isOptionDisabled={(option) =>
                (vacationData?.daysLeft === 0 &&
                  !registeredEmployee.unlimitedVacationDays &&
                  option.label ===
                    t('vacation.vacation_data.paid_vacations')) ||
                (vacationData?.accumulatedDays === undefined &&
                  !registeredEmployee.unlimitedVacationDays &&
                  option.label ===
                    t('vacation.vacation_data.paid_vacations')) ||
                !vacationTaskList.length
              }
              value={{
                label: dayOffType || 'Select...',
                value: dayOffType || 'Select...',
              }}
              onChange={(e) =>
                setHandleValues({
                  type: 'SET_SELECTABLES',
                  payload: { dayOffType: e.value },
                })
              }
              styles={controlsDayOffStyle}
            />
            <ButtonBlock
              checkboxState={checkboxState}
              currentDate={currentDate}
              currentSelectedMonth={currentSelectedMonth}
              dayAlreadyLogged={dayAlreadyLogged}
              dayOffType={dayOffType}
              employeeData={employeeData}
              endDate={endDate}
              handleValues={handleValues}
              setHandleValues={setHandleValues}
              startDate={startDate}
              updateVacationList={updateVacationList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBlock;

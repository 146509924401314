import { useTranslation } from 'react-i18next';
import { EmployeeProjectTaskDto, ProjectData } from '../../../api/api-types';
import modals from '../../../constants/modals';
import { ModalNames } from '../../../providers/ModalProvider';
import { ModalNameToModalMap } from '../../../components/Modal/types';
import { TeamMemberState } from '../../../components/Modal/TeamMemberModal/types';

type ModalSetupReturn = {
  openAddTeamMemberModal: () => void;
  openEditProjectModal: () => void;
  openEditTeamMemberModal: (data: EmployeeProjectTaskDto) => void;
};

type UseModalSetupProps = {
  onEditMember: (teamMemberState: TeamMemberState) => Promise<void>;
  showModal: <ModalName extends ModalNames>(
    modalName: ModalName,
    payload?: ModalNameToModalMap[ModalName],
  ) => void;
  onAddMember: (teamMemberState: TeamMemberState) => Promise<void>;
  onEditProject: (projectState: ProjectData) => Promise<void>;
};

export const useModalSetup = ({
  onEditMember,
  showModal,
  onAddMember,
  onEditProject,
}: UseModalSetupProps): ModalSetupReturn => {
  const { t } = useTranslation();

  const openEditTeamMemberModal = (data: EmployeeProjectTaskDto) =>
    showModal(modals.addEditProjectTeamMemberModal, {
      data,
      modalTitle: t('modals.titles.team_member'),
      onSave: onEditMember,
    });

  const openEditProjectModal = () =>
    showModal(modals.editProjectModal, {
      onSave: onEditProject,
    });

  const openAddTeamMemberModal = () =>
    showModal(modals.addEditProjectTeamMemberModal, {
      modalTitle: t('modals.titles.new_team_member'),
      onSave: onAddMember as (state: TeamMemberState) => void,
      data: null,
    });

  return {
    openAddTeamMemberModal,
    openEditProjectModal,
    openEditTeamMemberModal,
  };
};

export const modalsEN = {
  titles: {
    edit_client: 'Edit client',
    employee_report_on: "'s detailed report on",
    new_client: 'New client',
    new_project: 'New project',
    new_task: 'New task',
    new_team_member: 'New team member',
    project: 'Project',
    support_sickness_edit: 'Support edit sickness',
    team_member: 'Team member',
    team_task: 'Team task',
  },
  fields: {
    client_name: 'Client name',
    contact_person: 'Contact person',
    customer: 'Customer',
    department: 'Department',
    description: 'Description',
    duration: 'Duration',
    email: 'Email',
    employee_name: 'Employee name',
    end_date: 'End date',
    is_billable: 'Is billable',
    is_included_in_planning: 'Is included in planning',
    location: 'Location',
    name: 'Name',
    overtime_rate: 'Overtime rate',
    parttime_rate: 'Part-time rate',
    phone_number: 'Phone number',
    project_manager: 'Project manager',
    project_name: 'Project name',
    rate_per_hour: 'Rate per hour',
    role: 'Role',
    start_date: 'Start date',
    status: 'Status',
    support_edit_sickness_txt:
      'You can edit only the note and the attachment for the sickness range. For adding or changing attachment please select new file, then click submit. For deleting attachment from some sickness range please click submit without selecting any file.',
    task: 'Task',
    task_name: 'Task name',
    unit: 'Unit',
    working_involvement: 'Working involvement',
    overtime_start_date: 'Overtime start date',
    overtime_end_date: 'Overtime end date',
    employeeFTE: 'Employee FTE',
  },
} as const;

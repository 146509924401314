export const textInputXSSRemoval = (text: string) => {
  const xssRegexPt1 = '(<(';
  const xssRegexPt2 = '[^>]';
  const xssRegexPt3 = '+)>)';

  const xssRegexPrimaryCheck = new RegExp(
    `${xssRegexPt1}${xssRegexPt2}${xssRegexPt3}`,
    'ig',
  );
  return xssRegexPrimaryCheck.test(text) ? '' : text;
};

export const formatExchangeValue = (
  str: string,
  containerClassName: string,
  valueClassName: string,
  currencyClassName = '',
) => {
  const arrFromStr = str.split(' ');
  return (
    <div className={containerClassName}>
      <span className={valueClassName}>{arrFromStr[0]}</span>
      {currencyClassName ? (
        <span className={currencyClassName}>{`${arrFromStr[1]}`}</span>
      ) : (
        arrFromStr[1]
      )}
      <span className={valueClassName}>{` ${arrFromStr[2]} `}</span>
      <span className={valueClassName}>{arrFromStr[3]}</span>
      {currencyClassName ? (
        <span className={currencyClassName}>{`${arrFromStr[4]}`}</span>
      ) : (
        arrFromStr[4]
      )}
    </div>
  );
};

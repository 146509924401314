import {
  TaskNameOptionsHandlerProps,
  TaskNameOptionsHandlerReturn,
} from '../types';

export const taskNameOptionsHandler = ({
  setTeamMemberState,
  taskOptions,
  teamMemberState,
}: TaskNameOptionsHandlerProps): TaskNameOptionsHandlerReturn => {
  const taskNameChangeHandler = (e: { value: number }) =>
    setTeamMemberState((prevState) => ({
      ...prevState,
      taskId: e.value,
    }));

  const taskNameValue = taskOptions.find(
    ({ value }) => value === teamMemberState.taskId,
  ) || { value: null, label: '' };

  return { taskNameChangeHandler, taskNameValue };
};

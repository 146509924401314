import { DepartmentDto, EmployeeTask } from '../../api/api-types';
import { GET_DEPARTMENTS, GET_TASKS, GET_TASK_ID } from '../actions';

export const getTasksDataAction = (data: EmployeeTask[]) =>
  GET_TASKS({ payload: data });

export const getDepartmentsAction = (data: DepartmentDto[]) =>
  GET_DEPARTMENTS({ payload: data });

export const getTaskIdAction = (id: string) => GET_TASK_ID({ payload: id });

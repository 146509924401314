import { ReactComponent as PenIcon } from '../../../../icons/PenIcon.svg';
import { ReactComponent as TrashBinIcon } from '../../../../icons/TrashBinIcon.svg';
import styles from '../../../../styles/sickness.module.scss';
import Button from '../../../../components/Button/Button';
import { formatDate, isDeletable } from './../utils';
import { FC } from 'react';
import { ButtonsBlockProps } from '../types';

const ButtonsBlock: FC<ButtonsBlockProps> = ({
  handleConfirmPopUp,
  row,
  setDraftToEdit,
}) => {
  const formattedStartDate = formatDate(row.original.startPeriod || '');
  const deletable = isDeletable(formattedStartDate);

  return (
    <div className='innerBtnWrapper'>
      <div className={styles.buttonArea}>
        <Button icon={<PenIcon />} onClick={setDraftToEdit} />
      </div>
      {deletable && (
        <div className={styles.buttonArea}>
          <Button
            icon={<TrashBinIcon />}
            onClick={() => handleConfirmPopUp(true, row.original.ids)}
          />
        </div>
      )}
    </div>
  );
};

export default ButtonsBlock;

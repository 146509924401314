import { Dispatch, SetStateAction } from 'react';
import { TimeLogDto } from '../../../../api/api-types';

export const descriptionChangeHandler = (
  setTimeLogState: Dispatch<SetStateAction<TimeLogDto>>,
) => {
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setTimeLogState((state) => ({
      ...state,
      note: e.target.value,
    }));
  };

  return handleDescriptionChange;
};

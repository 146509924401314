import { Msg, SET_TEXT } from '../../../store/actions';
import { formatCurrentToISO } from '../../../utils/date-formatting';

export const useMonthChangeHandler =
  (
    updateVacationList: (selectedDate: string) => Promise<void>,
    setHandleValues: React.Dispatch<Msg>,
  ) =>
  (month: string | number | Date) => {
    const formattedMonth = formatCurrentToISO(month)
      .split('-')
      .slice(0, 2)
      .join('-');
    setHandleValues(
      SET_TEXT({
        payload: { selectedMonth: formattedMonth },
      }),
    );
    updateVacationList(formattedMonth);
  };

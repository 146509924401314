import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import { pmEmployeeReportColumnsXLS } from './xlsColumns';
import { sortByDefault } from '../utils/filtering';
import { ProjectManagerEmployeeReport } from '../api/api-types';

export const exportPmEmployeeReport = async (
  reportPMEmployee: ProjectManagerEmployeeReport,
  startDate: string,
  endDate: string,
) => {
  const workbook = new Excel.Workbook();
  try {
    const fileName = `${reportPMEmployee.projectManagerFullName?.name} report ${startDate}-${endDate}`;

    const worksheet = workbook.addWorksheet('PM employee report');

    worksheet.columns = pmEmployeeReportColumnsXLS;

    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A6CCF7' },
      };
      cell.font = { bold: true };
    });

    const addSubtotalRow = (employeeName: string, subTotalValue: string) => {
      //TODO: added default data {} to addRow. Needs testing
      const createRow = worksheet.addRow({});
      createRow.getCell('A').value = {
        richText: [{ font: { bold: true }, text: 'Subtotal: ' }],
      };
      createRow.getCell('B').value = {
        richText: [{ font: { bold: true }, text: `${employeeName}` }],
      };
      createRow.getCell('F').value = {
        richText: [{ font: { bold: true }, text: `${subTotalValue}` }],
      };
      createRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E0E0E0' },
        };
      });
      return createRow;
    };

    const createDivider = () => {
      //TODO: added default data {} to addRow. Needs testing
      const divider = worksheet.addRow({});
      divider.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' },
      };
      return divider;
    };

    const addTotalRow = (totalValue: string, rowNumber: number) => {
      const getLastRow = worksheet.getRow(rowNumber + 1);
      getLastRow.getCell('A').value = {
        richText: [{ font: { bold: true }, text: 'Total: ' }],
      };
      getLastRow.getCell('F').value = {
        richText: [{ font: { bold: true }, text: `${totalValue}` }],
      };
      getLastRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E0E0E0' },
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      getLastRow.commit();
    };

    reportPMEmployee?.timeLogsPerProjectAndEmployee?.map((employee) =>
      [
        ...(employee.timeLogs ?? []).map((el) =>
          worksheet.addRow({
            manager: reportPMEmployee.projectManagerFullName?.name,
            employee: reportPMEmployee.fullEmployeeName?.name,
            project: employee.projectName,
            date: el.date,
            task: el.taskName,
            duration: el.duration,
          }),
        ),
        addSubtotalRow(
          reportPMEmployee.fullEmployeeName?.name ?? '',
          employee.subTotal ?? '',
        ),
        createDivider(),
        //TODO: probably not possible to type, as library does not provide normal ways to do so
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
      ].sort((a, b) => sortByDefault(a, b, 'Date')),
    );

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      if (column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 24;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      }
      column.width = maxLength > 24 ? 24 : maxLength;
      column.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    });

    const { lastRow } = worksheet;

    if (lastRow?.number)
      addTotalRow(reportPMEmployee.totalDurationHours ?? '', lastRow.number);

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), `${fileName}.xlsx`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.message;
  } finally {
    workbook.removeWorksheet('PM employee report');
  }
};

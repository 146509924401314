/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getTimeLogDetails } from '../../api/tracking';
import { getBackendReportByEmployee } from '../../api/reports';
import { useModal } from '../../providers/ModalProvider';
import modals from '../../constants/modals';
import {
  clearReportProjectEmployeeAction,
  getReportProjectEmployeeAction,
} from '../../store/actionCreators/reportsActionCreators';
import { parseId } from '../../utils/idParser';
import { handleError } from '../../api/config';
import { UseReportProjectEmployeeReturn } from './types';

export const useReportProjectEmployee = (): UseReportProjectEmployeeReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { reportProjectEmployee, key } = useAppSelector((state) => state);

  const {
    projectId,
    employeeId,
    startDate,
    endDate,
    billable,
    employeeTaskName,
  } = useParams();

  const tableRef = useRef<HTMLTableElement>(null);

  const { showModal } = useModal();

  const parsedProjectId = parseId(projectId);
  const parsedEmployeeId = parseId(employeeId);

  const taskName =
    employeeTaskName === t('projects.listing.value.0') ? '' : employeeTaskName;

  const isInternalAdministration =
    reportProjectEmployee?.projectName === 'Internal Administration';

  const getTimeLogList = async () => {
    if (
      !billable ||
      !endDate ||
      !parsedEmployeeId ||
      !parsedProjectId ||
      !startDate
    )
      return;

    setIsLoading(true);

    try {
      const data = await getBackendReportByEmployee({
        billableTypes: billable,
        employeeId: parsedEmployeeId,
        employeeTaskName: taskName,
        endDate: endDate,
        projectId: parsedProjectId,
        startDate: startDate,
      });

      setIsLoading(false);

      if (!data) return;

      dispatch(getReportProjectEmployeeAction(data));
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // remove any
  const editTimeLogDetails = async (row: any) => {
    try {
      const data = await getTimeLogDetails(row.original.timeLogId);

      if (!data) return;

      const modalTitle = `${reportProjectEmployee.employeeFullNameAndId
        ?.name}${t('modals.titles.employee_report_on')} ${data.project}`;
      const employeeId = reportProjectEmployee.employeeFullNameAndId?.id;

      showModal(modals.editProjectReportModal, {
        data,
        employeeId,
        modalTitle,
        updateTimeLogList: getTimeLogList,
      });
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getTimeLogList();

    return () => {
      dispatch(clearReportProjectEmployeeAction());
    };
  }, [
    billable,
    employeeId,
    employeeTaskName,
    endDate,
    key,
    projectId,
    startDate,
  ]);

  return {
    editTimeLogDetails,
    isInternalAdministration,
    isLoading,
    reportProjectEmployee,
    t,
    tableRef,
  };
};

import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import './confirmation-popup.scss';
import { ReactComponent as CircleSuccess } from '../../icons/CircleSuccess.svg';

type TextModel = {
  title: string;
  subtitle: string;
};

export type ConfirmationPopupSuccessProps = {
  text: TextModel;
};

export const ConfirmationPopupSuccess = (
  props: ConfirmationPopupSuccessProps,
) => (
  <>
    <CircleSuccess className='popup_success-icon' />
    <Dialog.Title className='popup_title'>{props.text.title}</Dialog.Title>
    <p className='popup_description'>{props.text.subtitle}</p>
  </>
);

import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import { taskListXLS } from './xlsColumns';
import { currentDateFormatted } from '../constants/date-values';
import { TaskDto } from '../api/api-types';

export const exportTaskList = async (taskList: TaskDto[]) => {
  const workbook = new Excel.Workbook();

  try {
    const fileName = `Task list ${currentDateFormatted}`;

    const worksheet = workbook.addWorksheet('Tasks');

    worksheet.columns = taskListXLS;

    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A6CCF7' },
      };
      cell.font = { bold: true };
    });

    taskList?.map((item) =>
      worksheet.addRow({
        task: item.name,
        //TODO: description does not exist on TaskDto, check with backend
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        description: item.description,
      }),
    );

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      if (column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 72;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      }
      column.width = maxLength > 72 ? 72 : maxLength;
      column.alignment = {
        vertical: 'middle',
        horizontal: 'left',
        wrapText: true,
      };
    });

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), `${fileName}.xlsx`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.message;
  } finally {
    workbook.removeWorksheet('Tasks');
  }
};

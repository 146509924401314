import { z } from 'zod';
import { ACCRUAL_FILTER_TYPE_VALUES } from '../translations/accrualTypeFilterEN';

export const EditTimelogSchema = z.object({
  id: z.number({ required_error: 'Report ID is required' }),
  eptId: z.number({ required_error: 'Task is required' }),
  duration: z
    .string({ required_error: 'Duration cannot be empty' })
    .transform((val) => {
      const [hours, minutes] = val.split(':');
      return {
        hours: Number(hours),
        minutes: Number(minutes),
      };
    }),
  date: z.coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Date is invalid',
  }),
  note: z.string(),
  billableType: z.enum(ACCRUAL_FILTER_TYPE_VALUES),
});

export type EditTimeLog = z.infer<typeof EditTimelogSchema>;

import { FC } from 'react';

interface CardAdditionalDataProps {
  additionalData: readonly string[];
}

const CardAdditionalData: FC<CardAdditionalDataProps> = ({
  additionalData,
}) => {
  return (
    <>
      <div className='card-additional-data'>
        {additionalData
          .slice(0, 3)
          .map((item, index) => (index !== 2 ? `${item} • ` : item))}
      </div>
      <div className='card-additional-data'>
        {additionalData
          .slice(3, 6)
          .map((item, index) => (index !== 2 ? `${item} • ` : item))}
      </div>
    </>
  );
};

export default CardAdditionalData;

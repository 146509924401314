import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useGetTotalReport from '../../hooks/useGetTotalReport';
import { groupBy } from '../../utils/filtering';
import { decimalToTime } from '../../utils/date-formatting';
import { CLEAR_REPORT_CLIENT, GET_REPORT_CLIENT } from '../../store/actions';
import { HoursByProjectAndEmployee, TotalReportDto } from '../../api/api-types';
import { useClientReportTableData } from './hooks/useClientReportTableData';
import { RootState } from '../../store';
import {
  ClientProjectHours,
  ClientReportTableDataItem,
  RowType,
  UseReportClientReturn,
} from './types';

const ROW_DEPTH = 1;
const TOTAL_OR_CLIENT_REPORT = 'client';
const REPORT_TYPE = 'reportClientData';

export const useReportClient = (): UseReportClientReturn => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef<HTMLTableElement>(null);

  const { endDate, billable, startDate } = useParams<{
    endDate: string;
    billable: string;
    startDate: string;
  }>();

  const { reportClient } = useAppSelector((state: RootState) => state);

  const dispatchGetReportClient = (data: TotalReportDto) =>
    dispatch(GET_REPORT_CLIENT({ payload: data }));

  const dispatchClearReportClient = () =>
    dispatch(CLEAR_REPORT_CLIENT({ payload: reportClient }));

  const { isLoading } = useGetTotalReport({
    getReportAction: dispatchGetReportClient,
    clearReportAction: dispatchClearReportClient,
    totalOrClientReport: TOTAL_OR_CLIENT_REPORT,
    reportType: REPORT_TYPE,
  });

  const groupedByClient = groupBy<HoursByProjectAndEmployee>(
    reportClient.projectCountHoursDtoList ?? [],
    (item) => item.client ?? '',
  );

  const clientReportRows: ClientProjectHours[] = Array.from(
    groupedByClient,
    ([client, projects]) => ({
      client,
      projects,
    }),
  );

  const clientReportTableData = useClientReportTableData({
    clientReportRows,
  });

  const [expandedRowId, setExpandedRowId] = useState<string | null>(
    localStorage.getItem('expandedClient') ?? null,
  );

  const goToEmployee = (row: RowType) => {
    if (row.depth !== 2) return;

    const employeePath = `../by-projects/project${row.original.projectId}/employee${row.original.employeeId}/start${startDate}/end${endDate}/accrual${billable}`;

    navigate(employeePath);
  };

  const allSubRowsExpanded = (expand: boolean): Record<string, boolean> => {
    if (!expandedRowId) return {};

    return {
      [expandedRowId.split('.')[0]]: expand,
      [expandedRowId]: expand,
    };
  };

  const expandByDefault = allSubRowsExpanded(true);

  const totalClientHours = decimalToTime(
    clientReportTableData
      .map((el: ClientReportTableDataItem | null) => el?.totalHours ?? 0)
      .reduce((a, b) => a + b, 0),
  );

  return {
    ROW_DEPTH,
    clientReportTableData,
    expandByDefault,
    goToEmployee,
    isLoading,
    setExpandedRowId,
    tableRef,
    totalClientHours,
  };
};

export const durationRange = () => {
  const values = Array.from({ length: 81 }, (_, index) => {
    const hours = String(Math.floor(index / 4)).padStart(2, '0');
    const minutes = String((index % 4) * 15).padStart(2, '0');
    const label = `${hours}:${minutes}`;
    return { label, value: label };
  }).slice(1, 81);

  return values;
};

export const durationHours = () => {
  const values = Array.from({ length: 22 }, (_, index) => {
    const hours = String(index - 1).padStart(2, '0');
    const label = `${hours}`;
    return { label, value: label };
  }).slice(1, 22);

  return values;
};

export const durationMinutes = () => {
  const values = Array.from({ length: 5 }, (_, index) => {
    const minutes = String((index - 1) * 15).padStart(2, '0');
    const label = `${minutes}`;
    return { label, value: label };
  }).slice(1, 5);

  return values;
};

import { SET_SELECTABLES } from '../../../store/actions';
import { findRegisteredPM } from '../../../utils/filtering';
import { PMFilterProps, PMFilterReturn } from './types';

export const usePMFilter = ({
  clearSelectedProject,
  pmList,
  registeredEmployee,
  selectedPMId,
  selectedPMName,
  setCurrentPage,
  setHandleValues,
  t,
}: PMFilterProps): PMFilterReturn => {
  const pmOptions = [
    {
      id: '',
      label: t('projects.listing.value.0'),
      value: t('projects.listing.value.0'),
    },
    {
      id: registeredEmployee?.id ?? '',
      label: t('projects.listing.value.1'),
      value: t('projects.listing.value.1'),
    },
    {
      id: 0,
      label: t('projects.listing.value.2'),
      value: t('projects.listing.value.2'),
    },
    ...findRegisteredPM(pmList, registeredEmployee?.id).map((pm) => ({
      id: pm.id ?? '',
      label: pm.name ?? '',
      value: pm.name ?? '',
    })),
  ];

  const PMValue = {
    id: selectedPMId || '',
    label: selectedPMName || t('projects.listing.value.0'),
    value: selectedPMName || t('projects.listing.value.0'),
  };

  const PMOnChangeHandler = (e: {
    id: string;
    label: string;
    value: string;
  }) => {
    setHandleValues(
      SET_SELECTABLES({
        payload: { selectedPMId: e.id, selectedPMName: e.value },
      }),
    );

    clearSelectedProject();
    setCurrentPage(0);
  };

  return {
    pmOptions,
    PMValue,
    PMOnChangeHandler,
  };
};

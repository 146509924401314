import BCoinImage from '../../../images/BCoinImage.png';
import BCoinBodyLeftCol from '../components/BCoinBodyLeftCol';

const BCoinWhatIsIt = () => (
  <div className='bcoin-body'>
    <BCoinBodyLeftCol
      description='This is our own corporate token, which you can use within the company and exchange for a variety of gifts'
      descriptionClassName='bcoin-what-is-it-description'
      subtitle='b-coin 3.0'
      title='Meet'
    />
    <div className='bcoin-body-right-col'>
      <div className='right-col-animated'>
        <div className='bcoin-icon-wrapper'>
          <img src={BCoinImage} alt='b-coin' />
        </div>
      </div>
    </div>
  </div>
);

export default BCoinWhatIsIt;

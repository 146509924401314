import SimpleSelect from '../../../../components/Select/SimpleSelect';
import commonStyles from '../../../../styles/pm-flow.module.scss';
import useHeader from './useHeader';
import { HeaderProps } from './types';
import { Link } from 'react-router-dom';
import { controlsListingStyle } from '../../../../components/Select/selectStyles';
import { useTranslation } from 'react-i18next';

const Header: React.FC<HeaderProps> = ({
  clearSelectedStatus,
  clientId,
  clientListAllSorted,
  clientName,
  clientPage,
  id,
  setHandleValues,
}) => {
  const { t } = useTranslation();

  const { options, value, handleSelectChange } = useHeader({
    clientListAllSorted,
    clientId,
    clientName,
    setHandleValues,
    clearSelectedStatus,
  });

  return (
    <div className={commonStyles.header}>
      <h2 className={commonStyles.heading}>
        <Link to='/clients'>{t('clients.title')}</Link>
        {id ? ` > ${clientPage?.clientName || ''}` : ''}
      </h2>
      {!id && (
        <SimpleSelect
          key='key-report-3'
          onChange={handleSelectChange}
          options={options}
          selectTitle={t('clients.select_client') as string}
          selectTitleClassName='select_title'
          selectWrapperClass='select_vertical_wrapper'
          styles={controlsListingStyle}
          value={value}
        />
      )}
    </div>
  );
};

export default Header;

import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import Loader from '../../components/Loader/Loader';
import PMReportList from '../../components/PMReportList/PMReportList';
import { useReportPMAll } from './useReportPMAll';

const ReportPMAll = () => {
  const { reportPMAll, isLoading } = useReportPMAll();

  return (
    <>
      {reportPMAll ? (
        <PMReportList reportPMAll={reportPMAll} />
      ) : (
        <EmptyDataBlock />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default ReportPMAll;

import { deleteTimeLogRange, getTimeLog } from '../../../api/tracking';
import { setLoaderAction } from '../../../store/actionCreators/loaderActionCreator';
import { getTimeTrackedDataAction } from '../../../store/actionCreators/timeTrackingActionCreators';
import { UseTableRowDeletionProps } from '../types';

const useTableRowDeletion = ({
  dispatch,
  handleApprovingOfConfirmPopUp,
  handleConfirmPopUp,
  selectedCurrentDate,
  setHandleValues,
}: UseTableRowDeletionProps) => {
  const handleTableRowDeleting = async (id: number) => {
    await deleteTimeLogRange([`${id}`]);

    if (selectedCurrentDate.current) {
      setHandleValues(setLoaderAction(true));

      try {
        const data = await getTimeLog({ date: selectedCurrentDate.current });

        if (!data) return;

        dispatch(getTimeTrackedDataAction(data));
      } finally {
        setHandleValues(setLoaderAction(false));
        handleApprovingOfConfirmPopUp(false);
        handleConfirmPopUp(false);
      }
    }
  };

  return { handleTableRowDeleting };
};

export default useTableRowDeletion;

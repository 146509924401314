import getDay from 'date-fns/getDay';
import { getTimeLog } from '../api/tracking';
import { AppDispatch } from '../store';
import { Msg } from '../store/actions';

export const addBusinessDays = (currentDate: string, amountOfDays: number) => {
  let date = new Date(currentDate);
  date = new Date(date.getTime());
  const day = date.getDay();
  date.setDate(
    date.getDate() +
      amountOfDays +
      (day === 6 ? 2 : +!day) +
      Math.floor((amountOfDays - 1 + (day % 6 || 1)) / 5) * 2,
  );
  return date;
};

export const clearDates = (setState: React.Dispatch<Msg>) => {
  setState({ type: 'SET_START_DATE', payload: { startDate: '' } });
  setState({ type: 'SET_END_DATE', payload: { endDate: '' } });
};

export type SelectedDate = Date | null;

export const dateRangeHandler = (
  setState: React.Dispatch<Msg>,
  selected: [SelectedDate, SelectedDate],
) => {
  setState({ type: 'SET_START_DATE', payload: { startDate: '' } });
  setState({ type: 'SET_END_DATE', payload: { endDate: '' } });
  const [start, end] = selected;
  if (start) {
    setState({
      type: 'SET_START_DATE',
      payload: { startDate: start.toUTCString() },
    });
  }
  if (end) {
    setState({ type: 'SET_END_DATE', payload: { endDate: end.toUTCString() } });
  }
};

export const dateReducerHandler = (
  setState: React.Dispatch<Msg>,
  selected: Date | null,
) => {
  if (!selected) return;
  setState({ type: 'SET_DATE_VALUE', payload: { currentDate: null } });

  setState({
    type: 'SET_DATE_VALUE',
    payload: { currentDate: selected.toUTCString() },
  });
};

export const dateArrayReducerHandler = (
  setState: React.Dispatch<Msg>,
  selectedDates: Date[] | null,
) => {
  if (!selectedDates || selectedDates.length === 0) return;

  setState({ type: 'SET_DATE_VALUES_ARRAY', payload: { currentDates: [] } });

  setState({
    type: 'SET_DATE_VALUES_ARRAY',
    payload: { currentDates: selectedDates.map((date) => date.toUTCString()) },
  });
};

export const dateReducerHandlerKey = (
  setState: React.Dispatch<Msg>,
  pickedDate: Date | null,
  key: string,
) => {
  if (pickedDate) {
    setState({
      type: 'SET_DATE_VALUE',
      payload: { [key]: pickedDate.toUTCString() },
    });
  }
};

export const getDateDifference = (endDate: string) => {
  const dateFirst = new Date();
  const dateSecond = new Date(endDate);

  const difference = dateFirst.getTime() - dateSecond.getTime();
  const dateDifference = Math.ceil(difference / (1000 * 3600 * 24));
  return dateDifference;
};

export const getCurrentWeek = (date: string) => {
  const currentDate = new Date(date);
  const currentYear = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor(
    (currentDate.getTime() - currentYear.getTime()) / (24 * 60 * 60 * 1000),
  );

  const weekNumber = Math.ceil(days / 7);

  return weekNumber;
};

export const getCurrentMonth = (date: string) => {
  const dateParts = date.split('-');
  const month = Number(dateParts[1]);
  const year = Number(dateParts[0]);
  const selectedDate = new Date();
  const currentMonth = selectedDate.getMonth() + 1;
  const currentYear = selectedDate.getFullYear();

  if (month === currentMonth && year === currentYear) {
    return true;
  }

  return false;
};

export const filterWeekends = (date: Date | number) => {
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

export const onMonthChange = (dispatch: AppDispatch, month: Date) => {
  getTimeLog({ date: month.toISOString().split('T')[0] }).then((data) => {
    if (!data) return;
    dispatch({
      type: 'GET_TIME_TRACKED_DATA',
      payload: data,
    });
  });
};

export const sickTodayHandler = (
  setState: React.Dispatch<Msg>,
  currentDate: string,
) => {
  setState({ type: 'SET_START_DATE', payload: { startDate: currentDate } });
  setState({ type: 'SET_END_DATE', payload: { endDate: currentDate } });
};

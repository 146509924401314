import { useAuth0 } from '@auth0/auth0-react';
import { namespace } from '../constants/text-values';
import { findRoles } from '../utils/filtering';

const rolesToAccessBillable = [
  'Accountant',
  'Delivery Manager',
  'Head of Accountant',
  'Head of Delivery',
  'Project Manager',
] as const;

const useRolesToAccessBillable = () => {
  const { user } = useAuth0();
  if (!user) return false;
  const extendedRole = findRoles(
    user[`${namespace}/roles`],
    rolesToAccessBillable,
  );

  return extendedRole;
};

export default useRolesToAccessBillable;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/vacation.module.scss';
import { formatToDDMMYYYY } from '../../utils/date-formatting';

interface VacationData {
  periodStart?: string;
  periodEnd?: string;
  annulationDate?: string;
  lastUpdate?: string;
  accumulatedDays?: number;
  burnDays?: number;
}

interface TextBlockProps {
  vacationData: VacationData;
}

const TextBlock: FC<TextBlockProps> = ({ vacationData }) => {
  const { t } = useTranslation();

  const formatDateRange = (start?: string, end?: string) =>
    start && end ? `${formatToDDMMYYYY(start)} - ${formatToDDMMYYYY(end)}` : '';

  const formatNumber = (number?: number, fallback: number = 0) =>
    number?.toFixed(1) || fallback;

  const renderVacationItem = (
    titleKey: string,
    content: React.ReactNode,
    isRed?: boolean,
  ) => (
    <div className={styles.item}>
      <div className={styles.itemTitle}>{t(titleKey)}</div>
      <div className={isRed ? styles.itemTxtRed : styles.itemTxtRegular}>
        {content}
      </div>
    </div>
  );

  return (
    <div className={styles.itemWrapper}>
      <div className={styles.itemWrapperCol}>
        {renderVacationItem(
          'vacation.vacation_data.estimation_period',
          formatDateRange(vacationData.periodStart, vacationData.periodEnd),
        )}
        {renderVacationItem(
          'vacation.vacation_data.annulation_date',
          vacationData.annulationDate
            ? formatToDDMMYYYY(vacationData.annulationDate)
            : 0,
          true,
        )}
        {renderVacationItem(
          'vacation.vacation_data.last_update',
          vacationData.lastUpdate
            ? formatToDDMMYYYY(vacationData.lastUpdate)
            : '',
        )}
      </div>
      <div className={styles.itemWrapperCol}>
        {renderVacationItem(
          'vacation.vacation_data.accumulated_days',
          formatNumber(vacationData.accumulatedDays),
        )}
        {renderVacationItem(
          'vacation.vacation_data.days_to_burn',
          formatNumber(vacationData.burnDays),
          true,
        )}
      </div>
    </div>
  );
};

export default TextBlock;

import { ReportHeaderProps } from '../types';

const getClientReportHeader = ({
  billable,
  clientId,
  employeeId,
  endDate,
  location,
  reportClient,
  startDate,
  t,
}: ReportHeaderProps) => {
  const client = reportClient?.projectCountHoursDtoList?.[0]?.client;
  const employee =
    reportClient?.projectCountHoursDtoList?.[0]?.employeeFullName?.name;

  const basePath = '/reports/by-client';
  const path = location.pathname;
  const subtitle = ` > ${t('reports.subtitle.client_report')}`;

  const conditions: { condition: boolean; path: string }[] = [
    {
      condition:
        path ===
        `${basePath}/start${startDate}/accrual${billable}/end${endDate}`,
      path: subtitle,
    },
    {
      condition:
        path ===
        `${basePath}/client${clientId}/start${startDate}/accrual${billable}/end${endDate}`,
      path: `${subtitle}${client ? `/${client}` : ''}`,
    },
    {
      condition:
        path ===
        `${basePath}/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`,
      path: `${subtitle}${employee ? `/${employee}` : ''}`,
    },
    {
      condition:
        path ===
        `${basePath}/client${clientId}/employee${employeeId}/start${startDate}/accrual${billable}/end${endDate}`,
      path: `${subtitle}${client ? `/${client}` : ''}${
        employee ? `/${employee}` : ''
      }`,
    },
  ];

  const result = conditions.find((cond) => cond.condition)?.path || null;
  
  return result;
};

export default getClientReportHeader;

import React from 'react';

const SearchIcon = () => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7 0C8.72391 0 10.3772 0.684819 11.5962 1.90381C12.8152 3.12279 13.5 4.77609 13.5 6.5C13.5 8.11 12.91 9.59 11.94 10.73L12.21 11H13L18 16L16.5 17.5L11.5 12.5V11.71L11.23 11.44C10.09 12.41 8.61 13 7 13C5.27609 13 3.62279 12.3152 2.40381 11.0962C1.18482 9.87721 0.5 8.22391 0.5 6.5C0.5 4.77609 1.18482 3.12279 2.40381 1.90381C3.62279 0.684819 5.27609 0 7 0ZM7 2C4.5 2 2.5 4 2.5 6.5C2.5 9 4.5 11 7 11C9.5 11 11.5 9 11.5 6.5C11.5 4 9.5 2 7 2Z'
      fill='black'
    />
  </svg>
);

export default SearchIcon;

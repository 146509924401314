import { ModalNameToModalMap } from '../components/Modal/types';

const modals = Object.freeze({
  addEmployeeTimeLogModal: 'addEmployeeTimeLogModal',
  editProjectReportModal: 'editProjectReportModal',
  editProjectModal: 'editProjectModal',
  addEditProjectTeamMemberModal: 'addEditProjectTeamMemberModal',
  addEditClientModal: 'addEditClientModal',
});

export default modals;

export type ModalTypes = {
  addEmployeeTimeLogModal: keyof ModalNameToModalMap;
  editProjectReportModal: keyof ModalNameToModalMap;
  editProjectModal: keyof ModalNameToModalMap;
  addEditProjectTeamMemberModal: keyof ModalNameToModalMap;
  addEditClientModal: keyof ModalNameToModalMap;
};

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useInspectItem from '../../hooks/useInspectItem';
import { getClientPageData, getProjectByClientId } from '../../api/customers';
import { ClientColumn, UseClientPageReturn } from './types';
import { getClientDataAction } from '../../store/actionCreators/customerActionCreators';

export const useClientPage = (): UseClientPageReturn => {
  const { id } = useParams();
  const { clientPage, projectsByClientID, triggerUpdate } = useAppSelector(
    (state) => state,
  );
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const clientId = id ? parseInt(id) : null;
  const dispatch = useAppDispatch();
  const { goToItemDetails } = useInspectItem('clients', '/projects/');

  const clientColumns: ClientColumn[] = useMemo(
    () => [
      {
        Header: t('columns.project_name') || undefined,
        accessor: 'projectName',
      },
      {
        Header: t('columns.start_date') || undefined,
        accessor: 'startDate',
      },
      {
        Header: t('columns.project_manager') || undefined,
        accessor: 'managerName',
      },
    ],
    [],
  );

  const fetchData = async (id: number) => {
    const clientData = getClientPageData({ id });
    const projectData = getProjectByClientId({ id });

    const [clientResponse, projectResponse] = await Promise.all([
      clientData,
      projectData,
    ]);

    if (clientResponse) {
      dispatch(getClientDataAction(clientResponse));
    }

    if (projectResponse) {
      dispatch({ type: 'GET_PROJECTS_BY_CLIENT_ID', payload: projectResponse });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (clientId) {
      setIsLoading(true);
      fetchData(clientId);
    }
  }, [clientId, triggerUpdate]);

  return {
    clientColumns,
    clientPage,
    goToItemDetails,
    isLoading,
    projectsByClientID,
  };
};

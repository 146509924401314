const capitalize = (key: string) =>
  `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

export const createColumns = (arr: string[]) =>
  arr.map((el) => ({
    header: `${el.split(' ').map(capitalize).join(' ')}`,
    key: el,
  }));

export const customerListXLS = createColumns(['customer']);

export const pmEmployeeReportColumnsXLS = createColumns([
  'manager',
  'employee',
  'project',
  'date',
  'task',
  'duration',
]);

export const pmReportColumnsXLS = createColumns([
  'manager',
  'employee',
  'project',
  'duration',
]);

export const projectEmployeeReportColumnsXLS = createColumns([
  'date',
  'employee',
  'client',
  'project',
  'task',
  'duration',
  'note',
]);

export const projectListXLS = createColumns([
  'project',
  'customer name',
  'project manager',
  'start date',
  'end date',
]);

export const projectReportColumnsXLS = createColumns([
  'date',
  'user',
  'client',
  'project',
  'task',
  'duration',
  'note',
]);

export const taskListXLS = createColumns(['task', 'description']);

export const totalReportXLS = createColumns([
  'customer',
  'employee',
  'project',
  'billable hours',
  'non-billable hours',
  'total hours',
]);

import { durationRange } from '../../../../utils/createDuration';
import {
  DurationSelectHandlerProps,
  DurationSelectHandlerReturn,
} from '../types';

export const durationSelectHandler = ({
  timeLogState,
  setTimeLogState,
}: DurationSelectHandlerProps): DurationSelectHandlerReturn => {
  const durationOptions = durationRange();

  const handleDurationChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setTimeLogState((prevState) => ({
      ...prevState,
      duration: selectedOption.value,
    }));
  };

  const durationValue = {
    label: timeLogState.duration,
    value: timeLogState.duration,
  };

  return {
    durationOptions,
    handleDurationChange,
    durationValue,
  };
};

export const formFieldsEN = {
  attach_msg: 'Please attach the file',
  characters_left: 'characters left',
  duration: 'Duration',
  edit_log: 'Edit time log',
  left: 'Left',
  note: 'Note',
  paid_sickness_days: 'Paid sickness days',
  project: 'Project',
  task: 'Task',
  vacation_days: 'Vacation days',
  billable_type: 'Billable type',
} as const;

import { parse, differenceInMonths, differenceInDays } from 'date-fns';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { VacationList } from '../../../api/api-types';
import { TranslationFunction } from '../../../components/type-helpers/translation';

export const handleAnnulationDateWarnings = (
  annulationDate: VacationList['annulationDate'],
  daysLeft: VacationList['daysLeft'],
  t: TranslationFunction,
) => {
  if (!annulationDate || daysLeft === 0) return;

  const parsedAnnulationDate = parse(annulationDate, 'dd.MM.yyyy', new Date());

  const monthsDifference = differenceInMonths(parsedAnnulationDate, new Date());
  const daysDifference = differenceInDays(parsedAnnulationDate, new Date());

  const cookiesExpirationDays = daysDifference + 1; // cookies will expire in one day after the vacation annulation date

  const cookies = Cookies.get();
  const is2MonthToastShown = cookies['toast-2-month'];
  const is14DayToastShown = cookies['toast-14-days'];

  if (monthsDifference < 2 && !is2MonthToastShown && daysDifference > 14) {
    toast.warn(t('warning.less_than_2_months'), {
      autoClose: false,
    });
    Cookies.set('toast-2-month', 'true', { expires: cookiesExpirationDays });
  }

  if (daysDifference <= 14 && !is14DayToastShown) {
    toast.warn(t('warning.less_than_14_days'), {
      autoClose: false,
    });
    Cookies.set('toast-14-days', 'true', { expires: cookiesExpirationDays });
  }
};

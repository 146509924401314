import React from 'react';
import styles from './styles/warning-modal.module.scss';
import Modal from './Modal';
import { ConfirmButtons } from '../ConfirmationButtons/ConfirmationButtons';

export type WarningModalProps = {
  buttons: ConfirmButtons;
  onSave: () => void;
  onClose: () => void;
  warning: string;
};

const WarningModal = ({
  buttons,
  onSave,
  onClose,
  warning,
}: WarningModalProps) => (
  <div className={styles.container}>
    <Modal buttons={buttons(onClose, onSave)} styles={styles}>
      <p>{warning}</p>
    </Modal>
  </div>
);

export default WarningModal;

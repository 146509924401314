import { getTask } from '../../../api/tasks';
import { getTasksDataAction } from '../../../store/actionCreators/tasksActionCreators';
import { SET_LOADER } from '../../../store/actions';
import { createUpdateTaskListProps } from '../types';

export const createUpdateTaskList = ({
  dispatch,
  selectedStatus,
  setHandleValues,
}: createUpdateTaskListProps) => {
  return async () => {
    setHandleValues(SET_LOADER({ payload: { isLoading: true } }));

    try {
      const data = await getTask({
        status: selectedStatus,
      });

      if (!data) return;

      dispatch(getTasksDataAction(data));
    } catch (error) {
      console.error('Error while getting tasks', error);
    } finally {
      setHandleValues(SET_LOADER({ payload: { isLoading: false } }));
    }
  };
};

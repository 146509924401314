import { useTranslation } from 'react-i18next';
import React from 'react';
import commonStyles from '../../../styles/pm-flow.module.scss';

const NoItemsWarning = () => {
  const { t } = useTranslation();

  return <p className={commonStyles.noItems}>{t('warning.no_clients_data')}</p>;
};

export default NoItemsWarning;

import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export const useMultiDateToolTip = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [canShowTooltip, setCanShowTooltip] = useState(true);

  let tooltipTimeout: NodeJS.Timeout;

  useEffect(() => {
    const tooltipHidden = Cookies.get('hideTooltip');
    const sessionTooltipHidden = sessionStorage.getItem('hideTooltip');

    if (sessionTooltipHidden || tooltipHidden) {
      setCanShowTooltip(false);
    }
  }, []);

  const handleTooltipMouseEnter = () => {
    if (!canShowTooltip) return;

    clearTimeout(tooltipTimeout);
    tooltipTimeout = setTimeout(() => {
      setIsTooltipVisible(true);
    }, 500);
  };

  const handleTooltipMouseLeave = () => {
    clearTimeout(tooltipTimeout);
    tooltipTimeout = setTimeout(() => {
      setIsTooltipVisible(false);
    }, 500);
  };

  const handleTooltipHover = () => {
    clearTimeout(tooltipTimeout);
  };

  const handleDontShowAgainClick = () => {
    Cookies.set('hideTooltip', 'true', { expires: 365 });
    setIsTooltipVisible(false);
    setCanShowTooltip(false);
  };

  const handleOkClick = () => {
    sessionStorage.setItem('hideTooltip', 'true');
    setIsTooltipVisible(false);
    setCanShowTooltip(false);
  };

  return {
    isTooltipVisible,
    handleTooltipHover,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    handleOkClick,
    handleDontShowAgainClick,
    canShowTooltip,
  };
};

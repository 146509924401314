import { CustomerRequestDto } from '../../../../api/api-types';
import { UseCreateClientProps } from '../types';

export const useCreateClient = ({
  updateClientsList,
  clientState,
  createClient,
  closeModal,
}: UseCreateClientProps) => {
  const onCreateClient = async () => {
    if (typeof updateClientsList !== 'function') return;

    const body = {
      clientName: clientState.clientName,
      unitId: clientState.unitId,
      customerContactPersonRequestDtos: clientState.contactPersons
        .filter(
          ({ name, phoneNumber, emails }) =>
            name || phoneNumber || emails.some(({ email }) => email),
        )
        .map(({ emails, name, phoneNumber }) => ({
          emailRequestSet: emails
            .filter(({ email }) => email)
            .map(({ email = '' }) => email),
          name,
          phoneNumber,
        })),
      location: clientState.locationDtoSet
        .filter(({ name }) => name)
        .map(({ name }) => name),
    } as CustomerRequestDto;

    try {
      await createClient({ body });

      closeModal();
      updateClientsList();
    } catch (error) {
      console.error('Failed to create client:', error);
    }
  };
  return onCreateClient;
};

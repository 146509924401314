export const accrualTypeFilterEN = {
  title: 'Accrual type',
  value: {
    ALL: 'All',
    BILLABLE: 'Billable',
    INTERNAL_BILLABLE: 'Internal billable',
    NON_BILLABLE: 'Non-Billable',
  },
};

export const ACCRUAL_FILTER_TYPE = {
  ALL: '',
  BILLABLE: 'BILLABLE',
  INTERNAL_BILLABLE: 'INTERNAL_BILLABLE',
  NON_BILLABLE: 'NON_BILLABLE',
} as const;

export const ACCRUAL_FILTER_TYPE_VALUES = [
  'BILLABLE',
  'INTERNAL_BILLABLE',
  'NON_BILLABLE',
] as const;

export type AccrualFilterType = typeof ACCRUAL_FILTER_TYPE;
export type AccrualFilterKeys = keyof AccrualFilterType;
export type AccrualFilter = AccrualFilterType[AccrualFilterKeys];
export type AccrualFilterSingular = (typeof ACCRUAL_FILTER_TYPE_VALUES)[number];

import { useTranslation } from 'react-i18next';
import styles from '../../styles/vacation.module.scss';
import { VacationList } from '../../api/api-types';

type FormInfoProps = {
  vacationData: VacationList;
};

const FormInfo = (props: FormInfoProps) => {
  const { vacationData } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.circularProgressBarInfo}>
      <div className={styles.circularProgressBarTitle}>
        {t('form.vacation_days')}
      </div>
      <div className={styles.circularProgressBarValue}>
        {`${t('form.left')} ${
          Number(vacationData?.daysLeft ?? 0).toFixed(1) || 0
        }/${vacationData?.maximumDays || 0}`}
      </div>
    </div>
  );
};

export default FormInfo;

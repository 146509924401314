import { GroupBase, StylesConfig } from 'react-select';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const controlsStatusStyle: StylesConfig<any, boolean, GroupBase<any>> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
  container: (base) => ({
    ...base,
    width: 'max-content',
  }),
  control: (base) => ({
    ...base,
    width: '8.75rem',
    minHeight: 0,
    background: '#FAFDFF',
    borderRadius: 0,
    height: '1.875rem',
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
    flexWrap: 'nowrap',
  }),
  menu: (base) => ({
    ...base,
    fontSize: 13,
    width: '8.75rem',
    borderRadius: 0,
    marginTop: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#171717' : '#737373',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
    height: '1.5rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  input: (provided) => ({
    ...provided,
    height: '1.5rem',
    margin: '0',
    padding: '0',
    minWidth: '50px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13,
    color: '#171717',
  }),
};

export const controlsListingStyle: StylesConfig<
  any,
  boolean,
  GroupBase<any>
> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
  container: (base) => ({
    ...base,
    width: 'max-content',
  }),
  control: (base) => ({
    ...base,
    width: '16rem',
    minHeight: 0,
    background: '#FAFDFF',
    borderRadius: 0,
    height: '1.875rem',
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
    flexWrap: 'nowrap',
  }),
  menu: (base) => ({
    ...base,
    fontSize: 13,
    width: '16rem',
    borderRadius: 0,
    marginTop: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#171717' : '#737373',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
    height: '1.5rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 13,
    color: '#171717',
  }),
  input: (provided) => ({
    ...provided,
    height: '1.5rem',
    margin: '0',
    padding: '0',
    minWidth: '50px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13,
    color: '#171717',
  }),
};

export const selectPMModalMain: StylesConfig<any, boolean, GroupBase<any>> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    color: '#171717',
    padding: '0 2px 2px 2px',
  }),
  control: (base, state) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 14,
    width: '12rem',
    height: '1.5rem',
    minHeight: 0,
    background: state.isFocused ? '#FFFFF' : '#E7EBF1',
    borderRadius: 2,
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
  }),
  menu: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 13,
    borderRadius: 0,
    marginTop: 1,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '200px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#171717' : '#737373',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  placeholder: (base) => ({
    ...base,
    display: 'none',
    color: '#171717',
  }),
  input: (base) => ({
    ...base,
    padding: 0,
    color: '#171717',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
  }),
};

export const selectProjectStatus: StylesConfig<any, boolean, GroupBase<any>> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    color: '#171717',
    padding: '0 2px 2px 2px',
  }),
  control: (base, state) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 14,
    width: '7rem',
    height: '1.5rem',
    minHeight: 0,
    background: state.isFocused ? '#FFFFF' : '#E7EBF1',
    borderRadius: 2,
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
  }),
  menu: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 13,
    borderRadius: 0,
    marginTop: 1,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#171717' : '#737373',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  placeholder: (base) => ({
    ...base,
    display: 'none',
    color: '#171717',
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
    color: '#171717',
  }),
};

export const timeTrackingLongStyle: StylesConfig<
  any,
  boolean,
  GroupBase<any>
> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
  container: (base) => ({
    ...base,
    width: 'max-content',
  }),
  control: (base) => ({
    ...base,
    '@media only screen and (max-width: 780px)': {
      width: '18rem',
    },
    '@media only screen and (min-width: 781px) and (max-width: 960px)': {
      width: '24.2rem',
    },
    '@media only screen and (min-width: 961px)': {
      width: '17rem',
    },
    minHeight: 0,
    background: '#FFFFFF',
    borderRadius: 0,
    height: '2.25rem',
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
    flexWrap: 'nowrap',
  }),
  menu: (base) => ({
    ...base,
    fontSize: 13,
    '@media only screen and (max-width: 780px)': {
      width: '18rem',
    },
    '@media only screen and (min-width: 781px) and (max-width: 960px)': {
      width: '24.2rem',
    },
    '@media only screen and (min-width: 961px)': {
      width: '17rem',
    },
    borderRadius: 0,
    marginTop: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#171717' : '#737373',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
    height: '1.5rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 13,
    color: '#171717',
  }),
  input: (provided) => ({
    ...provided,
    height: '1.5rem',
    margin: '0',
    padding: '0',
    minWidth: '50px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13,
    color: '#171717',
  }),
};

export const timeTrackingShortStyle: StylesConfig<
  any,
  boolean,
  GroupBase<any>
> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
  container: (base) => ({
    ...base,
    width: 'max-content',
  }),
  control: (base) => ({
    ...base,
    '@media only screen and (max-width: 780px)': {
      width: '8.5rem',
    },
    '@media only screen and (min-width: 781px) and (max-width: 960px)': {
      width: '11.6rem',
    },
    '@media only screen and (min-width: 961px)': {
      width: '8rem',
    },
    width: '8rem',
    minHeight: 0,
    background: '#FFFFFF',
    borderRadius: 0,
    height: '2.25rem',
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
    flexWrap: 'nowrap',
  }),
  menu: (base) => ({
    ...base,
    fontSize: 13,
    '@media only screen and (max-width: 780px)': {
      width: '8.5rem',
    },
    '@media only screen and (min-width: 781px) and (max-width: 960px)': {
      width: '11.6rem',
    },
    '@media only screen and (min-width: 961px)': {
      width: '8rem',
    },
    borderRadius: 0,
    marginTop: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#D3D3D3' : '#171717',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
    height: '1.5rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 13,
    color: '#171717',
  }),
  input: (provided) => ({
    ...provided,
    height: '1.5rem',
    margin: '0',
    padding: '0',
    minWidth: '50px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13,
    color: '#171717',
  }),
};

export const controlsDayOffStyle: StylesConfig<any, boolean, GroupBase<any>> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
  container: (base) => ({
    ...base,
    width: 'max-content',
  }),
  control: (base) => ({
    ...base,
    width: '10rem',
    minHeight: 0,
    background: '#FAFDFF',
    borderRadius: 0,
    height: '1.875rem',
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
    flexWrap: 'nowrap',
  }),
  menu: (base) => ({
    ...base,
    fontSize: 13,
    width: '10rem',
    borderRadius: 0,
    marginTop: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#D3D3D3' : '#171717',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
    height: '1.5rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 13,
    color: '#171717',
  }),
  input: (provided) => ({
    ...provided,
    height: '1.5rem',
    margin: '0',
    padding: '0',
    minWidth: '50px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13,
    color: '#171717',
  }),
};

export const multiSelect: StylesConfig<any, boolean, GroupBase<any>> = {
  control: (base) => ({
    ...base,
    width: '16rem',
    height: 'fit-content',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'fit-content',
  }),
  input: (provided) => ({
    ...provided,
    height: 'fit-content',
  }),
};

export const selectNewClientModal: StylesConfig<
  any,
  boolean,
  GroupBase<any>
> = {
  dropdownIndicator: (provided, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    color: '#171717',
    padding: '0 2px 2px 2px',
  }),
  control: (base, state) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 14,
    width: '240px',
    height: '1.5rem',
    minHeight: 0,
    background: state.isFocused ? '#FFFFF' : '#E7EBF1',
    borderRadius: 2,
    border: '0.3px solid rgba(140, 159, 195, 0.29)',
  }),
  menu: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: 13,
    borderRadius: 0,
    marginTop: 1,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '200px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#171717' : '#737373',
    background: state.isSelected ? '#DEEBFF' : 'transparent',
    margin: 0,
  }),
  placeholder: (base) => ({
    ...base,
    display: 'none',
    color: '#171717',
  }),
  input: (base) => ({
    ...base,
    padding: 0,
    color: '#171717',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
  }),
};

import { ZodError } from 'zod';
import { TimeLogDto } from '../../../../api/api-types';
import { putTimeLog } from '../../../../api/tracking';
import { EditTimelogSchema } from '../../../../validation/timelog';
import { handleOneLevelZodError } from '../../../../validation/utils';
import { toast } from 'react-toastify';
import {
  UseUpdateEditedTimelogProps,
  UseUpdateEditedTimelogReturn,
} from '../types';

export const useUpdateEditedTimelog = ({
  updateTimeLogList,
  closeModal,
}: UseUpdateEditedTimelogProps): UseUpdateEditedTimelogReturn => {
  const updateEditedTimelog = async (timeLogState: TimeLogDto) => {
    try {
      const validatedState = EditTimelogSchema.parse(timeLogState);

      await putTimeLog({
        id: validatedState?.id,
        data: validatedState,
      });

      updateTimeLogList();

      closeModal();
    } catch (err) {
      if (err instanceof ZodError) {
        handleOneLevelZodError(err).forEach((issue) => {
          toast.error(issue.message);
        });
      } else {
        toast.error('Oops! Something went wrong');
      }
    }
  };

  return updateEditedTimelog;
};

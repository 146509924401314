import React, { useRef } from 'react';

import ClientInfo from '../../components/ClientInfo/ClientInfo';
import styles from '../../styles/client.module.scss';

import Loader from '../../components/Loader/Loader';
import ReportTable from '../../components/Tables/ReportTable';
import { useClientPage } from './useClientPage';
import { useTranslation } from 'react-i18next';

const ClientPage = () => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLTableElement>(null);

  const {
    clientColumns,
    clientPage,
    goToItemDetails,
    isLoading,
    projectsByClientID,
  } = useClientPage();

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      {clientPage && <ClientInfo clientPage={clientPage} />}

      {!!projectsByClientID?.length && !isLoading && (
        <ReportTable
          ref={tableRef}
          columns={clientColumns}
          //TODO: improve types after moving to react-table v8
          // @ts-ignore
          data={projectsByClientID}
          //TODO: improve types after moving to react-table v8
          //@ts-ignore
          onRowClick={goToItemDetails}
          tableName='pm-projects-list'
        /> 
      )}

      {!projectsByClientID?.length && !isLoading && (
        <p>{`${clientPage?.clientName} ${t('warning.no_clients_projects')}`}</p>
      )}
    </div>
  );
};

export default ClientPage;

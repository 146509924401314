/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTimeLogDataForEmployee } from '../../../api/tracking';
import { getVacationData } from '../../../api/vacation';
import { getEmployeeDataAction } from '../../../store/actionCreators/employeeActionCreators';
import { getVacationDataAction } from '../../../store/actionCreators/vacationActionCreators';
import { SET_LOADER } from '../../../store/actions';
import { useUpdateVacationListProps } from '../types';

export const useUpdateVacationList = ({
  dayOffType,
  dispatch,
  registeredEmployee,
  setHandleValues,
  t,
}: useUpdateVacationListProps) => {
  const updateVacationList = async (selectedDate?: string) => {
    if (!selectedDate) return;

    try {
      setHandleValues(SET_LOADER({ payload: { isLoading: true } }));

      const timeLogData = await getTimeLogDataForEmployee({});

      if (timeLogData) {
        dispatch(getEmployeeDataAction(timeLogData));
        getEmployeeDataAction;
      }

      const vacationData = await getVacationData({
        yearMonth: selectedDate,
      });

      setHandleValues(SET_LOADER({ payload: { isLoading: false } }));

      if (!vacationData) return;

      dispatch(getVacationDataAction(vacationData));

      if (!dayOffType) {
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: {
            dayOffType:
              vacationData.daysLeft || registeredEmployee.unlimitedVacationDays
                ? t('vacation.vacation_data.paid_vacations')
                : t('vacation.vacation_data.unpaid_day_offs'),
          },
        });
      }
    } catch (error) {
      console.error('Failed to update vacation list:', error);
      setHandleValues(SET_LOADER({ payload: { isLoading: false } }));
    }
  };

  return updateVacationList;
};

import { useTranslation } from 'react-i18next';
import styles from '../../styles/vacation.module.scss';
import TextField from '../../components/TextField/TextField';
import { clearDates } from '../../utils/date-handling';
import Button from '../../components/Button/Button';
import {
  formatDateYYYYMMDD,
  formatTodayToISO,
} from '../../utils/date-formatting';
import { Msg } from '../../store/actions';

type HeaderBlockProps = {
  checkboxState: number;
  currentDate: string;
  dayOffType: string;
  endDate: string;
  setHandleValues: React.Dispatch<Msg>;
  startDate: string;
};

const HeaderBlock = (props: HeaderBlockProps) => {
  const {
    currentDate,
    dayOffType,
    endDate,
    setHandleValues,
    startDate,
    checkboxState,
  } = props;

  const { t } = useTranslation();

const shouldDisplayRange =
  (dayOffType === '' ||
    dayOffType === t('vacation.vacation_data.paid_vacations') ||
    dayOffType === t('vacation.vacation_data.unpaid_day_offs')) &&
  checkboxState === 0;

  return (
    <div className={styles.subheader}>
      {shouldDisplayRange ? (
        <>
          <div className={styles.datePickerSummaryButton}>
            <TextField
              labelclassname={styles.labelEmpty}
              className={styles.datePickerInput}
              value={startDate ? formatDateYYYYMMDD(new Date(startDate)) : ''}
              readOnly
            />
            <TextField
              labelclassname={styles.labelEmpty}
              className={styles.datePickerInput}
              value={endDate ? formatDateYYYYMMDD(new Date(endDate)) : ''}
              readOnly
            />
          </div>
          <Button
            label={t('buttons.clear') as string}
            styleClass='btn__rounded-blue-narrow'
            onClick={() => clearDates(setHandleValues)}
            disabled={!startDate}
          />
        </>
      ) : (
        <>
          <div className={styles.datePickerSummaryButton}>
            <TextField
              labelclassname={styles.labelEmpty}
              className={styles.datePickerInput}
              value={formatDateYYYYMMDD(new Date(currentDate))}
              readOnly
            />
          </div>
          <Button
            label={t('buttons.today') as string}
            styleClass='btn__rounded-blue-narrow'
            onClick={() =>
              setHandleValues({
                type: 'SET_DATE_VALUE',
                payload: { currentDate: formatTodayToISO() },
              })
            }
            disabled={currentDate === formatTodayToISO()}
          />
        </>
      )}
    </div>
  );
};

export default HeaderBlock;

import { useEffect, useReducer, useRef, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { reducer, valuesInitialState } from '../../reducer/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { eptIdChecker } from '../../utils/eptIdChecker';
import useEditMode from '../../hooks/useEditMode';
import { sortObjectsByDate } from '../../utils/filtering';
import { deleteSicknessRange } from '../../api/sickness';
import { clearedTimeLogRequest } from '../../constants/cleared-objects';

import { EditDraftSickness } from './types';
import { transformSicknessData } from './utilities/TableSicknessData';
import { getLoggedDates } from './helpers/GetLoggedDates';
import { checkIfDayAlreadyLogged } from './helpers/DayAlreadyLogged';
import { createSicknessRequest } from './helpers/CreateSicknessRequest';
import { setLoaderAction } from '../../store/actionCreators/loaderActionCreator';
import { CLEAR_TEXT, EDIT_MODE } from '../../store/actions';
import { createSicknessColumns } from './helpers/CreateSicknessColumns';
import { makeUpdateSicknessList } from './utilities/UpdateSicknessList';

export const useSicknessPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tableRef = useRef<HTMLTableElement>(null);

  const { sicknessData, employeeData, registeredEmployee } = useAppSelector(
    (state) => state,
  );
  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );
  const {
    currentDate,
    editValuesMode,
    endDate,
    fileItem,
    fileName,
    isLoading,
    selectedMonth,
    startDate,
    timeLogId,
  } = handleValues;

  const [checkboxState, setCheckboxState] = useState(0);
  const [editedRowId, setEditedRowId] = useState<string | undefined>(undefined);
  const [editDraftSickness, setEditDraftSickness] = useState<EditDraftSickness>(
    {
      duration: {
        hours: 8,
        minutes: 0,
      },
      date: '',
      eptId: eptIdChecker(employeeData.projectDTOList, 'Sickness'),
      fileName: '',
      isPartDay: checkboxState !== 0,
      note: '',
    },
  );

  const {
    confirmPopUp,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    handleGlobalEditMode,
    isConfirmPopUpApproved,
  } = useEditMode();

  const tableSicknessData = transformSicknessData(sicknessData);

  const sortedByDate = sortObjectsByDate(tableSicknessData ?? [], 'date');

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const loggedDates = getLoggedDates(sicknessData);

  const dayAlreadyLogged = checkIfDayAlreadyLogged(sicknessData);

  const timeLogRequest = createSicknessRequest({
    endDate: endDate,
    startDate: startDate,
    eptId: employeeData.projectDTOList,
    isPartDay: checkboxState,
    note: editDraftSickness.note,
  });

  const sickTodayRequest = createSicknessRequest({
    endDate: currentDate,
    startDate: currentDate,
    eptId: employeeData.projectDTOList,
    isPartDay: checkboxState,
    note: editDraftSickness.note,
  });

  const updateSicknessList = makeUpdateSicknessList({
    setHandleValues,
    dispatch,
    selectedMonth,
  });

  const handleTableRowDeleting = async (id: number) => {
    setHandleValues(setLoaderAction(true));

    try {
      await deleteSicknessRange(`${id}`);
      await updateSicknessList();
    } catch (error) {
      console.error('Error deleting sickness range:', error);
    } finally {
      setHandleValues(CLEAR_TEXT({ payload: clearedTimeLogRequest }));

      setHandleValues(
        EDIT_MODE({
          payload: {
            editValuesMode: false,
          },
        }),
      );
      setHandleValues(setLoaderAction(false));
      handleApprovingOfConfirmPopUp(false);
      handleConfirmPopUp(false);
    }
  };

  const constPartValue = registeredEmployee?.constPart;

  const isEmployee = () => registeredEmployee?.employmentForm === 'EMPLOYEE';

  useEffect(() => {
    if (isLoading && !isEmployee) {
      navigate('/');
    }
  }, [isEmployee, isLoading]);

  useEffect(() => {
    if (confirmPopUp.status && !!confirmPopUp.rowId && isConfirmPopUpApproved) {
      handleTableRowDeleting(confirmPopUp.rowId);
    }
  }, [confirmPopUp.status, confirmPopUp.rowId, isConfirmPopUpApproved]);

  useEffect(() => {
    updateSicknessList();
  }, [dispatch, selectedMonth]);

  const sicknessColumns = createSicknessColumns({
    editDraftSickness,
    editValuesMode,
    handleConfirmPopUp,
    handleGlobalEditMode,
    setEditDraftSickness,
    setEditedRowId,
    setHandleValues,
    setIsOpenEditModal,
    t,
  });

  return {
    checkboxState,
    constPartValue,
    currentDate,
    dayAlreadyLogged,
    editDraftSickness,
    editValuesMode,
    editedRowId,
    endDate,
    fileItem,
    fileName,
    handleGlobalEditMode,
    isLoading,
    isOpenEditModal,
    loggedDates,
    setCheckboxState,
    setEditDraftSickness,
    setEditedRowId,
    setHandleValues,
    setIsOpenEditModal,
    sickTodayRequest,
    sicknessColumns,
    sicknessData,
    sortedByDate,
    startDate,
    t,
    tableRef,
    timeLogId,
    timeLogRequest,
    updateSicknessList,
  };
};

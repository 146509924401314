import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { store } from './store/index';

import './i18n';
import ModalProvider from './providers/ModalProvider';
import InitModals from './components/Modal/InitModals';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? '';

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    audience={audience}
    cacheLocation='memory'
  >
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <ModalProvider>
            <App />
            <InitModals />
          </ModalProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </Auth0Provider>,
);

reportWebVitals(() => undefined);

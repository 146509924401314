import { UnitDto } from './api-types';
import { UNITS, handleError, makeRequest } from './config';

export const getUnits = async () => {
  try {
    const url = UNITS;
    const response = await makeRequest.get<UnitDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

import React, { forwardRef } from 'react';
import { Row, useTable } from 'react-table';
import {
  ChangeRowColor,
  ReportRowData,
} from '../../utils/conditionalRowStyles';

type ReportTableProps<TData extends ReportRowData = ReportRowData> = {
  changeRowColor?: ChangeRowColor;
  columns?: any;
  data?: TData[];
  editRowId?: number | null;
  onRowClick?: (row: Row<TData>) => void;
  tableName?: string;
};

const ReportTable = forwardRef<HTMLTableElement, ReportTableProps>(
  (
    { changeRowColor, columns, data, editRowId, onRowClick, tableName },
    ref,
  ) => {
    const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
      useTable({ columns: columns ?? [], data: data ?? [] });
    return (
      <table className={tableName} {...getTableProps()} ref={ref}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                //TODO: check response and update types
                //@ts-ignore
                className={row.original.id === editRowId ? 'edit' : ''}
                onClick={() => (onRowClick ? onRowClick(row) : undefined)}
                {...row.getRowProps(
                  changeRowColor ? changeRowColor(row) : undefined,
                )}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);

export default ReportTable;

import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import { totalReportXLS } from './xlsColumns';
import { TotalReportDto } from '../api/api-types';

type ExportTotalReport = {
  reportType: 'Client' | 'Total';
  reportTotal: TotalReportDto;
  startDate: string;
  endDate: string;
};

export const exportTotalReport = async ({
  endDate,
  reportTotal,
  reportType,
  startDate,
}: ExportTotalReport) => {
  const workbook = new Excel.Workbook();
  try {
    const fileName = `${reportType} report ${startDate}-${endDate}`;

    const worksheet = workbook.addWorksheet(`${reportType} report`);

    worksheet.columns = totalReportXLS;

    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A6CCF7' },
      };
      cell.font = { bold: true };
    });

    reportTotal?.projectCountHoursDtoList?.map((el) =>
      worksheet.addRow({
        customer: el.client,
        employee: el.employeeFullName?.name,
        project: el.projectNameAndId?.name,
        'billable hours': el.billableHours,
        'non-billable hours': el.notBillableHours,
        'total hours': el.countHours,
      }),
    );

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      if (column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 28;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      }
      column.width = column.header?.includes('Hours') ? 32 : 28;
      column.alignment = column.header?.includes('Hours')
        ? { vertical: 'middle', horizontal: 'right', wrapText: true }
        : { vertical: 'middle', horizontal: 'center', wrapText: true };
    });

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), `${fileName}.xlsx`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.message;
  } finally {
    workbook.removeWorksheet(`${reportType} report`);
  }
};

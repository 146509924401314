import { useTranslation } from 'react-i18next';
import { clearedTimeLogRequest } from '../../../constants/cleared-objects';
import { CLEAR_TEXT, EDIT_MODE, SET_LOADER } from '../../../store/actions';
import { HeaderBlockProps } from './types';
import { postSicknessLog } from '../../../api/sickness';
import { toast } from 'react-toastify';
import { sickTodayHandler } from '../../../utils/date-handling';
import { editDraftInitialState } from './helpers/utils';

export const useHeaderBlock = ({
  currentDate,
  dayAlreadyLogged,
  handleGlobalEditMode,
  setEditDraftSickness,
  setEditedRowId,
  setHandleValues,
  sickTodayRequest,
  updateSicknessList,
  startDate,
  endDate,
}: HeaderBlockProps) => {
  const { t } = useTranslation();

  const handleClearClick = () => {
    setHandleValues(CLEAR_TEXT({ payload: clearedTimeLogRequest }));
    setEditDraftSickness(editDraftInitialState);

    setHandleValues(
      EDIT_MODE({
        payload: {
          editValuesMode: false,
        },
      }),
    );

    setEditedRowId(undefined);
    handleGlobalEditMode(false);
  };

  const handlePostSicknessLog = async () => {
    try {
      await postSicknessLog({
        data: sickTodayRequest,
      });
      updateSicknessList();
    } finally {
      setHandleValues(CLEAR_TEXT({ payload: clearedTimeLogRequest }));
      setHandleValues(
        EDIT_MODE({
          payload: {
            editValuesMode: false,
          },
        }),
      );

      setEditDraftSickness(editDraftInitialState);
      setHandleValues(SET_LOADER({ payload: { isLoading: false } }));
    }
  };

  const handleSickTodayClick = () => {
    if (dayAlreadyLogged) {
      toast.warn(t('warning.day_already_logged'));
    } else {
      setHandleValues(SET_LOADER({ payload: { isLoading: true } }));

      sickTodayHandler(setHandleValues, currentDate);

      handlePostSicknessLog();
    }
  };

  const isDisabledSickToday =
    startDate === currentDate && endDate === currentDate; // TODO: needs to be fixed, wrong dates are being compared

  return {
    handleClearClick,
    handleSickTodayClick,
    isDisabledSickToday,
    t,
  };
};

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useValues from '../../hooks/useValues';
import { getBackendReportByPMAll } from '../../api/reports';
import { UseReportPMAllReturn } from './types';
import { RootState } from '../../store';
import {
  clearReportPMAction,
  getReportPMAllAction,
} from '../../store/actionCreators/reportsActionCreators';
import { handleError } from '../../api/config';
import { setLoaderAction } from '../../store/actionCreators/loaderActionCreator';
import { useTranslation } from 'react-i18next';

export const useReportPMAll = (): UseReportPMAllReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { startDate, endDate, billable } = useParams();

  const { handleValues, setHandleValues } = useValues('reportPMData');
  const { isLoading } = handleValues;

  const { reportPMAll, key } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate || !billable) return;

      setHandleValues(setLoaderAction(true));

      try {
        const data = await getBackendReportByPMAll({
          billableTypes: billable,
          endDate,
          startDate,
        });

        setHandleValues(setLoaderAction(false));

        if (!data) throw new Error(t('error.no_data').toString());

        dispatch(getReportPMAllAction(data));
      } catch (error) {
        handleError(error);
      } finally {
        setHandleValues(setLoaderAction(false));
      }
    };

    fetchData();

    return () => {
      dispatch(clearReportPMAction());
    };
  }, [startDate, endDate, billable, key]);

  return {
    isLoading,
    reportPMAll,
  };
};

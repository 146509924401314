import { getSicknessData } from '../../../api/sickness';
import { setLoaderAction } from '../../../store/actionCreators/loaderActionCreator';
import { getEmployeeDataAction } from '../../../store/actionCreators/employeeActionCreators';
import { getSicknessDataAction } from '../../../store/actionCreators/sicknessActionCreators';
import { getTimeLogDataForEmployee } from '../../../api/tracking';
import { UpdateSicknessListProps } from '../types';

export const makeUpdateSicknessList =
  ({ setHandleValues, dispatch, selectedMonth }: UpdateSicknessListProps) =>
  async () => {
    try {
      setHandleValues(setLoaderAction(true));

      const timeLogData = await getTimeLogDataForEmployee({
        userId: undefined,
      });

      if (timeLogData) {
        dispatch(getEmployeeDataAction(timeLogData));
      }

      const sicknessData = await getSicknessData({
        query: selectedMonth,
      });

      if (sicknessData) {
        dispatch(getSicknessDataAction(sicknessData));
      }
    } catch (error) {
      console.error('Failed to update sickness list:', error);
    } finally {
      setHandleValues(setLoaderAction(false));
    }
  };

import { toast } from 'react-toastify';
import { TranslationFunction } from '../../../components/type-helpers/translation';

export const showWarning = (
  month: Date,
  warningShown: boolean,
  setWarningShown: (shown: boolean) => void,
  t: TranslationFunction,
) => {
  const currentMonth = new Date().getMonth();
  const selectedMonth = month.getMonth();

  if (selectedMonth < currentMonth && !warningShown) {
    toast.warn(t('warning.logs_from_previous_month'));
    setWarningShown(true);
  }

  if (selectedMonth === currentMonth) {
    setWarningShown(false);
  }
};

import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reducer, valuesInitialState } from '../../reducer/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useEditMode from '../../hooks/useEditMode';
import { formatDateYYYYMMDD } from '../../utils/date-formatting';
import { getFilteredEmployeeData } from './utils/getFilteredEmployeeData';
import { createGetTimeLogsList } from './utils/fetchTimeLogs';
import useTableRowDeletion from './hooks/useTableRowDeletion';
import useTimeTrackingColumns from './hooks/useTimeTrackingColumns';
import { useAutoSetCurrentMinute } from './hooks/useAutoSetCurrentMinute';
import { useSetInitialValues } from './hooks/useSetInitialValues';
import { TimeTrackingPageReturn } from './types';
import { useFetchEmployeeProjectData } from './hooks/useFetchEmployeeProjectData';
import { dateReducerHandler, onMonthChange } from '../../utils/date-handling';
import { showWarning } from './utils/showWarning';
import { fetchVacationData } from './utils/fetchVacationData';
import { handleAnnulationDateWarnings } from './utils/handleAnnulationDateWarnings';

export const useTimeTrackingPage = (): TimeTrackingPageReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [warningShown, setWarningShown] = useState(false);

  const selectedProjectName = useRef<string | undefined>();
  const selectedCurrentDate = useRef<string | undefined>();
  const selectedCurrentMinute = useRef<string | undefined>();
  const tableRef = useRef<HTMLTableElement>(null);

  const [activeRow, setActiveRow] = useState<number | null | undefined>();
  const { timeTrackedData, employeeData, vacationData, registeredEmployee } =
    useAppSelector((state) => state);
  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const isEmployee = registeredEmployee?.employmentForm === 'EMPLOYEE'; // check if FULL_TIME is the employment form

  useEffect(() => {
    if (!vacationData && isEmployee)
      fetchVacationData(setHandleValues, dispatch);
  }, [vacationData, dispatch]);

  useEffect(() => {
    if (vacationData?.annulationDate) {
      const { annulationDate, daysLeft } = vacationData;

      handleAnnulationDateWarnings(annulationDate, daysLeft, t);
    }
  }, [vacationData, t]);

  const {
    comment,
    currentDate,
    currentHour,
    currentMinute,
    isLoading,
    projectId,
    projectName,
    taskId,
    taskName,
    timeLogId,
    currentDates,
  } = handleValues;

  const {
    confirmPopUp,
    editMode,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    handleGlobalEditMode,
    isConfirmPopUpApproved,
  } = useEditMode();

  useSetInitialValues({
    dispatch,
    employeeData,
    projectName,
    setHandleValues,
  });

  const filteredEmployeeData = getFilteredEmployeeData(
    employeeData,
    projectName,
  );

  const getTimeLogsList = createGetTimeLogsList({
    currentDate,
    dispatch,
    setHandleValues,
  });

  const { handleTableRowDeleting } = useTableRowDeletion({
    dispatch,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    selectedCurrentDate,
    setHandleValues,
  });

  const handleMonthChange = (month: Date) => {
    showWarning(month, warningShown, setWarningShown, t);
    onMonthChange(dispatch, month);
    dateReducerHandler(setHandleValues, month);
  };

  useEffect(() => {
    if (confirmPopUp.status && !!confirmPopUp.rowId && isConfirmPopUpApproved) {
      handleTableRowDeleting(confirmPopUp.rowId);
    }
  }, [confirmPopUp.status, confirmPopUp.rowId, isConfirmPopUpApproved]);

  useEffect(() => {
    const fetchTimeLogsList = async () => {
      await getTimeLogsList();
    };

    fetchTimeLogsList();
  }, [currentDate, timeLogId]);

  useFetchEmployeeProjectData({
    dispatch,
    projectName,
    selectedProjectName,
  });

  useEffect(() => {
    selectedCurrentDate.current = formatDateYYYYMMDD(new Date(currentDate));
  }, [currentDate]);

  useEffect(() => {
    selectedCurrentMinute.current = currentMinute;
  }, [currentMinute]);

  useAutoSetCurrentMinute({
    currentHour,
    currentMinute,
    setHandleValues,
  });

  const timeTrackingColumns = useTimeTrackingColumns({
    activeRow,
    currentDate,
    handleConfirmPopUp,
    handleGlobalEditMode,
    setActiveRow,
    setHandleValues,
    t,
    timeTrackedData,
  });

  return {
    activeRow,
    comment,
    currentDate,
    currentHour,
    currentMinute,
    dispatch,
    editMode,
    employeeData,
    filteredEmployeeData,
    getTimeLogsList,
    handleGlobalEditMode,
    handleMonthChange,
    isLoading,
    projectId,
    projectName,
    selectedCurrentMinute,
    selectedProjectName,
    setActiveRow,
    setHandleValues,
    t,
    tableRef,
    taskId,
    taskName,
    timeLogId,
    timeTrackedData,
    timeTrackingColumns,
    currentDates,
  };
};

import Slider from 'react-slick';
import PointerBlue from '../../../../../images/PointerBlue.gif';
import { settings } from '../constants';
import { FC } from 'react';
import { BCoinItemModalSliderProps } from '../types';

const BCoinItemModalSlider: FC<BCoinItemModalSliderProps> = ({
  images,
  slider,
  props,
}) => (
  <>
    <Slider {...settings} ref={slider}>
      {images.map((image) => (
        <div
          className='bcoin-modal-carousel-item'
          key={image?.split('.').slice(-2).join('')}
        >
          <img src={image} alt={props.item.name} />
        </div>
      ))}
    </Slider>
    {images.length > 1 && (
      <div className='pointer-modal'>
        <img src={PointerBlue} alt='pointer' />
      </div>
    )}
  </>
);

export default BCoinItemModalSlider;

import React from 'react';

type BCoinItemsCharacteristicsProps = {
  characteristics: string[];
};

export const BCoinItemsCharacteristics = (
  props: BCoinItemsCharacteristicsProps,
) => (
  <div className='bcoin-modal-characteristic'>
    <div className='bcoin-modal-characteristic-scroll'>
      {props.characteristics.map((characteristic) => (
        <div
          className='bcoin-modal-characteristic-singular-container'
          key={characteristic}
        >
          <span className='bcoin-modal-characteristic-singular-key'>
            {characteristic}
          </span>
        </div>
      ))}
      <div className='bcoin-modal-characteristic-spacing' />
    </div>
  </div>
);

import { toast } from 'react-toastify';
import { handleError, makeRequest, RANGE, SICKNESSES, TIME } from './config';
import { fileTooBig } from '../constants/text-values';
import { SicknessesDto, SicknessNoteDto, TrackFormRange } from './api-types';

type GetSicknessData = {
  query?: string;
};

export const getSicknessData = async ({ query }: GetSicknessData) => {
  try {
    const queryParam = query ? `?yearMonth=${query}` : '';
    const url = `${TIME}${SICKNESSES}${queryParam}`;
    const response = await makeRequest.get<SicknessesDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PostSicknessLog = {
  data: TrackFormRange;
};

export const postSicknessLog = async ({ data }: PostSicknessLog) => {
  try {
    const url = `${TIME}${SICKNESSES}`;
    const response = await makeRequest.post<number[]>(url, data);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PatchSicknessLog = {
  timeLogId: number;
  data: SicknessNoteDto;
};

export const patchSicknessLog = async ({
  timeLogId,
  data,
}: PatchSicknessLog) => {
  try {
    const url = `${TIME}/${timeLogId}/note`;
    await makeRequest.patch(url, data);
  } catch (e) {
    handleError(e);
  }
};

type PostSicknessFile = {
  timeLogId: number;
  fileItem: File | null;
};

export const postSicknessFile = async ({
  timeLogId,
  fileItem,
}: PostSicknessFile) => {
  if (!fileItem) return;
  if (fileItem.size > 15 * 1048576) {
    toast.warn(fileTooBig);
  }
  try {
    const formData = new FormData();
    formData.append('file', fileItem);

    const url = `${TIME}/${timeLogId}`;
    await makeRequest.patch(url, formData);
  } catch (e) {
    handleError(e);
  }
};

export const deleteSicknessRange = async (ids: string) => {
  try {
    const url = `${TIME}${RANGE}?idList=${ids}`;
    await makeRequest.delete(url);
  } catch (e) {
    handleError(e);
  }
};

import { useTranslation } from 'react-i18next';
import {
  AccrualTypeOption,
  AccrualTypeOptions,
  accrualTypes,
  defaultAccrualType,
} from '../../../constants/select-values';
import { ACCRUAL_FILTER_TYPE } from '../../../translations/accrualTypeFilterEN';
import {
  removeDuplicatedEmployees,
  sortByDefault,
} from '../../../utils/filtering';
import SimpleSelect from '../../../components/Select/SimpleSelect';
import {
  controlsListingStyle,
  multiSelect,
} from '../../../components/Select/selectStyles';
import { clearedSelectedEmployee } from '../../../constants/cleared-objects';
import CalendarDatePicker from '../../../components/DatePickers/DatePicker/CalendarDatePicker';
import { dateReducerHandlerKey } from '../../../utils/date-handling';
import Button from '../../../components/Button/Button';
import ReportsFilterPanel from '../../../components/ReportsFilterPanel/ReportsFilterPanel';
import { MultiValue } from 'react-select';
import {
  DepartmentDto,
  PageEmployeeProjectTaskDto,
  ProjectManagerReportItem,
  ProjectWebDto,
  UserNameIdDto,
} from '../../../api/api-types';
import { Msg } from '../../../store/actions';
import useEmployeeOptions from './useFilterPanelBlock';
import { useMemo } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { useParams } from 'react-router-dom';

type FilterPanelBlockProps = {
  clientId: number | undefined;
  clientName: string;
  departmentList: DepartmentDto[];
  customerNames: {
    id: number | undefined;
    label: string | undefined;
    value: string | undefined;
  }[];
  departmentId: any;
  departmentName: any;
  employeesByRole: UserNameIdDto[];
  accrualTypeFilter: AccrualTypeOptions;
  employeeId: number | undefined;
  getReportsByCase: () => void;
  memberList: PageEmployeeProjectTaskDto['content'];
  pmList: UserNameIdDto[];
  pmMemberList: ProjectManagerReportItem[];
  projectId: string | number;
  projectListAll: ProjectWebDto[];
  projectName: string;
  reportEndDate: string;
  reportStartDate: string;
  setHandleValues: React.Dispatch<Msg>;
  teamMemberName: string;
  typeOfReport: string;
  userId: string | undefined;
  userName: string;
  employeeTaskName: string;
};

const FilterPanelBlock = ({
  accrualTypeFilter,
  clientId,
  clientName,
  customerNames,
  departmentList,
  departmentId,
  departmentName,
  employeesByRole,
  employeeId,
  getReportsByCase,
  memberList,
  pmList,
  pmMemberList,
  projectId,
  projectListAll,
  projectName,
  reportEndDate,
  reportStartDate,
  setHandleValues,
  teamMemberName,
  typeOfReport,
  userId,
  userName,
  employeeTaskName,
}: FilterPanelBlockProps) => {
  const { t } = useTranslation();
  const { employeeId: employeeIdFromUrl } = useParams();

  const key = useAppSelector((state) => state.key);

  const allOption = {
    id: '',
    label: t('projects.listing.value.0'),
    value: t('projects.listing.value.0'),
  };

  const filteredCustomerNames = customerNames.filter(
    (value, index, self) => index === self.findIndex((x) => x.id === value.id),
  );

  const sortedMemberList = memberList?.sort((a, b) =>
    sortByDefault(a, b, 'employeeName'),
  );
  const taskNameToFilter =
    employeeTaskName === allOption.value ? '' : employeeTaskName;

  const filteredMemberList = taskNameToFilter
    ? sortedMemberList?.filter(({ task }) => task?.name === employeeTaskName)
    : sortedMemberList;

  const accrualTypesTranslated = accrualTypes(t);

  const onAccrualTypeChange = (newFilter: MultiValue<AccrualTypeOption>) => {
    const idx = newFilter.findIndex(
      (el) => el.value === ACCRUAL_FILTER_TYPE.ALL,
    );
    const allValueFound = idx !== -1;
    const allSelectedLast = allValueFound && idx === newFilter.length - 1;
    const filter = allSelectedLast
      ? [newFilter[idx]]
      : newFilter.filter((el) => el.value !== ACCRUAL_FILTER_TYPE.ALL);
    setHandleValues({
      type: 'SET_SELECTABLES',
      payload: {
        accrualTypeFilter: filter,
      },
    });
  };

  const projectListAllSorted = projectListAll.sort((a, b) =>
    sortByDefault(a, b, 'projectName'),
  );

  const filteredCustomerNamesSorted = filteredCustomerNames.sort((a, b) =>
    sortByDefault(a, b, 'label'),
  );
  const storageAccrualType = accrualTypeFilter ?? [defaultAccrualType(t)];

  const filterAccrualType = [
    <FilterAccrualType
      options={accrualTypesTranslated}
      value={accrualTypeFilter}
      key={t('reports.accrual_type.title')}
      storageAccrualType={storageAccrualType}
      onChange={onAccrualTypeChange}
    />,
  ];

  const filterAllEmployees = [
    <SimpleSelect
      key={t('reports.filters.employee_name')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.employee_name') as string}
      options={
        Array.isArray(employeesByRole)
          ? [
              allOption,
              ...employeesByRole.map((employee) => ({
                id: employee.id,
                label: employee.name,
                value: employee.name,
              })),
            ]
          : []
      }
      value={{
        id: employeeId || 'select',
        label: teamMemberName || 'Select...',
        value: teamMemberName || 'Select...',
      }}
      onChange={(e) =>
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { employeeId: e.id, teamMemberName: e.value },
        })
      }
      styles={controlsListingStyle}
      isDisabled={!employeesByRole.length}
    />,
  ];

  const filterAllEmployeesAndAccrual = [
    ...filterAllEmployees,
    ...filterAccrualType,
  ];

  const filterCustomer = [
    <SimpleSelect
      key={t('reports.filters.client')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.client') as string}
      options={
        Array.isArray(filteredCustomerNamesSorted)
          ? [
              {
                id: null,
                label: t('projects.listing.value.0'),
                value: t('projects.listing.value.0'),
              },
              ...filteredCustomerNamesSorted,
            ]
          : []
      }
      value={{
        id: clientId || 'select',
        label: clientName || 'Select...',
        value: clientName || 'Select...',
      }}
      onChange={(e) =>
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { clientId: e.id, clientName: e.value },
        })
      }
      styles={controlsListingStyle}
    />,
    <FilterAccrualType
      options={accrualTypesTranslated}
      value={accrualTypeFilter}
      key={t('reports.accrual_type.title')}
      storageAccrualType={storageAccrualType}
      onChange={onAccrualTypeChange}
    />,
  ];

  const filterDepartments = [
    <SimpleSelect
      key={t('reports.filters.department')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.department') as string}
      options={
        Array.isArray(departmentList)
          ? [
              allOption,
              ...departmentList.map((department) => ({
                id: department.id,
                label: department.name,
                value: department.name,
              })),
              {
                id: null,
                label: 'Non-delivery',
                value: 'Non-delivery',
              },
            ]
          : []
      }
      value={{
        id: departmentId || 'select',
        label: departmentName || 'Select...',
        value: departmentName || 'Select...',
      }}
      onChange={(e) => {
        setHandleValues({
          type: 'CLEAR_TEXT',
          payload: clearedSelectedEmployee,
        });
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { departmentId: e.id, departmentName: e.value },
        });
      }}
      styles={controlsListingStyle}
    />,
  ];

  const { employeeOptions, setEmployeeOptions } =
    useEmployeeOptions(pmMemberList);

  const filterEmployeeName = [
    <SimpleSelect
      key={t('reports.filters.employee_name')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.employee_name') as string}
      options={employeeOptions}
      value={{
        id: employeeId || 'select',
        label: teamMemberName || 'All',
        value: teamMemberName || 'All',
      }}
      onChange={(e) =>
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { employeeId: e.id, teamMemberName: e.value },
        })
      }
      styles={controlsListingStyle}
      isDisabled={!projectId && !userId}
    />,
  ];

  const filterEmployeesByProjectOptions = [
    allOption,
    ...removeDuplicatedEmployees(filteredMemberList ?? []).map((employee) => ({
      id: employee?.employeeId ?? '',
      label: `${employee?.employeeName}`,
      value: `${employee?.employeeName}`,
    })),
  ];

  const filterEmployeesByProject = [
    <SimpleSelect
      key={t('reports.filters.employee_name')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.employee_name') as string}
      options={filterEmployeesByProjectOptions}
      value={{
        id: employeeId || 'select',
        label: teamMemberName || 'Select...',
        value: teamMemberName || 'Select...',
      }}
      onChange={(e) => {
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { employeeId: e.id, teamMemberName: e.value },
        });
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { employeeTaskName: 'All' },
        });
      }}
      styles={controlsListingStyle}
      isDisabled={!projectId && !userId}
    />,
  ];

  const filteredTasksList = useMemo(() => {
    if (!employeeId && !employeeIdFromUrl) {
      return memberList;
    }

    return memberList?.filter(
      ({ employeeId: id }) =>
        id === employeeId || id === Number(employeeIdFromUrl),
    );
  }, [key, employeeId, employeeIdFromUrl, memberList]);

  const uniqueTasksList = useMemo(() => {
    return [...new Set(filteredTasksList?.map(({ task }) => task?.name))];
  }, [filteredTasksList, employeeId]);

  const filterTasksByProjectOptions = useMemo(() => {
    return [
      allOption,
      ...uniqueTasksList.map((taskName) => ({
        id: taskName,
        label: taskName,
        value: taskName,
      })),
    ];
  }, [uniqueTasksList, employeeId]);

  const filterTasksByProject = [
    <SimpleSelect
      key={t('reports.filters.tasks')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.tasks') as string}
      options={filterTasksByProjectOptions}
      value={{
        id: employeeTaskName || 'select',
        label: employeeTaskName || 'Select...',
        value: employeeTaskName || 'Select...',
      }}
      onChange={(e) =>
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { employeeTaskName: e.value },
        })
      }
      styles={controlsListingStyle}
      isDisabled={!projectId && !userId}
    />,
  ];

  const pmListWithAll = [{ id: '', label: 'All', name: 'All' }, ...pmList];

  const filterPM = [
    <SimpleSelect
      key={t('reports.filters.pm_name')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.pm_name') as string}
      options={pmListWithAll.map((pm) => ({
        id: pm.id ?? '',
        label: pm.name ?? '',
        value: pm.name ?? '',
      }))}
      value={{
        id: userId || '',
        label: userName || 'All',
        value: userName || 'All',
      }}
      onChange={(e) => {
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { userId: e.id, userName: e.value },
        });
        setHandleValues({
          type: 'CLEAR_TEXT',
          payload: clearedSelectedEmployee,
        });
        if (e.value === 'All') {
          setEmployeeOptions([]);
        }
      }}
      styles={controlsListingStyle}
    />,
    <FilterAccrualType
      options={accrualTypesTranslated}
      value={accrualTypeFilter}
      key={t('reports.accrual_type.title')}
      storageAccrualType={storageAccrualType}
      onChange={onAccrualTypeChange}
      isDisabled
    />,
  ];

  const filterProject = [
    <SimpleSelect
      key={t('reports.filters.project_name')}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.filters.project_name') as string}
      options={projectListAllSorted.map((project) => ({
        id: project.id ?? '',
        label: `${project.projectName}: ${
          project.status ? 'active' : 'inactive'
        }`,
        value: project.projectName ?? '',
      }))}
      value={{
        id: projectId || 'select',
        label: projectName || 'Select...',
        value: projectName || 'Select...',
      }}
      onChange={(e) => {
        setHandleValues({
          type: 'SET_SELECTABLES',
          payload: { projectId: e.id, projectName: e.value },
        });
        setHandleValues({
          type: 'CLEAR_TEXT',
          payload: clearedSelectedEmployee,
        });
      }}
      styles={controlsListingStyle}
    />,
    <FilterAccrualType
      options={accrualTypesTranslated}
      value={accrualTypeFilter}
      key={t('reports.accrual_type.title')}
      storageAccrualType={storageAccrualType}
      onChange={onAccrualTypeChange}
    />,
  ];

  const filterStartDate = [
    <CalendarDatePicker
      key={t('reports.filters.start_date')}
      wrapperClassName='reports__date--wrapper'
      inputClassName='reports__date--input'
      titleClassName='reports__date--title'
      title={t('reports.filters.start_date') as string}
      onChange={(selected) =>
        dateReducerHandlerKey(setHandleValues, selected, 'reportStartDate')
      }
      startDate={new Date(reportStartDate)}
      maxDate={new Date()}
    />,
  ];

  const filterEndDate = [
    <CalendarDatePicker
      key={t('reports.filters.end_date')}
      wrapperClassName='reports__date--wrapper'
      inputClassName='reports__date--input'
      titleClassName='reports__date--title'
      title={t('reports.filters.end_date') as string}
      onChange={(selected) =>
        dateReducerHandlerKey(setHandleValues, selected, 'reportEndDate')
      }
      startDate={
        new Date(reportEndDate).getTime() >= new Date(reportStartDate).getTime()
          ? new Date(reportEndDate)
          : undefined
      }
      maxDate={null}
    />,
  ];

  const shouldBeDisabled = () => {
    const isEndDateBeforeStartDate =
      new Date(reportEndDate).getTime() < new Date(reportStartDate).getTime();

    const isProjectIdMissing =
      typeOfReport === t('reports.types.value.by_project') && !projectId;

    return isEndDateBeforeStartDate || isProjectIdMissing;
  };

  const disabled = shouldBeDisabled();

  const filterBtnReport = [
    <Button
      key='key-report-9'
      styleClass='btn__rounded-blue filter-panel-report-button'
      label={t('buttons.create_report') as string}
      onClick={getReportsByCase}
      disabled={disabled}
    />,
  ];

  const renderFilterPanel = () => {
    switch (typeOfReport) {
      case t('reports.types.value.by_project'):
        return (
          <ReportsFilterPanel
            filterItemFirst={filterProject}
            filterItemThird={filterEmployeesByProject}
            filterItemFourth={filterStartDate}
            filterItemFifth={filterEndDate}
            filterItemSixth={[...filterTasksByProject, ...filterBtnReport]}
          />
        );
      case t('reports.types.value.by_pm'):
        return (
          <ReportsFilterPanel
            filterItemFirst={filterPM}
            filterItemThird={filterEmployeeName}
            filterItemFourth={filterStartDate}
            filterItemFifth={filterEndDate}
            filterItemSixth={filterBtnReport}
          />
        );
      case t('reports.types.value.by_client'):
        return (
          <ReportsFilterPanel
            filterItemFirst={filterCustomer}
            filterItemThird={filterAllEmployees}
            filterItemFourth={filterStartDate}
            filterItemFifth={filterEndDate}
            filterItemSixth={filterBtnReport}
          />
        );
      case t('reports.types.value.by_total'):
        return (
          <ReportsFilterPanel
            filterItemFirst={filterAllEmployeesAndAccrual}
            filterItemThird={filterDepartments}
            filterItemFourth={filterStartDate}
            filterItemFifth={filterEndDate}
            filterItemSixth={filterBtnReport}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderFilterPanel()}</>;
};

const FilterAccrualType = (props: FilterAccrualTypeProps) => {
  const { t } = useTranslation();
  return (
    <SimpleSelect
      isMulti={true}
      components={{
        ClearIndicator: () => null,
      }}
      selectWrapperClass='select_vertical_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('reports.accrual_type.title') as string}
      options={props.options}
      defaultValue={props.storageAccrualType}
      value={props.value}
      onChange={props.onChange}
      styles={{ ...controlsListingStyle, ...multiSelect }}
      isDisabled={props.isDisabled}
    />
  );
};

type FilterAccrualTypeProps = {
  storageAccrualType: AccrualTypeOptions;
  onChange: (newFilter: MultiValue<AccrualTypeOption>) => void;
  options: AccrualTypeOptions;
  value: AccrualTypeOptions;
  isDisabled?: boolean;
};

export default FilterPanelBlock;

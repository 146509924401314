import {
  UseProjectManagerOptionsProps,
  UseProjectManagerOptionsReturn,
} from '../types';

export const useProjectManagerOptions = ({
  projectManagerList,
  projectState,
  setProjectState,
}: UseProjectManagerOptionsProps): UseProjectManagerOptionsReturn => {
  const projectManagerOptions = projectManagerList.map((pm) => ({
    id: pm.id,
    label: pm.name ?? '',
    value: pm.name ?? '',
  }));

  const handleProjectManagerChange = (selectedOption: {
    id: number;
    value: string;
  }) => {
    setProjectState((prevState) => ({
      ...prevState,
      projectManagerId: selectedOption.id,
      projectManagerName: selectedOption.value,
    }));
  };

  const selectedProjectManager = {
    id: projectState?.projectManagerId,
    label: projectState?.projectManagerName ?? '',
    value: projectState?.projectManagerName ?? '',
  };

  return {
    projectManagerOptions,
    handleProjectManagerChange,
    selectedProjectManager,
  };
};

import React from 'react';
import Button from '../../../../Button/Button';
import { selectPMModalMain } from '../../../../Select/selectStyles';
import SimpleSelect from '../../../../Select/SimpleSelect';

import styles from '../../../styles/client-modal.module.scss';

import { ReactComponent as TrashBinIcon } from '../../../../../icons/TrashBinIcon.svg';
import { LocationsBlockProps } from './types';
import { useLocationsBlock } from './useLocationsBlock';

export const LocationsBlock = ({
  idx,
  location,
  handleClientState,
}: LocationsBlockProps) => {
  const { handleLocationChange, locationsOptions, removeLocation, t } =
    useLocationsBlock({ idx, handleClientState });

  return (
    <div className={styles.customer_block}>
      <SimpleSelect
        isMulti={false}
        selectTitle={`${t('modals.fields.location')}${idx ? '' : '*'}`}
        selectWrapperClass={styles.common_input}
        selectTitleClassName={styles.common_input_title}
        styles={selectPMModalMain}
        options={locationsOptions}
        value={
          locationsOptions.find(({ value }) => value === location) || {
            value: '',
            label: '',
          }
        }
        onChange={(e) => handleLocationChange(e.value, idx)}
      />
      {idx ? (
        <Button
          icon={<TrashBinIcon />}
          styleClass='btn__icon'
          onClick={removeLocation}
        />
      ) : null}
    </div>
  );
};

import 'slick-carousel/slick/slick.css';
import { ReactComponent as CloseXLIcon } from '../../../../icons/CloseXLIcon.svg';
import ConfirmationPopup from '../../../../components/ConfirmationPopup/ConfirmationPopup/ConfirmationPopup';
import { exchangeBCoinsForReward } from '../../../../api/bcoin';
import Loader from '../../../../components/Loader/Loader';
import { BCoinItemModalProps } from './types';
import { useBCoinItemModal } from './useBCoinItemModal';
import BCoinItemModalSlider from './components/BCoinItemModalSlider';
import BCoinModalData from './components/BCoinModalData';
import { FC } from 'react';

const BCoinItemModal: FC<BCoinItemModalProps> = (props) => {
  const {
    characteristics,
    confirmModalOpen,
    containerRef,
    exchangeText,
    images,
    isLoading,
    itemId,
    setConfirmModalOpen,
    slider,
  } = useBCoinItemModal(props);

  return (
    <div className='bcoin-modal'>
      <div className='bcoin-modal-container'>
        <div ref={containerRef} className='bcoin-modal-carousel-wrapper'>
          {images.length ? (
            <BCoinItemModalSlider
              {...{
                images,
                slider,
                props,
              }}
            />
          ) : null}
        </div>
        <BCoinModalData {...{ setConfirmModalOpen, characteristics, props }} />
        <div
          className='bcoin-modal-close-btn'
          onClick={props.onCancelButtonClick}
        >
          <CloseXLIcon />
        </div>
      </div>
      <ConfirmationPopup
        onSubmit={() => (itemId ? exchangeBCoinsForReward(itemId) : undefined)}
        text={exchangeText}
        isOpen={confirmModalOpen}
        setOpen={setConfirmModalOpen}
      />
      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default BCoinItemModal;

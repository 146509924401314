import { postTimeLog } from '../../../../api/tracking';
import { SET_LOADER, SET_TEXT } from '../../../../store/actions';
import { SubmitHandlerProps } from '../types';

export const submitHandler = async ({
  getTimeLogsList,
  setHandleValues,
  setIsLoading,
  timeLogRequestsArray,
}: SubmitHandlerProps) => {
  setIsLoading(true);
  setHandleValues(SET_LOADER({ payload: { isLoading: true } }));

  try {
    for (const timeLogRequest of timeLogRequestsArray) {
      await postTimeLog(timeLogRequest);
    }
    setHandleValues(
      SET_TEXT({
        payload: {
          comment: '',
        },
      }),
    );
    getTimeLogsList();
  } catch (error) {
    console.error('Error posting time log or fetching time logs list:', error);
  } finally {
    setIsLoading(false);
    setHandleValues(SET_LOADER({ payload: { isLoading: false } }));
  }
};

import { useAuth0 } from '@auth0/auth0-react';
import { findRoles } from './filtering';
import { namespace } from '../constants/text-values';
import { headRoles } from '../constants/role-values';

export const isHighLevelRole = () => {
  const { user } = useAuth0();
  const extendedRole = findRoles(
    user ? user[`${namespace}/roles`] : null,
    headRoles,
  );
  return [extendedRole];
};

type BCoinBodyLeftColumnProps = {
  description?: string;
  imageAsset?: string;
  imageClassName?: string;
  subtitle?: string;
  title?: string;
  className?: string;
  descriptionClassName?: string;
  info?: string;
  infoClassName?: string;
  subtitleClassName?: string;
  titleClassName?: string;
};

const BCoinBodyLeftCol = (props: BCoinBodyLeftColumnProps) => (
  <div className={`bcoin-body-left-col ${props.className ?? ''}`}>
    <div className={`bcoin-body-title ${props.titleClassName ?? ''}`}>
      {props.title}
    </div>
    {!!props.subtitle && (
      <div className={`bcoin-body-subtitle ${props.subtitleClassName ?? ''}`}>
        {props.subtitle}
      </div>
    )}
    {!!props.description && (
      <div
        className={`bcoin-body-description ${props.descriptionClassName ?? ''}`}
      >
        {props.description}
      </div>
    )}
    {!!props.info && (
      <div className={`bcoin-body-info ${props.infoClassName ?? ''}`}>
        {props.info}
      </div>
    )}
    {!!props.imageClassName && (
      <div className={props.imageClassName}>
        <img src={props.imageAsset} alt={props.title} />
      </div>
    )}
  </div>
);

export default BCoinBodyLeftCol;

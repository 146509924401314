import { useCallback } from 'react';
import { getProject, getProjectById, postProject } from '../../../api/projects';
import { createdProjectRequest } from '../../../utils/request-objects';
import { CreateProjectSchema } from '../../../validation/project';
import { ZodError } from 'zod';
import { handleOneLevelZodError } from '../../../validation/utils';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../hooks/redux';
import { Msg } from '../../../store/actions';
import { PageProjectWebDto } from '../../../api/api-types';
import { CreateProjectModalData } from '../../../components/Modal/CreateProjectModal/types';

interface UseProjectDataProps {
  currentPage: number;
  dispatch: ReturnType<typeof useAppDispatch>;
  selectedPMId?: string;
  selectedStatus: string | null;
  setHandleValues: React.Dispatch<Msg>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPageCurrentData: (data: PageProjectWebDto) => void;
  sortParam: { key: string; sortOrder: string };
  toggleAddProject: boolean;
}

type UseProjectDataReturn = {
  createProject: (projectState: CreateProjectModalData) => Promise<void>;
  onSelectProject: (e: { id: number | null; value: string }) => void;
  getAllProjects: () => void;
  updateProjectsList: () => void;
  clearSelectedProject: () => void;
};

export const useProjectData = ({
  currentPage,
  dispatch,
  selectedPMId,
  selectedStatus,
  setHandleValues,
  setIsLoading,
  setPageCurrentData,
  sortParam,
  toggleAddProject,
}: UseProjectDataProps): UseProjectDataReturn => {
  const getAllProjects = () => {
    getProject({
      sortParam: 'name,asc',
      unpaged: true,
    }).then((data) => {
      if (!data) return;

      dispatch({ type: 'GET_PROJECTS_ALL', payload: data.content ?? [] });
    });
  };

  const updateProjectsList = useCallback(() => {
    setIsLoading(true);

    getProject({
      page: currentPage,
      size: 20,
      pmId: selectedPMId,
      status: selectedStatus === 'Active',
      sortParam: `${sortParam.key},${sortParam.sortOrder}`,
    }).then((data) => {
      if (!data) return;

      setPageCurrentData(data);

      dispatch({ type: 'GET_PROJECTS', payload: data.content ?? [] });

      getAllProjects();

      setIsLoading(false);
    });
  }, [selectedStatus, selectedPMId, currentPage, sortParam]);

  const createProject = async (projectState: CreateProjectModalData) => {
    try {
      const project = createdProjectRequest(projectState);
      const validatedProject = CreateProjectSchema.parse(project);

      await postProject({
        data: validatedProject,
      });
    } catch (err) {
      if (err instanceof ZodError) {
        handleOneLevelZodError(err).forEach((issue) => {
          toast.error(issue.message);
        });
      } else {
        toast.error('Oops! Something went wrong during project creation');
      }
      return;
    }

    try {
      updateProjectsList();
    } catch (err) {
      toast.error('Failed to update projects list');
    }

    setHandleValues({
      type: 'ON_TOGGLE_VIEW',
      payload: {
        toggleAddProject: !toggleAddProject,
      },
    });
  };

  const onSelectProject = (e: { id: number | null; value: string }) => {
    setHandleValues({
      type: 'SET_SELECTABLES',
      payload: { projectId: e.id, projectName: e.value },
    });

    if (e.id !== null) {
      setIsLoading(true);

      getProjectById(e.id).then((data) => {
        if (!data) return;
        setPageCurrentData({ content: [data], totalElements: 1 });

        dispatch({ type: 'GET_PROJECTS', payload: [data] });

        setIsLoading(false);
      });
    } else {
      updateProjectsList();
    }
  };

  const clearSelectedProject = () =>
    setHandleValues({
      type: 'SET_SELECTABLES',
      payload: { projectId: null, projectName: '' },
    });

  return {
    createProject,
    onSelectProject,
    getAllProjects,
    updateProjectsList,
    clearSelectedProject,
  };
};

import { useTranslation } from 'react-i18next';
import { countries } from '../../../../../constants/select-values';
import { UseLocationsBlockProps, UseLocationsBlockReturn } from './types';

export const useLocationsBlock = ({
  idx,
  handleClientState,
}: UseLocationsBlockProps): UseLocationsBlockReturn => {
  const { t } = useTranslation();

  const locationsOptions = countries.map((country) => ({
    label: country,
    value: country,
  }));

  const handleLocationChange = (value: string, i: number) => {
    handleClientState((state) => ({
      ...state,
      locationDtoSet: state.locationDtoSet.map((location, locationIndex) => {
        if (locationIndex === i) {
          return { ...location, name: value };
        }

        return location;
      }),
    }));
  };

  const removeLocation = () => {
    handleClientState((state) => ({
      ...state,
      locationDtoSet: state.locationDtoSet.filter((_, id) => id !== idx),
    }));
  };

  return {
    handleLocationChange,
    locationsOptions,
    removeLocation,
    t,
  };
};

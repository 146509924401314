import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button/Button';
import SimpleSelect from '../../../components/Select/SimpleSelect';
import ToggleSlider from '../../../components/ToggleSlider/ToggleSlider';
import TotalItems from '../../../components/ControlsPanel/TotalItems';
import { controlsStatusStyle } from '../../../components/Select/selectStyles';

import { Msg } from '../../../store/actions';

type ProjectControls = {
  controlsProjectLeft: JSX.Element[];
  controlsProjectRight: JSX.Element[];
};

type UseProjectControls = {
  openAddTeamMemberModal: () => void;
  setHandleValues: React.Dispatch<Msg>;
  toggleStatusValue: boolean;
  toggleViewValue: boolean;
  totalAmount: number;
};

const selectOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

export const useProjectControls = ({
  openAddTeamMemberModal,
  setHandleValues,
  toggleStatusValue,
  toggleViewValue,
  totalAmount,
}: UseProjectControls): ProjectControls => {
  const { t } = useTranslation();
  
  const handleSelectChange = (e: { value: boolean }) => {
    setHandleValues({
      type: 'ON_TOGGLE_VIEW',
      payload: { toggleStatusValue: e.value },
    });
  };

  const handleToggleChange = () => {
    setHandleValues({
      type: 'ON_TOGGLE_VIEW',
      payload: { toggleViewValue: !toggleViewValue },
    });
  };

  const controlsProjectLeft = [
    <TotalItems
      key='total-items'
      textLabel={`${t('projects.total_team')}: `}
      data={totalAmount}
    />,
    <SimpleSelect
      key='select-status'
      selectWrapperClass='select_horizontal_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t('status.employeeStatus') as string}
      options={selectOptions}
      value={{
        label: toggleStatusValue ? 'Active' : 'Inactive',
        value: toggleStatusValue,
      }}
      styles={controlsStatusStyle}
      onChange={handleSelectChange}
    />,
  ];

  const controlsProjectRight = [
    <Button
      key='add-team-member-button'
      styleClass='btn__string--bold--blue'
      label={t('buttons.add_team_member') as string}
      onClick={openAddTeamMemberModal}
    />,
    <ToggleSlider
      key='toggle-slider'
      isOn={toggleViewValue}
      type='checkbox'
      className='slider-checkbox'
      id='detailedView'
      handleToggle={handleToggleChange}
      toggleSliderTitle={t('buttons.detailed_view') as string}
    />,
  ];

  return {
    controlsProjectLeft,
    controlsProjectRight,
  };
};

import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import { projectEmployeeReportColumnsXLS } from './xlsColumns';
import { ProjectEmployeeReport } from '../api/api-types';

export const exportProjectEmployeeReport = async (
  reportProjectEmployee: ProjectEmployeeReport,
) => {
  const workbook = new Excel.Workbook();
  try {
    const fileName = `${reportProjectEmployee.projectName}-${reportProjectEmployee.employeeFullNameAndId?.name} report ${reportProjectEmployee.startDate}-${reportProjectEmployee.endDate}`;

    const worksheet = workbook.addWorksheet('Project employee report');

    worksheet.columns = projectEmployeeReportColumnsXLS;

    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A6CCF7' },
      };
      cell.font = { bold: true };
    });

    const addTotalRow = (totalValue: string, rowNumber: number) => {
      const getLastRow = worksheet.getRow(rowNumber + 1);
      getLastRow.getCell('A').value = {
        richText: [{ font: { bold: true }, text: 'Total: ' }],
      };
      getLastRow.getCell('B').value = {
        richText: [
          {
            font: { bold: true },
            text: `${reportProjectEmployee.employeeFullNameAndId?.name}`,
          },
        ],
      };
      getLastRow.getCell('G').value = {
        richText: [{ font: { bold: true }, text: `${totalValue}` }],
      };
      getLastRow.getCell('G').alignment = { horizontal: 'right' };
      getLastRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E0E0E0' },
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      getLastRow.commit();
    };

    (reportProjectEmployee.timeLogs ?? []).map((item) =>
      worksheet.addRow({
        date: item.date,
        task: item.taskName,
        note: item.note,
        employee: reportProjectEmployee.employeeFullNameAndId?.name,
        duration: item.duration,
        project: reportProjectEmployee.projectName,
        client: reportProjectEmployee.customerFullName?.name,
      }),
    );

    worksheet.eachRow((row) => {
      row.getCell('G').alignment = { horizontal: 'right' };
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      if (column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 24;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      }
      column.width = column.header === 'Note' ? 54 : 24;
      column.alignment =
        column.header !== 'Note'
          ? { vertical: 'middle', horizontal: 'center', wrapText: true }
          : { vertical: 'middle', horizontal: 'left', wrapText: true };
    });

    const { lastRow } = worksheet;

    if (lastRow?.number)
      addTotalRow(reportProjectEmployee?.subTotal ?? '', lastRow.number);

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), `${fileName}.xlsx`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.message;
  } finally {
    workbook.removeWorksheet('Project employee report');
  }
};

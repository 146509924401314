import { certifications, levels } from '../constants';

const answerMap: { [key: number]: JSX.Element } = {
  0: (
    <div className='answer-txt'>
      After successfully completing the task, simply inform Marvin.
    </div>
  ),
  1: (
    <>
      <div className='answer-txt'>
        The amount of b-coins for a successful candidate recommendation varies
        depending on the position level:
      </div>
      <ul className='answer-txt-list'>
        {levels.map((level, index) => (
          <li key={index}>
            {level.title} - <b>{level.cost}</b>
          </li>
        ))}
      </ul>
      <div className='answer-txt'>
        b-coin is accrued after the candidate has worked for <b>1 month</b> in
        the company.
      </div>
    </>
  ),
  2: (
    <div className='answer-txt'>
      b-coin for the anniversary of work in the company begins to accrue from 20
      bc for the first year, and +10 bc for each subsequent year.
    </div>
  ),
  3: (
    <>
      <div className='answer-txt'>
        The total limit per month is: 5 tags for stories and 2 tags for posts.
      </div>
      <div className='answer-txt'>
        The total limit of reposts per month is: 5
      </div>
    </>
  ),
  4: (
    <>
      <div className='answer-txt'>
        b-Coin season 3 ends on <b>12/31/2024</b>.
      </div>
      <div className='answer-txt'>
        The participant who takes 1st place in the b-coin rating as of{' '}
        <b>12/31/2024</b>, will be able to choose any corporate merch from the
        store as an additional reward.
      </div>
      <div className='answer-txt'>
        Gifts worth as little as 150 b-coins can only be gained during{' '}
        <b>December 2024</b>.
      </div>
    </>
  ),
  5: (
    <div className='answer-txt'>
      No, the b-coin cannot be presented to other co-workers or combined to
      share the gift
    </div>
  ),
  6: (
    <>
      <div className='answer-txt'>
        You can leave a feedback about the company on <b>DOU</b>,{' '}
        <b>Glassdoor</b>, <b>Comparably</b> and <b>Google</b> <b>only once</b>.
      </div>
      <div className='answer-txt'>
        <i>
          We also ask you to leave your feedback after 48 hours after the
          previous one, which was posted in the profile.
        </i>
      </div>
    </>
  ),
  7: (
    <>
      {certifications.map((cert, index) => (
        <div className='answer-txt' key={index}>
          {cert.cost && <b>{cert.cost}</b>} {cert.description}
          {cert.note && <i>{cert.note}</i>}
        </div>
      ))}
    </>
  ),
  8: (
    <>
      <div className='answer-txt'>
        You need to wait for about <b>2-10</b> business days from the date of
        request.
      </div>
      <div className='answer-txt'>
        The delivery time of the order depends on several factors, including{' '}
        <b>delivery and the availability of the goods</b> with us or the store.
      </div>
      <div className='answer-txt'>
        Branded goods, such as <b>T-shirts, cups, bags, and other merch</b>, can
        be produced in about <b>20 business days</b>, since this process takes
        longer and depends on design factors and the quantity of the product to
        be produced.
      </div>
    </>
  ),
};

export const selectAnswer = (index: number) => {
  return answerMap[index] || null;
};

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const LoginPage = () => {
  const { loginWithRedirect, isLoading, user } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading]);
  return null;
};

export default LoginPage;

import { generatorObject } from '../../utils/generate-ids';
import { displayBillable } from '../../utils/conditionalRowStyles';
import ReportTable from '../../components/Tables/ReportTable';
import {
  employeePMReport3Col,
  employeePMReport5Col,
} from '../../modules/Reports/columns';
import Loader from '../../components/Loader/Loader';
import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import { useReportPMEmployee } from './useReportPMEmployee';
import { useMemo } from 'react';
import ProjectEmployeeReportName from './components/ProjectEmployeeReportName';
import Row from '../../components/Row/Row';

const ReportPmEmployee = () => {
  const {
    displayEmptyDataBlock,
    displayTotalRow,
    isLoading,
    reportPMEmployee,
    t,
    tableRef,
  } = useReportPMEmployee();

  const renderEmployeeReports = useMemo(
    () =>
      reportPMEmployee?.timeLogsPerProjectAndEmployee?.map((employee) => (
        <div key={`key-employee-${generatorObject.next().value}`}>
          <ProjectEmployeeReportName
            employeeName={reportPMEmployee.fullEmployeeName?.name}
            projectName={employee.projectName}
          />

          <ReportTable
            data={employee.timeLogs}
            changeRowColor={displayBillable}
            columns={
              employee?.projectName === 'Internal Administration'
                ? employeePMReport5Col(t)
                : employeePMReport3Col(t)
            }
            ref={tableRef}
            tableName='pm-employee-list'
          />

          <Row
            label={t('reports.subtitle.sub_total')}
            rowClass='sub_total'
            value={employee.subTotal}
          />
        </div>
      )),
    [reportPMEmployee, t, tableRef],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {renderEmployeeReports}
      {displayTotalRow && (
        <Row
          label={t('reports.subtitle.only_total')}
          rowClass='total'
          value={reportPMEmployee.totalDurationHours}
        />
      )}
      {displayEmptyDataBlock && <EmptyDataBlock />}
    </>
  );
};

export default ReportPmEmployee;

import { useMemo } from 'react';
import { VacationList } from '../../../api/api-types';
import { TranslationFunction } from '../../../components/type-helpers/translation';
import { sortObjectsByDate } from '../../../utils/filtering';

export const useVacationTableData = (
  vacationData: VacationList | null,
  t: TranslationFunction,
) => {
  return useMemo(() => {
    const archivedVacation =
      vacationData?.archivedVacations?.map((el) => ({
        vacation: t('columns.archived'),
        ...el,
        days: el.duration === 4.0 ? '0.5' : el.days,
      })) || [];

    const upcomingVacation =
      vacationData?.upcomingVacations?.map((el) => ({
        vacation: t('columns.upcoming'),
        ...el,
        days: el.duration === 4.0 ? '0.5' : el.days,
      })) || [];

    const upcomingByDate = sortObjectsByDate(upcomingVacation, 'start');
    const archivedByDate = sortObjectsByDate(archivedVacation, 'start');

    return [...upcomingByDate, ...archivedByDate];
  }, [vacationData, t]);
};

import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import './confirmation-popup.scss';

export type ConfirmationPopupInitialProps = {
  onSuccess: () => void;
  text: TextModel;
};

type TextModel = {
  title: string;
  subtitle?: string;
  cancel: string;
  confirm: string;
};

export const ConfirmationPopupInitial = (
  props: ConfirmationPopupInitialProps,
) => (
  <>
    <Dialog.Title className='popup_title'>{props.text.title}</Dialog.Title>
    {props.text.subtitle ? (
      <p className='popup_description'>{props.text.subtitle}</p>
    ) : (
      <p className='popup_description_empty' />
    )}
    <div className='popup_actions'>
      <Dialog.Close asChild>
        <button className='popup_button popup_button_cancel' type='button'>
          {props.text.cancel}
        </button>
      </Dialog.Close>
      <button
        onClick={props.onSuccess}
        className='popup_button popup_button_confirm'
        type='button'
      >
        {props.text.confirm}
      </button>
    </div>
  </>
);

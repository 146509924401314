import { FC } from 'react';
import { exportTotalReport } from '../../../../xls/totalReportXLS';
import { CLIENT_REPORT_TITLE } from '../utils/titleHandler';
import { GenerateReportButton } from './GenerateReportButton';
import { ClientReportHeaderProps } from './types';

export const ClientReportHeader: FC<ClientReportHeaderProps> = ({
  endDate,
  reportClient,
  startDate,
  t,
}) => (
  <>
    <h3>{CLIENT_REPORT_TITLE(t, startDate, endDate)}</h3>
    {!!reportClient?.projectCountHoursDtoList?.length && (
      <GenerateReportButton
        label={t('generate_report.title') as string}
        onClick={() =>
          exportTotalReport({
            reportType: 'Client',
            reportTotal: reportClient,
            startDate: startDate ?? '',
            endDate: endDate ?? '',
          })
        }
        styleClass='btn__string--underlined--grey'
      />
    )}
  </>
);

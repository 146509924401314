import Button from '../../../../components/Button/Button';
import { TranslationFunction } from '../../../../components/type-helpers/translation';

type SubmitButtonProps = {
  disabled: boolean;
  isLoading: boolean;
  submitHandler: () => void;
  t: TranslationFunction;
};

export const renderSubmitButton = ({
  disabled,
  isLoading,
  submitHandler,
  t,
}: SubmitButtonProps) => (
  <Button
    label={t('buttons.submit') as string}
    styleClass='btn__rounded-blue'
    disabled={disabled || isLoading}
    onClick={submitHandler}
  />
);

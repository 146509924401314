import { useEffect } from 'react';
import { getTask } from '../../../api/tasks';
import { fetchEmployeesByRole } from '../../../api/team';
import { getProjectInfo } from '../../../api/projects';
import { getAllCustomersLocations } from '../../../api/customers';
import { useAppDispatch } from '../../../hooks/redux';
import { PageEmployeeProjectTaskDto } from '../../../api/api-types';

interface UseFetchDataProps {
  currentPage: number;
  dispatch: ReturnType<typeof useAppDispatch>;
  memberList: PageEmployeeProjectTaskDto['content'];
  sortParam: {
    key: string;
    sortOrder: string;
  };
  statusCurrent: React.MutableRefObject<boolean>;
  toggleStatusValue: boolean;
  updateProjectData: () => void;
  updateTeamMemberList: () => void;
}

export const useFetchData = ({
  currentPage,
  dispatch,
  memberList,
  sortParam,
  statusCurrent,
  toggleStatusValue,
  updateProjectData,
  updateTeamMemberList,
}: UseFetchDataProps) => {
  useEffect(() => {
    updateProjectData();

    fetchEmployeesByRole('management', true, true).then((data) => {
      if (!data) return;

      dispatch({ type: 'GET_PM', payload: data });
    });

    getTask({}).then((data) => {
      if (!data) return;

      dispatch({ type: 'GET_TASKS', payload: data });
    });

    getAllCustomersLocations().then((data) => {
      if (!data) return;

      dispatch({ type: 'GET_LOCATIONS', payload: data });
    });

    getProjectInfo().then((data) => {
      if (!data) return;

      dispatch({ type: 'GET_PROJECTS_INFO', payload: data });
    });

    fetchEmployeesByRole().then((data) => {
      if (!data) return;

      dispatch({
        type: 'GET_EMPLOYEES_BY_ROLE',
        payload: data,
      });
    });
  }, []);

  useEffect(() => {
    statusCurrent.current = toggleStatusValue;

    updateTeamMemberList();

    return () => {
      dispatch({ type: 'CLEAR_TEAM_MEMBERS', payload: memberList });
    };
  }, [currentPage, sortParam, toggleStatusValue]);
};

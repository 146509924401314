import Button from '../../components/Button/Button';
import styles from '../../styles/sickness.module.scss';
import { useTranslation } from 'react-i18next';

type EditContentModal = {
  onCancel: () => void;
  onContinue: () => void;
};

const EditContentModal = (props: EditContentModal) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h3>{t('modals.titles.support_sickness_edit')}</h3>
        <p>{t('modals.fields.support_edit_sickness_txt')}</p>
      </div>
      <div className={styles.editingModalBtnBlock}>
        <Button
          label='Continue editing'
          className={`btn__rounded-blue ${styles.editingButton}`}
          onClick={props.onContinue}
        />
        <Button
          className={`btn__string--normal--grey ${styles.editingButton}`}
          onClick={props.onCancel}
          label='Cancel'
        />
      </div>
    </>
  );
};

export default EditContentModal;

import { useState } from 'react';

export type SortName =
  | 'employeeName'
  | 'endDate'
  | 'managerIdAndName.name'
  | 'overtimeRate'
  | 'partTimeRate'
  | 'projectName'
  | 'startDate'
  | 'task.name'
  | (string & Record<never, never>);

const chooseSortParam = (sortName: SortName) => {
  switch (sortName) {
    case 'employeeName':
      return 'employee.name';
    case 'endDate':
      return 'endDate';
    case 'managerIdAndName.name':
      return 'projectManager.name';
    case 'overtimeRate':
      return 'overtimeRate';
    case 'partTimeRate':
      return 'partTimeRate';
    case 'projectName':
      return 'name';
    case 'startDate':
      return 'startDate';
    case 'task.name':
      return 'task.name';
    default:
      return 'name';
  }
};

const useSortDataTable = (defaultKey: string) => {
  const [sortParam, setSortParam] = useState({
    key: defaultKey,
    sortOrder: 'asc',
  });

  const toggleSortParam = (sortName: SortName) => {
    const name = chooseSortParam(sortName);

    const order = sortParam.sortOrder === 'asc' ? 'desc' : 'asc';

    return setSortParam({
      ...sortParam,
      key: name,
      sortOrder: order,
    });
  };

  return {
    sortParam,
    toggleSortParam,
  };
};

export default useSortDataTable;

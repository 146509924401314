import {
  TIME,
  RANGE,
  ONE_DAY_ACTIVITY_LOGS,
  makeRequest,
  handleError,
} from './config';
import {
  TimeLogDto,
  MonthLogs,
  TimeLogEditDto,
  TrackForm,
  ProjectLastUsedDto,
} from './api-types';

type GetTimeLog = {
  date: string;
  userId?: number;
};

export const getTimeLog = async ({ date, userId }: GetTimeLog) => {
  try {
    const query = `?date=${date}${userId ? `&userId=${userId}` : ''}`;
    const url = `${TIME}${query}`;
    const response = await makeRequest.get<MonthLogs>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetTimeLogForEmployee = {
  userId?: number;
};

export const getTimeLogDataForEmployee = async ({
  userId,
}: GetTimeLogForEmployee) => {
  try {
    const query = userId ? `/parameters?userId=${userId}` : '/parameters';
    const url = `${TIME}${query}`;
    const response = await makeRequest.get<ProjectLastUsedDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getTimeLogDetails = async (id: number) => {
  try {
    const url = `${TIME}/${id}`;
    const response = await makeRequest.get<TimeLogDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const postTimeLog = async (data: TrackForm) => {
  try {
    const url = `${TIME}${ONE_DAY_ACTIVITY_LOGS}`;
    const response = await makeRequest.post<number>(url, data);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PutTimeLog = {
  id: number;
  data: TimeLogEditDto;
};

export const putTimeLog = async ({ id, data }: PutTimeLog) => {
  try {
    const url = `${TIME}/${id}`;
    await makeRequest.put(url, data);
  } catch (e) {
    handleError(e);
  }
};

export const deleteTimeLogRange = async (ids: string[]) => {
  try {
    const idList = ids.join(',');
    const url = `${TIME}${RANGE}/?idList=${idList}`;
    await makeRequest.delete(url);
  } catch (e) {
    handleError(e);
  }
};

import styles from '../../../styles/time-tracking.module.scss';
import { ButtonBlockProps } from './types';
import { useButtonBlock } from './useButtonBlock';
import { renderSubmitButton } from './buttons/submitButton';
import { renderEditButtons } from './buttons/editButtons';

const ButtonBlock = (props: ButtonBlockProps) => {
  const {
    editValuesMode,
    disabled,
    isLoading,
    removeEditMode,
    saveEditsHandler,
    submitHandler,
    t,
  } = useButtonBlock(props);

  return (
    <div className={styles.formFooterEnd}>
      {!editValuesMode
        ? renderSubmitButton({
            disabled,
            isLoading,
            submitHandler,
            t,
          })
        : renderEditButtons({
            disabled,
            removeEditMode,
            saveEditsHandler,
            t,
          })}
    </div>
  );
};

export default ButtonBlock;

import React, { useState } from 'react';

type CheckboxProps = {
  labelclassname?: string;
  title?: string;
} & JSX.IntrinsicElements['input'];

const Checkbox = (props: CheckboxProps) => {
  const {
    labelclassname = 'checkbox_label',
    title = '',
    type = 'checkbox',
    name = 'input',
    checked = true,
    disabled = false,
  } = props;
  const [isChecked, setIsChecked] = useState(false);
  return (
    <label className={labelclassname}>
      <input
        onClick={() => setIsChecked(!isChecked)}
        type={type}
        name={name}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <span className='custom-indicator' />
      <span>{title}</span>
    </label>
  );
};

export default Checkbox;

// TODO: find better way to work with translations

import { TranslationFunction } from '../components/type-helpers/translation';
import { ACCRUAL_FILTER_TYPE } from '../translations/accrualTypeFilterEN';

export const restAccrualTypes = (t: TranslationFunction) =>
  [
    {
      label: t('reports.accrual_type.value.BILLABLE'),
      value: ACCRUAL_FILTER_TYPE.BILLABLE,
    },
    {
      label: t('reports.accrual_type.value.INTERNAL_BILLABLE'),
      value: ACCRUAL_FILTER_TYPE.INTERNAL_BILLABLE,
    },
    {
      label: t('reports.accrual_type.value.NON_BILLABLE'),
      value: ACCRUAL_FILTER_TYPE.NON_BILLABLE,
    },
  ] as const;

export const defaultAccrualType = (t: TranslationFunction) =>
  ({
    label: t('reports.accrual_type.value.ALL'),
    value: ACCRUAL_FILTER_TYPE.ALL,
    // eslint-disable-next-line prettier/prettier
  }) as const;

export const accrualTypes = (t: TranslationFunction) =>
  [defaultAccrualType(t), ...restAccrualTypes(t)] as const;

export type AccrualTypeOptions = ReturnType<typeof accrualTypes>;
export type AccrualTypeOption = AccrualTypeOptions[number];

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Romania',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
] as const;

export const dayOffOptions = (t: TranslationFunction) => [
  {
    label: t('vacation.vacation_data.paid_vacations'),
    value: t('vacation.vacation_data.paid_vacations'),
  },
  {
    label: t('vacation.vacation_data.unpaid_day_offs'),
    value: t('vacation.vacation_data.unpaid_day_offs'),
  },
];

export const monthName = (t: TranslationFunction) => [
  t('months.0'),
  t('months.1'),
  t('months.2'),
  t('months.3'),
  t('months.4'),
  t('months.5'),
  t('months.6'),
  t('months.7'),
  t('months.8'),
  t('months.9'),
  t('months.10'),
  t('months.11'),
];

export const reportType = (t: TranslationFunction) => [
  {
    label: t('reports.types.value.by_client'),
    value: t('reports.types.value.by_client'),
  },
  {
    label: t('reports.types.value.by_project'),
    value: t('reports.types.value.by_project'),
  },
  {
    label: t('reports.types.value.by_pm'),
    value: t('reports.types.value.by_pm'),
  },
  {
    label: t('reports.types.value.by_total'),
    value: t('reports.types.value.by_total'),
  },
];

export const sortByOptions = (t: TranslationFunction) => [
  { label: t('reports.filters.0.0'), value: t('reports.filters.0.0') },
  {
    label: t('reports.accrual_type.title'),
    value: t('reports.accrual_type.title'),
  },
  { label: t('reports.filters.1.0'), value: t('reports.filters.1.0') },
  { label: t('reports.filters.0.1'), value: t('reports.filters.0.1') },
];

export const statusOptions = (t: TranslationFunction) => [
  { label: t('status.value.0'), value: t('status.value.0') },
  { label: t('status.value.1'), value: t('status.value.1') },
];

import React, { forwardRef, useEffect, useState } from 'react';
import { useExpanded, useSortBy, useTable } from 'react-table';
import Button from '../Button/Button';
import { ReactComponent as VectorDown } from '../../icons/VectorDown.svg';
import { ReactComponent as VectorUp } from '../../icons/VectorUp.svg';
import './tables.scss';

type ExpandableTableProps = {
  columns: any[];
  data: any[];
  editedRowId?: string;
  expandedRowId?: any;
  expandedRowKey?: string;
  expandedRowStyle?: any;
  onRowClick: (row: any) => void;
  rowDepth?: number;
  setExpandedRowId: (id: string) => void;
  sortOrder?: any[];
  tableName: string;
};

const ExpandableTable = forwardRef<HTMLTableElement, ExpandableTableProps>(
  (
    {
      columns,
      data,
      editedRowId,
      expandedRowId,
      expandedRowKey,
      expandedRowStyle,
      onRowClick,
      rowDepth,
      setExpandedRowId,
      sortOrder,
      tableName,
    },
    ref,
  ) => {
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const expandAllRows = () => {
      setIsAllExpanded((prev) => !prev);
    };

    const {
      getTableBodyProps,
      getTableProps,
      headerGroups,
      rows,
      prepareRow,
      //@ts-ignore
      toggleAllRowsExpanded, //TODO: resolve this issue with typing
    } = useTable(
      {
        columns: columns ?? [],
        data: data ?? [],
        autoResetPage: false,
        // autoResetExpanded: false, // causes row collapse on changing page
        // autoResetGroupBy: false, 
        // autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        // autoResetRowState: false, // causes delay before row expanding on changing page
        disableSortRemove: true,
        defaultCanSort: true,
        initialState: {
          //@ts-ignore
          expanded: expandedRowId ?? '',
          sortBy: sortOrder ?? [],
        },
      },
      useSortBy,
      useExpanded,
    );

    useEffect(() => {
      toggleAllRowsExpanded(isAllExpanded);
    }, [isAllExpanded, toggleAllRowsExpanded]);

    return (
      <table className={tableName} {...getTableProps()} ref={ref}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                sortOrder?.length !== 0 ? (
                  //TODO: resolve this issue when moving to react-table v8
                  //@ts-ignore
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </th>
                ) : (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ),
              )}
            </tr>
          ))}
        </thead>
        <thead className='expand_all_container'>
          <tr>
            <td>
              <Button
                icon={isAllExpanded ? <VectorUp /> : <VectorDown />}
                onClick={expandAllRows}
              ></Button>
            </td>
          </tr>
          <tr className='expand_all_label_container'>
            <td className='expand_all_label'>
              {isAllExpanded ? 'Minimize' : 'Expand All'}
            </td>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            //TODO: resolve this issue when moving to react-table v8
            //@ts-ignore
            if (row.depth > (rowDepth ?? 0)) {
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => onRowClick(row)}
                  className={
                    editedRowId
                      ? //TODO: resolve this issue when moving to react-table v8
                        //@ts-ignore
                        chooseRowStyle(editedRowId, row, row.depth)
                      : ''
                  }
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} style={expandedRowStyle}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            }
            return (
              <tr
                className={
                  //TODO: resolve this issue when moving to react-table v8
                  //@ts-ignore
                  editedRowId ? chooseRowStyle(editedRowId, row, row.depth) : ''
                }
                {...row.getRowProps()}
                onClick={() => {
                  if (expandedRowKey) {
                    setExpandedRowId(row.id);
                    localStorage.setItem(expandedRowKey, row.id);
                  }
                }}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);

const chooseRowStyle = (editedRowId: string, row: any, rowDepth: number) => {
  switch (true) {
    case editedRowId === row.id.split('.')[0]:
      return 'editSubRow';
    case editedRowId === row.id:
      return 'editRow';
    default:
      return `subRow-${rowDepth}`;
  }
};

export default ExpandableTable;

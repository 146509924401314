import { ConfirmationPopupInitial } from '../ConfirmationPopupInitial';
import { ConfirmationPopupSuccess } from '../ConfirmationPopupSuccess';
import { ConfirmationPopupContentProps } from './types';

export const getConfirmationPopupContent = (
  props: ConfirmationPopupContentProps,
) => {
  switch (props.state) {
    case 'initial': {
      return (
        <ConfirmationPopupInitial
          onSuccess={props.onSuccess}
          text={props.text.initialState}
        />
      );
    }
    case 'success': {
      return <ConfirmationPopupSuccess text={props.text.successState} />;
    }
    default: {
      return null;
    }
  }
};

import { ReactComponent as SicknessIcon } from './../icons/SicknessIcon.svg';
import { ReactComponent as TimeTrackingIcon } from './../icons/TimeTrackingIcon.svg';
import { ReactComponent as VacationIcon } from './../icons/VacationIcon.svg';
import { ReactComponent as BCoinIcon } from './../icons/BCoinIcon.svg';
import { ConfigsArray, ConfigsProps } from './types';

const configDefinitions = [
  {
    id: 'bcoin-icon',
    title: 'menu.bcoin',
    icon: <BCoinIcon />,
    isEmployeeSpecific: false,
  },
  {
    id: 'tracking-icon',
    title: 'menu.time_tracking',
    icon: <TimeTrackingIcon />,
    isEmployeeSpecific: true,
  },
  {
    id: 'sickness-icon',
    title: 'menu.sickness',
    icon: <SicknessIcon />,
    isEmployeeSpecific: false,
  },
  {
    id: 'vacation-icon',
    title: 'menu.vacation',
    icon: <VacationIcon />,
    isEmployeeSpecific: false,
  },
];

export const createEmployeeConfigs = ({
  isEmployee,
  t,
}: ConfigsProps): ConfigsArray => {
  return configDefinitions
    .filter((config) => isEmployee || config.isEmployeeSpecific)
    .map(({ id, icon, title }) => ({
      id,
      icon,
      title: t(title),
    }));
};

import { HoursByProjectAndEmployee } from '../../../api/api-types';
import { groupBy } from '../../../utils/filtering';

export const groupProjectsByEmployee = (
  projectCountHoursDtoList: HoursByProjectAndEmployee[],
) => {
  return groupBy(
    projectCountHoursDtoList,
    (item) => item?.employeeFullName?.name ?? '',
  );
};

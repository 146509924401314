import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBillable from '../../../hooks/useBillable';
import { CustomerLocationDto, UserNameIdDto } from '../../../api/api-types';
import { CreateProjectModalData, UseCreateProjectModalReturn } from './types';
import { useModalButtons } from './hooks/useModalButtons';
import { useCustomerNames } from './hooks/useCustomerNames';
import { useProjectManagerOptions } from './hooks/useProjectManagerOptions';

export const useCreateProjectModal = (
  customerLocationList: CustomerLocationDto[],
  data: CreateProjectModalData,
  onSave: (event: CreateProjectModalData) => void,
  onClose: () => void,
  projectManagerList: UserNameIdDto[],
): UseCreateProjectModalReturn => {
  const { t } = useTranslation();

  const [projectState, setProjectState] = useState(data);
  const [billableType, setBillableType] = useState(projectState?.billableType);

  const modalButtons = useModalButtons({ t, onSave, projectState, onClose });

  const {
    handleProjectManagerChange,
    projectManagerOptions,
    selectedProjectManager,
  } = useProjectManagerOptions({
    projectManagerList,
    projectState,
    setProjectState,
  });

  const { filteredCustomers, customerNames } =
    useCustomerNames(customerLocationList);

  const billableTypeCheckboxes = useBillable({
    className: 'modal_default_checkbox',
    disabled: false,
    setState: setProjectState,
  });

  useEffect(() => {
    setBillableType(projectState?.billableType);
  }, [projectState?.billableType]);

  return {
    billableType,
    billableTypeCheckboxes,
    customerNames,
    filteredCustomers,
    projectState,
    setBillableType,
    setProjectState,
    t,
    modalButtons,
    projectManagerOptions,
    handleProjectManagerChange,
    selectedProjectManager,
  };
};

import { TranslationFunction } from '../../../type-helpers/translation';

export const getModalButtons = (
  isSaveDisabled: boolean,
  t: TranslationFunction,
  closeModal: () => void,
  onSave: () => void,
) => [
  {
    id: '0',
    className: 'btn__rounded-blue',
    label: t('buttons.save'),
    save: onSave,
    disabled: isSaveDisabled,
  },
  {
    id: '1',
    className: 'btn__string--normal--grey',
    close: closeModal,
    label: t('buttons.cancel'),
  },
];

import { useReducer, useRef, useEffect, useMemo, useCallback } from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { valuesInitialState, reducer } from '../../reducer/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../store';
import useTablePagination from '../../hooks/useTablePagination';
import { getClientPageData, getCustomer } from '../../api/customers';
import useSortDataTable from '../../hooks/useSortDataTable';
import { PageNameIdDto } from '../../api/api-types';
import { useModal } from '../../providers/ModalProvider';
import modals from '../../constants/modals';
import { sortByDefault } from '../../utils/filtering';
import { UseClientListPageReturn } from './types';

export const useClientListPage = (): UseClientListPageReturn => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { t } = useTranslation();
  
  const { showModal } = useModal();

  const state = useAppSelector((state) => state);

  const { clientList, clientListAll, clientPage } = useAppSelector(
    (state) => state,
  );

  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const { sortParam, toggleSortParam } = useSortDataTable('firstName');

  const { clientId, clientName, isLoading, pageData, selectedStatus } =
    handleValues;

  const { totalAmount } = pageData;

  const { currentPage, setCurrentPage } = useTablePagination();

  const { pathname } = useLocation();

  const showTable = !id && !!clientList.length && !isLoading;
  const showNoItemsWarning = !clientList?.length && !isLoading && !id;

  const setPageInitialData = (prevState: RootState) => {
    setHandleValues({
      type: 'SET_PAGE_DATA',
      payload: { ...prevState, rowData: [] },
    });
  };

  const setPageCurrentData = (info: PageNameIdDto) => {
    setHandleValues({
      type: 'SET_PAGE_DATA',
      payload: {
        pageData: { totalAmount: info.totalElements, rowData: info.content },
      },
    });
  };

  const getAllClients = () => {
    getCustomer({
      sortParam: `firstName,${sortParam.sortOrder}`,
      paged: false,
      unpaged: true,
    }).then((data) => {
      if (!data) return;
      dispatch({ type: 'GET_CLIENTS_ALL', payload: data.content });
    });
  };

  const updateClientsList = useCallback(() => {
    setHandleValues({ type: 'SET_LOADER', payload: { isLoading: true } });

    getCustomer({
      status: selectedStatus,
      pageNumber: currentPage,
      pageSize: 20,
      sortParam: `${sortParam.key},${sortParam.sortOrder}`,
    }).then((data) => {
      if (!data) return;

      setPageCurrentData(data);

      dispatch({ type: 'GET_CLIENTS', payload: data.content });

      getAllClients();

      setHandleValues({ type: 'SET_LOADER', payload: { isLoading: false } });
    });
  }, [selectedStatus, currentPage, sortParam]);

  const openAddClientModal = () =>
    showModal(modals.addEditClientModal, {
      modalTitle: 'New client',
      updateClientsList,
    });

  const openEditClientModal = () =>
    showModal(modals.addEditClientModal, {
      modalTitle: 'Edit client',
      data: clientPage || null,
      updateClientsList,
    });

  useEffect(() => {
    if (!clientListAll?.length) {
      getAllClients();
    }
  }, [!!clientListAll?.length, dispatch]);

  useEffect(() => {
    if (!clientId) return;

    setHandleValues({ type: 'SET_LOADER', payload: { isLoading: true } });

    getClientPageData({ id: clientId }).then((data) => {
      if (!data) return;
      setPageCurrentData({ content: [data], totalElements: 1 });
      dispatch({ type: 'GET_CLIENTS', payload: [data] });
      setHandleValues({ type: 'SET_LOADER', payload: { isLoading: false } });
    });
  }, [clientId]);

  useEffect(() => {
    if (!id) {
      dispatch({ type: 'CLEAR_CLIENT_DATA', payload: clientPage });
    }
  }, [id]);
  //TODO: why so much useEffects that depend on clientId? probably needs refactoring
  useEffect(() => {
    if (!clientId) {
      setHandleValues({ type: 'SET_LOADER', payload: { isLoading: true } });

      setPageInitialData(state);

      getCustomer({
        status: selectedStatus,
        pageNumber: currentPage,
        sortParam: `firstName,${sortParam.sortOrder}`,
        pageSize: 20,
      }).then((data) => {
        if (!data) return;

        setHandleValues({ type: 'SET_LOADER', payload: { isLoading: false } });

        setPageCurrentData(data);
        
        dispatch({ type: 'GET_CLIENTS', payload: data.content });
      });
    }
  }, [clientId, currentPage, pathname, selectedStatus, sortParam]);

  const clientColumns = useMemo(
    () => [
      {
        Header: `↑↓${t('columns.customer_name')}`,
        accessor: (client: any) => client.name ?? client.clientName,
      },
    ],
    [],
  );

  const tableInstance = useTable<{ pageIndex: number }>(
    {
      columns: clientColumns,
      //TODO: improve type handling when moving to react-table v8
      //@ts-expect-error
      data: clientList,
      //@ts-expect-error
      initialState: { pageIndex: 0 },
      manualPagination: true,
    },
    useGlobalFilter,
    useFilters,
    usePagination,
  );

  const tableRef = useRef<HTMLTableElement>(null);

  const clearSelectedStatus = () =>
    setHandleValues({
      type: 'SET_SELECTABLES',
      payload: { selectedStatus: t('status.value.0') },
    });

  const clearSelectedClient = () =>
    setHandleValues({
      type: 'SET_SELECTABLES',
      payload: { clientId: null, clientName: '' },
    });

  const clientListAllSorted = clientListAll.sort((a, b) =>
    sortByDefault(a, b, 'name'),
  );

  return {
    clearSelectedClient,
    clearSelectedStatus,
    clientColumns,
    clientId,
    clientList,
    clientListAll,
    clientListAllSorted,
    clientName,
    clientPage,
    currentPage,
    id,
    isLoading,
    navigate,
    openAddClientModal,
    openEditClientModal,
    selectedStatus,
    setCurrentPage,
    setHandleValues,
    showNoItemsWarning,
    showTable,
    tableInstance,
    tableRef,
    toggleSortParam,
    totalAmount,
  };
};

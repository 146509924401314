import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  Image,
  Font,
} from '@react-pdf/renderer';
import Button from '../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { ProjectReport } from '../api/api-types';
import { styles } from './styles';
import { formatDateYYYYMMDD } from '../utils/date-formatting';

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: '/fonts/Poppins/Poppins-Bold.ttf',
      fontWeight: 600,
    },
    {
      src: '/fonts/Poppins/Poppins-SemiBold.ttf',
      fontWeight: 500,
    },
    {
      src: '/fonts/Poppins/Poppins-Light.ttf',
      fontWeight: 300,
    },
  ],
});

export const MyDocument = ({
  data,
  employeeTaskName,
}: {
  data: ProjectReport;
  employeeTaskName?: string;
}) => {
  const startDate =
    data && formatDateYYYYMMDD(new Date(data.periodStartDate || new Date()));
  const endDate =
    data && formatDateYYYYMMDD(new Date(data.periodEndDate || new Date()));

  return (
    <Document>
      <Page size='A4' orientation='landscape' style={styles.page}>
        <View style={styles.header}>
          <View style={styles.projectNameContainer}>
            <Text style={styles.customerName}>
              {data.customerFullName?.name &&
                `${data.customerFullName?.name} - `}
            </Text>
            <Text style={styles.projectName}>{data.projectName?.name} </Text>
            {employeeTaskName && (
              <Text style={styles.customerName}>
                {` - ${employeeTaskName}`}
              </Text>
            )}
          </View>
          <Text style={styles.period}>
            {startDate} - {endDate}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCellHeader, styles.tableCellDate]}>
              Date
            </Text>
            <Text style={[styles.tableCellHeader, styles.tableCellUser]}>
              User
            </Text>
            <Text style={[styles.tableCellHeader, styles.tableCellProject]}>
              Project
            </Text>
            <Text style={[styles.tableCellHeader, styles.tableCellTask]}>
              Task
            </Text>
            <Text style={[styles.tableCellHeader, styles.tableCellDuration]}>
              Duration
            </Text>
            <Text style={[styles.tableCellHeader, styles.tableCellNotes]}>
              Notes
            </Text>
          </View>
          {data
            ? data.employeeTaskTimeLogsList?.map((logsList, index) => {
                return (
                  <View key={`${logsList.employeeFullName?.id}-${index}`}>
                    {logsList.timeLogs?.map((timeLog, timeLogIndex) => (
                      <View
                        style={
                          timeLogIndex % 2 === 0
                            ? styles.tableRow
                            : styles.tableRowOdd
                        }
                        key={`${timeLog.epdId}-${timeLog.timeLogId}`}
                        wrap={false}
                      >
                        <Text
                          style={[styles.tableCell, styles.tableCellDate]}
                          wrap={false}
                        >
                          {timeLog.date &&
                            formatDateYYYYMMDD(new Date(timeLog.date))}
                        </Text>
                        <Text
                          style={[styles.tableCell, styles.tableCellUser]}
                          wrap={false}
                        >
                          {logsList.employeeFullName?.name}
                        </Text>
                        <Text
                          style={[styles.tableCell, styles.tableCellProject]}
                          wrap={false}
                        >
                          {data.customerFullName?.name?.toUpperCase()}{' '}
                          {data.projectName?.name}
                        </Text>
                        <Text
                          style={[styles.tableCell, styles.tableCellTask]}
                          wrap={false}
                        >
                          {timeLog.taskName}
                        </Text>
                        <Text
                          style={[styles.tableCell, styles.tableCellDuration]}
                          wrap={false}
                        >
                          {timeLog.duration}
                        </Text>
                        <Text
                          style={[styles.tableCell, styles.tableCellNotes]}
                          wrap={false}
                        >
                          {timeLog.note}
                        </Text>
                      </View>
                    ))}
                    <View
                      style={[styles.tableRowOdd, styles.borderBottom]}
                      wrap={false}
                    >
                      <Text
                        style={[
                          styles.tableCell,
                          styles.tableCellDate,
                          styles.subtotalBold,
                        ]}
                        wrap={false}
                      >
                        Subtotal
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          styles.tableCellUser,
                          styles.subtotalBold,
                        ]}
                        wrap={false}
                      >
                        {logsList.employeeFullName?.name}
                      </Text>
                      <Text
                        style={[styles.tableCell, styles.tableCellProject]}
                        wrap={false}
                      ></Text>
                      <Text
                        style={[styles.tableCell, styles.tableCellTask]}
                        wrap={false}
                      ></Text>
                      <Text
                        style={[
                          styles.tableCell,
                          styles.tableCellDuration,
                          styles.subtotalBold,
                        ]}
                        wrap={false}
                      >
                        {logsList.subtotalDuration}
                      </Text>
                      <Text
                        style={[styles.tableCell, styles.tableCellNotes]}
                        wrap={false}
                      ></Text>
                    </View>
                    <View style={[styles.emptyRow]} wrap={false}></View>
                  </View>
                );
              })
            : null}
          <View style={[styles.tableRow, styles.borderBottom]} wrap={false}>
            <Text
              style={[
                styles.tableCell,
                styles.tableCellDate,
                styles.subtotalBold,
              ]}
              wrap={false}
            >
              Grand Total
            </Text>
            <Text
              style={[
                styles.tableCell,
                styles.tableCellDuration,
                styles.subtotalBold,
                styles.grandTotal,
              ]}
              wrap={false}
            >
              {' '}
              {data.totalHours}
            </Text>
          </View>
        </View>
        <Image
          fixed
          source={require('./logo.png')}
          style={{
            position: 'absolute',
            width: '110px',
            height: '21px',
            bottom: 34,
            right: 49,
          }}
        />
      </Page>
    </Document>
  );
};

const TotalReportPDFLink = ({
  data,
  employeeTaskName,
}: {
  data: ProjectReport;
  employeeTaskName?: string;
}) => {
  const { t } = useTranslation();

  const startDate =
    data && formatDateYYYYMMDD(new Date(data.periodStartDate || new Date()));
  const endDate =
    data && formatDateYYYYMMDD(new Date(data.periodEndDate || new Date()));

  const reportName = employeeTaskName
    ? `${data.projectName?.name} - ${employeeTaskName} - ${startDate} - ${endDate}.pdf`
    : `${data.projectName?.name} - ${startDate} - ${endDate}.pdf`;

  return (
    <PDFDownloadLink
      document={<MyDocument data={data} employeeTaskName={employeeTaskName} />}
      fileName={reportName}
    >
      {({ loading }) =>
        loading ? (
          <div className='btn__string--underlined--grey'>
            {t('generate_report.pdf_generating')}
          </div>
        ) : (
          <Button
            label={t('generate_report.pdf_title') as string}
            styleClass='btn__string--underlined--grey'
          />
        )
      }
    </PDFDownloadLink>
  );
};

export default TotalReportPDFLink;

import { CustomerLocationDto } from '../../../../api/api-types';
import { UseCustomerNamesReturn } from '../types';

export const useCustomerNames = (
  customerLocationList: CustomerLocationDto[],
): UseCustomerNamesReturn => {
  const customerNames = customerLocationList.map((location) => ({
    label: location.customerNameAndId?.name ?? '',
    value: location.customerNameAndId?.id,
  }));

  const filteredCustomers = customerNames.filter(
    (option, index, self) =>
      index ===
      self.findIndex(
        (el) => el.value === option.value && el.label === option.label,
      ),
  );

  return {
    customerNames,
    filteredCustomers,
  };
};

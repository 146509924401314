import classNames from 'classnames';
import Button from '../Button/Button';
import { useTablePagination } from './useTablePagination';
import { TablePaginationProps } from './types';

const pagination = classNames('pagination');
const pageButtons = classNames('pagination-buttons');
const pageBtn = classNames('btn__string--thin--grey');
const activeBtn = classNames('btn__string--underlined--grey');

const TablePagination = ({
  totalRows,
  rowsPerPage,
  currentPage,
  setCurrentPage,
}: TablePaginationProps) => {
  const {
    noOfPages,
    pagesArr,
    canGoBack,
    canGoNext,
    onNextPage,
    onPrevPage,
    onPageSelect,
  } = useTablePagination({
    totalRows,
    rowsPerPage,
    currentPage,
    setCurrentPage,
  });

  return (
    <div>
      {noOfPages > 1 && (
        <div className={pagination}>
          <div className={pageButtons}>
            <Button
              styleClass={pageBtn}
              onClick={onPrevPage}
              disabled={!canGoBack}
              label='&#8249;'
            />
            {pagesArr.map((_, index) => (
              <Button
                key={index + 1}
                onClick={() => onPageSelect(index)}
                styleClass={`${pageBtn}  ${
                  index === currentPage ? activeBtn : ''
                }`}
                label={`${index + 1}`}
              />
            ))}
            <Button
              styleClass={pageBtn}
              onClick={onNextPage}
              disabled={!canGoNext}
              label='&#8250;'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TablePagination;

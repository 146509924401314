import Button from '../../../components/Button/Button';
import ColumnEditBlock from '../../../modules/Sickness/ColumnEditBlock/ColumnEditBlock';
import { ReactComponent as DocumentIcon } from '../../../icons/DocumentIcon.svg';
import { CreateSicknessColumnsProps } from '../types';
import { SicknessPeriod } from '../../../api/api-types';
import { ExtendedRow } from '../../../modules/Sickness/ColumnEditBlock/types';

export const createSicknessColumns = ({
  editDraftSickness,
  editValuesMode,
  handleConfirmPopUp,
  handleGlobalEditMode,
  setEditDraftSickness,
  setEditedRowId,
  setHandleValues,
  setIsOpenEditModal,
  t,
}: CreateSicknessColumnsProps) => {
  return [
    {
      Header: t('columns.date'),
      accessor: 'date',
    },
    {
      Header: 'Days',
      accessor: 'days',
    },
    {
      Header: t('columns.note'),
      accessor: 'note',
    },
    {
      Header: `${t('columns.payment')} %`,
      accessor: 'payment',
    },
    {
      Header: t('columns.attachment'),
      accessor: 'fileKey',
      Cell: ({ row }: { row: ExtendedRow<SicknessPeriod> }) => (
        <div className='sickness_btn_wrapper'>
          {row.original.attachmentName && (
            <Button
              icon={<DocumentIcon />}
              onClick={() => {
                window.open(row.original.attachment, '_blank');
              }}
            />
          )}
        </div>
      ),
    },
    {
      Header: '',
      accessor: 'editRemove',
      Cell: ({ row }: { row: ExtendedRow<SicknessPeriod> }) => (
        <ColumnEditBlock
          editDraftSickness={editDraftSickness}
          editValuesMode={editValuesMode}
          handleConfirmPopUp={handleConfirmPopUp}
          handleGlobalEditMode={handleGlobalEditMode}
          row={row}
          setEditDraftSickness={setEditDraftSickness}
          setEditedRowId={setEditedRowId}
          setHandleValues={setHandleValues}
          setIsOpenEditModal={setIsOpenEditModal}
        />
      ),
    },
  ];
};

import React from 'react';

const TextAreaField = (props: TextAreaFieldProps) => {
  const {
    field,
    title,
    helperText,
    className,
    fieldClassName,
    titleClassName,
    helperTextClassName,
    ...rest
  } = props;
  return (
    <label htmlFor={field} className={className}>
      <span className={titleClassName}>{title}</span>
      <textarea name={field} className={fieldClassName} {...rest} />
      <span className={helperTextClassName}>{helperText}</span>
    </label>
  );
};

type TextAreaFieldProps = {
  field?: string;
  title?: string;
  helperText?: string;
  className?: string;
  fieldClassName?: string;
  titleClassName?: string;
  helperTextClassName?: string;
} & JSX.IntrinsicElements['textarea'];

export default TextAreaField;

import { useMemo } from 'react';
import TotalItems from '../../components/ControlsPanel/TotalItems';
import SimpleSelect from '../../components/Select/SimpleSelect';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import { ReactComponent as PenIcon } from '../../icons/PenIcon.svg';

import styles from '../../styles/client.module.scss';
import { ClientListControlsProps, ClientListControlsReturn } from './types';

export const clientListControls = ({
  totalAmount,
  selectedStatus,
  setHandleValues,
  clientId,
  clearSelectedClient,
  clientListAll,
  openEditClientModal,
  openAddClientModal,
  exportCustomerList,
  statusOptions,
  controlsStatusStyle,
}: ClientListControlsProps): ClientListControlsReturn => {
  const { t } = useTranslation();

  const controlsProjectLeft = useMemo(
    () => [
      <TotalItems
        key='key-clients-1'
        textLabel={`${t('clients.total')}: `}
        data={totalAmount}
      />,
      <SimpleSelect
        key='key-clients-2'
        selectWrapperClass='select_horizontal_wrapper'
        selectTitleClassName='select_title'
        selectTitle={t('status.title') as string}
        options={statusOptions(t)}
        value={{
          label: selectedStatus,
          value: selectedStatus,
        }}
        styles={controlsStatusStyle}
        onChange={(e) => {
          setHandleValues({
            type: 'SET_SELECTABLES',
            payload: { selectedStatus: e.value },
          });
          if (clientId) {
            clearSelectedClient();
          }
        }}
      />,
    ],
    [totalAmount, t],
  );

  const controlsProjectRightClientPage = [
    <Button
      key='key-client-7'
      icon={<PenIcon />}
      styleClass={`btn__icon ${styles.ml100}`}
      onClick={openEditClientModal}
    />,
  ];

  const controlsProjectRight = [
    <Button
      key='key-clients-4'
      icon='+'
      label={t('buttons.add_new_client') ?? ''}
      styleClass='btn__rounded-blue'
      onClick={openAddClientModal}
    />,
    !!clientListAll?.length && (
      <Button
        key='key-clients-3'
        label={t('generate_report.title') ?? ''}
        onClick={() => exportCustomerList(clientListAll)}
        styleClass='btn__string--underlined--grey'
      />
    ),
  ];

  return {
    controlsProjectLeft,
    controlsProjectRight,
    controlsProjectRightClientPage,
  };
};

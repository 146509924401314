import React from 'react';
import { Outlet } from 'react-router-dom';
import { statusOptions } from '../../constants/select-values';
import { controlsStatusStyle } from '../../components/Select/selectStyles';
import commonStyles from '../../styles/pm-flow.module.scss';
import Loader from '../../components/Loader/Loader';
import { exportCustomerList } from '../../xls/customerListXLS';
import ControlsPanel from '../../components/ControlsPanel/ControlsPanel';
import { useClientListPage } from './useClientListPage';
import { clientListControls } from './useClientListControls';
import Header from './components/Header/Header';
import ClientTable from './components/ClientTable/ClientTable';
import NoItemsWarning from './components/NoItemsWarning';

const ClientListPage = () => {
  const {
    clearSelectedClient,
    clearSelectedStatus,
    clientColumns,
    clientId,
    clientList,
    clientListAll,
    clientListAllSorted,
    clientName,
    clientPage,
    currentPage,
    id,
    isLoading,
    openAddClientModal,
    openEditClientModal,
    selectedStatus,
    setCurrentPage,
    setHandleValues,
    showNoItemsWarning,
    showTable,
    tableInstance,
    tableRef,
    toggleSortParam,
    totalAmount,
  } = useClientListPage();

  const {
    controlsProjectLeft,
    controlsProjectRight,
    controlsProjectRightClientPage,
  } = clientListControls({
    clearSelectedClient,
    clientId,
    clientListAll,
    controlsStatusStyle,
    exportCustomerList,
    openAddClientModal,
    openEditClientModal,
    selectedStatus,
    setHandleValues,
    statusOptions,
    totalAmount,
  });

  return (
    <div className={commonStyles.containerMinWidth}>
      <Header
        {...{
          clientListAllSorted,
          clientId,
          clientName,
          clientPage,
          clearSelectedStatus,
          id,
          setHandleValues,
        }}
      />

      <div className={commonStyles.container}>
        <ControlsPanel
          controlsLeftBlock={id ? null : controlsProjectLeft}
          controlsRightBlock={
            id ? controlsProjectRightClientPage : controlsProjectRight
          }
        />

        <Outlet />

        {showTable && (
          <ClientTable
            {...{
              clientColumns,
              clientList,
              currentPage,
              setCurrentPage,
              tableRef,
              tableInstance,
              toggleSortParam,
              totalAmount,
            }}
          />
        )}

        {showNoItemsWarning && <NoItemsWarning />}
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default ClientListPage;

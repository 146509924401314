import { CustomerRequestDto } from '../../../../api/api-types';
import { UseEditClientProps } from '../types';

export const useEditClient = ({
  clientState,
  closeModal,
  data,
  updateClient,
  updateClientsList,
}: UseEditClientProps) => {
  const onEditClient = async (): Promise<void> => {
    if (!data || !updateClientsList) return;

    const body = {
      clientName: clientState.clientName,
      unitId: clientState.unitId,
      customerContactPersonRequestDtos: clientState.contactPersons
        .filter(
          ({ name, phoneNumber, emails }) =>
            name || phoneNumber || emails.some(({ email }) => email),
        )
        .map(({ emails, name, phoneNumber }) => ({
          emailRequestSet: emails
            .filter(({ email }) => email)
            .map(({ email = '' }) => ({ email })),
          name,
          phoneNumber,
        })),
      location: clientState.locationDtoSet
        .filter(({ name }) => name)
        .map(({ name }) => name),
    } as CustomerRequestDto;

    await updateClient({ id: data?.id || 0, body });

    closeModal();
    updateClientsList?.();
  };

  return onEditClient;
};

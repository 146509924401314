export const levels = [
  { title: 'Trainee', cost: '25 b-coin' },
  { title: 'Junior', cost: '35 b-coin' },
  { title: 'Middle', cost: '45 b-coin' },
  { title: 'Senior', cost: '60 b-coin' },
  { title: 'Lead', cost: '70 b-coin' },
  { title: 'Architect', cost: '80 b-coin' },
];

export const certifications = [
  {
    cost: '5 b-coins',
    description:
      'for successful profile certifications by Microsoft Certification, for example, Developing ASP.NET MVC Web Applications.',
  },
  {
    cost: '10 b-coins',
    description:
      'for successful profile certifications by Microsoft Certification, for example, Developing ASP.NET MVC Web Applications.',
  },
  {
    cost: '25 b-coins',
    description:
      'for successful certifications by ISTQB and Scrum Master Certification.',
  },
  {
    cost: '35 b-coins',
    description:
      'for passing top certifications: Azure, AWS Certified, GCP professional, PMP, etc.',
  },
  {
    note: '* The list of certifications is not complete, so if you pass a certification that is not on the list, write to Marvin.',
  },
];

export const frequentQuestions = [
  {
    id: 'question-01',
    question: 'How do I get b-coins?',
  },
  {
    id: 'question-02',
    question:
      'How many b-coins will I get for a recommendation of a candidate?',
  },
  {
    id: 'question-03',
    question: 'How many b-coins will I get for my work anniversary?',
  },
  {
    id: 'question-04',
    question: 'How many company social media tags can I make per month?',
  },
  {
    id: 'question-05',
    question: 'When is the exchange deadline?',
  },
  {
    id: 'question-06',
    question: 'Can b-coin be presented to someone else?',
  },
  {
    id: 'question-07',
    question: 'How many feedbacks can I leave about the company?',
  },
  {
    id: 'question-08',
    question: 'What certifications can I get b-coin for?',
  },
  {
    id: 'question-09',
    question: 'How long should I wait for my order?',
  },
];

import './pm-report-list.scss';
import { ProjectManagerReportItem } from '../../api/api-types';
import { useParams } from 'react-router-dom';
import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import usePMReportList from './usePMReportList';
import Loader from '../Loader/Loader';

type PMReportListProps = {
  reportPMAll: ProjectManagerReportItem[];
};

const PMReportList: React.FC<PMReportListProps> = ({ reportPMAll }) => {
  const { pmId } = useParams();
  const { billableTypes, isLoading, pmSelectHandler } = usePMReportList();

  return (
    <div className='container'>
      <div className='list_header'>
        <div className='projects_count'>Projects ({reportPMAll.length})</div>
        <div className='employee_name'>Employee name</div>
        <div className='employee_duration'>Total duration</div>
      </div>

      {!!reportPMAll.length ? (
        reportPMAll.map((project) => (
          <div key={project.projectId} className='project_card'>
            <div className='project_info_col'>
              <div className='project_name'>{project.projectName}</div>
              {!pmId && (
                <div
                  className='pm_name'
                  onClick={
                    project.pmName
                      ? () => pmSelectHandler(project.pmId as number)
                      : undefined
                  }
                >
                  PM: <span>{project.pmName || '--'}</span>
                </div>
              )}
            </div>

            <div className='employee_list_col'>
              {project.employeeHoursMap &&
                Object.entries(project.employeeHoursMap).map(([key, value]) => (
                  <div key={`${key}-${value}`} className='duration_item'>
                    <div className='employee_name__value'>{value.name}</div>
                    <div className='employee_duration__value'>
                      <span>{value.nonBillable}</span> / {value.billable}
                    </div>
                  </div>
                ))}

              <div className='duration_item'>
                <div className='duration_item__label'>
                  Total{' '}
                  {billableTypes.length < 3 ? billableTypes.join(', ') : ''}
                </div>
                <div className='duration_item__value'>{project.total}</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <EmptyDataBlock />
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default PMReportList;

import React from 'react';

interface RowProps {
  value?: string;
  label: string;
  rowClass: string;
}

const Row: React.FC<RowProps> = ({ value, label, rowClass }) => {
  if (!value) return null;

  return (
    <div className={`${rowClass}_row`}>
      <div className={`${rowClass}_title`}>{label}</div>
      <div className={`${rowClass}_duration`}>{value}</div>
    </div>
  );
};

export default Row;

import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import { ReactComponent as WalletIcon } from '../../../icons/WalletIcon.svg';

const BCoinHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === '/b-coin/balance';
  return (
    <div className='bcoinheader'>
      <div className='bcoinheader__logo' />
      <div className='bcoinheader__btn-container'>
        <Button
          styleClass={`btn__bcoin-balance ${
            isActive ? 'btn__bcoin-balance-active' : ''
          }`}
          label='Your B-coin balance'
          icon={<WalletIcon />}
          onClick={() => navigate('/b-coin/balance')}
        />
        <a
          href='https://contacts.google.com/b-coin@binariks.com?hl=en'
          target='_blank'
          rel='noreferrer'
        >
          <Button styleClass='btn__bcoin-contact' label='Contact Marvin' />
        </a>
      </div>
    </div>
  );
};

export default BCoinHeader;

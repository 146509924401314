import Button from '../../../../components/Button/Button';
import TotalReportPDFLink from '../../../../pdf/ProjectReportPDF';
import { exportProjectReport } from '../../../../xls/projectReportXLS';
import { styles } from '../styles';
import { ProjectReportHeadersProps, ProjectReportHeadersReturn } from './types';

export const projectReportHeaders = ({
  ProjectEmployeeReportPDFLink,
  employeeReportTitle,
  employeeTaskName,
  exportProjectEmployeeReport,
  isEmployeeTimeLogs,
  reportProject,
  reportProjectEmployee,
  reportTitle,
  t,
}: ProjectReportHeadersProps): ProjectReportHeadersReturn => {
  // case for project-employee-taskName filter selected
  const ProjectEmployeeTaskSubHeader = () => (
    <>
      <h3>
        {reportProjectEmployee?.employeeFullNameAndId?.name
          ? employeeReportTitle
          : ''}
      </h3>
      <div style={styles.subHeaderBlock}>
        {/* XLS report for project-employee-task */}
        {!!reportProjectEmployee?.timeLogs?.length && (
          <Button
            label={t('generate_report.title') as string}
            onClick={() => exportProjectEmployeeReport(reportProjectEmployee)}
            styleClass='btn__string--underlined--grey'
          />
        )}
        {/* PDF report for project-employee-task */}
        {reportProjectEmployee.timeLogs && (
          <ProjectEmployeeReportPDFLink
            data={reportProjectEmployee}
            employeeTaskName={employeeTaskName}
          />
        )}
      </div>
    </>
  );

  // case for project-employeeAll-taskName filter selected
  const ProjectEmployeeAllTasksSubHeader = () => (
    <>
      <h3>{reportTitle}</h3>
      <div style={styles.subHeaderBlock}>
        {/* XLS report for project-task */}
        {isEmployeeTimeLogs && (
          <Button
            label={t('generate_report.title') as string}
            onClick={() => exportProjectReport(reportProject)}
            styleClass='btn__string--underlined--grey'
          />
        )}
        {/* PDF report for project-task */}
        {!!reportProject.employeeTaskTimeLogsList?.length && (
          <TotalReportPDFLink
            data={reportProject}
            employeeTaskName={employeeTaskName}
          />
        )}
      </div>
    </>
  );

  // case for only project filter selected
  const ProjectSubHeader = () => (
    <>
      <h3>{reportTitle}</h3>
      <div style={styles.subHeaderBlock}>
        {isEmployeeTimeLogs && (
          <Button
            label={t('generate_report.title') as string}
            onClick={() => exportProjectReport(reportProject)}
            styleClass='btn__string--underlined--grey'
          />
        )}
        {/* PDF report for project */}
        {isEmployeeTimeLogs && <TotalReportPDFLink data={reportProject} />}
      </div>
    </>
  );

  return {
    ProjectEmployeeTaskSubHeader,
    ProjectEmployeeAllTasksSubHeader,
    ProjectSubHeader,
  };
};

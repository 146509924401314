import { useState } from 'react';

const useTablePagination = () => {
  const [currentPage, setCurrentPage] = useState(0);

  return {
    currentPage,
    setCurrentPage,
  };
};

export default useTablePagination;

import { deleteTimeLogRange } from '../../../api/tracking';
import { UseTableRowDeletingProps } from '../types';

export const useTableRowDeleting = ({
  handleApprovingOfConfirmPopUp,
  handleConfirmPopUp,
  selectedMonth,
  updateVacationList,
}: UseTableRowDeletingProps) => {
  const handleTableRowDeleting = async (id: number) => {
    await deleteTimeLogRange([`${id}`]);

    updateVacationList(selectedMonth);
    handleApprovingOfConfirmPopUp(false);
    handleConfirmPopUp(false);
  };

  return handleTableRowDeleting;
};

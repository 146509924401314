import React from 'react';
import styles from './data-pair.module.scss';

type DataPairProps = {
  dataPairTitle?: string;
  title: string;
  value?: string;
  placeholder?: string;
};

const DataPair = (props: DataPairProps) => (
  <div className={styles.data_pair}>
    <span className={props.dataPairTitle}>{props.title}</span>
    {props.value && props.value === '' ? (
      <span className={styles.data_pair_placeholder}>{props.placeholder}</span>
    ) : props.value && props.value !== '' ? (
      <span className={styles.data_pair_value}>{props.value}</span>
    ) : null}
  </div>
);

export default DataPair;

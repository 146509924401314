import { BCoinEmployeeDto, BCoinRewardDto, BCoinTaskDto } from './api-types';
import {
  BCOIN,
  EMPLOYEES,
  IMAGES,
  REWARDS,
  TASKS,
  handleError,
  makeRequest,
} from './config';

export const getBCoinBalances = async (name?: string) => {
  const url = `${BCOIN}${EMPLOYEES}${name ? `?name=${name}` : ''}`;
  try {
    const response = await makeRequest.get<BCoinEmployeeDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinTasks = async () => {
  const url = `${BCOIN}${TASKS}`;
  try {
    const response = await makeRequest.get<BCoinTaskDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinRewards = async () => {
  const url = `${BCOIN}${REWARDS}`;
  try {
    const response = await makeRequest.get<BCoinRewardDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinRewardImages = async (id: number) => {
  const url = `${BCOIN}${REWARDS}/${id}${IMAGES}`;
  try {
    const response = await makeRequest.get<string[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const exchangeBCoinsForReward = async (id: number) => {
  const url = `${BCOIN}${REWARDS}/${id}`;
  try {
    await makeRequest.post(url);
  } catch (e) {
    handleError(e);
  }
};

export const sendBCoinTaskCompletion = async ({
  id,
  status,
}: {
  id: number;
  status: BCoinTaskStatusValue;
}) => {
  const url = `${BCOIN}${TASKS}/${id}?status=${status}`;
  try {
    await makeRequest.post(url);
  } catch (e) {
    handleError(e);
  }
};

export const BCOIN_TASK_STATUS = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
} as const;

type BCoinTaskStatus = typeof BCOIN_TASK_STATUS;
type BCoinTaskStatusKey = keyof BCoinTaskStatus;
export type BCoinTaskStatusValue = BCoinTaskStatus[BCoinTaskStatusKey];

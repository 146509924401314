import { FC } from 'react';
import Button from '../../../../components/Button/Button';

export const GenerateReportButton: FC<{
  label: string;
  onClick: () => void;
  styleClass: string;
}> = ({ label, onClick, styleClass }) => (
  <Button label={label} onClick={onClick} styleClass={styleClass} />
);

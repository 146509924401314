import { ReportHeaderProps } from '../types';

const getProjectReportHeader = ({
  billable,
  employeeId,
  employeeTaskName,
  endDate,
  location,
  projectId,
  reportProject,
  reportProjectEmployee,
  startDate,
  t,
}: ReportHeaderProps) => {
  const employeeName = reportProjectEmployee?.employeeFullNameAndId?.name;
  const projectName = reportProjectEmployee?.projectName;

  const basePath = '/reports/by-projects';
  const path = location.pathname;

  const taskName = employeeTaskName?.replace(' ', '%20'); // for url parsing

  const conditions: { condition: boolean; result: string }[] = [
    {
      condition:
        path ===
        `${basePath}/project${projectId}/employee${employeeId}/start${startDate}/end${endDate}/accrual${billable}/tasksAll`,
      result: ` > ${t('reports.subtitle.report')}${
        employeeName ? `/${employeeName}` : ''
      }${projectName ? `/${projectName}` : ''}`,
    },
    {
      condition:
        path ===
        `${basePath}/project${projectId}/employee${employeeId}/start${startDate}/end${endDate}/accrual${billable}/tasks${taskName}`,
      result: ` > ${t('reports.subtitle.report')}${
        employeeName ? `/${employeeName}` : ''
      }${projectName ? `/${projectName}` : ''}${
        employeeTaskName ? `/${employeeTaskName}` : ''
      }`,
    },
    {
      condition:
        path ===
        `${basePath}/project${projectId}/start${startDate}/end${endDate}/accrual${billable}/tasksAll`,
      result: ` > ${t('reports.subtitle.report')}${
        reportProject?.projectName?.name
          ? `/${reportProject.projectName.name}`
          : ''
      }`,
    },
  ];

  return conditions.find((cond) => cond.condition)?.result || null;
};

export default getProjectReportHeader;

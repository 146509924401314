import { format } from 'date-fns';
import { getVacationData } from '../../../api/vacation';
import { getVacationDataAction } from '../../../store/actionCreators/vacationActionCreators';
import { Msg, SET_LOADER } from '../../../store/actions';
import { Dispatch } from 'react';
import { AppDispatch } from '../../../store';

export const fetchVacationData = async (
  setHandleValues: Dispatch<Msg>,
  dispatch: AppDispatch,
) => {
  try {
    const vacationData = await getVacationData({
      yearMonth: format(new Date(), 'yyyy-MM'),
    });

    setHandleValues(SET_LOADER({ payload: { isLoading: false } }));

    if (!vacationData) return;

    dispatch(getVacationDataAction(vacationData));
  } catch (error) {
    console.error('Failed to fetch vacation data:', error);
    setHandleValues(SET_LOADER({ payload: { isLoading: false } }));
  }
};

import { UseOnTaskChangeProps } from '../types';

export const useOnTaskChange = ({
  employeeProjectTasks,
  formValues,
  setFormValues,
}: UseOnTaskChangeProps) => {
  const onTaskChange = async (taskId: number | null | undefined) => {
    if (!taskId) return;

    const defaultType = employeeProjectTasks?.find(
      ({ eptId }) => eptId === taskId,
    )?.billableType;

    setFormValues({
      ...formValues,
      taskId,
      accrualType: defaultType || '',
    });
  };

  return onTaskChange;
};

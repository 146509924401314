import DataPair from '../../../../DataPair/DataPair';
import TextAreaField from '../../../../TextAreaField/TextAreaField';
import dataPairStyles from '../../../../DataPair/data-pair.module.scss';
import { ReportDescriptionProps } from './types';

const ReportDescription = ({
  title,
  editMode,
  value,
  onChange,
  maxLength,
  styles,
}: ReportDescriptionProps) => (
  <>
    <DataPair dataPairTitle={dataPairStyles.data_pair_title} title={title} />
    <div className={styles.data_input}>
      {editMode ? (
        <TextAreaField
          field='description'
          fieldClassName={styles.report_description_input}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
      ) : (
        <div className={styles.data_textarea_readonly}>{value}</div>
      )}
    </div>
  </>
);

export default ReportDescription;

import { toast } from 'react-toastify';
import { Msg } from '../store/actions';

export const fileNameTrim = (fileName: string) => {
  const splittedName = fileName.split('.');
  const extension = splittedName[1];
  let fileNameSplitted = splittedName[0];
  let result = fileName;
  if (fileNameSplitted.length > 20) {
    fileNameSplitted = fileNameSplitted.substring(0, 20);
    result = `${fileNameSplitted}.${extension}`;
  }
  return result;
};

export const fileUploadHandler = (
  setState: React.Dispatch<Msg>,
  e: React.ChangeEvent<HTMLInputElement>,
) => {
  const file = e.target?.files?.[0];
  if (!file) {
    toast.warn('Oops, something went wrong. No file found.');
    return;
  }
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'application/pdf' ||
    file.type === 'application/msword' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    setState({ type: 'SET_FILE_NAME', payload: { fileName: file.name } });
    setState({ type: 'SET_FILE', payload: { fileItem: file } });
  } else {
    toast.warn('File format not supported!');
  }
};

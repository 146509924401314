import React from 'react';
import classNames from 'classnames';

const CONTROLS_PANEL = classNames('controls_panel');
const CONTROLS_PANEL_LEFT = classNames('controls_panel_left');
const CONTROLS_PANEL_RIGHT = classNames('controls_panel_right');

type ControlsPanelProps = {
  controlsLeftBlock: React.ReactElement[] | null;
  controlsRightBlock: React.ReactNode[] | null;
};

const ControlsPanel = ({
  controlsLeftBlock,
  controlsRightBlock,
}: ControlsPanelProps) => (
  <div className={CONTROLS_PANEL}>
    <div className={CONTROLS_PANEL_LEFT}>{controlsLeftBlock}</div>
    <div className={CONTROLS_PANEL_RIGHT}>{controlsRightBlock}</div>
  </div>
);

export default ControlsPanel;

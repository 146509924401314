import { CLEAR_TEXT, EDIT_MODE } from '../../../../store/actions';
import { clearedTimeLog } from '../../../../constants/cleared-objects';
import { RemoveEditModeProps } from '../types';

export const removeEditMode = ({
  setHandleValues,
  setActiveRow,
  handleGlobalEditMode,
}: RemoveEditModeProps) => {
  setHandleValues(CLEAR_TEXT({ payload: clearedTimeLog }));

  setHandleValues(
    EDIT_MODE({
      payload: {
        editValuesMode: false,
      },
    }),
  );
  setActiveRow(undefined);
  handleGlobalEditMode(false);
};

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent, UseHeaderProps, UseHeaderReturn } from './types';

const SELECT_LABEL = 'Select...';

const useHeader = ({
  clientListAllSorted,
  clientId,
  clientName,
  setHandleValues,
  clearSelectedStatus,
}: UseHeaderProps): UseHeaderReturn => {
  const { t } = useTranslation();

  const createOptions = useCallback(
    () => [
      {
        id: null,
        label: t('projects.listing.value.0'),
        value: t('projects.listing.value.0'),
      },
      ...clientListAllSorted.map((client) => ({
        id: client.id,
        label: client.name,
        value: client.name,
      })),
    ],
    [clientListAllSorted],
  );

  const handleSelectChange = useCallback(
    (e: SelectChangeEvent) => {
      setHandleValues({
        type: 'SET_SELECTABLES',
        payload: { clientId: e.id, clientName: e.value },
      });
      clearSelectedStatus();
    },
    [setHandleValues, clearSelectedStatus],
  );

  const options = createOptions();

  const value = {
    id: clientId || 'select',
    label: clientName || SELECT_LABEL,
    value: clientName || SELECT_LABEL,
  };

  return { options, value, handleSelectChange };
};

export default useHeader;

import { HoursByProjectAndEmployee } from '../../../api/api-types';
import { decimalToTime, timeToDecimal } from '../../../utils/date-formatting';
import { calculateTotalHours } from './calculateTotalHours';
import { mapProjectDetails } from './mapProjectDetails';

export const prepareTotalReportTableData = (
  groupedByEmployee: Map<string, HoursByProjectAndEmployee[]>,
) => {
  return Array.from(groupedByEmployee, ([employee, projects]) => ({
    employee,
    projects,
    countHours: projects.reduce(
      (acc, el) => acc + timeToDecimal(el.countHours ?? ''),
      0,
    ),
  })).map((period) => {
    const { employee: employeeName, projects } = period;
    const totalHours = projects?.length
      ? calculateTotalHours(projects)
      : period.countHours;

    return {
      countHours: `Total: ${decimalToTime(totalHours)}`,
      name: employeeName,
      subRows: projects?.map(mapProjectDetails) ?? null,
    };
  });
};

import { UseSelectedCustomerValueProps } from '../../types';

export const useSelectedCustomerValue = ({
  filteredCustomers,
  projectState,
}: UseSelectedCustomerValueProps) =>
  filteredCustomers?.find(({ value }) => value === projectState.customerId) || {
    value: null,
    label: '',
  };

import { useEffect, useState } from 'react';
import RobotLearnsImage from '../../../images/RobotLearnsImage.png';
import BCoinBodyLeftCol from '../components/BCoinBodyLeftCol';
import { getBCoinRewards } from '../../../api/bcoin';
import BCoinItemsList from '../components/BCoinItemsList';
import Loader from '../../../components/Loader/Loader';
import { BCoinRewardDto } from '../../../api/api-types';

const BCoinWhatToGet = () => {
  const [items, setItems] = useState<BCoinRewardDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getItems = async () => {
      setIsLoading(true);
      const apiItems = await getBCoinRewards();
      setItems(apiItems ?? []);
      setIsLoading(false);
    };
    getItems();
  }, []);

  return (
    <div className='bcoin-body'>
      <BCoinBodyLeftCol
        imageAsset={RobotLearnsImage}
        imageClassName='bcoin-body-left-col-img'
        title='b-coin store'
        className='bcoin-what-to-get-left-col'
      />
      <div className='bcoin-body-right-col bcoin-body-what-to-get'>
        {items.length ? (
          <BCoinItemsList isLoading={isLoading} items={items} />
        ) : null}
      </div>
      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default BCoinWhatToGet;

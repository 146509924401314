import React from 'react';
import classNames from 'classnames';

type ReportsFilterPanelProps = {
  filterItemFirst?: React.ReactNode[];
  filterItemSecond?: React.ReactNode[];
  filterItemThird?: React.ReactNode[];
  filterItemFourth?: React.ReactNode[];
  filterItemFifth?: React.ReactNode[];
  filterItemSixth?: React.ReactNode[];
};

const REPORTS_PANEL = classNames('reports__filter');
const REPORTS_FILTER_BLOCK = classNames('reports__filter__block');
const REPORTS_COLUMN_WRAPPER = classNames(
  'reports__filter__block__col--wrapper',
);
const REPORTS_COLUMN_WIDE = classNames('reports__filter__block__col--wide');
const REPORTS_COLUMN_NARROW = classNames('reports__filter__block__col--narrow');

const ReportsFilterPanel = ({
  filterItemFirst,
  filterItemSecond,
  filterItemThird,
  filterItemFourth,
  filterItemFifth,
  filterItemSixth,
}: ReportsFilterPanelProps) => (
  <div className={REPORTS_PANEL}>
    <div className={REPORTS_FILTER_BLOCK}>
      <div className={REPORTS_COLUMN_WIDE}>{filterItemFirst}</div>
      <div className={REPORTS_COLUMN_WIDE}>{filterItemSecond}</div>
      {filterItemThird && (
        <div className={REPORTS_COLUMN_WIDE}>
          {filterItemThird}
          <div className={REPORTS_COLUMN_WRAPPER}>
            {filterItemFourth && (
              <div className={REPORTS_COLUMN_NARROW}>{filterItemFourth}</div>
            )}
            {filterItemFifth && (
              <div className={REPORTS_COLUMN_NARROW}>{filterItemFifth}</div>
            )}
          </div>
        </div>
      )}
    </div>
    <div className={REPORTS_FILTER_BLOCK}>
      <div className={REPORTS_COLUMN_WIDE}>{filterItemSixth}</div>
    </div>
  </div>
);

export default ReportsFilterPanel;

import { FC } from 'react';
import { cleanURL } from '../helpers/cleanURL';

interface CardLinkProps {
  link: string;
}

const CardLink: FC<CardLinkProps> = ({ link }) => {
  return (
    <div className='card-description'>
      <a
        className='card-link'
        href={link}
        target='_blank'
        rel='noreferrer'
      >
        {cleanURL(link)}
      </a>
    </div>
  );
};

export default CardLink;
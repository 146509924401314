import { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useGetTotalReport from '../../hooks/useGetTotalReport';
import { sortByDefault } from '../../utils/filtering';
import {
  clearReportTotalAction,
  getReportTotalAction,
} from '../../store/actionCreators/reportsActionCreators';
import { RootState } from '../../store';
import { useRowExpanded } from './hooks/useRowExpanded';
import { groupProjectsByEmployee } from './helpers/groupProjectsByEmployee';
import { prepareTotalReportTableData } from './helpers/prepareTotalReportTableData';
import { EmployeeRow, UseReportTotalReturn } from './types';

const REPORT_TYPE = 'reportTotalData';
const TOTAL = 'total';
const expandedRowStyle = { color: '#171717', fontWeight: 400, fontSize: 14 };

export const useReportTotal = (): UseReportTotalReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tableRef = useRef<HTMLTableElement>(null);
  const { endDate, billable, startDate } = useParams();

  const { expandByDefault, setExpandedRowId } = useRowExpanded();

  const { reportTotal } = useAppSelector((state: RootState) => state);

  const { isLoading } = useGetTotalReport({
    clearReportAction: () => dispatch(clearReportTotalAction(null)),
    getReportAction: (data) => dispatch(getReportTotalAction(data)),
    reportType: REPORT_TYPE,
    totalOrClientReport: TOTAL,
  });

  const groupedByEmployee = useMemo(
    () => groupProjectsByEmployee(reportTotal.projectCountHoursDtoList ?? []),
    [reportTotal.projectCountHoursDtoList],
  );

  const totalReportTableData = useMemo(
    () => prepareTotalReportTableData(groupedByEmployee),
    [groupedByEmployee],
  );

  const goToEmployee = (row: EmployeeRow) =>
    navigate(
      `../by-projects/project${row.original.projectNameAndId.id}/employee${row.original.employeeFullName.id}/start${startDate}/end${endDate}/accrual${billable}`,
    );

  const sortedTotalReportTableData = useMemo(
    () => totalReportTableData.sort((a, b) => sortByDefault(a, b, 'name')),
    [totalReportTableData],
  );

  return {
    expandByDefault,
    expandedRowStyle,
    goToEmployee,
    isLoading,
    setExpandedRowId,
    sortedTotalReportTableData,
    t,
    tableRef,
  };
};

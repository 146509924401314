import { ReactComponent as VectorDown } from '../../../icons/VectorDown.svg';
import { ReactComponent as VectorUp } from '../../../icons/VectorUp.svg';

import Button from '../../../components/Button/Button';
import { ColumnEditBlockProps } from './types';
import { useColumnEditBlock } from './useColumnEditBlock';
import ButtonsBlock from './components/ButtonsBlock';

const ColumnEditBlock = (props: ColumnEditBlockProps) => {
  const { row, handleConfirmPopUp } = props;
  const { setDraftToEdit } = useColumnEditBlock(props);

  return (
    <div className='table_btn_wrapper'>
      {!row.canExpand && row.depth === 0 ? (
        <ButtonsBlock
          {...{
            row,
            handleConfirmPopUp,
            setDraftToEdit,
          }}
        />
      ) : row.canExpand && row.depth === 0 ? (
        <>
          <ButtonsBlock
            {...{
              row,
              handleConfirmPopUp,
              setDraftToEdit,
            }}
          />
          <div className='innerBtnWrapper'>
            <Button
              icon={row.isExpanded ? <VectorUp /> : <VectorDown />}
              {...row.getToggleRowExpandedProps()}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ColumnEditBlock;

import { NavigateOptions, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { ProjectWebDto } from '../api/api-types';
const useInspectItem = (
  locationState: NavigateOptions['state'],
  url: string,
) => {
  const navigate = useNavigate();

  const goToItemDetails = (row: Row<ProjectWebDto>) => {
    navigate(`${url}${row.original.id}`, { state: locationState });
  };

  return {
    goToItemDetails,
  };
};

export default useInspectItem;

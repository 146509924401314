import { useMemo } from 'react';
import ColumnEditBlock from '../../../modules/TimeTracking/ColumnEditBlock/ColumnEditBlock';
import { getTimeFromString } from '../../../utils/getTimeFromString';
import { getCurrentMonth } from '../../../utils/date-handling';
import { formatDateYYYYMMDD } from '../../../utils/date-formatting';
import { UseTimeTrackingColumnsProps } from '../types';

const useTimeTrackingColumns = ({
  activeRow,
  currentDate,
  handleConfirmPopUp,
  handleGlobalEditMode,
  setActiveRow,
  setHandleValues,
  t,
  timeTrackedData,
}: UseTimeTrackingColumnsProps) => {
  const isEqualMonth = getCurrentMonth(
    formatDateYYYYMMDD(new Date(currentDate)),
  );

  const timeTrackingColumns = useMemo(
    () => [
      {
        Header: t('columns.project'),
        accessor: 'project',
      },
      {
        Header: t('columns.task'),
        accessor: 'task',
      },
      {
        Header: t('columns.duration'),
        accessor: 'duration',
        Cell: (row: any) => {
          const [h, m] = getTimeFromString(row.value);
          return `${h}:${m}`;
        },
      },
      {
        Header: t('columns.note'),
        accessor: 'note',
      },
      {
        Header: ' ',
        accessor: 'editRemove',
        Cell: ({ row }) => (
          <ColumnEditBlock
            activeRow={activeRow}
            handleConfirmPopUp={handleConfirmPopUp}
            handleGlobalEditMode={handleGlobalEditMode}
            isEqualMonth={isEqualMonth}
            setActiveRow={setActiveRow}
            setHandleValues={setHandleValues}
            row={row}
          />
        ),
      },
    ],
    [
      timeTrackedData?.dayCurrent,
      timeTrackedData?.dayTotal,
      activeRow,
      isEqualMonth,
    ],
  );

  return timeTrackingColumns;
};

export default useTimeTrackingColumns;

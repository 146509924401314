import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUnits } from '../../../api/unit';
import { UnitDto } from '../../../api/api-types';
import { createClient, updateClient } from '../../../api/customers';
import {
  ClientState,
  EditClientData,
  UseAddEditClientModalReturn,
} from './types';
import { initialCustomerData, initialLocation } from './constants';
import { BaseModalComponentType, ModalNameToModalMap } from '../types';
import { isSaveDisabled } from './utils/isSaveDisabled';
import { useCreateClient } from './hooks/useCreateClient';
import { useEditClient } from './hooks/useEditClient';

export const useAddEditClientModal = ({
  payload,
  closeModal,
}: BaseModalComponentType & {
  payload: ModalNameToModalMap['addEditClientModal'];
}): UseAddEditClientModalReturn => {
  const { t } = useTranslation();
  const { modalTitle, data, updateClientsList } = payload;

  const [units, setUnits] = useState<UnitDto[]>([]);
  const [clientState, setClientState] = useState<ClientState>({
    clientName: data?.clientName || '',
    unitId: data?.unitId || null,
    contactPersons: data?.customerContactPersons?.length
      ? data.customerContactPersons
      : [initialCustomerData],
    locationDtoSet: data?.locationDtoSet?.length
      ? data.locationDtoSet
      : [initialLocation],
  });

  const unitsOptions = units.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const handleClientName = (e: ChangeEvent<HTMLInputElement>) => {
    setClientState((state) => ({ ...state, clientName: e.target.value }));
  };

  const handleUnitChange = (unitId: number) => {
    setClientState((state) => ({ ...state, unitId }));
  };

  const onAddContactPerson = () => {
    setClientState((state) => ({
      ...state,
      contactPersons: [
        ...clientState.contactPersons,
        { ...initialCustomerData, id: new Date().getMilliseconds() },
      ],
    }));
  };

  const onAddLocation = () => {
    setClientState((state) => ({
      ...state,
      locationDtoSet: [
        ...clientState.locationDtoSet,
        { ...initialLocation, id: new Date().getMilliseconds() },
      ],
    }));
  };

  const onCreateClient = useCreateClient({
    clientState,
    closeModal,
    createClient,
    updateClientsList,
  });

  const onEditClient = useEditClient({
    clientState,
    closeModal,
    data: data as EditClientData,
    updateClient,
    updateClientsList,
  });

  const onSave = () => {
    if (data) {
      return onEditClient();
    }

    return onCreateClient();
  };

  useEffect(() => {
    getUnits().then((data) => {
      if (!data) return;

      setUnits(data);
    });
  }, []);

  return {
    clientState,
    handleClientName,
    handleUnitChange,
    isSaveDisabled: isSaveDisabled(clientState),
    modalTitle,
    onAddContactPerson,
    onAddLocation,
    onSave,
    setClientState,
    t,
    unitsOptions,
  };
};

import { toast } from 'react-toastify';
import {
  ProjectSelectHandlerProps,
  ProjectSelectHandlerReturn,
} from '../types';

export const projectSelectHandler = ({
  employeeData,
  timeLogState,
  setTimeLogState,
}: ProjectSelectHandlerProps): ProjectSelectHandlerReturn => {
  const projectOptions = employeeData.projectDTOList
    .filter((project) => project.name !== 'Internal Administration')
    .map((project) => ({
      label: project.name,
      value: project.name,
    }));

  const handleProjectChange = (selectedOption: { value: string }) => {
    setTimeLogState((prevState) => ({
      ...prevState,
      project: selectedOption.value,
      task: undefined,
      eptId: undefined,
    }));

    toast.warn('You are about to change the billable type');
  };

  const projectValue = {
    label: timeLogState?.project,
    value: timeLogState?.project,
  };

  return {
    handleProjectChange,
    projectOptions,
    projectValue,
  };
};

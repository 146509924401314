import { useLocation, useNavigate, NavigateFunction } from 'react-router-dom';
import { bCoinTabs } from './constants';
import { useEffect } from 'react';

export const useBCoinPage = (): { navigate: NavigateFunction } => {
  const navigate: NavigateFunction = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.split('/').length < 3 ||
      location.pathname.split('/')[2] === ''
    ) {
      navigate(`/b-coin/${bCoinTabs[0].id}`);
    }
  }, [location]);

  return { navigate };
};

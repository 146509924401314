import React from 'react';
import commonStyles from '../../styles/pm-flow.module.scss';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import ControlsPanel from '../../components/ControlsPanel/ControlsPanel';
import Table from '../../components/Tables/Table';
import TaskModal from '../../components/Modal/TaskModal/TaskModal';
import Loader from '../../components/Loader/Loader';
import { useTaskListPage } from './useTaskListPage';
import { useProjectControls } from './hooks/useProjectControls';
import { ON_TOGGLE_VIEW } from '../../store/actions';
import { clearedSelectedTask } from './constants';

const TaskListPage = () => {
  const {
    addTaskHandler,
    departmentList,
    editTaskHandler,
    isLoading,
    selectedStatus,
    selectedTask,
    setGlobalFilter,
    setHandleValues,
    sortedTaskList,
    state,
    t,
    tableInstance,
    tableRef,
    taskColumns,
    taskList,
    toggleAddTask,
    toggleEditTask,
    toggleSortParam,
  } = useTaskListPage();

  const { controlsProjectLeft, controlsProjectRight } = useProjectControls({
    t,
    taskList,
    selectedStatus,
    setHandleValues,
    toggleAddTask,
    sortedTaskList,
  });

  return (
    <div className={commonStyles.containerMinWidth}>
      <div className={commonStyles.header}>
        <h2>{t('tasks.title')}</h2>
        {/* @ts-ignore */}
        <SearchFilter filter={state.globalFilter} setFilter={setGlobalFilter} />
      </div>
      <div className={commonStyles.container}>
        <ControlsPanel
          controlsLeftBlock={controlsProjectLeft}
          controlsRightBlock={controlsProjectRight}
        />
        {taskList.length ? (
          <Table
            //@ts-ignore
            columns={taskColumns}
            onToggleSortParam={toggleSortParam}
            ref={tableRef}
            sortByApiRequest={false}
            tableInstance={tableInstance}
            tableName='pm-tasks-list'
          />
        ) : (
          <p className={commonStyles.noItems}>{t('warning.no_tasks_data')}</p>
        )}
      </div>
      {toggleAddTask && (
        <TaskModal
          modalTitle={t('modals.titles.new_task')}
          data={clearedSelectedTask}
          endPointData={departmentList}
          onSave={addTaskHandler}
          onClose={() =>
            setHandleValues(
              ON_TOGGLE_VIEW({
                payload: {
                  toggleAddTask: !toggleAddTask,
                },
              }),
            )
          }
        />
      )}
      {toggleEditTask && (
        <TaskModal
          modalTitle={t('modals.titles.team_task')}
          data={selectedTask}
          endPointData={departmentList}
          onSave={editTaskHandler}
          onClose={() =>
            setHandleValues(
              ON_TOGGLE_VIEW({
                payload: {
                  toggleEditTask: !toggleEditTask,
                },
              }),
            )
          }
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default TaskListPage;

import React, { useMemo } from 'react';
import Button from '../../../components/Button/Button';
import { ReactComponent as PenIcon } from '../../../icons/PenIcon.svg';
import { UseTaskColumnsProps } from '../types';
import { getTaskIdAction } from '../../../store/actionCreators/tasksActionCreators';
import { ON_TOGGLE_VIEW, SELECT_DATA } from '../../../store/actions';

const useTaskColumns = ({
  t,
  dispatch,
  setHandleValues,
  toggleEditTask,
}: UseTaskColumnsProps) => {
  const taskColumns = useMemo(
    () => [
      {
        Header: `↑↓${t('columns.task_name')}`,
        accessor: 'name',
      },
      {
        Header: `↑↓${t('columns.task_description')}`,
        accessor: 'description',
      },
      {
        Header: '',
        accessor: 'editButton',
        Cell: (
          { row }: any, // replace any with the correct type
        ) => (
          <Button
            icon={<PenIcon />}
            onClick={() => {
              dispatch(getTaskIdAction(row.original));
              setHandleValues(
                ON_TOGGLE_VIEW({
                  payload: {
                    toggleEditTask: !toggleEditTask,
                  },
                }),
              );
              setHandleValues(
                SELECT_DATA({
                  payload: { selectedTask: row.original },
                }),
              );
            }}
          />
        ),
      },
    ],
    [t, dispatch, setHandleValues, toggleEditTask],
  );

  return taskColumns;
};

export default useTaskColumns;

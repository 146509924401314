import { Dispatch, SetStateAction } from 'react';
import { formatDateYYYYMMDD } from '../utils/date-formatting';

interface HasStartDate {
  startDate?: string;
  endDate?: string;
}

type UseDateChangeReturnType = {
  getEndDate: () => Date;
  getMaxDate: () => Date;
  getMinDate: () => Date;
  getStartDate: () => Date;
  handleEndDateChange: (selectedDate: Date | null) => void;
  handleStartDateChange: (selectedDate: Date | null) => void;
};

export const useDateChange = <T extends HasStartDate>({
  projectState,
  setProjectState,
}: {
  projectState: T;
  setProjectState: Dispatch<SetStateAction<T>>;
}): UseDateChangeReturnType => {
  // start date change handler
  const handleStartDateChange = (selectedDate: Date | null) => {
    setProjectState((currentState) => ({
      ...currentState,
      startDate: selectedDate
        ? formatDateYYYYMMDD(selectedDate)
        : currentState.startDate,
    }));
  };

  const getMaxDate = () =>
    projectState.endDate ? new Date(projectState.endDate) : new Date();

  const getStartDate = () => {
    if (!projectState?.startDate) return new Date();

    const startDate = new Date(projectState.startDate);

    return new Date(startDate.setMinutes(startDate.getTimezoneOffset()));
  };

  // end date change handler
  const handleEndDateChange = (selectedDate: Date | null) => {
    setProjectState((currentState) => ({
      ...currentState,
      endDate: selectedDate
        ? formatDateYYYYMMDD(selectedDate)
        : currentState.endDate,
    }));
  };

  const getMinDate = () =>
    projectState.startDate ? new Date(projectState.startDate) : new Date();

  const getEndDate = () =>
    projectState.endDate ? new Date(projectState.endDate) : new Date();

  return {
    getEndDate,
    getMaxDate,
    getMinDate,
    getStartDate,
    handleEndDateChange,
    handleStartDateChange,
  };
};

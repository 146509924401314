import {
  ProjectEmployeeReport,
  ProjectReport,
} from '../../../../api/api-types';
import { TranslationFunction } from '../../../../components/type-helpers/translation';
import { formatToDDMMYYYY } from '../../../../utils/date-formatting';

export const CLIENT_REPORT_TITLE = (
  t: TranslationFunction,
  startDate?: string,
  endDate?: string,
) =>
  `${t('reports.subtitle.client_report')} ${formatToDDMMYYYY(
    startDate,
  )} - ${formatToDDMMYYYY(endDate)}`;

export const EMPLOYEE_REPORT_TITLE = (
  reportProjectEmployee: ProjectEmployeeReport,
  startDate?: string,
  endDate?: string,
) =>
  `${reportProjectEmployee?.employeeFullNameAndId?.name} ${formatToDDMMYYYY(
    startDate,
  )} - ${formatToDDMMYYYY(endDate)}`;

export const IS_EMPLOYEE_TIME_LOGS = (reportProject: ProjectReport) =>
  !!reportProject?.employeeTaskTimeLogsList?.length;

export const REPORT_TITLE = (
  reportProject: ProjectReport,
  startDate?: string,
  endDate?: string,
) =>
  reportProject?.customerFullName?.name
    ? `${reportProject.customerFullName.name} - ${reportProject.projectName
        ?.name} status report ${formatToDDMMYYYY(
        startDate,
      )} - ${formatToDDMMYYYY(endDate)}`
    : '';

import React from 'react';

const SearchLineIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <path
      d='M14.0232 12.8477L17.5921 16.4166L16.4136 17.5951L12.8447 14.0262C11.5615 15.0528 9.93406 15.667 8.16406 15.667C4.02406 15.667 0.664062 12.307 0.664062 8.16699C0.664062 4.02699 4.02406 0.666992 8.16406 0.666992C12.3041 0.666992 15.6641 4.02699 15.6641 8.16699C15.6641 9.93699 15.0499 11.5644 14.0232 12.8477ZM12.3513 12.2293C13.3703 11.1792 13.9974 9.74666 13.9974 8.16699C13.9974 4.94408 11.387 2.33366 8.16406 2.33366C4.94115 2.33366 2.33073 4.94408 2.33073 8.16699C2.33073 11.3899 4.94115 14.0003 8.16406 14.0003C9.74373 14.0003 11.1762 13.3732 12.2264 12.3542L12.3513 12.2293Z'
      fill='white'
    />
  </svg>
);

export default SearchLineIcon;

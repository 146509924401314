import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { timeIntervalOverlaps, policyWarning } from '../constants/text-values';
import { ResponseErrorCode } from './response';

export const API_URL = process.env.REACT_APP_API_URL;

export const BCOIN = '/bcoin';
export const CONTACT_PERSONS = '/contact-persons';
export const CURRENT = '/current';
export const CUSTOMERS = '/customers';
export const CUSTOMER_LOCATIONS = '/customer-locations';
export const CLIENT = '/client';
export const DEPARTMENTS = '/departments';
export const EMAILS = '/emails';
export const EMPLOYEES = '/employees';
export const INFO = '/info';
export const IMAGES = '/images';
export const LOCATIONS = '/locations';
export const ONE_DAY_ACTIVITY_LOGS = '/one-day-activity-logs';
export const ONLY_TOTAL = '/only-total';
export const PM_TOOL = '/pm-tool';
export const PREVIEW = '/preview';
export const PROJECTS = '/projects';
export const PROJECT_MANAGER = '/project-manager';
export const PROJECT_MANAGERS = '/project-managers';
export const RANGE = '/range';
export const REGISTRATION = '/registration';
export const REPORTS = '/reports';
export const RESET = '/reset';
export const REWARDS = '/rewards';
export const SHORT_LIST = '/without-internal-administration';
export const SICKNESS = '/sickness';
export const SICKNESSES = '/sicknesses';
export const SUB_DEPARTMENT = '/subDepartment';
export const SWITCHERS = '/switchers';
export const TASKS = '/tasks';
export const TEAM_MEMBERS = '/team-members';
export const TIME = '/time';
export const TRACKING = '/tracking';
export const UNITS = '/units';
export const USERS = '/users';
export const VACATION = '/vacation';
export const VACATIONS = '/vacations';
export const WEB = '/web';

export const config = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Security-Policy': `script-src 'self'`,
        'Permissions-Policy': 'camera=(), microphone=()',
        'Strict-Transport-Security': 'max-age=31536000',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'Cache-Control': 'no-store, no-cache',
        Expires: '0',
        Pragma: 'no-cache',
      },
    };
  }
  return {
    accessToken,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleError = (err: any) => {
  if (
    err.response?.data.message === 'EmployeeProjectTask with id: 0 not found'
  ) {
    toast.warn(policyWarning);
    return;
  }
  toast.error(
    `${err.response?.data.message || err.response?.data.error || err.message}`,
  );
  if (err.message.includes('409')) {
    toast.warn(timeIntervalOverlaps);
  }
  return {
    code: err?.code as ResponseErrorCode,
    message: err?.message as string,
  };
};

type CustomAxiosRequestConfig<T> = Omit<AxiosRequestConfig, 'params'> & {
  params: T;
};
type CustomInstancePromises = {
  get<T, U>(url: string, config?: CustomAxiosRequestConfig<U>): Promise<T>;
  post<T, U>(
    url: string,
    data?: unknown,
    config?: CustomAxiosRequestConfig<U>,
  ): Promise<T>;
  put<T, U>(
    url: string,
    data?: unknown,
    config?: CustomAxiosRequestConfig<U>,
  ): Promise<T>;
  patch<T, U>(
    url: string,
    data?: unknown,
    config?: CustomAxiosRequestConfig<U>,
  ): Promise<T>;
  delete<T, U>(url: string, config?: CustomAxiosRequestConfig<U>): Promise<T>;
};

type AxiosInstanceType = CustomInstancePromises & AxiosInstance;

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
}) as AxiosInstanceType;

axiosInstance.interceptors.request.use((request) => {
  const configHeaders = config().headers;
  request.headers = { ...request.headers, ...configHeaders };
  return request;
});

export const makeRequest = axiosInstance;

import BCoinsXS from '../../../../images/BCoinsXS.png';
import { ReactComponent as CircleCheckmark } from '../../../../icons/CircleCheckmark.svg';
import { ReactComponent as Rocket } from '../../../../icons/Rocket.svg';
import { BCOIN_TASK_STATUS } from '../../../../api/bcoin';
import { textCompleted, textWillComplete } from './helpers/textStates';
import { FC } from 'react';
import { HowToGetCardProps } from './types';
import CardLink from './components/CardLink';
import CardAdditionalData from './components/CardAdditionalData';
import { PopupButton } from './components/PopupButton';

const HowToGetCard: FC<HowToGetCardProps> = ({
  additionalData,
  description,
  id,
  link,
  name,
  price,
}) => (
  <div className='card'>
    <div className='card-txt-container'>
      <div className='card-title'>{name}</div>
      <div className='card-description'>{description}</div>
      {!!additionalData && (
        <CardAdditionalData additionalData={additionalData} />
      )}
      {!!link && <CardLink link={link} />}
    </div>
    <div className='card-btn-container'>
      <PopupButton
        {...{
          IconComponent: Rocket,
          buttonClass: 'card-btn card-btn-complete',
          buttonText: 'Apply',
          id,
          status: BCOIN_TASK_STATUS.PENDING,
          text: textWillComplete,
        }}
      />
      <PopupButton
        {...{
          IconComponent: CircleCheckmark,
          buttonClass: 'card-btn card-btn-have-completed',
          buttonText: 'Completed',
          id,
          status: BCOIN_TASK_STATUS.COMPLETED,
          text: textCompleted,
        }}
      />
    </div>
    <div className='card-you-get-container'>
      <div className='card-you-get-title'>receive</div>
      <div className='card-you-get-value-container'>
        <div className='card-you-get-value'>{price}</div>
        <div className='card-you-get-icon'>
          <img src={BCoinsXS} alt={name} />
        </div>
      </div>
    </div>
  </div>
);

export default HowToGetCard;

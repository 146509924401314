import React from 'react';
import styles from '../../styles/client-modal.module.scss';

import Button from '../../../Button/Button';

export const AddItemButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => {
  return (
    <div className={styles.title_wrapper}>
      <h3>{title}</h3>
      <Button
        label='+'
        styleClass='btn__icon-blue'
        type='button'
        onClick={onClick}
      />
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CcpEmailGetDto } from '../../../../../api/api-types';
import {
  UseContactPersonBlockProps,
  UseContactPersonBlockReturn,
} from './types';

export const useContactPersonBlock = ({
  handleClientState,
  idx,
  initialEmails,
}: UseContactPersonBlockProps): UseContactPersonBlockReturn => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<CcpEmailGetDto[]>(initialEmails);

  useEffect(() => {
    if (initialEmails.length === 0) {
      setEmails([{ email: '', id: 0 }]);
    } else {
      setEmails(initialEmails);
    }
  }, [initialEmails]);

  const onNamePhoneChange = (key: 'name' | 'phoneNumber', value: string) => {
    handleClientState((state) => ({
      ...state,
      contactPersons: state.contactPersons.map((cp, cpIndex) => {
        if (cpIndex === idx) {
          return {
            ...cp,
            [key]: value,
          };
        }

        return cp;
      }),
    }));
  };

  const onEmailChange = (i: number, value: string) => {
    const newEmails = emails.map((email, index) => {
      if (index === i) {
        return { ...email, email: value };
      }

      return email;
    });

    setEmails(newEmails);

    handleClientState((state) => ({
      ...state,
      contactPersons: state.contactPersons.map((cp, cpIndex) => {
        if (cpIndex === idx) {
          return {
            ...cp,
            emails: newEmails,
          };
        }

        return cp;
      }),
    }));
  };

  const addEmail = () => {
    handleClientState((state) => ({
      ...state,
      contactPersons: state.contactPersons.map((cp, cpIndex) => {
        if (cpIndex === idx) {
          return {
            ...cp,
            emails: [
              ...cp.emails,
              { email: '', id: new Date().getMilliseconds() },
            ],
          };
        }

        return cp;
      }),
    }));
  };

  const removeEmail = (i: number) => {
    handleClientState((state) => ({
      ...state,
      contactPersons: state.contactPersons.map((cp, cpIndex) => {
        if (cpIndex === idx) {
          return {
            ...cp,
            emails: cp.emails.filter(({ id }) => id !== i),
          };
        }

        return cp;
      }),
    }));
  };

  return { t, emails, onNamePhoneChange, onEmailChange, addEmail, removeEmail };
};

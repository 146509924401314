import { useTranslation } from 'react-i18next';

interface ButtonConfig {
  id: string;
  label: string;
  className: string;
  close?: () => void;
  save?: () => void;
}

export type ConfirmButtons = (
  onClose: () => void,
  onSave: () => void,
) => ButtonConfig[];

export const confirmDeleteBtns: ConfirmButtons = (
  onClose: () => void,
  onSave: () => void,
) => {
  const { t } = useTranslation();

  return [
    {
      id: 'btn-cancel',
      label: t('buttons.cancel'),
      className: 'btn__string--normal--grey',
      close: onClose,
    },
    {
      id: 'btn-delete',
      label: t('buttons.delete'),
      className: 'btn__rounded-blue',
      save: onSave,
    },
  ];
};

export const confirmNotSaveBtns: ConfirmButtons = (
  onClose: () => void,
  onSave: () => void,
) => {
  const { t } = useTranslation();

  return [
    {
      id: 'btn-dont-save',
      label: t('buttons.dont_save'),
      className: 'btn__string--normal--grey',
      close: onClose,
    },
    {
      id: 'btn-back-to-editing',
      label: t('buttons.back_to_editing'),
      className: 'btn__string--bold--blue',
      save: onSave,
    },
  ];
};

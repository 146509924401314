import { CSSObjectWithLabel } from 'react-select';

export const selectCustomStyles = {
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    height: 30,
    minHeight: 30,
    fontSize: 14,
  }),
  indicatorsContainer: (styles: CSSObjectWithLabel) => ({
    ...styles,
    height: 30,
  }),
};

export const initialFormValues = {
  employeeId: null,
  projectName: '',
  taskId: null,
  accrualType: '',
  date: new Date().toUTCString(),
  hours: '',
  minutes: '',
  comment: '',
};

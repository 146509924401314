import React from 'react';
import { ReactComponent as ArrowIcon } from './icons/Arrow.svg';

import { removeEvent } from '../../utils/ui-handling';
import Button from '../Button/Button';
import WarningModal from '../Modal/WarningModal';
import { useMenuBar } from './useMenuBar';
import CustomNavLink from './components/CustomNavLink';
import { LogoSection } from './components/LogoSection';
import { confirmDeleteBtns, confirmNotSaveBtns } from '../ConfirmationButtons/ConfirmationButtons';

const MenuBar = () => {
  const {
    childElement,
    configs,
    confirmPopUp,
    editMode,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    handleGlobalEditMode,
    handleLogout,
    handleNavigateUrl,
    handleWarningPopUp,
    isExtendedMenu,
    navigate,
    navigateUrl,
    ref,
    registeredEmployee,
    t,
    toggleMenu,
    warningPopUp,
  } = useMenuBar();

  return (
    <div
      className={
        isExtendedMenu ? 'menu_pm_wrapper__extended' : 'menu_pm_wrapper'
      }
      ref={ref}
      onClick={toggleMenu}
    >
      <LogoSection />

      {typeof registeredEmployee?.employmentForm === 'string' && (
        <div className='menu_pm_container' onClick={removeEvent}>
          {configs.map((config) => (
            <CustomNavLink
              key={config.id}
              config={config}
              editMode={editMode}
              handleWarningPopUp={handleWarningPopUp}
              handleNavigateUrl={handleNavigateUrl}
              childElement={childElement}
            />
          ))}
        </div>
      )}

      {!isExtendedMenu && (
        <div className='menu_pm_arrow'>
          <div className='icon_pm_type'>
            <ArrowIcon className='iconSvg' />
          </div>
        </div>
      )}

      <div className='menu_pm_footer'>
        <Button
          label='Log out'
          styleClass='btn__log-out'
          onClick={handleLogout}
        />
      </div>

      {warningPopUp && (
        <WarningModal
          buttons={confirmNotSaveBtns}
          onClose={() => {
            handleWarningPopUp(false);
            handleGlobalEditMode(false);
            navigate(navigateUrl);
          }}
          onSave={() => {
            handleWarningPopUp(false);
          }}
          warning={t('warning.finish_edit')}
        />
      )}

      {confirmPopUp.status && (
        <WarningModal
          buttons={confirmDeleteBtns}
          onClose={() => handleConfirmPopUp(false)}
          onSave={() => handleApprovingOfConfirmPopUp(true)}
          warning={t('warning.confirm_to_delete_report')}
        />
      )}
    </div>
  );
};

export default React.memo(MenuBar);

import useBillable from '../../../../../hooks/useBillable';
import {
  UseBillableTypeCheckboxesProps,
  UseBillableTypeCheckboxesReturn,
} from './types';

export const useBillableTypeCheckboxes = ({
  timeLogState,
  setTimeLogState,
}: UseBillableTypeCheckboxesProps): UseBillableTypeCheckboxesReturn => {
  const billableTypeCheckboxes = useBillable({
    className: 'modal_default_checkbox',
    disabled: timeLogState.task === undefined,
    setState: setTimeLogState,
  });

  return billableTypeCheckboxes;
};

import { formatDateYYYYMMDD } from '../../../../utils/date-formatting';

export const editDraftInitialState = {
  duration: {
    hours: 8,
    minutes: 0,
  },
  eptId: null,
  note: '',
  date: '',
  fileName: '',
  isPartDay: false,
};

export const formatDateValue = (date: string): string =>
  (date && formatDateYYYYMMDD(new Date(date))) || '';

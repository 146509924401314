import { setEditModeAction } from '../store/actionCreators/editModeActionCreator';
import { setNavigateUrlAction } from '../store/actionCreators/navigationActionCreators';
import {
  setConfirmPopUpAction,
  setIsPopUpApprovedAction,
  setWarningPopUpAction,
} from '../store/actionCreators/popUpActionCreators';
import { ConfirmPopUp } from '../store/reducer';
import { useAppDispatch, useAppSelector } from './redux';

export interface UseEditModeReturn {
  confirmPopUp: ConfirmPopUp;
  editMode: boolean;
  handleApprovingOfConfirmPopUp: (status: boolean) => void;
  handleConfirmPopUp: (status: boolean, rowId?: number | null) => void;
  handleGlobalEditMode: (status: boolean) => void;
  handleNavigateUrl: (url: string) => void;
  handleWarningPopUp: (status: boolean) => void;
  isConfirmPopUpApproved: boolean;
  navigateUrl: string;
  warningPopUp: boolean;
}

const useEditMode = (): UseEditModeReturn => {
  const dispatch = useAppDispatch();
  const {
    warningPopUp,
    editMode,
    navigateUrl,
    confirmPopUp,
    isConfirmPopUpApproved,
  } = useAppSelector((state) => state);

  const handleGlobalEditMode = (status: boolean) => {
    dispatch(setEditModeAction(status));
  };

  const handleWarningPopUp = (status: boolean) => {
    dispatch(setWarningPopUpAction(status));
  };

  const handleConfirmPopUp = (status: boolean, rowId?: number | null) => {
    dispatch(setConfirmPopUpAction({ status, rowId }));
  };

  const handleApprovingOfConfirmPopUp = (status: boolean) => {
    dispatch(setIsPopUpApprovedAction(status));
  };

  const handleNavigateUrl = (url: string) => {
    dispatch(setNavigateUrlAction(url));
  };

  return {
    confirmPopUp,
    editMode,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    handleGlobalEditMode,
    handleNavigateUrl,
    handleWarningPopUp,
    isConfirmPopUpApproved,
    navigateUrl,
    warningPopUp,
  };
};

export default useEditMode;

import styles from '../../../styles/sickness.module.scss';
import Button from '../../../components/Button/Button';
import TextField from '../../../components/TextField/TextField';
import { HeaderBlockProps } from './types';
import { useHeaderBlock } from './useHeaderBlock';
import { formatDateValue } from './helpers/utils';

const HeaderBlock = (props: HeaderBlockProps) => {
  const { handleClearClick, handleSickTodayClick, isDisabledSickToday, t } =
    useHeaderBlock(props);

  return (
    <div className={styles.subheader}>
      <Button
        label={t('buttons.clear') as string}
        styleClass='btn__rounded-blue-narrow'
        onClick={handleClearClick}
        disabled={!props.startDate}
      />
      <div className={styles.datePickerSummaryButton}>
        <TextField
          labelclassname={styles.labelEmpty}
          className={styles.datePickerInput}
          value={formatDateValue(props.startDate)}
          readOnly
        />
        <TextField
          labelclassname={styles.labelEmpty}
          className={styles.datePickerInput}
          value={formatDateValue(props.endDate)}
          readOnly
        />
        <Button
          label={t('buttons.sick_today') as string}
          styleClass='btn__rounded-blue-narrow'
          onClick={handleSickTodayClick}
          disabled={isDisabledSickToday}
        />
      </div>
    </div>
  );
};

export default HeaderBlock;

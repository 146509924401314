import { HoursByProjectAndEmployee } from '../api/api-types';
import { CreateProjectModalData } from '../components/Modal/CreateProjectModal/types';
import { ACCRUAL_FILTER_TYPE } from '../translations/accrualTypeFilterEN';

export const clearedCurrentMinute = {
  currentMinute: '',
};

export const clearedSelectedEmployee = {
  employeeId: '',
  teamMemberName: '',
  employeeTaskName: '',
};

export const clearedSelectedMember = {
  employeeId: null,
  endDate: null,
  billableType: null,
  overtimeRate: 0,
  partTimeRate: 0,
  role: null,
  startDate: 0,
  taskId: 0,
  workingInvolvement: 0,
};

export const clearedSelectedProject: CreateProjectModalData = {
  id: undefined,
  customerId: undefined,
  customerName: '',
  description: '',
  endDate: undefined,
  billableType: ACCRUAL_FILTER_TYPE.NON_BILLABLE,
  isIncludedInPlanning: false,
  managerIdAndName: {
    id: undefined,
    name: '',
  },
  projectManagerId: undefined,
  projectManagerName: '',
  projectName: undefined,
  startDate: undefined,
  status: true,
};

export const clearedSelectedTask = {
  taskId: '',
  taskName: '',
};

export const clearedTimeLog = {
  currentHour: '',
  currentMinute: '',
  comment: '',
};

export const clearedTimeLogRequest = {
  startDate: '',
  endDate: '',
  comment: '',
  fileName: '',
  fileItem: '',
};

export const dummyTotalReport: HoursByProjectAndEmployee[] = [
  {
    countHours: '',
    employeeFullName: {
      name: '',
      id: -1,
    },
    projectNameAndId: {
      name: '',
      id: -1,
    },
  },
];

export const clearedCustomerData = {
  customerContactPersonRequestDtos: [
    {
      name: '',
      phoneNumber: '',
      emailRequestSet: [
        {
          email: '',
        },
      ],
    },
  ],
  clientName: '',
  location: [
    {
      name: '',
    },
  ],
};

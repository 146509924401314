import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import './../confirmation-popup.scss';
import { toast } from 'react-toastify';
import { ConfirmationPopupProps, ConfirmationPopupState } from './types';
import { getConfirmationPopupContent } from './GetConfirmationPopupContent';

const ConfirmationPopup = (props: ConfirmationPopupProps) => {
  const [state, setState] = useState<ConfirmationPopupState>('initial');
  const resetState = () => setState('initial');
  return (
    <Dialog.Root
      open={props.children ? undefined : props.isOpen}
      onOpenChange={
        props.children ? (open) => (!open ? resetState() : null) : props.setOpen
      }
    >
      <Dialog.Trigger asChild>{props.children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='popup_overlay' />
        <Dialog.Content className='popup_content'>
          {getConfirmationPopupContent({
            state,
            onSuccess: () => {
              try {
                props.onSubmit();
                setState('success');
              } catch (e) {
                toast.error('Oops…something went wrong. Try again later');
              }
            },
            text: props.text,
          })}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConfirmationPopup;

import { useAppSelector } from '../../../../hooks/redux';
import { CustomerName } from '../../types';

const useFilteredCustomers = (): CustomerName[] => {
  const { locationList } = useAppSelector((state) => state);

  const customerNames: CustomerName[] = locationList.map((location) => ({
    label: location.customerNameAndId?.name ?? '',
    value: location.customerNameAndId?.id ?? -1,
  }));

  // Filter out duplicate customer names and IDs
  return customerNames.filter(
    (option, index, self) =>
      index ===
      self.findIndex(
        (el) => el.value === option.value && el.label === option.label,
      ),
  );
};

export default useFilteredCustomers;

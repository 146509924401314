import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PenIcon } from '../../icons/PenIcon.svg';
import Button from '../../components/Button/Button';
import ControlsPanel from '../../components/ControlsPanel/ControlsPanel';
import Loader from '../../components/Loader/Loader';
import PMProjectInfo from '../../components/PMProjectInfo/PMProjectInfo';
import Table from '../../components/Tables/Table';
import TablePagination from '../../components/TablePagination/TablePagination';
import commonStyles from '../../styles/pm-flow.module.scss';

import modals from '../../constants/modals';
import { useClientProjectDetailedPage } from './useClientProjectDetailedPage';
import { useFetchData } from './hooks/useFetchData';
import { useModalSetup } from './hooks/useModalSetup';
import { useProjectControls } from './hooks/useProjectControls';
import { useTableSetup } from './hooks/useTableSetup';
import { useAppDispatch } from '../../hooks/redux';

const ClientProjectDetailedPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    currentPage,
    isLoading,
    location,
    memberList,
    onAddMember,
    onDeleteMember,
    onEditMember,
    onEditProject,
    projectData,
    projectName,
    setCurrentPage,
    setHandleValues,
    showModal,
    sortParam,
    statusCurrent,
    tableRef,
    toggleSortParam,
    toggleStatusValue,
    toggleViewValue,
    totalAmount,
    updateProjectData,
    updateTeamMemberList,
  } = useClientProjectDetailedPage({ modals });

  useFetchData({
    currentPage,
    dispatch,
    memberList,
    sortParam,
    statusCurrent,
    toggleStatusValue,
    updateProjectData,
    updateTeamMemberList,
  });

  const {
    openEditProjectModal,
    openAddTeamMemberModal,
    openEditTeamMemberModal,
  } = useModalSetup({
    onEditMember,
    showModal,
    onAddMember,
    onEditProject,
  });

  const { teamDetailedColumns, teamColumns, tableInstance } = useTableSetup({
    memberList,
    onDeleteMember,
    openEditTeamMemberModal,
    statusCurrent,
    toggleViewValue,
  });

  const { controlsProjectLeft, controlsProjectRight } = useProjectControls({
    openAddTeamMemberModal,
    setHandleValues,
    toggleStatusValue,
    toggleViewValue,
    totalAmount,
  });

  return (
    <>
      <div className={commonStyles.header}>
        <h2 className={commonStyles.heading}>
          <Link to={`/${location.state}`}>
            {location.state === 'projects'
              ? t('projects.title')
              : t('clients.title')}
          </Link>
          <a onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
            {location.state === 'clients' &&
              projectData?.customerName &&
              ` > ${projectData.customerName}`}
          </a>
          {' > '}
          {projectData?.customerName &&
            projectName}
        </h2>
      </div>

      <div className={`${commonStyles.header} ${commonStyles.headerInfo}`}>
        <PMProjectInfo data={projectData} />
        <Button icon={<PenIcon />} onClick={openEditProjectModal} />
      </div>

      <div className={commonStyles.container}>
        <ControlsPanel
          controlsLeftBlock={controlsProjectLeft}
          controlsRightBlock={controlsProjectRight}
        />
        {memberList?.length && !isLoading ? (
          <>
            <Table
              //@ts-ignore
              // TODO: handle types for react-table around the app
              columns={toggleViewValue ? teamDetailedColumns : teamColumns}
              data={memberList}
              onToggleSortParam={toggleSortParam}
              ref={tableRef}
              sortByApiRequest
              tableInstance={tableInstance}
              tableName='pm-team-list'
            />
            <TablePagination
              totalRows={totalAmount}
              rowsPerPage={20}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <p className={commonStyles.noItems}>{t('warning.no_team_data')}</p>
        )}
      </div>

      {isLoading && <Loader />}
    </>
  );
};

export default ClientProjectDetailedPage;

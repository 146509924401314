import { ProjectData } from '../../../../api/api-types';
import { PMOption, SetProjectState, UseProjectManagerReturn } from '../types';

export const useProjectManagerOptions = (
  setProjectState: SetProjectState,
  pmOptions: PMOption[],
  projectState: ProjectData,
): UseProjectManagerReturn => {
  const handlePMChange = (selectedOption: PMOption) => {
    setProjectState((state) => ({
      ...state,
      projectManagerId: selectedOption.value,
    }));
  };

  const selectedPMOption = pmOptions.find(
    ({ value }) => value === projectState.projectManagerId,
  );

  return {
    pmOptions,
    handlePMChange,
    selectedPMOption,
  };
};

import { FC } from 'react';
import { formatExchangeValue } from '../../../../../utils/text-handling';
import { BCoinItemModalProps } from '../types';

const ItemPrice: FC<BCoinItemModalProps> = (props) =>
  // TODO: contact backend to define different types of rewards and remove this hardcode
  props.item.id === 71 ? (
    formatExchangeValue(
      '1 b-coin = 1 usd',
      'bcoin-modal-price-container',
      'bcoin-modal-price-value',
      'bcoin-modal-price-currency',
    )
  ) : (
    <div className='bcoin-modal-price-container'>
      <div className='bcoin-modal-price-value'>{props.item.price}</div>
      <div className='bcoin-modal-price-currency'>b-coins</div>
    </div>
  );

export default ItemPrice;

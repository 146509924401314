import React from 'react';
import classNames from 'classnames';

const TOTAL_ITEMS_STYLE = classNames('total_items');

type TotalItemsProps = {
  textLabel: string;
  data?: number;
}

const TotalItems = ({ textLabel, data }: TotalItemsProps) => (
  <div className={TOTAL_ITEMS_STYLE}>
    {textLabel}
    {data}
  </div>
);

export default TotalItems;

import { eachWeekendOfYear, addBusinessDays } from 'date-fns';
import { t } from 'i18next';
import { setFormatDDMMYYYYtoMMDDYYYY } from '../../../../../utils/date-formatting';
import { dateRangeHandler } from '../../../../../utils/date-handling';
import { UseDatePickerDoubleDatesProps } from './types';

export const useDatePickerDoubleDates = ({
  dayOffType,
  endDate,
  loggedDates,
  registeredEmployee,
  setHandleValues,
  startDate,
  vacationData,
}: UseDatePickerDoubleDatesProps) => {
  const getStartDate = startDate ? new Date(startDate) : undefined;
  const getEndDate = endDate ? new Date(endDate) : undefined;

  const excludeDates = loggedDates && [
    ...loggedDates,
    ...eachWeekendOfYear(new Date()),
  ];

  const onChangeHandler = (selected: [Date | null, Date | null]): void => {
    dateRangeHandler(setHandleValues, selected);
  };

  const minDate = vacationData.periodStart
    ? new Date(setFormatDDMMYYYYtoMMDDYYYY(vacationData.periodStart))
    : undefined;

  const maxDate =
    startDate &&
    dayOffType === t('vacation.vacation_data.paid_vacations') &&
    !registeredEmployee.unlimitedVacationDays
      ? addBusinessDays(new Date(startDate), (vacationData.daysLeft ?? 0) - 0.5)
      : null;

  return {
    excludeDates,
    getEndDate,
    getStartDate,
    maxDate,
    minDate,
    onChangeHandler,
  };
};

import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styled-calendar.scss';

type StyledPickerTwoDatesProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> = {
  titleClassName?: string;
  startDate?: Date;
} & ReactDatePickerProps<CustomModifierNames, WithRange>;

const StyledPickerTwoDates = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  props: StyledPickerTwoDatesProps<CustomModifierNames, WithRange>,
) => (
  <div className={props.wrapperClassName}>
    <div className={props.titleClassName}>{props.title}</div>
    <DatePicker
      {...props}
      selected={props.startDate}
      startDate={props.startDate}
      endDate={props.endDate}
      maxDate={props.maxDate}
      calendarStartDay={1}
      openToDate={props.startDate}
      excludeDates={props.excludeDates}
      highlightDates={props.highlightDates}
      disabledKeyboardNavigation
      inline
    />
  </div>
);

export default StyledPickerTwoDates;

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ButtonBlock from './ButtonBlock/ButtonBlock';
import styles from '../../styles/time-tracking.module.scss';
import { durationHours, durationMinutes } from '../../utils/createDuration';
import { textInputXSSRemoval } from '../../utils/text-handling';
import SimpleSelect from '../../components/Select/SimpleSelect';
import {
  clearedCurrentMinute,
  clearedSelectedTask,
} from '../../constants/cleared-objects';
import {
  timeTrackingLongStyle,
  timeTrackingShortStyle,
} from '../../components/Select/selectStyles';
import TextAreaField from '../../components/TextAreaField/TextAreaField';
import { textareaLength } from '../../constants/input-restrictions';
import { Msg } from '../../store/actions';
import { ProjectDto, ProjectLastUsedDto, TrackForm } from '../../api/api-types';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

type TimeTrackingFormProps = {
  activeRow?: number | null;
  comment: string;
  currentDate: string;
  currentHour?: string;
  currentMinute?: string;
  editValuesMode: boolean;
  employeeData: ProjectLastUsedDto;
  filteredEmployeeData: ProjectDto | undefined;
  getTimeLogsList: () => void;
  handleGlobalEditMode: (value: boolean) => void;
  projectId: string;
  projectName: string;
  selectedCurrentMinute: MutableRefObject<string | undefined>;
  selectedProjectName: MutableRefObject<string | undefined>;
  setActiveRow: Dispatch<SetStateAction<number | null | undefined>>;
  setHandleValues: Dispatch<Msg>;
  taskId?: number;
  taskName: string;
  timeLogId: number;
  currentDates?: string[] | undefined;
};

const time_helper_buttons = [
  ['8h', '08'],
  ['4h', '04'],
  ['1h', '01'],
  ['30m', '30'],
] as const;

const TimeTrackingForm = (props: TimeTrackingFormProps) => {
  const {
    activeRow,
    comment,
    currentDate,
    currentHour,
    currentMinute,
    editValuesMode,
    employeeData,
    filteredEmployeeData,
    getTimeLogsList,
    handleGlobalEditMode,
    projectId,
    projectName,
    selectedCurrentMinute,
    selectedProjectName,
    setActiveRow,
    setHandleValues,
    taskId,
    taskName,
    timeLogId,
    currentDates,
  } = props;

  const hours = durationHours();
  const minutes = durationMinutes();

  const { t } = useTranslation();

  const createTimeLogRequest = (
    taskId: number | undefined,
    currentHour: string | undefined,
    currentMinute: string | undefined,
    currentDate: string | undefined,
    comment: string,
    currentDates: string[] | undefined,
  ) => {
    if (currentDates && currentDates.length > 0) {
      return currentDates.map((date) => ({
        eptId: taskId ?? 0,
        duration: {
          hours: parseInt(currentHour ?? '0'),
          minutes: parseInt(currentMinute ?? '0'),
        },
        date: format(new Date(date), 'dd.MM.yyyy') ?? '',
        note: textInputXSSRemoval(comment),
      }));
    }

    return [
      {
        eptId: taskId ?? 0,
        duration: {
          hours: parseInt(currentHour ?? '0'),
          minutes: parseInt(currentMinute ?? '0'),
        },
        date:
          (currentDate && format(new Date(currentDate), 'dd.MM.yyyy')) ?? '',
        note: textInputXSSRemoval(comment),
      },
    ];
  }

  const timeLogRequestsArray = createTimeLogRequest(
    taskId,
    currentHour,
    currentMinute,
    currentDate,
    comment,
    currentDates,
  );

  const timeLogEditRequest: TrackForm = {
    eptId: taskId ?? 0,
    duration: {
      hours: parseInt(currentHour ?? '0'),
      minutes: parseInt(currentMinute ?? '0'),
    },
    note: textInputXSSRemoval(comment),
    date: currentDate && format(new Date(currentDate), 'dd.MM.yyyy'),
  };

  const setHours = (value: string) =>
    setHandleValues({
      type: 'SET_SELECTABLES',
      payload: { currentHour: value },
    });

  const setMinutes = (value: string) =>
    setHandleValues({
      type: 'SET_SELECTABLES',
      payload: { currentMinute: value },
    });

  const onTimeButtonClick = (key: string, value: string) => {
    if (key.includes('h')) {
      setHours(value);
      setMinutes('00');
    } else {
      setMinutes(value);
      setHours('00');
    }
  };

  return (
    <div className={`${styles.form} ${activeRow ? 'onFocus' : ''}`}>
      <div className={styles.formContent}>
        <div className={styles.formContentCol}>
          <div className={styles.formInputs}>
            {!!activeRow && <h3>{t('form.edit_log')}</h3>}
            <SimpleSelect
              selectWrapperClass='select_vertical_wrapper'
              selectTitleClassName='select_title'
              selectTitle={`${t('form.project')}*`}
              options={employeeData.projectDTOList
                .filter((el) => el.name !== 'Internal Administration')
                .map((eachEmployeeData) => ({
                  id: eachEmployeeData.name ?? '',
                  label: eachEmployeeData.name ?? '',
                  value: eachEmployeeData.name ?? '',
                }))}
              value={{
                id: projectId || 'select-project',
                label: projectName,
                value: projectName,
              }}
              onChange={(e) => {
                setHandleValues({
                  type: 'SET_SELECTABLES',
                  payload: { projectName: e.value },
                });
                setHandleValues({
                  type: 'CLEAR_TEXT',
                  payload: clearedSelectedTask,
                });
              }}
              styles={timeTrackingLongStyle}
            />
            <SimpleSelect
              selectWrapperClass='select_vertical_wrapper'
              selectTitleClassName='select_title'
              selectTitle={`${t('form.task')}*`}
              options={filteredEmployeeData?.tasks?.map((task) => ({
                id: task.eptId ?? 0,
                label: task.name ?? '',
                value: task.name ?? '',
              }))}
              value={
                selectedProjectName.current === projectName && {
                  id: taskId,
                  label: taskName,
                  value: taskName,
                }
              }
              onChange={(e) =>
                setHandleValues({
                  type: 'SET_SELECTABLES',
                  payload: { taskId: e.id, taskName: e.value },
                })
              }
              styles={timeTrackingLongStyle}
              isDisabled={!projectName}
            />
            <div className={styles.formContentLine}>
              <SimpleSelect
                selectWrapperClass='select_vertical_wrapper'
                selectTitleClassName='select_title'
                selectTitle={`${t('form.duration')}*`}
                options={hours}
                value={{
                  label: currentHour || 'hh',
                  value: currentHour || 'hh',
                }}
                onChange={(e) => {
                  setHours(e.value);
                  if (selectedCurrentMinute.current === '00') {
                    setHandleValues({
                      type: 'CLEAR_TEXT',
                      payload: clearedCurrentMinute,
                    });
                  }
                }}
                styles={timeTrackingShortStyle}
              />
              <SimpleSelect
                options={minutes}
                value={{
                  label: currentMinute || 'mm',
                  value: currentMinute || 'mm',
                }}
                isOptionDisabled={(option) =>
                  currentHour === '00' && option.label === '00'
                }
                onChange={(e) => setMinutes(e.value)}
                styles={timeTrackingShortStyle}
                isDisabled={!currentHour}
              />
            </div>
          </div>
          <div className={styles.time_helper__container}>
            {time_helper_buttons.map(([key, value]) => (
              <button
                key={key}
                onClick={() => onTimeButtonClick(key, value)}
                className={styles.time_helper}
              >
                {key}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.formContentCol}>
          <div className={styles.formInputs}>
            <div
              className={
                activeRow ? styles.textAreaWrapperEdit : styles.textAreaWrapper
              }
            >
              <TextAreaField
                fieldClassName={styles.textArea}
                titleClassName={styles.textAreaTitle}
                className={styles.textAreaLabel}
                maxLength={textareaLength}
                title={t('form.note') as string}
                value={comment}
                onChange={(e) =>
                  setHandleValues({
                    type: 'SET_TEXT',
                    payload: {
                      comment: e.target.value,
                    },
                  })
                }
                helperText={`${textareaLength - comment.length} ${t(
                  'form.characters_left',
                )}`}
                helperTextClassName={styles.textAreaLength}
              />
            </div>
          </div>
          <ButtonBlock
            editValuesMode={editValuesMode}
            getTimeLogsList={getTimeLogsList}
            handleGlobalEditMode={handleGlobalEditMode}
            setActiveRow={setActiveRow}
            setHandleValues={setHandleValues}
            timeLogEditRequest={timeLogEditRequest}
            timeLogId={timeLogId}
            timeLogRequestsArray={timeLogRequestsArray}
            disabled={
              !projectName || !taskName || !currentHour || !currentMinute
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TimeTrackingForm;

import React, { ComponentPropsWithoutRef } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LoginPage from '../pages/LoginPage';

type ProtectedComponentProps<
  TComponent extends
    | React.LazyExoticComponent<React.ComponentType>
    | React.ComponentType,
> = {
  component: TComponent;
} & ComponentPropsWithoutRef<TComponent>;

const ProtectedComponent = <
  TComponent extends
    | React.LazyExoticComponent<React.ComponentType>
    | React.ComponentType,
>(
  props: ProtectedComponentProps<TComponent>,
) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) return <LoginPage />;

  const { component, ...propsForComponent } = props;
  const ComponentContainer = withAuthenticationRequired(component);
  return <ComponentContainer {...propsForComponent} />;
};

export default ProtectedComponent;

import { getTimeLogDataForEmployee } from '../../../../api/tracking';
import { UseOnEmployeeChangeProps } from '../types';

export const useOnEmployeeChange = ({
  setTimeLogs,
  setFormValues,
  formValues,
}: UseOnEmployeeChangeProps) => {
  const onEmployeeChange = async (userId: number) => {
    const timeLogsResponse = await getTimeLogDataForEmployee({ userId });

    if (!timeLogsResponse) return;

    const filteredTimeLogs = timeLogsResponse.projectDTOList.filter(
      ({ name }) => name !== 'Internal Administration',
    );

    setTimeLogs(filteredTimeLogs);

    setFormValues({
      ...formValues,
      employeeId: userId,
      projectName: '',
      taskId: null,
      accrualType: '',
    });
  };

  return onEmployeeChange;
};

import React, { useRef } from 'react';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import '../date-picker.scss';
import CalendarInput from './CalendarInput';

export type CalendarDatePickerProps<CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined> = {
  inputClassName?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  title?: string;
  startDate?: Date;
} & ReactDatePickerProps<CustomModifierNames, WithRange>

const CalendarDatePicker = <CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>({
  inputClassName,
  wrapperClassName,
  titleClassName,
  title,
  startDate,
  ...props
}: CalendarDatePickerProps<CustomModifierNames, WithRange>) => {
  const inputRef = useRef<HTMLButtonElement>(null);
  return (
  <div className={wrapperClassName}>
    <div className={titleClassName}>{title}</div>
    <DatePicker
      {...props}
      selected={startDate}
      calendarStartDay={1}
      customInput={<CalendarInput inputClassName={inputClassName}  ref={inputRef} />}
    />
  </div>
)
};

export default CalendarDatePicker;

/* eslint-disable @typescript-eslint/no-explicit-any */

import { z } from 'zod';

export const validateObjectRequest = (arr: readonly string[], obj: object) => {
  // Dynamically create a Zod schema based on arr
  const schemaParts = arr.reduce(
    (acc, key) => {
      acc[key] = z.union([
        z.string().min(1),
        z
          .number()
          .refine((val) => val !== 0, { message: 'Number cannot be zero' }),
      ]);
      return acc;
    },
    {} as Record<string, z.ZodTypeAny>,
  );

  const schema = z.object(schemaParts);

  // Validate the object against the schema
  const result = schema.safeParse(obj);

  // Return true if there are any errors, false otherwise
  return result.success;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reducer, valuesInitialState } from '../../reducer/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useTablePagination from '../../hooks/useTablePagination';
import useSortDataTable from '../../hooks/useSortDataTable';
import {
  deleteTeamMember,
  getTeamMember,
  postTeamMember,
  putTeamMember,
} from '../../api/team';
import { getProjectById, putProject } from '../../api/projects';
import {
  editedProjectRequest,
  teamMemberRequest,
} from '../../utils/request-objects';
import { validateObjectRequest } from '../../utils/validate-objects';
import { requiredKeys } from '../../constants/required-keys';
import { PageEmployeeProjectTaskDto, ProjectData } from '../../api/api-types';
import { useModal } from '../../providers/ModalProvider';
import { ModalTypes } from '../../constants/modals';
import { UseClientProjectDetailedPageReturn } from './types';

export const useClientProjectDetailedPage = ({
  modals,
}: {
  modals: ModalTypes;
}): UseClientProjectDetailedPageReturn => {
  const { t } = useTranslation();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const { memberList, projectData } = useAppSelector((state) => state);

  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const dispatch = useAppDispatch();

  const { id } = useParams();

  const { currentPage, setCurrentPage } = useTablePagination();
  const { showModal, hideModal } = useModal();

  const { sortParam, toggleSortParam } = useSortDataTable('employee.name');

  const projectId = Number(id);

  const { projectName } = projectData;

  const { pageData, toggleStatusValue, toggleViewValue } = handleValues;

  const { totalAmount } = pageData;

  localStorage.setItem('currentPage', `${currentPage}`);

  const setPageCurrentData = (data: PageEmployeeProjectTaskDto) => {
    setHandleValues({
      type: 'SET_PAGE_DATA',
      payload: {
        pageData: {
          totalAmount: data.totalElements,
          projectList: data.content,
        },
      },
    });
  };

  const statusCurrent = useRef<boolean>(false);
  const tableRef = useRef<HTMLTableElement>(null);

  const updateTeamMemberList = () => {
    if (!projectId) return;

    setIsLoading(true);

    getTeamMember(
      projectId,
      `${sortParam.key},${sortParam.sortOrder}`,
      parseInt(localStorage.getItem('currentPage') ?? '0'),
      20,
      toggleStatusValue,
    ).then((data) => {
      if (!data || !data.content) return;

      setPageCurrentData(data);

      dispatch({ type: 'GET_TEAM', payload: data.content });

      setIsLoading(false);
    });
  };

  const updateProjectData = () => {
    if (!projectId) return;

    setIsLoading(true);

    getProjectById(projectId).then((data) => {
      setIsLoading(false);

      if (!data) return;

      dispatch({ type: 'GET_PROJECT_ID', payload: data });
    });
  };

  const onEditProject = async (projectState: ProjectData) => {
    editedProjectRequest(projectState);

    if (validateObjectRequest(requiredKeys, projectState)) {
      toast.warn(t('warning.no_filled_data'));
    } else {
      if (!projectId) return;

      putProject({ id: projectId, data: editedProjectRequest(projectState) })
        .then((data) => {
          if (!data) return;

          hideModal(modals.editProjectModal);

          dispatch({ type: 'UPDATE_PROJECT', payload: data });

          updateProjectData();
        })
        .then(() => updateTeamMemberList());
    }
  };

  const onAddMember = async (teamMemberState: any) => {
    if (
      validateObjectRequest(requiredKeys, teamMemberRequest(teamMemberState))
    ) {
      toast.warn(t('warning.no_filled_data'));
    } else {
      if (projectId) {
        hideModal(modals.addEditProjectTeamMemberModal);

        postTeamMember(projectId, teamMemberRequest(teamMemberState)).then(
          () => {
            updateTeamMemberList();
          },
        );
      }
    }
  };

  const onEditMember = async (teamMemberState: any) => {
    if (
      validateObjectRequest(requiredKeys, teamMemberRequest(teamMemberState))
    ) {
      toast.warn(t('warning.no_filled_data'));
    } else {
      if (projectId) {
        hideModal(modals.addEditProjectTeamMemberModal);

        putTeamMember(
          projectId,
          teamMemberState.id,
          teamMemberRequest(teamMemberState),
        ).then(() => updateTeamMemberList());
      }
    }
  };

  const onDeleteMember = async (teamMemberId: number) => {
    if (projectId) {
      deleteTeamMember(projectId, teamMemberId).then(() =>
        updateTeamMemberList(),
      );
    }
  };

  return {
    currentPage,
    dispatch,
    isLoading,
    location,
    memberList,
    onAddMember,
    onDeleteMember,
    onEditMember,
    onEditProject,
    projectData,
    projectName,
    setCurrentPage,
    setHandleValues,
    showModal,
    sortParam,
    statusCurrent,
    tableRef,
    toggleSortParam,
    toggleStatusValue,
    toggleViewValue,
    totalAmount,
    updateProjectData,
    updateTeamMemberList,
  };
};

import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
const useMouseWheelScroll = (ref: React.RefObject<HTMLElement>) => {
  const slider = useRef<Slider>(null);

  const scroll = (e: WheelEvent) => {
    if (slider === null) {
      return 0;
    }
    const { deltaX, deltaY } = e;
    const isHorizontal = Math.abs(deltaX) > Math.abs(deltaY);
    const scrollRight = isHorizontal ? deltaX > 0 : deltaY > 0;
    return scrollRight
      ? slider.current?.slickNext()
      : slider.current?.slickPrev();
  };

  useEffect(() => {
    ref.current?.addEventListener('wheel', scroll, true);

    return () => {
      ref.current?.removeEventListener('wheel', scroll, true);
    };
  }, []);

  return {
    slider,
  };
};

export default useMouseWheelScroll;

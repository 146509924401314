import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import {
  EMPLOYEE_REPORT_TITLE,
  IS_EMPLOYEE_TIME_LOGS,
  REPORT_TITLE,
} from './utils/titleHandler';
import { UseSubHeaderBlockProps, UseSubHeaderBlockReturn } from './types';

export const useSubHeaderBlock = ({
  reportProjectEmployee,
  reportProject,
}: UseSubHeaderBlockProps): UseSubHeaderBlockReturn => {
  const { t } = useTranslation();
  const location = useLocation();

  const decodedLocation = decodeURIComponent(location.pathname);
  const match = (pattern: RegExp) => pattern.test(decodedLocation);

  const {
    billable,
    clientId,
    employeeId,
    endDate,
    pmId,
    projectId,
    startDate,
    employeeTaskName,
    departmentName,
  } = useParams();

  const employeeReportTitle = EMPLOYEE_REPORT_TITLE(
    reportProjectEmployee,
    startDate,
    endDate,
  );

  const isEmployeeTimeLogs = IS_EMPLOYEE_TIME_LOGS(reportProject);
  const reportTitle = REPORT_TITLE(reportProject, startDate, endDate);

  return {
    billable,
    clientId,
    employeeId,
    employeeReportTitle,
    employeeTaskName,
    endDate,
    isEmployeeTimeLogs,
    match,
    pmId,
    projectId,
    reportTitle,
    startDate,
    t,
    decodedLocation,
    departmentName,
  };
};

import React from 'react';
import Button from '../Button/Button';
import SearchIcon from './SearchIcon';

type SearchFieldProps = {
  type: JSX.IntrinsicElements['input']['type'];
  onChange: JSX.IntrinsicElements['input']['onChange'];
  placeholder: JSX.IntrinsicElements['input']['placeholder'];
  value: JSX.IntrinsicElements['input']['value'];
};

const SearchField = ({
  onChange,
  placeholder,
  type,
  value,
}: SearchFieldProps) => (
  <div className='search_input_wrapper'>
    <input
      className='search_field'
      onChange={onChange}
      value={value}
      type={type}
      placeholder={placeholder}
    />
    <Button
      type='button'
      icon={<SearchIcon />}
      style={{ pointerEvents: 'none' }}
    />
  </div>
);

export default SearchField;

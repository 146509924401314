import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { accrualTypeFilterEN } from './translations/accrualTypeFilterEN';
import { vacationDataEN } from './translations/vacationDataEN';
import { buttonsEN } from './translations/buttonsEN';
import { columnsEN } from './translations/columnsEN';
import { formFieldsEN } from './translations/formFieldsEN';
import { generateReportEN } from './translations/generateReportEN';
import { itemInfoEN } from './translations/itemInfoEN';
import { modalsEN } from './translations/modalsEN';
import { monthsEN } from './translations/monthsEN';
import { statusFilterEN } from './translations/statusFilterEN';
import { warningEN } from './translations/warningEN';
import { errorEN } from './translations/errorEN';

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: {
      translation: {
        menu: {
          bcoin: 'B-coin',
          clients: 'Clients',
          projects: 'Projects',
          reports: 'Reports',
          sickness: 'Sickness',
          tasks: 'Tasks',
          time_tracking: 'Tracking',
          vacation: 'Vacation',
        },
        time_tracking: {
          title: "It's time to track your time!",
          total_values: {
            day_total: 'Day total',
            month_total: 'Month total',
            week_total: 'Week total',
          },
        },
        sickness: {
          title: 'Sickness',
        },
        vacation: {
          title: 'Vacation',
          vacation_data: vacationDataEN,
        },
        projects: {
          title: 'Projects',
          total: 'Total projects',
          total_team: 'Total team members',
          select_project: 'Select project',
          listing: {
            title: 'Project Manager',
            value: ['All', 'Managed by me', 'Managed by none', 'None'],
          },
        },
        clients: {
          title: 'Clients',
          total: 'Total clients',
          select_client: 'Select client',
        },
        tasks: {
          title: 'Tasks',
          total: 'Total tasks',
        },
        reports: {
          title: 'Reports',
          subtitle: {
            all: 'All',
            client_report: 'Client report',
            only_total: 'Total',
            report: 'Report',
            total_report: 'Total report',
            sub_total: 'Sub total',
          },
          types: {
            title: 'Type of report',
            value: {
              by_client: 'Client report',
              by_project: 'Project report',
              by_pm: 'PM report',
              by_total: 'Total report',
            },
          },
          filters: {
            client: 'Client',
            department: 'Department',
            employee_name: 'Employee name',
            tasks: 'Tasks',
            end_date: 'End date',
            pm_name: 'Project manager',
            project_name: 'Project',
            start_date: 'Start date',
            date: 'Date',
          },
          new_item: 'Create report',
          accrual_type: accrualTypeFilterEN,
        },
        buttons: buttonsEN,
        columns: columnsEN,
        form: formFieldsEN,
        generate_report: generateReportEN,
        item_info: itemInfoEN,
        modals: modalsEN,
        months: monthsEN,
        status: statusFilterEN,
        warning: warningEN,
        error: errorEN,
      },
    },
  },
});

export default i18n;

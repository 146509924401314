import styles from '../../../styles/time-tracking.module.scss';
import TextField from '../../../components/TextField/TextField';
import Button from '../../../components/Button/Button';

import { HeaderBlockProps } from './types';
import { FC } from 'react';
import { useHeaderBlock } from './useHeaderBlock';

const HeaderBlock: FC<HeaderBlockProps> = ({
  currentDate,
  getTimeLogsList,
  setHandleValues,
}) => {
  const { t, onClickHandler, dateValue, isButtonDisabled } = useHeaderBlock({
    currentDate,
    getTimeLogsList,
    setHandleValues,
  });

  return (
    <div className={styles.formFooterEnd}>
      <TextField
        labelclassname={styles.labelEmpty}
        className={styles.datePickerInput}
        value={dateValue}
        readOnly
      />
      <Button
        label={t('buttons.today') as string}
        styleClass='btn__rounded-blue'
        onClick={onClickHandler}
        disabled={isButtonDisabled}
      />
    </div>
  );
};

export default HeaderBlock;

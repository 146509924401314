import {
  TaskSelectHandlerProps,
  TaskSelectHandlerReturn,
  SelectedTaskOption,
} from '../types';

export const taskSelectHandler = ({
  filteredEmployeeData,
  timeLogState,
  setTimeLogState,
  setIsChangingTask,
}: TaskSelectHandlerProps): TaskSelectHandlerReturn => {
  const taskOptions = filteredEmployeeData?.tasks?.map((task) => ({
    id: task.eptId,
    label: task.name,
    value: task.name,
  }));

  const handleTaskChange = (selectedOption: SelectedTaskOption) => {
    setIsChangingTask(true);
    setTimeLogState((prevState) => ({
      ...prevState,
      task: selectedOption.value,
      eptId: selectedOption.id,
    }));
  };

  const taskValue = {
    label: timeLogState?.task,
    value: timeLogState?.task,
  };

  return {
    taskOptions: taskOptions || [],
    taskValue,
    handleTaskChange,
  };
};

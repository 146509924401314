export const msgType =
  <T extends string>(type: T) =>
  () =>
    ({ type }) as const;

export const msgWithPayload =
  <P>() =>
  <T extends string>(type: T) =>
  (payload: P) =>
    ({
      ...payload,
      type,
    }) as const;

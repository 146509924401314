import React from 'react';
import SearchField from '../SearchField/SeachField';

type SearchFilterProps = {
  filter: string;
  setFilter: (value: string) => void;
};

const SearchFilter = ({ filter, setFilter }: SearchFilterProps) => (
  <SearchField
    type='text'
    value={filter || ''}
    onChange={(e) => setFilter(e.target.value)}
    placeholder='Search...'
  />
);

export default SearchFilter;

export const headRoles = [
  'Accountant',
  'CEO',
  'Delivery Manager',
  'Head of Accountant',
  'Head of Delivery',
  'Head of HR',
  'Head of Lead Generation',
  'Head of Recruitment',
  'Head of Sales And Marketing',
  'Lead Marketing Manager',
  'Lead of System Administration',
  'Project Manager',
  'Recruitment Lead',
] as const;

import { useState } from 'react';
import { UseRowExpandedReturn } from '../types';

export const useRowExpanded = (): UseRowExpandedReturn => {
  const [expandedRowId, setExpandedRowId] = useState(
    localStorage.getItem('expandedEmployee') ?? null,
  );
  const allSubRowsExpanded = (expand: boolean) => ({
    [expandedRowId ?? '']: expand,
  });
  const expandByDefault = allSubRowsExpanded(true);

  return {
    expandByDefault,
    setExpandedRowId,
  };
};

import React from 'react';
import {
  ACCRUAL_FILTER_TYPE,
  AccrualFilterSingular,
} from '../translations/accrualTypeFilterEN';
import useRolesToAccessBillable from './useRolesToAccessBillable';
import { useTranslation } from 'react-i18next';

export type WithBillableType = {
  billableType?: AccrualFilterSingular;
};

type UseBillableParams<
  TState extends WithBillableType,
  TCompleteState extends TState,
> = {
  className: string;
  disabled: boolean;
  setState: React.Dispatch<React.SetStateAction<TCompleteState>>;
};

const useBillable = <
  TState extends WithBillableType,
  TCompleteState extends TState & WithBillableType,
>(
  params: UseBillableParams<TState, TCompleteState>,
) => {
  const extendedRole = useRolesToAccessBillable();
  const { t } = useTranslation();

  const { className, disabled, setState } = params;

  const billableTypeCheckboxes = [
    {
      checked: ACCRUAL_FILTER_TYPE.BILLABLE,
      className,
      disabled,
      id: t('reports.accrual_type.value.BILLABLE'),
      onChange: () =>
        setState((prevState) => ({
          ...prevState,
          billableType: ACCRUAL_FILTER_TYPE.BILLABLE,
        })),
    },
    {
      checked: ACCRUAL_FILTER_TYPE.NON_BILLABLE,
      className,
      disabled,
      id: t('reports.accrual_type.value.NON_BILLABLE'),
      onChange: () =>
        setState((prevState) => ({
          ...prevState,
          billableType: ACCRUAL_FILTER_TYPE.NON_BILLABLE,
        })),
    },
    {
      checked: ACCRUAL_FILTER_TYPE.INTERNAL_BILLABLE,
      className,
      disabled: !extendedRole || disabled,
      id: t('reports.accrual_type.value.INTERNAL_BILLABLE'),
      onChange: () =>
        setState((prevState) => ({
          ...prevState,
          billableType: ACCRUAL_FILTER_TYPE.INTERNAL_BILLABLE,
        })),
    },
  ];

  return billableTypeCheckboxes;
};

export default useBillable;

import { FC } from 'react';
import Button from '../../../../components/Button/Button';
import { formatToDDMMYYYY } from '../../../../utils/date-formatting';
import { exportTotalReport } from '../../../../xls/totalReportXLS';
import { TotalReportHeaderProps } from './types';

export const TotalReportHeader: FC<TotalReportHeaderProps> = ({
  endDate,
  reportTotal,
  startDate,
  t,
}) => (
  <>
    <h3>
      {`${t('reports.subtitle.total_report')} ${formatToDDMMYYYY(
        startDate,
      )} - ${formatToDDMMYYYY(endDate)}`}
    </h3>
    {!!reportTotal?.projectCountHoursDtoList?.length && (
      <Button
        label={t('generate_report.title') as string}
        onClick={() =>
          exportTotalReport({
            reportType: 'Total',
            reportTotal: reportTotal,
            startDate: startDate ?? '',
            endDate: endDate ?? '',
          })
        }
        styleClass='btn__string--underlined--grey'
      />
    )}
  </>
);

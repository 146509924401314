import { postSicknessLog, postSicknessFile } from '../../../api/sickness';
import { CLEAR_TEXT, SET_LOADER } from '../../../store/actions';
import { handleSubmitEditProps, HandleSubmitProps } from './types';
export const handleSubmit = async ({
  timeLogRequest,
  fileItem,
  clearedTimeLogRequest,
  setHandleValues,
  setDraftToEdit,
  updateSicknessList,
}: HandleSubmitProps) => {
  try {
    const data = await postSicknessLog({ data: timeLogRequest });

    await Promise.all(
      (data ?? []).map((id) =>
        postSicknessFile({
          timeLogId: id,
          fileItem,
        }),
      ),
    );
    updateSicknessList();
  } catch (error) {
    console.error('Error posting sickness log:', error);
  } finally {
    setHandleValues(CLEAR_TEXT({ payload: clearedTimeLogRequest }));
    setDraftToEdit();
  }
};

export const handleSubmitEdit = async ({
  endingRequest,
  fileItem,
  setHandleValues,
  timeLogId,
  updateLog,
  updateSicknessList,
}: handleSubmitEditProps) => {
  setHandleValues(SET_LOADER({ payload: { isLoading: true } }));

  try {
    if (timeLogId?.length) {
      await updateLog();
      updateSicknessList();
    } else {
      await postSicknessFile({
        //TODO: test: first array was passed here which is completely wrong
        //hint: probably need to map over every timeLogId and send request
        timeLogId: timeLogId[0],
        fileItem,
      });
      updateSicknessList();
    }
  } catch (error) {
    console.error('Error handling button click:', error);
  } finally {
    endingRequest();
    setHandleValues(SET_LOADER({ payload: { isLoading: false } }));
  }
};

import { useState } from 'react';
import { UseBCoinFAQContentReturn } from './types';

export const useBCoinFAQContent = (): UseBCoinFAQContentReturn => {
  const [isActiveTile, setIsActiveTile] = useState([0]);

  const handleActiveTab = (index: number) => {
    setIsActiveTile((prev) =>
      prev.includes(index)
        ? prev.filter((el) => el !== index)
        : [...prev, index],
    );
  };

  return { isActiveTile, handleActiveTab };
};

import PropTypes from 'prop-types';
import Slider from 'react-slick';
import HowToGetCard from './HowToGetCard/HowToGetCard';
import PointerWhite from '../../../images/PointerWhite.gif';
import 'slick-carousel/slick/slick.css';
import useMouseWheelScroll from '../../../hooks/useMouseWheelScroll';
import { BCoinTaskDto } from '../../../api/api-types';
import { useRef } from 'react';

const settings = {
  arrows: false,
  autoplay: false,
  className: 'how-to-get-list',
  dots: false,
  draggable: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 3,
  swipeToSlide: true,
  touchMove: true,
  variableWidth: true,
};

type HowToGetListProps = {
  items: BCoinTaskDto[];
};

const HowToGetList = ({ items }: HowToGetListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { slider } = useMouseWheelScroll(containerRef);
  return (
    <div ref={containerRef} className='how-to-get-container'>
      <Slider {...settings} ref={slider}>
        {items.map((item) => {
          // TODO: after admin panel is added this is to be removed
          const result = item.description?.split(/\r\n/);
          if (!result || !result.length) return null;
          const description = result[0];
          const additionalData = result.slice(1, result.length - 1);
          return (
            <HowToGetCard
              id={item.id}
              additionalData={additionalData.length ? additionalData : null}
              description={description}
              key={item.id}
              link={item.link}
              name={item.name}
              price={item.price}
            />
          );
        })}
      </Slider>
      <div className='pointer-bottom'>
        <img src={PointerWhite} alt='pointer' />
      </div>
    </div>
  );
};

HowToGetList.propTypes = {
  items: PropTypes.instanceOf(Array),
};

export default HowToGetList;

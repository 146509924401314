import { ProjectDto } from '../../../../api/api-types';

export const getProjectOpitons = (timeLogs: ProjectDto[]) => {
  const projectOptions = timeLogs.map(({ name = '' }) => ({
    label: name,
    value: name,
  }));

  return projectOptions;
};

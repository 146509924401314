import { CustomerSelelectHandlerProps } from '../types';

const customerSelectHandler =
  ({ setProjectState, getClientData }: CustomerSelelectHandlerProps) =>
  (customerId: number) => {
    setProjectState((prevState) => ({
      ...prevState,
      customerId,
      customerLocationId: null,
    }));

    getClientData(customerId);
  };

export default customerSelectHandler;

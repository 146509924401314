import {
  SET_CONFIRM_POP_UP,
  SET_IS_POP_UP_APPROVED,
  SET_WARNING_POP_UP,
} from '../actions';

type SetEditModeAction = {
  status: boolean;
  rowId?: number | null;
};

export const setConfirmPopUpAction = ({ status, rowId }: SetEditModeAction) =>
  SET_CONFIRM_POP_UP({ payload: { status, rowId } });

export const setWarningPopUpAction = (status: boolean) =>
  SET_WARNING_POP_UP({ payload: status });

export const setIsPopUpApprovedAction = (status: boolean) =>
  SET_IS_POP_UP_APPROVED({ payload: status });

import { useTranslation } from 'react-i18next';
import useBillable, { WithBillableType } from '../../../hooks/useBillable';
import { useAppSelector } from '../../../hooks/redux';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { ModalNameToModalMap } from '../types';
import { TeamMemberState, UseTeamMemberModalReturn } from './types';
import { EmployeeProjectTaskDto } from '../../../api/api-types';
import { getOnlyNumbers } from '../../../utils/number-handling';

export const useTeamMemberModal = (
  payload: ModalNameToModalMap['addEditProjectTeamMemberModal'],
): UseTeamMemberModalReturn => {
  const { t } = useTranslation();
  const { modalTitle, onSave, data } = payload;

  const { employeesByRole, projectData, taskList } = useAppSelector(
    (state) => state,
  );

  const initializeTeamMemberState = (
    data: EmployeeProjectTaskDto | null = null,
  ) => {
    const {
      billableType = '',
      employeeId = null,
      endDate = null,
      id = null,
      role = null,
      startDate = '',
      task = { id: null },
      overtime = null,
    } = data ?? {};

    return {
      billableType,
      employeeId,
      endDate,
      id,
      role,
      startDate,
      taskId: task?.id ?? null,
      overtime,
    };
  };

  const [validationMessage, setValidationMessage] = useState('');
  const [teamMemberState, setTeamMemberState] = useState<TeamMemberState>(
    initializeTeamMemberState(data as EmployeeProjectTaskDto),
  );

  const isRequiredStartDate =
    Boolean(teamMemberState.overtime?.overtimeRate) &&
    !teamMemberState.overtime?.startDate; // start date is required if overtime rate is set

  const employeeOptions = employeesByRole?.map((employee) => ({
    label: employee.name,
    value: employee.id,
  }));

  const billableTypeCheckboxes = useBillable({
    className: 'checkbox_reversed',
    disabled: false,
    setState: setTeamMemberState as Dispatch<SetStateAction<WithBillableType>>,
  });

  const taskOptions = taskList?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const overtimeRateChangeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setTeamMemberState((state) => ({
      ...state,
      overtime: {
        ...state.overtime,
        overtimeRate: Number(getOnlyNumbers(e.target.value)),
      },
    }));

  const FTEChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const FTEInputValue = e.target.value;
    const FTEValue = Number(FTEInputValue);
    const validValues = ['', '0', '0.5', '1'];

    if (!validValues.includes(FTEInputValue)) {
      setValidationMessage('Must be 1, 0,5 or empty.');
      return;
    } else {
      setValidationMessage('');
    }

    if (FTEValue === 0) {
      setTeamMemberState((state) => ({
        ...state,
        overtime: null,
      }));
      return;
    }

    if (FTEValue > 0) {
      setTeamMemberState((state) => ({
        ...state,
        overtime: {
          ...state.overtime,
          fte: parseFloat(FTEInputValue),
          overtimeRate: teamMemberState.overtime?.overtimeRate ?? 0,
        },
      }));
      return;
    }

    return null;
  };

  return {
    FTEChangeHandler,
    billableTypeCheckboxes,
    employeeOptions,
    isRequired: isRequiredStartDate,
    modalTitle,
    onSave,
    overtimeRateChangeHandler,
    projectData,
    setTeamMemberState,
    t,
    taskOptions,
    teamMemberState,
    validationMessage,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACCRUAL_FILTER_TYPE } from '../translations/accrualTypeFilterEN';
import { Row } from 'react-table';

export type ReportRowData = {
  billableType?: unknown;
  isBillable?: boolean;
};
export const displayBillable = <TRow extends Row<ReportRowData>>(row: TRow) => {
  const hasBillableType = !!row.original.billableType;
  const isBillable =
    hasBillableType &&
    row.original.billableType === ACCRUAL_FILTER_TYPE.BILLABLE;
  const hasIsBillable = !!row.original.isBillable;

  if (isBillable || hasIsBillable) {
    return {
      style: {
        color: '#000000',
      },
    };
  }

  return {
    style: {
      color: '#2979FF',
    },
  };
};
//TODO: make return type more specific
export type ChangeRowColor = (
  row: Row<ReportRowData>,
) => Record<string, unknown>;

import React from 'react';

interface EmployeeReportNameProps {
  employeeFullName?: {
    name?: string;
  };
  isDelivery?: boolean;
  taskName?: string;
}

const EmployeeReportName: React.FC<EmployeeReportNameProps> = ({
  employeeFullName,
  isDelivery,
  taskName,
}) => {
  return (
    <div className='project_report_name'>
      {`${employeeFullName?.name}${!isDelivery ? ' (n/d)' : ''} - ${taskName}`}
    </div>
  );
};

export default EmployeeReportName;

export const generateReportEN = {
  title: 'Generate excel report',
  pdf_title: 'Download PDF report',
  pdf_generating: 'Creating PDF report...',
  file_names: {
    client_report: 'Client report',
    pm_report: 'PM report',
    pm_employee_report: 'PM employee report',
    projects_report: 'Projects report',
    tasks_report: 'Tasks report',
    timelogs_per_project_report: 'Timelogs per project report',
    timelogs_report: 'Timelogs report',
    total_report: 'Total report',
  },
} as const;

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import RobotStandsImage from '../../../images/RobotStandsImage.png';
import BCoinBalanceContent from '../components/BCoinBalanceContent';
import BCoinBodyLeftCol from '../components/BCoinBodyLeftCol';
import SearchLineIcon from './SearchLineIcon';
import { getBCoinBalances } from '../../../api/bcoin';
import Loader from '../../../components/Loader/Loader';
import { BCoinEmployeeDto, UserFullDto } from '../../../api/api-types';
import { getRegisteredEmployee } from '../../../api/projects';

const EMPLOYEE_POSITION = 5;

const BCoinBalance = () => {
  const [currentValue, setCurrentValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<BCoinEmployeeDto[]>([]);
  const [user, setUser] = useState<UserFullDto>({} as UserFullDto);
  const [currentEmloyee, setCurrentEmloyee] = useState<BCoinEmployeeDto | null>(
    null,
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const registeredUser = await getRegisteredEmployee();
        setUser(registeredUser ?? ({} as UserFullDto));
      } catch (error) {
        console.error('Failed to fetch registered employee:', error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const getItems = async () => {
      setIsLoading(true);

      const apiItems = await getBCoinBalances();
      const userItemIndex =
        apiItems?.findIndex((item) => item.id === user.id) ?? 0;
      const userItem = apiItems ? apiItems[userItemIndex] : null;

      if (userItemIndex > EMPLOYEE_POSITION) {
        setCurrentEmloyee(userItem);
      } else {
        setCurrentEmloyee(null);
      }
      setItems(apiItems ?? []);
      setIsLoading(false);
    };
    getItems();
  }, [user]);

 return (
   <div className='bcoin-body'>
     <BCoinBodyLeftCol
       description='Here you can see the total amount of b-coins and how many you’ve spent'
       imageAsset={RobotStandsImage}
       imageClassName='bcoin-body-left-col-img-offset'
       title='b-coin'
       subtitle='balance'
     />
     <div className='bcoin-body-right-col bcoin-balance-right-col'>
       <div className='bcoin-search-bar-container'>
         <BCoinBalanceSearchBar
           currentValue={currentValue}
           handleChangeValue={(e) => setCurrentValue(e.target.value)}
         />
       </div>
       {items.length ? (
         <BCoinBalanceContent
           currentEmloyee={currentEmloyee}
           topItems={items.slice(0, 3)}
           items={items.filter((item) => {
             const lowerCaseCurrentValue = currentValue.toLowerCase();
             return item.name?.toLowerCase().includes(lowerCaseCurrentValue);
           })}
         />
       ) : null}
     </div>
     {isLoading ? <Loader /> : null}
   </div>
 );
};

const BCoinBalanceSearchBar = (props: BCoinBalanceSearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFind = (event: KeyboardEvent) => {
    const isCtrlKey = event.metaKey || event.ctrlKey;
    const isFKey = event.key === 'f' || event.key === 'F';
    if (isCtrlKey && isFKey) {
      // ? Disable default cmd/ctrl + F functionality in browser
      event.preventDefault();
      inputRef.current?.focus();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleFind);
    return () => window.removeEventListener('keydown', handleFind);
  }, []);
  return (
    <div className='bcoin-search-bar'>
      <div className='bcoin-search-bar-icon'>
        <SearchLineIcon />
      </div>
      <input
        className='bcoin-search-bar-input'
        type='text'
        placeholder='Search...'
        value={props.currentValue}
        onChange={props.handleChangeValue}
        ref={inputRef}
      />
      <div className='bcoin-search-bar-text'>General</div>
      <div className='bcoin-search-bar-text'>Spent</div>
    </div>
  );
};

type BCoinBalanceSearchBarProps = {
  currentValue: string;
  handleChangeValue: React.ChangeEventHandler<HTMLInputElement>;
};

BCoinBalanceSearchBar.propTypes = {
  currentValue: PropTypes.string,
  handleChangeValue: PropTypes.func,
};

export default BCoinBalance;

import { useEffect } from 'react';

const useOnClickOutside = (
  isEventActual: boolean,
  ref: React.RefObject<HTMLElement>,
  handler: () => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        isEventActual &&
        ref?.current &&
        !ref?.current.contains(event.target as HTMLElement)
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [isEventActual, ref, handler]);
};

export default useOnClickOutside;

import {
  TaskStatusOptionsHandlerProps,
  TaskStatusOptionsHandlerReturn,
} from '../types';

export const taskStatusOptionsHandler = ({
  taskState,
  setTaskState,
}: TaskStatusOptionsHandlerProps): TaskStatusOptionsHandlerReturn => {
  const taskStatusOnChangeHandler = (status: { value: string }) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskStatus: status.value,
    }));
  };

  const taskValue = {
    label: taskState?.taskStatus,
    value: taskState?.taskStatus,
  };
  return {
    taskStatusOnChangeHandler,
    taskValue,
  };
};

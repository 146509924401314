import { useEffect, useRef, useState } from 'react';
import useMouseWheelScroll from '../../../../hooks/useMouseWheelScroll';
import { ConfirmationPopupContentProps } from '../../../../components/ConfirmationPopup/ConfirmationPopup/types';
import { getBCoinRewardImages } from '../../../../api/bcoin';
import { BCoinItemModalProps, UseBCoinItemModalReturn } from './types';

export const useBCoinItemModal = (
  props: BCoinItemModalProps,
): UseBCoinItemModalReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const { slider } = useMouseWheelScroll(containerRef);

  const { id: itemId } = props.item;
  const characteristics = props.item?.characteristics
    ?.split(/\r\n/)
    .slice(0, -1);

  const exchangeText: ConfirmationPopupContentProps['text'] = {
    initialState: {
      title: 'Confirm the exchange?',
      cancel: 'Cancel',
      confirm: 'Yes',
    },
    successState: {
      title: 'The request has been sent',
      subtitle: 'Wait for a message from Marvin 🚀',
    },
  };

  useEffect(() => {
    if (itemId) {
      const getItems = async () => {
        setIsLoading(true);

        const apiItems = await getBCoinRewardImages(itemId);

        if (apiItems instanceof Array) setImages(apiItems);

        setIsLoading(false);
      };
      getItems();
    }
  }, []);

  return {
    characteristics,
    confirmModalOpen,
    containerRef,
    exchangeText,
    images,
    isLoading,
    itemId,
    setConfirmModalOpen,
    slider,
  };
};

import { useTranslation } from 'react-i18next';

import { DatePickerBlockProps } from './types';
import { DatePickerDoubleDates } from './components/DatePickerDoubleDates/DatePickerDoubleDates';
import { DatePickerSingleDate } from './components/DatePickerSingleDate/DatePickerSingleDate';

export const useDatePickerBlock = ({
  currentDate,
  checkboxState,
  dayOffType,
  endDate,
  onMonthChange,
  registeredEmployee,
  setHandleValues,
  startDate,
  vacationData,
}: DatePickerBlockProps) => {
  const { t } = useTranslation();

  const loggedDates =
    vacationData.dateAndProgressPercentage &&
    vacationData.dateAndProgressPercentage
      .filter((el) => el.progressPercentage === 100)
      .map(
        (log) =>
          new Date(
            new Date(log.date ?? '').setMinutes(
              new Date(log.date ?? '').getTimezoneOffset(),
            ),
          ),
      );

const choosePickerType = () => {
  const isFullDay =
    checkboxState === 0 &&
    (dayOffType === '' ||
      dayOffType === t('vacation.vacation_data.paid_vacations') ||
      dayOffType === t('vacation.vacation_data.unpaid_day_offs'));

  if (isFullDay) {
    return DatePickerDoubleDates({
      dayOffType,
      endDate,
      loggedDates,
      onMonthChange,
      registeredEmployee,
      setHandleValues,
      startDate,
      vacationData,
    });
  }

  return DatePickerSingleDate({
    currentDate,
    onMonthChange,
    setHandleValues,
    vacationData,
  });
};

  return { choosePickerType, t };
};

import { useState, useEffect } from 'react';
import { Msg } from '../../../store/actions';
import {
  dateArrayReducerHandler,
  dateReducerHandler,
} from '../../../utils/date-handling';

export const useDateSelection = (
  setHandleValues: React.Dispatch<Msg>,
  handleGlobalEditMode: (editMode: boolean) => void,
) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [isCommandOrCtrlPressed, setIsCommandOrCtrlPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey || event.ctrlKey) {
        setIsCommandOrCtrlPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.metaKey && !event.ctrlKey) {
        setIsCommandOrCtrlPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onChangeHandler = (selected: Date | null) => {
    if (!selected) return;

    handleGlobalEditMode(false);

    setHandleValues({
      type: 'EDIT_MODE',
      payload: {
        editValuesMode: false,
      },
    });

    setSelectedDates((prevDates) => {
      if (isCommandOrCtrlPressed) {
        const isAlreadySelected = prevDates.some(
          (d) => d.getTime() === selected.getTime(),
        );

        return isAlreadySelected
          ? prevDates.filter((d) => d.getTime() !== selected.getTime())
          : [...prevDates, selected];
      }

      return [selected];
    });

    if (isCommandOrCtrlPressed) {
      dateArrayReducerHandler(setHandleValues, [...selectedDates, selected]);
    } else {
      dateArrayReducerHandler(setHandleValues, [selected]);
      dateReducerHandler(setHandleValues, selected);
    }
  };

  return {
    selectedDates,
    onChangeHandler,
    isCommandOrCtrlPressed,
  };
};

import { HoursByProjectAndEmployee } from '../../../api/api-types';
import { groupBy } from '../../../utils/filtering';

type Item = [string, HoursByProjectAndEmployee[]];

export function groupByProjects(projects: HoursByProjectAndEmployee[]) {
  return Array.from(
    groupBy(
      projects,
      (item: HoursByProjectAndEmployee) => `${item.projectNameAndId?.id}` ?? '',
    ),
    (item: Item) => {
      return {
        project: item[0],
        employees: item[1],
      };
    },
  );
}

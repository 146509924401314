import { ReactComponent as PlusXLIcon } from '../../../../../icons/PlusXLIcon.svg';
import { ReactComponent as MinusXLIcon } from '../../../../../icons/MinusXLIcon.svg';
import { selectAnswer } from '../helpers/getQuestionsAnswers';
import { FAQTileProps } from '../types';

const FAQTile = ({
  item,
  index,
  isActiveTile,
  handleActiveTab,
}: FAQTileProps) => (
  <div className='bcoin-faq-tile' onClick={() => handleActiveTab(index)}>
    <div className='bcoin-faq-tile-header'>
      <div className='bcoin-faq-question'>{item.question}</div>
      <div className='bcoin-faq-tile-icon'>
        {isActiveTile.includes(index) ? <MinusXLIcon /> : <PlusXLIcon />}
      </div>
    </div>
    {isActiveTile.includes(index) && selectAnswer(index)}
  </div>
);

export default FAQTile;

import modals from '../../../constants/modals';
import { withModal } from '../../../providers/ModalProvider';
import { BaseModalComponentType, ModalNameToModalMap } from '../types';
import { ReactComponent as CancelIcon } from '../../../icons/CancelIcon.svg';

import Modal from '../Modal';
import TextField from '../../TextField/TextField';
import { nameAndEmailInputLength } from '../../../constants/input-restrictions';
import SimpleSelect from '../../Select/SimpleSelect';
import { selectPMModalMain } from '../../Select/selectStyles';

import styles from '../styles/client-modal.module.scss';

import ContactPersonBlock from './components/ContactPersonBlock/ContactPersonBlock';
import { AddItemButton } from './components/AddItemButton';
import { LocationsBlock } from './components/LocationsBlock/LocationsBlock';
import { getModalButtons } from './components/modalButtons';
import { useAddEditClientModal } from './useAddEditClientModal';

const AddEditClientModal = ({
  closeModal,
  payload,
}: BaseModalComponentType & {
  payload: ModalNameToModalMap['addEditClientModal'];
}) => {
  const {
    clientState,
    handleClientName,
    handleUnitChange,
    isSaveDisabled,
    modalTitle,
    onAddContactPerson,
    onAddLocation,
    onSave,
    setClientState,
    t,
    unitsOptions,
  } = useAddEditClientModal({ payload, closeModal });

  return (
    <Modal
      styles={styles}
      title={modalTitle}
      onClose={closeModal}
      closeRight
      topRightButton={<CancelIcon />}
      onTopRight={closeModal}
      buttons={getModalButtons(isSaveDisabled, t, closeModal, onSave)}
    >
      <form onSubmit={(v) => console.log(v)} className={styles.modal_form}>
        <div className={styles.cp_item}>
          <TextField
            field='client'
            value={clientState.clientName}
            name='clientName'
            onChange={handleClientName}
            labelclassname={styles.common_input_reverse}
            title={`${t('modals.fields.client_name')}*`}
            maxLength={nameAndEmailInputLength}
          />
          <SimpleSelect
            selectTitle={`${t('modals.fields.unit')}*`}
            name='unitId'
            selectWrapperClass={styles.common_input}
            selectTitleClassName={styles.common_input_title}
            styles={selectPMModalMain}
            options={unitsOptions}
            value={
              unitsOptions.find(
                ({ value }) => value === clientState.unitId,
              ) || { value: '', label: '' }
            }
            onChange={(e) => handleUnitChange(Number(e.value))}
          />
        </div>
        <hr />
        <div>
          <AddItemButton
            title={t('modals.fields.contact_person')}
            onClick={onAddContactPerson}
          />
          {clientState.contactPersons.map(
            ({ id, name, phoneNumber, emails }, idx) => (
              <ContactPersonBlock
                key={id}
                idx={idx}
                name={name}
                phoneNumber={phoneNumber}
                emails={emails}
                handleClientState={setClientState}
              />
            ),
          )}
          <hr />
          <AddItemButton
            title={t('modals.fields.location')}
            onClick={onAddLocation}
          />
          {clientState.locationDtoSet.map(({ name, id }, i) => (
            <LocationsBlock
              key={id}
              idx={i}
              location={name || ''}
              handleClientState={setClientState}
            />
          ))}
        </div>
      </form>
    </Modal>
  );
};

export default withModal(modals.addEditClientModal)(AddEditClientModal);

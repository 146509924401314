import { ReactComponent as CancelIcon } from '../../../icons/CancelIcon.svg';
import CalendarDatePicker from '../../DatePickers/DatePicker/CalendarDatePicker';
import { selectNewClientModal } from '../../Select/selectStyles';
import SimpleSelect from '../../Select/SimpleSelect';
import TextField from '../../TextField/TextField';
import Modal from '../Modal';
import styles from './../styles/team-member-modal.module.scss';
import { rateInputLength } from '../../../constants/input-restrictions';
import Checkbox from '../../Checkbox/Checkbox';
import { withModal } from '../../../providers/ModalProvider';
import modals from '../../../constants/modals';
import { BaseModalComponentType, ModalNameToModalMap } from '../types';
import { useTeamMemberModal } from './useTeamMemberModal';
import { getButtons } from './helpers/getButtons';
import { employeeNameOptionsHandler } from './helpers/employeeNameOptionsHandler';
import { useStartEndDate } from './hooks/useStartEndDate';
import { taskNameOptionsHandler } from './helpers/taskNameOptionsHandler';
import { useOvertimeStartEndDate } from './hooks/useOvertimeStartEndDate';

const TeamMemberModal = ({
  closeModal,
  payload,
}: BaseModalComponentType & {
  payload: ModalNameToModalMap['addEditProjectTeamMemberModal'];
}) => {
  const {
    billableTypeCheckboxes,
    employeeOptions,
    modalTitle,
    onSave,
    overtimeRateChangeHandler,
    // FTEChangeHandler,
    projectData,
    setTeamMemberState,
    t,
    taskOptions,
    teamMemberState,
    isRequired,
    validationMessage,
  } = useTeamMemberModal(payload);

  const teamMemberModalButtons = getButtons({
    t,
    onSave,
    teamMemberState,
    closeModal,
  });

  const { onEmployeeNameChange, employeeValue } = employeeNameOptionsHandler({
    employeeOptions,
    setTeamMemberState,
    teamMemberState,
  });

  const {
    endDate,
    handleEndDateChange,
    handleStartDateChange,
    minEndDate,
    minStartDate,
    startDate,
  } = useStartEndDate({
    projectData,
    setTeamMemberState,
    teamMemberState,
  });

  const {
    overtimeStartDate,
    overtimeEndDate,
    overtimeMinEndDate,
    handleOvertimeStartDateChange,
    handleOvertimeEndDateChange,
  } = useOvertimeStartEndDate({
    projectData,
    setTeamMemberState,
    teamMemberState,
  });

  const { taskNameChangeHandler, taskNameValue } = taskNameOptionsHandler({
    setTeamMemberState,
    taskOptions,
    teamMemberState,
  });

  return (
    <div className={styles.container}>
      <Modal
        buttons={teamMemberModalButtons}
        closeRight
        onClose={closeModal}
        onTopRight={closeModal}
        styles={styles}
        title={modalTitle}
        topRightButton={<CancelIcon />}
      >
        <form onSubmit={() => onSave} className={styles.form}>
          <div className={styles.form_block}>
            <div className={styles.form_row}>
              <div className={styles.form_block}>
                <SimpleSelect
                  selectWrapperClass={styles.input_label}
                  selectTitle={`${t('modals.fields.employee_name')}*`}
                  selectTitleClassName={styles.input_label_name}
                  isSearchable
                  options={employeeOptions}
                  onChange={onEmployeeNameChange}
                  value={employeeValue}
                  styles={selectNewClientModal}
                />
                <CalendarDatePicker
                  onChange={handleStartDateChange}
                  inputClassName={styles.date_input_field}
                  minDate={minStartDate}
                  startDate={startDate}
                  title={`${t('modals.fields.start_date')}*`}
                  wrapperClassName={styles.date_input}
                  titleClassName={styles.date_input_title}
                />
                <CalendarDatePicker
                  onChange={handleEndDateChange}
                  inputClassName={styles.date_input_field}
                  minDate={minEndDate}
                  startDate={endDate}
                  title={t('modals.fields.end_date') as string}
                  wrapperClassName={styles.date_input}
                  titleClassName={styles.date_input_title}
                />
                <SimpleSelect
                  selectTitleClassName={styles.input_label_name}
                  selectWrapperClass={styles.input_label}
                  options={taskOptions}
                  onChange={taskNameChangeHandler}
                  value={taskNameValue}
                  styles={selectNewClientModal}
                  selectTitle={`${t('modals.fields.task')}*`}
                />
              </div>
              <div className={styles.data_input}>
                {billableTypeCheckboxes.map((item) => (
                  <Checkbox
                    checked={teamMemberState.billableType === item.checked}
                    onChange={() => {}}
                    className={item.className}
                    disabled={item.disabled}
                    key={item.id}
                    labelclassname={styles.checkbox_input}
                    onClick={item.onChange}
                    title={item.id}
                  />
                ))}
              </div>
            </div>
            <div className={styles.divider} />
          </div>
          <div className={styles.form_block}>
            <div className={styles.form_row}>
              <div className={styles.form_block}>
                {/* overtime rate */}
                <TextField
                  title={t('modals.fields.overtime_rate') as string}
                  field='overtime'
                  value={teamMemberState.overtime?.overtimeRate ?? ''}
                  onChange={overtimeRateChangeHandler}
                  className={styles.rate}
                  labelclassname={styles.rate_input}
                  maxLength={rateInputLength}
                />
                {/* FTE */}
                {/* <TextField
                  title={t('modals.fields.employeeFTE') as string}
                  field='FTE'
                  value={teamMemberState.overtime?.fte ?? ''}
                  onChange={FTEChangeHandler}
                  className={styles.rate}
                  labelclassname={styles.rate_input}
                  maxLength={rateInputLength}
                  type='number'
                  min={0}
                  max={1}
                  step={0.5}
                /> */}
                {validationMessage && (
                  <div className={styles.validationMessage}>
                    {validationMessage}
                  </div>
                )}
              </div>
              <div className={styles.form_block}>
                {/* overtime start date */}
                <CalendarDatePicker
                  onChange={handleOvertimeStartDateChange}
                  inputClassName={styles.date_input_field}
                  minDate={minEndDate}
                  startDate={overtimeStartDate}
                  title={`${t('modals.fields.overtime_start_date')}`}
                  wrapperClassName={styles.date_input}
                  titleClassName={`${styles.date_input_title} ${
                    isRequired ? styles.required : ''
                  }`}
                />
                {/* overtime end date */}
                <CalendarDatePicker
                  onChange={handleOvertimeEndDateChange}
                  inputClassName={styles.date_input_field}
                  minDate={overtimeMinEndDate}
                  startDate={overtimeEndDate}
                  title={t('modals.fields.overtime_end_date') as string}
                  wrapperClassName={styles.date_input}
                  titleClassName={styles.date_input_title}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default withModal(modals.addEditProjectTeamMemberModal)(TeamMemberModal);

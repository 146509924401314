export const columnsEN = {
  archived: 'Archived',
  attachment: 'Attachment',
  client_name: 'Client name',
  customer_name: 'Customer name',
  date: 'Date',
  dates: 'Dates',
  days: 'Days',
  duration: 'Duration',
  employee_name: 'Employee name',
  end_date: 'End date',
  note: 'Note',
  overtime_rate: 'Overtime rate',
  parttime_rate: 'Part-time rate',
  payment: 'Payment',
  project: 'Project',
  project_manager: 'Project manager',
  project_name: 'Project name',
  rate_per_hour: 'Rate per hour',
  role: 'Role',
  start_date: 'Start date',
  task: 'Task',
  task_description: 'Task description',
  task_name: 'Task name',
  time_of_logging: 'Time of logging',
  total_duration: 'Total duration',
  upcoming: 'Upcoming',
  vacation: 'Vacation',
  vacation_type: 'Vacation type',
} as const;

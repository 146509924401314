import { useEffect } from 'react';
import {
  CustomerLocationDto,
  ProjectWebDto,
  UserNameIdDto,
} from '../../../api/api-types';
import { useAppDispatch } from '../../../hooks/redux';

interface UseFetchDataProps {
  currentPage: number;
  dispatch: ReturnType<typeof useAppDispatch>;
  fetchEmployeesByRole: (
    role?: string,
    heads?: boolean,
    leads?: boolean,
  ) => Promise<UserNameIdDto[] | null>;
  getAllCustomersLocations: () => Promise<CustomerLocationDto[] | null>;
  getAllProjects: () => void;
  projectListAll: ProjectWebDto[];
  selectedPMId: string;
  selectedStatus: string | null;
  sortParam: { key: string; sortOrder: string };
  updateProjectsList: () => void;
}

export const useFetchData = ({
  currentPage,
  dispatch,
  fetchEmployeesByRole,
  getAllCustomersLocations,
  getAllProjects,
  projectListAll,
  selectedPMId,
  selectedStatus,
  sortParam,
  updateProjectsList,
}: UseFetchDataProps) => {
  useEffect(() => {
    fetchEmployeesByRole('management', true, true).then((data) => {
      if (!data) return;

      dispatch({ type: 'GET_PM', payload: data });
    });

    getAllCustomersLocations().then((data) => {
      if (!data) return;

      dispatch({ type: 'GET_LOCATIONS', payload: data });
    });
  }, []);

  useEffect(() => {
    if (projectListAll?.length) return;

    getAllProjects();
  }, [!!projectListAll?.length]);

  useEffect(() => {
    updateProjectsList();
  }, [currentPage, selectedPMId, selectedStatus, sortParam]);
};

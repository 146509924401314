import { clearedTimeLog } from '../../../constants/cleared-objects';
import {
  CLEAR_TEXT,
  ON_TOGGLE_VIEW,
  SET_ID,
  SET_TEXT,
} from '../../../store/actions';
import { Row, UseColumnEditBlock, UseColumnEditBlockReturn } from './types';

export const useColumnEditBlock = ({
  editMode,
  handleConfirmPopUp,
  handleGlobalEditMode,
  row,
  setActiveRow,
  setHandleValues,
}: UseColumnEditBlock): UseColumnEditBlockReturn => {
  const editHandler = (row: Row) => {
    handleGlobalEditMode(true);

    setActiveRow(row.original?.id);

    setHandleValues(CLEAR_TEXT({ payload: clearedTimeLog }));
    setHandleValues(
      ON_TOGGLE_VIEW({
        payload: {
          editValuesMode: false,
        },
      }),
    );
    setHandleValues(SET_ID({ payload: { timeLogId: row.original.id } }));
    setHandleValues(
      SET_TEXT({
        payload: {
          comment: row.original.note || '',
          currentHour: row.original.duration.split(':')[0],
          currentMinute: row.original.duration.split(':')[1],
          projectName: row.original.project,
          taskId: row.original.eptId,
          taskName: row.original.task,
        },
      }),
    );
  };

  const deleteHandler = () => {
    if (editMode) return false;

    return handleConfirmPopUp(true, row.original.id);
  };

  return {
    editHandler,
    deleteHandler,
  };
};

import React from 'react';
import Select, { GroupBase, Props as SelectProps } from 'react-select';

type SimpleSelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  selectTitle?: string;
  selectTitleClassName?: string;
  selectWrapperClass?: string;
} & SelectProps<Option, IsMulti, Group>;

const SimpleSelect = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  selectWrapperClass,
  selectTitleClassName,
  selectTitle,
  ...props
}: SimpleSelectProps<Option, IsMulti, Group>) => (
  <div className={selectWrapperClass}>
    {selectTitle && <div className={selectTitleClassName}>{selectTitle}</div>}
    <Select
      {...props}
      menuPortalTarget={document.body}
      isSearchable
      menuPosition='absolute'
    />
  </div>
);

export default SimpleSelect;

import ExpandableTable from '../../components/Tables/ExpandableTable';
import { clientReportColumns } from '../../modules/Reports/columns';
import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import Loader from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useReportClient } from './useReportClient';
import { sortByName } from '../../constants/sort-order';

const EXPENDED_ROW_STYLE = {
  color: '#171717',
  fontWeight: 400,
  fontSize: 14,
};

const ReportClient = () => {
  const { t } = useTranslation();

  const {
    clientReportTableData,
    ROW_DEPTH,
    expandByDefault,
    goToEmployee,
    tableRef,
    setExpandedRowId,
    totalClientHours,
    isLoading,
  } = useReportClient();

  return (
    <>
      {clientReportTableData?.length ? (
        <ExpandableTable
          columns={clientReportColumns(t, ROW_DEPTH)}
          data={clientReportTableData}
          expandedRowId={expandByDefault}
          expandedRowKey='expandedClient'
          expandedRowStyle={EXPENDED_ROW_STYLE}
          onRowClick={goToEmployee}
          ref={tableRef}
          rowDepth={ROW_DEPTH}
          setExpandedRowId={setExpandedRowId}
          sortOrder={sortByName}
          tableName='total-reports-list'
        />
      ) : (
        <EmptyDataBlock />
      )}
      {clientReportTableData?.length > 1 ? (
        <div className='total_row'>
          <div className='total_title'>All clients</div>
          <div className='total_duration'>{totalClientHours}</div>
        </div>
      ) : null}
      {isLoading && <Loader />}
    </>
  );
};

export default ReportClient;

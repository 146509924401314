import { useTranslation } from 'react-i18next';
import {
  formatCurrentToISO,
  formatTodayToISO,
} from '../../../utils/date-formatting';
import { formatDateYYYYMMDD } from '../../../utils/date-formatting';
import { currentDateFormatted } from '../../../constants/date-values';
import { EDIT_MODE, SET_DATE_VALUE } from '../../../store/actions';
import { HeaderBlockProps, UseHeaderBlockReturn } from './types';

export const useHeaderBlock = ({
  currentDate,
  arrayOfDates,
  getTimeLogsList,
  setHandleValues,
}: HeaderBlockProps): UseHeaderBlockReturn => {
  const { t } = useTranslation();

  const dateValue = formatDateYYYYMMDD(new Date(currentDate));
  const isButtonDisabled =
    formatDateYYYYMMDD(new Date(currentDate)) === currentDateFormatted;

  const onClickHandler = () => {
    if (arrayOfDates) {
      setHandleValues({
        type: 'SET_DATE_VALUES_ARRAY',
        payload: { currentDates: arrayOfDates },
      });
    }
    
    setHandleValues(
      SET_DATE_VALUE({
        payload: { currentDate: new Date().toUTCString() },
      }),
    );

    if (formatCurrentToISO(currentDate) === formatTodayToISO()) {
      getTimeLogsList();
    }

    setHandleValues(
      EDIT_MODE({
        payload: {
          editValuesMode: false,
        },
      }),
    );
  };

  return { t, onClickHandler, dateValue, isButtonDisabled };
};

import React, { forwardRef } from 'react';
import { useTable } from 'react-table';

type StyledRowTableProps = {
  columns: any;
  data: any;
  tableName: string;
  onRowClick: any;
  changeRowColor: any;
};

const StyledRowTable = forwardRef<HTMLTableElement, StyledRowTableProps>(
  ({ columns, data, tableName, onRowClick, changeRowColor }, ref) => {
    const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data });
    return (
      <table className={tableName} {...getTableProps()} ref={ref}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                onClick={() => onRowClick(row)}
                {...row.getRowProps(changeRowColor(row))}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);

export default StyledRowTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { calculateTotalHours } from '../helpers/calculateTotalHours';
import { filterUniqueProjects } from '../helpers/filterUniqueProjects';
import { groupByProjects } from '../helpers/groupByProjects';
import { decimalToTime } from '../../../utils/date-formatting';

import { ClientProjectHours } from '../types';
import { createProjectSubRows } from '../helpers/createProjectSubRows';

export const useClientReportTableData = ({
  clientReportRows,
}: {
  clientReportRows: ClientProjectHours[];
  // TODO: Change the return type
}): any[] => {
  const clientReportTableData = clientReportRows.map((period) => {
    const customerName = period.client;

    if (period.projects?.length) {
      const filteredProjects = filterUniqueProjects(period.projects);
      const totalHours = calculateTotalHours(period.projects);
      const groupByProjectRows = groupByProjects(period.projects);

      return {
        name: customerName || '',
        countHours: `Total: ${decimalToTime(totalHours)}`,
        totalHours,
        subRows: createProjectSubRows(filteredProjects, groupByProjectRows),
      };
    }

    return null;
  });

  return clientReportTableData;
};

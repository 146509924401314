import { useAppDispatch } from '../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { getClientPageData } from '../../../api/customers';
import { useEffect } from 'react';
import { EditCustomerSelectProps, UseEditCustomerReturn } from './types';
import { getClientDataAction } from '../../../store/actionCreators/customerActionCreators';
import customerSelectHandler from './helpers/customerSelectHandler';

export const useEditCustomerSelect = ({
  projectState,
  setProjectState,
}: EditCustomerSelectProps): UseEditCustomerReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getClientData = async (id: number) => {
    const data = await getClientPageData({ id });
    if (!data) return;

    dispatch(getClientDataAction(data));
  };

  useEffect(() => {
    if (!projectState.customerId) return;

    getClientData(projectState.customerId);
  }, []);

  const onCustomerChange = customerSelectHandler({
    setProjectState,
    getClientData,
  });

  return { onCustomerChange, t };
};

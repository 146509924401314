import { ZodError } from 'zod';

type FormattedError = {
  name: string;
  message: string;
};

export const handleOneLevelZodError = ({ issues }: ZodError) => {
  const formData: FormattedError[] = [];

  /**
   *  line of code should be true if the schema is not an object
   *  This line is completely optional
   */
  if (issues.length === 1 && issues[0].path.length < 1) {
    formData.push({
      name: issues[0].path.join('-'),
      message: issues[0].message,
    });
    return formData;
  }

  issues.forEach(({ path, message }) => {
    formData.push({ name: path.join('-'), message });
  });

  return formData;
};

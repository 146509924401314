import { useCallback, useEffect, useReducer, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { reducer, valuesInitialState } from '../../reducer/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useSortDataTable from '../../hooks/useSortDataTable';
import { postTask, putTask } from '../../api/tasks';
import {
  createdTaskRequest,
  editedTaskRequest,
} from '../../utils/request-objects';
import { validateObjectRequest } from '../../utils/validate-objects';
import { requiredKeys } from '../../constants/required-keys';
import { sortByDefault } from '../../utils/filtering';

import { getDepartments } from '../../api/departments';
import { TaskRequest } from '../../api/api-types';
import useTaskColumns from './hooks/useTaskColumns';
import { createUpdateTaskList } from './utils/updateTaskList';
import { ON_TOGGLE_VIEW } from '../../store/actions';
import { getDepartmentsAction } from '../../store/actionCreators/tasksActionCreators';
import { useTableInstance } from './hooks/useTableInstance';
import { UseTaskListPageReturn } from './types';
import { sortByName } from '../../constants/sort-order';

export const useTaskListPage = (): UseTaskListPageReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tableRef = useRef<HTMLTableElement>(null);

  const { taskList, departmentList } = useAppSelector((state) => state);

  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const {
    isLoading,
    selectedStatus,
    selectedTask,
    toggleAddTask,
    toggleEditTask,
  } = handleValues;

  const { toggleSortParam } = useSortDataTable('');

  const updateTaskList = createUpdateTaskList({
    setHandleValues,
    selectedStatus,
    dispatch,
  });

  const addTaskHandler = useCallback(
    async (taskState: TaskRequest) => {
      const task = createdTaskRequest(taskState);

      const existingTaskName = taskList.find(
        (item) => item.name?.toLowerCase() === taskState?.name.toLowerCase(),
      );

      if (existingTaskName) {
        toast.warn(t('warning.task_already_exists'));
      } else if (validateObjectRequest(requiredKeys, task)) {
        toast.warn(t('warning.no_filled_data'));
      } else {
        setHandleValues(
          ON_TOGGLE_VIEW({
            payload: {
              toggleAddTask: !toggleAddTask,
            },
          }),
        );

        try {
          await postTask({
            body: task,
            shortList: false,
          });
          updateTaskList();
        } catch (error) {
          console.error('Failed to add task', error);
          toast.error(t('error.failed_to_add_task'));
        }
      }
    },
    [taskList, toggleAddTask, postTask, updateTaskList],
  );

  const editTaskHandler = useCallback(
    async (taskState: TaskRequest) => {
      const task = editedTaskRequest(taskState);

      const existingTaskName = taskList.find(
        (item) => item.name?.toLowerCase() === taskState.name.toLowerCase(),
      );

      if (selectedTask?.name !== task.name && existingTaskName) {
        toast.warn(t('warning.task_already_exists'));
      } else if (validateObjectRequest(requiredKeys, task)) {
        toast.warn(t('warning.no_filled_data'));
      } else {
        setHandleValues(
          ON_TOGGLE_VIEW({
            payload: {
              toggleEditTask: !toggleEditTask,
            },
          }),
        );

        try {
          await putTask({
            taskId: selectedTask.id,
            body: task,
            shortList: false,
          });

          updateTaskList();
        } catch (error) {
          console.error('Failed to edit task', error);
          toast.error(t('error.failed_to_edit_task'));
        }
      }
    },
    [selectedTask, putTask],
  );

  useEffect(() => {
    getDepartments().then((data) => {
      if (!data) return;

      dispatch(getDepartmentsAction(data));
    });
  }, []);

  useEffect(() => {
    (async () => {
      await updateTaskList();
    })();
  }, [selectedStatus]);

  useEffect(() => {
    if (!taskList.length) {
      updateTaskList();
    }
  }, [taskList?.length]);

  const sortedTaskList = taskList.sort((a, b) => sortByDefault(a, b, 'name'));

  const taskColumns = useTaskColumns({
    dispatch,
    setHandleValues,
    t,
    toggleEditTask,
  });

  const tableInstance = useTableInstance({
    taskColumns,
    sortedTaskList,
    sortOrder: sortByName,
  });

  const {
    state,
    //@ts-ignore
    setGlobalFilter,
  } = tableInstance;

  return {
    addTaskHandler,
    departmentList,
    editTaskHandler,
    isLoading,
    selectedStatus,
    selectedTask,
    setGlobalFilter,
    setHandleValues,
    sortedTaskList,
    state,
    t,
    tableInstance,
    tableRef,
    taskColumns,
    taskList,
    toggleAddTask,
    toggleEditTask,
    toggleSortParam,
  };
};

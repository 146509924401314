import { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { reducer, valuesInitialState } from '../../reducer/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useEditMode from '../../hooks/useEditMode';

import { isDayAlreadyLogged } from './utils/isDayLogged';
import { useUpdateVacationList } from './utils/updateVacationList';
import { useMonthChangeHandler } from './utils/monthChangeHandler';
import { useVacationTasksList } from './hooks/useVacationTasksList';
import { useVacationTableData } from './hooks/useVacationTableData';
import { useTableRowDeleting } from './hooks/useTableRowDeleting';
import useVacationColumns from './hooks/useVacationColumns';
import { UseVacationPageReturnType } from './types';

export const useVacationPage = (): UseVacationPageReturnType => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [checkboxState, setCheckboxState] = useState(0);
  const { vacationData, employeeData, registeredEmployee } = useAppSelector(
    (state) => state,
  );

  const currentSelectedMonth = useRef<string>();
  const tableRef = useRef<HTMLTableElement>(null);

  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const {
    confirmPopUp,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
    isConfirmPopUpApproved,
  } = useEditMode();

  const {
    currentDate,
    dayOffType,
    endDate,
    isLoading,
    selectedMonth,
    startDate,
  } = handleValues;

  const dayAlreadyLogged = isDayAlreadyLogged({
    endDate,
    startDate,
    vacationData,
  });

  const updateVacationList = useUpdateVacationList({
    dayOffType,
    dispatch,
    registeredEmployee,
    setHandleValues,
    t,
  });

  const onMonthChange = useMonthChangeHandler(
    updateVacationList,
    setHandleValues,
  );

  const vacationTaskList = useVacationTasksList(employeeData);

  const tableData = useVacationTableData(vacationData, t);

  const handleTableRowDeleting = useTableRowDeleting({
    updateVacationList,
    selectedMonth,
    handleApprovingOfConfirmPopUp,
    handleConfirmPopUp,
  });

  const isEmployee = useMemo(
    () => registeredEmployee?.employmentForm === 'EMPLOYEE',
    [registeredEmployee],
  );

  useEffect(() => {
    if (isLoading && !isEmployee) {
      navigate('/');
    }
  }, [isEmployee]);

  useEffect(() => {
    if (confirmPopUp.status && !!confirmPopUp.rowId && isConfirmPopUpApproved) {
      handleTableRowDeleting(confirmPopUp.rowId);
    }
  }, [confirmPopUp.status, confirmPopUp.rowId, isConfirmPopUpApproved]);

  useEffect(() => {
    updateVacationList(selectedMonth);
  }, []);

  useEffect(() => {
    currentSelectedMonth.current = selectedMonth;
  }, [selectedMonth]);

  const vacationColumns = useVacationColumns({ t, handleConfirmPopUp });

  return {
    checkboxState,
    constPartValue: registeredEmployee?.constPart,
    currentDate,
    currentSelectedMonth,
    dayAlreadyLogged,
    dayOffType,
    employeeData,
    endDate,
    handleValues,
    isLoading,
    onMonthChange,
    registeredEmployee,
    setCheckboxState,
    setHandleValues,
    startDate,
    t,
    tableData,
    tableRef,
    updateVacationList,
    vacationColumns,
    vacationData,
    vacationTaskList,
  };
};

import { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { reducer, valuesInitialState } from '../reducer/reducer';
import {
  getBackendReportClient,
  getBackendReportOnlyTotal,
} from '../api/reports';
import { TotalReportDto } from '../api/api-types';
import { useAppSelector } from './redux';

type TotalOrClientReport = 'total' | 'client';

type UseGetTotalReportProps<
  TReport extends TotalOrClientReport,
  TReportType extends `report${Capitalize<TReport>}Data`,
> = {
  getReportAction: (data: TotalReportDto) => void;
  clearReportAction: () => void;
  totalOrClientReport: TReport;
  reportType: TReportType;
};

const useGetTotalReport = <
  TReport extends TotalOrClientReport,
  TReportType extends `report${Capitalize<TReport>}Data`,
>(
  props: UseGetTotalReportProps<TReport, TReportType>,
) => {
  const { t } = useTranslation();
  const { clientId, departmentName, employeeId, endDate, billable, startDate } =
    useParams();
  const parsedClientId = clientId ? parseInt(clientId, 10) : undefined;
  const parsedEmployeeId = employeeId ? parseInt(employeeId, 10) : undefined;
  const sessionItem = sessionStorage.getItem(props.reportType);
  const { key } = useAppSelector((state) => state);

  const initState = sessionItem
    ? JSON.parse(sessionItem)
    : valuesInitialState(t);

  const [handleValues, setHandleValues] = useReducer(reducer, initState);

  const { isLoading } = handleValues;

  //TODO: this should be strictly typed after values are moved to global state
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const excludeProperty = (values: any) => {
    // eslint-disable-next-line no-unused-vars
    const { isLoading, ...rest } = values;

    return rest;
  };

  const getReport = async (type: TotalOrClientReport) => {
    if (!billable || !endDate || !startDate) return;
    return type === 'client'
      ? getBackendReportClient({
          clientId: parsedClientId,
          employeeId: parsedEmployeeId,
          billableTypes: billable,
          endDate,
          startDate,
        })
      : getBackendReportOnlyTotal({
          clientId: parsedClientId,
          department: departmentName,
          employeeId: parsedEmployeeId,
          billableTypes: billable,
          endDate,
          startDate,
        });
  };

  useEffect(() => {
    setHandleValues({ type: 'SET_LOADER', payload: { isLoading: true } });
    getReport(props.totalOrClientReport)
      .then((data) => {
        setHandleValues({
          type: 'SET_LOADER',
          payload: { isLoading: false },
        });
        if (!data) return;
        props.getReportAction(data);
      })
      .finally(() => {
        setHandleValues({
          type: 'SET_LOADER',
          payload: { isLoading: false },
        });
      });
  }, [clientId, departmentName, employeeId, endDate, billable, startDate, key]);

  useEffect(
    // TODO: somehow this works, but this should be reworked in a proper manner
    // ! useEffect function should not be another function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    () => () => {
      sessionStorage.setItem(
        props.reportType,
        JSON.stringify({
          ...excludeProperty(handleValues),
          toggleEditItem: false,
          editValuesMode: false,
        }),
      );

      return () => props.clearReportAction();
    },
    [handleValues],
  );

  return {
    handleValues,
    isLoading,
  };
};

export default useGetTotalReport;

import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AttachIcon } from '../../icons/AttachIcon.svg';
import ButtonBlock from './ButtonBlock/ButtonBlock';
import FormInfo from './FormInfo';
import styles from '../../styles/sickness.module.scss';
import useWindowResizeListener from '../../hooks/useWindowResizeListener';
import { fileNameTrim, fileUploadHandler } from '../../utils/file-upload';
import { checkboxes } from '../Vacation/halfDayBooking';
import Checkbox from '../../components/Checkbox/Checkbox';
import TextAreaField from '../../components/TextAreaField/TextAreaField';
import { textareaLength } from '../../constants/input-restrictions';
import FileInput from '../../components/FileInput/FileInput';
import { SicknessesDto } from '../../api/api-types';
import { Msg } from '../../store/actions';
import { EditDraftSickness } from '../../pages/SicknessPage/types';

type FormBlockProps = {
  checkboxState: number;
  constPartValue: number | undefined;
  editDraftSickness: EditDraftSickness;
  editValuesMode: boolean;
  endDate: string;
  fileItem: any;
  fileName: string;
  handleGlobalEditMode: (value: boolean) => void;
  isLoading: boolean;
  setCheckboxState: (value: number) => void;
  setEditDraftSickness: (value: EditDraftSickness) => void;
  setEditedRowId: (value: string | undefined) => void;
  setHandleValues: React.Dispatch<Msg>;
  sicknessData: SicknessesDto;
  startDate: string;
  timeLogId: number[];
  timeLogRequest: {
    endDate: any;
    eptId: number;
    isPartDay: boolean;
    note: string;
    startDate: any;
  };
  updateSicknessList: () => void;
};

const FormBlock = (props: FormBlockProps) => {
  const {
    checkboxState,
    constPartValue,
    editDraftSickness,
    editValuesMode,
    endDate,
    fileItem,
    fileName,
    handleGlobalEditMode,
    isLoading,
    setCheckboxState,
    setEditDraftSickness,
    setEditedRowId,
    setHandleValues,
    sicknessData,
    startDate,
    timeLogId,
    timeLogRequest,
    updateSicknessList,
  } = props;

  const { t } = useTranslation();

  const windowSize = useWindowResizeListener();

  const formInfoProps = {
    sicknessData,
    t,
  };

  const chooseWarning = () => {
    switch (true) {
      case !!editDraftSickness?.fileName:
        return editDraftSickness.fileName;
      case fileName === '':
        return t('form.attach_msg');
      default:
        return fileNameTrim(fileName ?? '');
    }
  };

  return (
    <div className={editValuesMode ? styles.formBorder : styles.form}>
      <div className={styles.formContent}>
        <div className={styles.formCol}>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar}>
              {sicknessData?.maximumDays && (
                <CircularProgressbarWithChildren
                  value={sicknessData.daysLeft ?? 0}
                  minValue={0}
                  maxValue={sicknessData?.maximumDays}
                  styles={buildStyles({
                    pathColor: '#FFD335',
                    trailColor: '#E7E7E7',
                    strokeLinecap: 'round',
                  })}
                >
                  <div className={styles.circularProgressBarContent}>
                    {(windowSize?.width ?? 0) >= 1024 ? (
                      <FormInfo {...formInfoProps} />
                    ) : null}
                  </div>
                  <CircularProgressbar
                    value={
                      (sicknessData.daysLeft ?? 0) - 5 > 0
                        ? sicknessData.maximumDays - 5
                        : sicknessData.daysLeft ?? 0
                    }
                    minValue={0}
                    maxValue={sicknessData.maximumDays}
                    styles={buildStyles({
                      trailColor: 'transparent',
                      pathColor: '#3581FF',
                      strokeLinecap: 'round',
                    })}
                  />
                </CircularProgressbarWithChildren>
              )}
            </div>
            {(windowSize?.width ?? 0) < 1024 ? (
              <FormInfo {...formInfoProps} />
            ) : null}
          </div>
          <div className={styles.colorWrapper}>
            <div className={styles.colorWrapperCol}>
              <div className={styles.colorWrapperCol100} />
              <div className={styles.colorWrapperColValue}>
                100% compensation
              </div>
            </div>
            <div className={styles.colorWrapperCol}>
              <div className={styles.colorWrapperCol75} />
              <div className={styles.colorWrapperColValue}>
                75% compensation
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formCol}>
          <div className={styles.isPartDayWrapper}>
            {constPartValue === 1 &&
              checkboxes.map((item, index) => (
                <Checkbox
                  key={item.id}
                  checked={checkboxState === index}
                  className='custom_checkbox'
                  onChange={() => setCheckboxState(index)}
                  title={item.title}
                  disabled={editValuesMode}
                />
              ))}
          </div>
          <div className={styles.textAreaWrapper}>
            <TextAreaField
              fieldClassName={styles.textArea}
              titleClassName={styles.textAreaTitle}
              className={styles.textAreaLabel}
              maxLength={textareaLength}
              title={t('form.note') as string}
              value={editDraftSickness?.note ?? ''}
              onChange={(e) =>
                setEditDraftSickness({
                  ...editDraftSickness,
                  note: e.target.value,
                })
              }
            />
          </div>
          <div className={styles.formFooter}>
            <FileInput
              fileInputLabel={t('buttons.attach')}
              fileInputWrapper='file-input__input--wrapper'
              fileInputClassName='file-input__input'
              fileInputIcon={<AttachIcon />}
              validationWarning={chooseWarning()}
              fileName={fileName}
              onChange={(e) => {
                setEditDraftSickness({
                  ...editDraftSickness,
                  fileName: '',
                });
                fileUploadHandler(setHandleValues, e);
              }}
            />
            <ButtonBlock
              editValuesMode={editValuesMode}
              endDate={endDate}
              fileItem={fileItem}
              handleGlobalEditMode={handleGlobalEditMode}
              isLoading={isLoading}
              setEditDraftSickness={setEditDraftSickness}
              setEditedRowId={(rowId) => setEditedRowId(`${rowId}`)}
              setHandleValues={setHandleValues}
              startDate={startDate}
              timeLogId={timeLogId}
              timeLogRequest={timeLogRequest}
              updateSicknessList={updateSicknessList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBlock;

import { toast } from 'react-toastify';
import Button from '../../components/Button/Button';
import { postVacationData } from '../../api/vacation';
import {
  createdVacationHalfDayRequest,
  createdVacationRangeRequest,
  createdVacationRequest,
} from '../../utils/request-objects';
import { postTimeLog } from '../../api/tracking';
import { useTranslation } from 'react-i18next';
import { Msg } from '../../store/actions';
import { ProjectDto } from '../../api/api-types';

type ButtonBlockProps = {
  checkboxState: number;
  currentDate: string;
  currentSelectedMonth: React.MutableRefObject<string | undefined>;
  dayAlreadyLogged?: boolean;
  dayOffType: string;
  employeeData: ProjectDto[];
  endDate: string;
  handleValues: any;
  setHandleValues: React.Dispatch<Msg>;
  startDate: string;
  updateVacationList: (value: string | undefined) => void;
};

const ButtonBlock = (props: ButtonBlockProps) => {
  const {
    checkboxState,
    currentDate,
    currentSelectedMonth,
    dayAlreadyLogged,
    dayOffType,
    employeeData,
    endDate,
    handleValues,
    setHandleValues,
    startDate,
    updateVacationList,
  } = props;

  const { t } = useTranslation();

  const clearedVacationRequest = {
    startDate: '',
    endDate: '',
  };

  const chooseBtnType = () => {
    switch (true) {
      case dayOffType === t('vacation.vacation_data.paid_vacations') &&
        checkboxState === 1:
      case dayOffType === t('vacation.vacation_data.unpaid_day_offs') &&
        checkboxState === 1:
        return (
          <Button
            label={t('buttons.book') as string}
            styleClass='btn__rounded-blue'
            disabled={dayOffType === '' || currentDate === ''}
            onClick={() => {
              if (dayAlreadyLogged) {
                toast.warn(t('warning.day_already_logged'));
              } else {
                postVacationData({
                  data: createdVacationHalfDayRequest(
                    handleValues,
                    employeeData,
                  ),
                }).then(() => {
                  updateVacationList(currentSelectedMonth.current);
                  setHandleValues({
                    type: 'CLEAR_TEXT',
                    payload: clearedVacationRequest,
                  });
                });
              }
            }}
          />
        );

      case dayOffType === t('vacation.vacation_data.paid_vacations') &&
        checkboxState === 0:
      case dayOffType === t('vacation.vacation_data.unpaid_day_offs') &&
        checkboxState === 0:
        return (
          <Button
            label={t('buttons.book') as string}
            styleClass='btn__rounded-blue'
            disabled={!dayOffType || !startDate || !endDate}
            onClick={() => {
              postVacationData({
                data: createdVacationRangeRequest(
                  handleValues,
                  checkboxState,
                  employeeData,
                ),
              }).then(() => {
                updateVacationList(currentSelectedMonth.current);
                setHandleValues({
                  type: 'CLEAR_TEXT',
                  payload: clearedVacationRequest,
                });
              });
            }}
          />
        );
      
      default:
        return (
          <Button
            label={t('buttons.book') as string}
            styleClass='btn__rounded-blue'
            disabled={dayOffType === '' || currentDate === ''}
            onClick={() => {
              if (dayAlreadyLogged) {
                toast.warn(t('warning.day_already_logged'));
              } else {
                postTimeLog(
                  createdVacationRequest(handleValues, employeeData),
                ).then(() => {
                  updateVacationList(currentSelectedMonth.current);
                  setHandleValues({
                    type: 'CLEAR_TEXT',
                    payload: clearedVacationRequest,
                  });
                });
              }
            }}
          />
        );
    }
  };

  return chooseBtnType();
};

export default ButtonBlock;

import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '34px 49px 85px',
    fontFamily: 'Poppins',
    fontWeight: 300,
    break: 'avoid',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '34px',
    left: '49px',
    width: '100%',
  },
  projectNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'rgba(37, 121, 255, 1)',
    fontSize: '16px',
    lineHeight: '20px',
  },
  customerName: {
    fontWeight: 300,
  },
  employeeTaskName: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
  },
  employeeName: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 300,
  },
  projectName: {
    fontWeight: 500,
  },
  period: {
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(23, 23, 23, 1)',
    fontWeight: 500,
  },
  table: {
    marginTop: '60px',
    display: 'flex',
    width: '100%',
    borderTop: '1px solid rgba(149, 161, 185, 1)',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    borderBottom: '0.5px solid rgba(149, 161, 185, 1)',
    borderTop: '0.5px solid rgba(149, 161, 185, 1)',
    justifyContent: 'flex-start',
  },
  emptyRow: {
    minHeight: '20px',
  },
  tableRowOdd: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#D3D3D3',
    borderBottom: '0.5px solid rgba(149, 161, 185, 1)',
    borderTop: '0.5px solid rgba(149, 161, 185, 1)',
    justifyContent: 'flex-start',
  },
  tableCol: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    padding: '2px 4px',
    fontSize: 10,
    borderLeft: '1px solid rgba(149, 161, 185, 1)',
    backgroundColor: 'rgba(37, 121, 255, 1)',
    color: 'white',
    fontWeight: 600,
  },
  grandTotal: {
    width: '90%',
    borderRight: '1px solid rgba(149, 161, 185, 1)',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(149, 161, 185, 1)',
  },
  subtotalBold: {
    fontWeight: 500,
  },
  tableCell: {
    padding: '2px 4px',
    fontSize: 10,
    borderLeft: '1px solid rgba(149, 161, 185, 1)',
  },
  tableCellDate: {
    width: '10%',
  },
  tableCellUser: {
    overflow: 'hidden',
    width: '14%',
  },
  tableCellProject: {
    width: '22%',
  },
  tableCellTask: {
    width: '18%',
  },
  tableCellDuration: {
    width: '10%',
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  tableCellNotes: {
    width: '26%',
    borderRight: '1px solid rgba(149, 161, 185, 1)',
  },
  backgroundImage: {
    width: '200px',
    height: '60px',
    backgroundImage: 'url(./logo)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

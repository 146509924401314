import { getProject, getProjectInfo } from '../../api/projects';
import {
  fetchEmployeesByRole,
  getPMTeamMember,
  getTeamMember,
} from '../../api/team';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getTimeLogDataForEmployee } from '../../api/tracking';
import {
  applySwitcher,
  getSwitcher,
} from '../../api/reports';
import useValues from '../../hooks/useValues';
import { getAllCustomersLocations } from '../../api/customers';
import { formatDateYYYYMMDD } from '../../utils/date-formatting';
import { getDepartments } from '../../api/departments';
import { useModal } from '../../providers/ModalProvider';
import modals from '../../constants/modals';
import {
  CustomerLocationDto,
  DepartmentDto,
  PageProjectWebDto,
  ProjectManagerReportItem,
  TeamMemberDto,
  UserNameIdDto,
} from '../../api/api-types';
import { useEffect, useState } from 'react';
import {
  GET_DEPARTMENTS,
  GET_EMPLOYEES_BY_ROLE,
  GET_EMPLOYEE_DATA,
  GET_LOCATIONS,
  GET_PM,
  GET_PM_TEAM,
  GET_PROJECTS_ALL,
  GET_PROJECTS_INFO,
  GET_TEAM,
} from '../../store/actions';
import { CustomerNamesType, UseReportsPageLogicReturn } from './types';

export const useReportsPageLogic = (): UseReportsPageLogicReturn => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();

  const {
    departmentList,
    employeesByRole,
    locationList,
    memberList,
    pmList,
    pmMemberList,
    projectListAll,
    reportClient,
    reportPM,
    reportPMEmployee,
    reportProject,
    reportProjectEmployee,
    reportTotal,
  } = useAppSelector((state) => state);

  const { handleValues, setHandleValues } = useValues('reportPageData');

  const [isOn, setIsOn] = useState<boolean>(false);

  const {
    accrualTypeFilter,
    clientId,
    clientName,
    departmentId,
    departmentName,
    employeeId,
    projectId,
    projectName,
    reportEndDate,
    reportStartDate,
    teamMemberName,
    typeOfReport,
    userId,
    userName,
    employeeTaskName,
  } = handleValues;

  // temporary disabled because of "Report type changes when you go to the list of time logs for a specific employee", caused by url changing
  // useReportTypeFromUrl(setHandleValues, pmList, employeesByRole);

  const start = formatDateYYYYMMDD(new Date(reportStartDate));
  const end = formatDateYYYYMMDD(new Date(reportEndDate));

  const customerNames: CustomerNamesType = locationList.map((location) => ({
    id: location.customerNameAndId?.id,
    label: location.customerNameAndId?.name,
    value: location.customerNameAndId?.name,
  }));

  const handleSwitcher = (): void => {
    const switcher = !isOn;
    setIsOn(switcher);
    applySwitcher({ data: { isOn: switcher } });
  };

  const openModalToAddTimeLog = (): void => {
    showModal(modals.addEmployeeTimeLogModal);
  };

  useEffect(() => {
    getProject({
      sortParam: 'name,asc',
      unpaged: true,
    }).then((data) => {
      if (!data) return;

      const content: NonNullable<PageProjectWebDto['content']> =
        data.content ?? [];

      dispatch(GET_PROJECTS_ALL({ payload: content }));
    });

    fetchEmployeesByRole('management', true, true).then(
      (data: UserNameIdDto[] | null) => {
        if (!data) return;

        dispatch(GET_PM({ payload: data }));
      },
    );

    getProjectInfo().then((data: TeamMemberDto | null) => {
      if (!data) return;

      dispatch(GET_PROJECTS_INFO({ payload: data }));
    });

    getAllCustomersLocations().then((data: CustomerLocationDto[] | null) => {
      if (!data) return;

      dispatch(GET_LOCATIONS({ payload: data }));
    });

    getDepartments().then((data: DepartmentDto[] | null) => {
      if (!data) return;

      dispatch(GET_DEPARTMENTS({ payload: data }));
    });
  }, []);

  useEffect(() => {
    fetchEmployeesByRole(
      typeOfReport === 'Total report' ? departmentName : undefined,
    ).then((data: UserNameIdDto[] | null) => {
      if (!data) return;

      dispatch(GET_EMPLOYEES_BY_ROLE({ payload: data }));
    });
  }, [departmentName, typeOfReport]);

  useEffect(() => {
    getTimeLogDataForEmployee({
      userId: undefined,
    }).then((data) => {
      if (!data) return;

      dispatch(GET_EMPLOYEE_DATA({ payload: data }));
    });
  }, [projectName]);

  useEffect(() => {
    if (projectId) {
      getTeamMember(
        projectId,
        'employee.name,asc',
        undefined,
        undefined,
        undefined,
        true,
      ).then((data) => {
        if (!data) return;
        dispatch(GET_TEAM({ payload: data.content }));
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (userId) {
      getPMTeamMember(userId, start, end).then((data) => {
        if (!data) return;

        const payload = data as ProjectManagerReportItem[]; // Convert data to ProjectManagerReportItem[]
        dispatch(GET_PM_TEAM({ payload }));
      });
    }
  }, [userId, reportStartDate, reportEndDate]);

  useEffect(() => {
    const getCurrentSwitcher = async () => {
      const res = await getSwitcher();

      if (res) {
        setIsOn(res);
      }
    };

    getCurrentSwitcher();
  }, []);

  return {
    accrualTypeFilter,
    clientId,
    clientName,
    customerNames,
    departmentId,
    departmentList,
    departmentName,
    employeeId,
    employeeTaskName,
    employeesByRole,
    end,
    handleSwitcher,
    isOn,
    memberList,
    openModalToAddTimeLog,
    pmList,
    pmMemberList,
    projectId,
    projectListAll,
    projectName,
    reportClient,
    reportEndDate,
    reportPM,
    reportPMEmployee,
    reportProject,
    reportProjectEmployee,
    reportStartDate,
    reportTotal,
    setHandleValues,
    start,
    teamMemberName,
    typeOfReport,
    userId,
    userName,
  };
};

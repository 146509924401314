import { Link } from 'react-router-dom';
import commonStyles from '../../../styles/pm-flow.module.scss';
import { HeaderBlockProps } from './types';
import { useHeaderBlock } from './useHeaderBlock';
import {
  getClientReportHeader,
  getTotalReportHeader,
  getPMReportHeader,
  getProjectReportHeader,
} from './helpers';

const HeaderBlock = ({
  reportClient,
  reportProjectEmployee,
  reportPM,
  reportProject,
  reportPMEmployee,
  reportTotal,
}: HeaderBlockProps) => {
  const {
    billable,
    clientId,
    employeeId,
    employeeTaskName,
    endDate,
    location,
    pmId,
    projectId,
    startDate,
    t,
  } = useHeaderBlock();

  const commonHeaderProps = {
    billable,
    employeeId,
    endDate,
    location,
    startDate,
    t,
  };

  const chooseHeaderType = () =>
    getClientReportHeader({ ...commonHeaderProps, reportClient, clientId }) ||
    getTotalReportHeader({ ...commonHeaderProps, reportTotal }) ||
    getPMReportHeader({
      ...commonHeaderProps,
      reportPM,
      reportPMEmployee,
      pmId,
    }) ||
    getProjectReportHeader({
      ...commonHeaderProps,
      employeeTaskName,
      projectId,
      reportProject,
      reportProjectEmployee,
    });

  return (
    <h2 className={commonStyles.heading}>
      <Link to='/reports'>{t('reports.title')}</Link>
      {chooseHeaderType()}
    </h2>
  );
};

export default HeaderBlock;

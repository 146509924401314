import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedComponent from './ProtectedComponent';
import { isHighLevelRole } from '../utils/isHighLevelRole';

import BCoinBalance from '../modules/BCoin/tabs/BCoinBalance';
import BCoinFAQ from '../modules/BCoin/tabs/BCoinFAQ';
import BCoinHowItWorks from '../modules/BCoin/tabs/BCoinHowItWorks';
import BCoinHowToGet from '../modules/BCoin/tabs/BCoinHowToGet';
import BCoinKudosSystem from '../modules/BCoin/tabs/BCoinKudosSystem';
import BCoinPage from '../pages/BCoinPage/BCoinPage';
import BCoinWhatIsIt from '../modules/BCoin/tabs/BCoinWhatIsIt';
import BCoinWhatToGet from '../modules/BCoin/tabs/BCoinWhatToGet';
import ClientListPage from '../pages/ClientListPage/ClientListPage';
import ClientPage from '../pages/ClientPage/ClientPage';
import ClientProjectDetailedPage from '../pages/ClientProjectDetailedPage/ClientProjectDetailedPage';
import ProjectListPage from '../pages/ProjectListPage/ProjectListPage';
import TaskListPage from '../pages/TaskListPage/TaskListPage';
import ReportsPage from '../pages/ReportsPage.tsx/ReportsPage';
import ReportProject from '../pages/ReportProject/ReportProject';
import ReportProjectEmployee from '../pages/ReportProjectEmployee/ReportProjectEmployee';
import ReportPmEmployee from '../pages/ReportPMEmployee/ReportPMEmployee';
import ReportTotal from '../pages/ReportTotal/ReportTotal';
import ReportClient from '../pages/ReportClient/ReportClient';
import SicknessPage from '../pages/SicknessPage/SicknessPage';
import ReportPMAll from '../pages/ReportPMAll/ReportPMAll';
import ReportPM from '../pages/ReportPM/ReportPM';
import TimeTrackingPage from '../pages/TimeTrackingPage/TimeTrackingPage';
import VacationPage from '../pages/VacationPage/VacationPage';

const MenuRoutes = () => {
  const [extendedRole] = isHighLevelRole();

  return (
    <Routes>
      <Route path='/' element={<Navigate to='tracking' />} />
      <Route
        path='b-coin'
        element={<ProtectedComponent component={BCoinPage} />}
      >
        <Route
          path='what-is-it'
          element={<ProtectedComponent component={BCoinWhatIsIt} />}
        />
        <Route
          path='how-it-works'
          element={<ProtectedComponent component={BCoinHowItWorks} />}
        />
        <Route
          path='how-to-get'
          element={<ProtectedComponent component={BCoinHowToGet} />}
        />
        <Route
          path='kudos-system'
          element={<ProtectedComponent component={BCoinKudosSystem} />}
        />
        <Route
          path='what-to-get'
          element={<ProtectedComponent component={BCoinWhatToGet} />}
        />
        <Route
          path='faq'
          element={<ProtectedComponent component={BCoinFAQ} />}
        />
        <Route
          path='balance'
          element={<ProtectedComponent component={BCoinBalance} />}
        />
      </Route>
      {extendedRole && (
        <Route
          path='projects'
          element={<ProtectedComponent component={ProjectListPage} />}
        />
      )}
      {extendedRole && (
        <Route
          path='projects/:id'
          element={<ProtectedComponent component={ClientProjectDetailedPage} />}
        />
      )}
      {extendedRole && (
        <Route
          path='clients'
          element={<ProtectedComponent component={ClientListPage} />}
        >
          <Route
            path=':id'
            element={<ProtectedComponent component={ClientPage} />}
          />
        </Route>
      )}
      {extendedRole && (
        <Route
          path='tasks'
          element={<ProtectedComponent component={TaskListPage} />}
        />
      )}
      {extendedRole && (
        <Route
          path='reports'
          element={<ProtectedComponent component={ReportsPage} />}
        >
          <Route
            path='by-projects/project:projectId/start:startDate/end:endDate/accrual:billable'
            element={<ProtectedComponent component={ReportProject} />}
          />
          <Route
            path='by-projects/project:projectId/start:startDate/end:endDate/accrual:billable/tasks:employeeTaskName'
            element={<ProtectedComponent component={ReportProject} />}
          />
          <Route
            path='by-projects/project:projectId/employee:employeeId/start:startDate/end:endDate/accrual:billable'
            element={<ProtectedComponent component={ReportProjectEmployee} />}
          />
          <Route
            path='by-projects/project:projectId/employee:employeeId/start:startDate/end:endDate/accrual:billable/tasks:employeeTaskName'
            element={<ProtectedComponent component={ReportProjectEmployee} />}
          />

          {/* projects by pm */}
          <Route
            path='by-pm/pm:pmId/start:startDate/end:endDate/accrual:billable'
            element={<ProtectedComponent component={ReportPM} />}
          />

          {/* all projects */}
          <Route
            path='by-pm/start:startDate/end:endDate/accrual:billable'
            element={<ProtectedComponent component={ReportPMAll} />}
          />
          <Route
            path='by-pm/pm:pmId/employee:employeeId/start:startDate/end:endDate/accrual:billable'
            element={<ProtectedComponent component={ReportPmEmployee} />}
          />
          <Route
            path='by-total/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportTotal} />}
          />
          <Route
            path='by-total/department:departmentName/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportTotal} />}
          />
          <Route
            path='by-total/employee:employeeId/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportTotal} />}
          />
          <Route
            path='by-total/department:departmentName/employee:employeeId/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportTotal} />}
          />
          <Route
            path='by-client/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportClient} />}
          />
          <Route
            path='by-client/client:clientId/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportClient} />}
          />
          <Route
            path='by-client/employee:employeeId/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportClient} />}
          />
          <Route
            path='by-client/client:clientId/employee:employeeId/start:startDate/accrual:billable/end:endDate'
            element={<ProtectedComponent component={ReportClient} />}
          />
        </Route>
      )}
      <Route
        path='tracking'
        element={<ProtectedComponent component={TimeTrackingPage} />}
      />
      <Route
        path='sickness'
        element={<ProtectedComponent component={SicknessPage} />}
      />
      <Route
        path='vacation'
        element={<ProtectedComponent component={VacationPage} />}
      />
    </Routes>
  );
};

export default MenuRoutes;

import { CalendarMonthDto } from '../../api/api-types';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const renderDayContents = <
  TData extends {
    dateAndProgressPercentage?: Array<CalendarMonthDto>;
  },
>(
  data: TData,
  day: number,
  date?: Date,
  selectedDates?: Date[],
) => {
  const dayToReplace = data?.dateAndProgressPercentage?.length
    ? data?.dateAndProgressPercentage.find(
        (element) =>
          date?.getDate() ===
          Number(new Date(element.date ?? '').toUTCString().split(' ')[1]),
      )
    : undefined;

  const isSelectedDate = selectedDates?.some(
    (selectedDate) => selectedDate.toDateString() === date?.toDateString(),
  );

  const dayClassName = isSelectedDate ? 'highlight_selected' : 'current_day';

  const monthToDisplay =
    data?.dateAndProgressPercentage?.length &&
    data?.dateAndProgressPercentage.find(
      (element) => date?.getMonth() === new Date(element.date ?? '').getMonth(),
    );

  const shouldShowPercentage = !!(monthToDisplay && dayToReplace?.date);

  return (
    <div className={dayClassName}>
      {day}
      <ProgressBar
        completed={
          shouldShowPercentage ? dayToReplace?.progressPercentage ?? 0 : 0
        }
      />
    </div>
  );
};

export default renderDayContents;

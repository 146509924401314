import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useValues from '../../hooks/useValues';
import { getBackendReportByPMEmployee } from '../../api/reports';
import { RootState } from '../../store';
import { parseId } from '../../utils/idParser';
import { setLoaderAction } from '../../store/actionCreators/loaderActionCreator';
import {
  clearReportPMEmployeeAction,
  getReportPMEmployeeAction,
} from '../../store/actionCreators/reportsActionCreators';
import { handleError } from '../../api/config';
import { UseReportPMEmployeeReturn } from './types';

export const useReportPMEmployee = (): UseReportPMEmployeeReturn => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pmId, employeeId, startDate, endDate, billable } = useParams();

  const tableRef = useRef<HTMLTableElement>(null);

  const { reportPMEmployee, key } = useAppSelector((state: RootState) => state);

  const { handleValues, setHandleValues } = useValues('reportPMEmployeeData');
  const { isLoading } = handleValues;

  const parsedPMId = useMemo(() => parseId(pmId), [pmId]);
  const parsedEmployeeId = useMemo(() => parseId(employeeId), [employeeId]);

  const displayEmptyDataBlock =
    reportPMEmployee?.timeLogsPerProjectAndEmployee?.length === 0;

  const displayTotalRow =
    Boolean(reportPMEmployee?.timeLogsPerProjectAndEmployee?.length) &&
    !isLoading;

  useEffect(() => {
    const fetchData = async () => {
      if (
        !startDate ||
        !endDate ||
        !billable ||
        !parsedPMId ||
        !parsedEmployeeId
      )
        return;

      try {
        setHandleValues(setLoaderAction(true));

        const data = await getBackendReportByPMEmployee({
          billableTypes: billable,
          employeeId: parsedEmployeeId,
          endDate,
          projectManagerId: parsedPMId,
          startDate,
        });

        if (!data) throw new Error(t('error.no_data').toString());

        dispatch(getReportPMEmployeeAction(data));
      } catch (error) {
        handleError(error);
      } finally {
        setHandleValues(setLoaderAction(false));
      }
    };

    fetchData();

    return () => {
      dispatch(clearReportPMEmployeeAction(reportPMEmployee));
    };
  }, [pmId, employeeId, startDate, endDate, billable, key]);

  return {
    displayEmptyDataBlock,
    displayTotalRow,
    isLoading,
    reportPMEmployee,
    t,
    tableRef,
  };
};

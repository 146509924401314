import { useTranslation } from 'react-i18next';

const EmptyDataBlock = () => {
  const { t } = useTranslation();

  return (
    <p>{t('warning.no_period_data')}</p>
  );
};

export default EmptyDataBlock;

import { useMemo } from 'react';
import Button from '../../../components/Button/Button';
import { controlsStatusStyle } from '../../../components/Select/selectStyles';
import { statusOptions } from '../../../constants/select-values';
import { ReactComponent as PlusIcon } from '../../../icons/PlusIcon.svg';
import TotalItems from '../../../components/ControlsPanel/TotalItems';
import SimpleSelect from '../../../components/Select/SimpleSelect';
import { exportTaskList } from '../../../xls/taskListXLS';
import { UseTaskListPageReturnType } from '../types';
import { ON_TOGGLE_VIEW, SET_SELECTABLES } from '../../../store/actions';

export const useProjectControls = ({
  selectedStatus,
  setHandleValues,
  sortedTaskList,
  t,
  taskList,
  toggleAddTask,
}: UseTaskListPageReturnType) => {
  const controlsProjectRight = useMemo(
    () => [
      <Button
        key='key-tasks-3'
        styleClass='btn__rounded-blue'
        label={t('buttons.new_task') as string}
        icon={<PlusIcon />}
        onClick={() =>
          setHandleValues(
            ON_TOGGLE_VIEW({
              payload: {
                toggleAddTask: !toggleAddTask,
              },
            }),
          )
        }
      />,
      !!taskList.length && (
        <Button
          key='key-tasks-4'
          label={t('generate_report.title') as string}
          onClick={() => exportTaskList(sortedTaskList)}
          styleClass='btn__string--underlined--grey'
        />
      ),
    ],
    [!!taskList.length],
  );

  const controlsProjectLeft = useMemo(
    () => [
      <TotalItems
        key='key-tasks-1'
        textLabel={`${t('tasks.total')}: `}
        data={taskList.length}
      />,
      <SimpleSelect
        key='key-tasks-2'
        selectWrapperClass='select_horizontal_wrapper'
        selectTitleClassName='select_title'
        selectTitle={t('status.title') as string}
        options={statusOptions(t)}
        value={{
          label: selectedStatus,
          value: selectedStatus,
        }}
        styles={controlsStatusStyle}
        onChange={(e) =>
          setHandleValues(
            SET_SELECTABLES({
              payload: { selectedStatus: e.value },
            }),
          )
        }
      />,
    ],
    [taskList.length, t],
  );
  return { controlsProjectRight, controlsProjectLeft };
};

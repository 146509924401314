import { useEffect } from 'react';
import { getEmployeeDataAction } from '../../../store/actionCreators/employeeActionCreators';
import { SET_SELECTABLES } from '../../../store/actions';
import { getLastTaskName } from '../utils/getLastTaskName';
import { UseSetInitialValuesProps } from '../types';

export const useSetInitialValues = ({
  projectName,
  dispatch,
  employeeData,
  setHandleValues,
}: UseSetInitialValuesProps) => {
  const lastTaskName = getLastTaskName(employeeData);

  useEffect(() => {
    if (!projectName) {
      dispatch(getEmployeeDataAction(employeeData));

      setHandleValues(
        SET_SELECTABLES({
          payload: {
            currentHour: employeeData.lastUsedProject?.duration?.hours
              ?.toString()
              .padStart(2, '0'),
            currentMinute: employeeData.lastUsedProject?.duration?.minutes
              ?.toString()
              .padStart(2, '0'),
            projectName: employeeData.lastUsedProject?.nameProject,
            taskId: employeeData.lastUsedProject?.eptId,
            taskName: lastTaskName,
          },
        }),
      );
    }
  }, [lastTaskName, employeeData, projectName, dispatch, setHandleValues]);
};

import ReportTable from '../../components/Tables/ReportTable';
import { generatorObject } from '../../utils/generate-ids';
import { displayBillable } from '../../utils/conditionalRowStyles';
import { sortByDefault } from '../../utils/filtering';
import {
  projectReport3Col,
  projectReport5Col,
} from '../../modules/Reports/columns';
import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import Loader from '../../components/Loader/Loader';
import { useReportProject } from './useReportProject';
import Row from '../../components/Row/Row';
import EmployeeReportName from './components/EmployeeReportName';

const ReportProject = () => {
  const {
    editTimeLogDetails,
    isLoading,
    reportProject,
    displayProjectTotal,
    t,
    tableRef,
  } = useReportProject();

  if (isLoading) return <Loader />;

  return (
    <>
      {/* for testing layout of pdf document */}

      {/* <PDFViewer style={{width: '100%', height: '1000px'}}>
        <MyDocument data={reportProject}/>
      </PDFViewer> */}

      {reportProject?.employeeTaskTimeLogsList?.length ? (
        reportProject.employeeTaskTimeLogsList.map((employee) => (
          <div key={`key-employee-${generatorObject.next().value}`}>
            <EmployeeReportName
              taskName={employee.taskName}
              employeeFullName={employee.employeeFullName}
              isDelivery={employee.isDelivery}
            />

            <ReportTable
              changeRowColor={displayBillable}
              columns={
                reportProject?.projectName?.name === 'Internal Administration'
                  ? projectReport5Col(t)
                  : projectReport3Col(t)
              }
              data={employee.timeLogs?.sort((a, b) =>
                sortByDefault(a, b, 'date'),
              )}
              onRowClick={(row) =>
                editTimeLogDetails(row, employee.employeeFullName)
              }
              ref={tableRef}
              tableName='pm-project-reports-list'
            />

            <Row
              label={t('reports.subtitle.sub_total')}
              rowClass={'sub_total'}
              value={employee.subtotalDuration}
            />
          </div>
        ))
      ) : (
        <EmptyDataBlock />
      )}
      
      {displayProjectTotal && (
        <Row
          label={t('reports.subtitle.only_total')}
          rowClass={'total'}
          value={reportProject?.totalHours}
        />
      )}
    </>
  );
};

export default ReportProject;

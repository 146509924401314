import BCoinItemCard from './BCoinItemCard/BCoinItemCard';
import PointerWhite from '../../../images/PointerWhite.gif';
import RobotNoItemsImage from '../../../images/RobotNoItemsImage.png';
import { BCoinRewardDto } from '../../../api/api-types';

type BCoinItemListProps = {
  items: BCoinRewardDto[];
  isLoading: boolean;
};

const BCoinItemsList = (props: BCoinItemListProps) => (
  <>
    <div className='bcoin-items-list-container'>
      <div className='bcoin-items-list'>
        {props.items.map((item) => (
          <BCoinItemCard key={item.id} item={item} />
        ))}
      </div>
      {!props.isLoading ? (
        <div className='bcoin-items-list-end'>
          <div className='bcoin-items-list-end-txt'>That's all for now</div>
          <div className='bcoin-items-list-end-img'>
            <img src={RobotNoItemsImage} alt='' />
          </div>
        </div>
      ) : null}
    </div>
    <div className='pointer-right'>
      <img src={PointerWhite} alt='pointer' />
    </div>
  </>
);

export default BCoinItemsList;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo, useRef } from 'react';
import {
  TableInstance,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { ProjectWebDto } from '../../../api/api-types';

type TableSetupReturnType = {
  projectColumns: { Header: string; accessor: string }[];
  tableRef: React.MutableRefObject<HTMLTableElement | null>;
  tableInstance: TableInstance<ProjectWebDto>;
};

export const useTableSetup = ({
  projectList,
}: {
  projectList: ProjectWebDto[];
}): TableSetupReturnType => {
  const { t } = useTranslation();

  const projectColumns = useMemo(
    () => [
      {
        Header: `↑↓${t('columns.project_name')}`,
        accessor: 'projectName',
      },
      {
        Header: `↑↓${t('columns.project_manager')}`,
        accessor: 'managerIdAndName.name',
      },
      {
        Header: `↑↓${t('columns.start_date')}`,
        accessor: 'startDate',
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      // TODO: improve types after moving to react-table v8
      //@ts-ignore
      columns: projectColumns,
      data: projectList,
      // @ts-expect-error
      initialState: { pageIndex: 0 },
      manualPagination: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  const tableRef = useRef<HTMLTableElement>(null);

  return { projectColumns, tableRef, tableInstance };
};

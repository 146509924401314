import { ModalButton } from '../../Modal';
import { GetButtonsProps } from '../types';

export const getButtons = ({
  t,
  onSave,
  teamMemberState,
  closeModal,
}: GetButtonsProps): ModalButton[] => {
  const teamMemberModalButtons = [
    {
      id: '1',
      label: t('buttons.save'),
      className: 'btn__rounded-blue',
      save: () => onSave(teamMemberState),
      type: 'submit' as const,
      disabled:
        !teamMemberState.employeeId ||
        !teamMemberState.billableType ||
        !teamMemberState.startDate ||
        !teamMemberState.taskId ||
        (!!teamMemberState.overtime?.overtimeRate &&
          !teamMemberState.overtime?.startDate),
    },
    {
      id: '2',
      label: t('buttons.cancel'),
      className: 'btn__string--normal--grey',
      close: closeModal,
    },
  ];

  return teamMemberModalButtons;
};

import { useEffect, useRef } from 'react';

const useCloseByClickOutside = <TRef extends HTMLElement>(
  isMenuExtended: boolean,
  setIsMenuExtended: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const ref = useRef<TRef>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.currentTarget as Node)) {
      setIsMenuExtended(isMenuExtended);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref };
};

export default useCloseByClickOutside;

import { eachWeekendOfYear } from 'date-fns';
import subDays from 'date-fns/subDays';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import styles from '../../styles/sickness.module.scss';
import { formatCurrentToISO } from '../../utils/date-formatting';
import HeaderBlock from '../../modules/Sickness/HeaderBlock/HeaderBlock';
import FormBlock from '../../modules/Sickness/FormBlock';
import StyledPickerTwoDates from '../../components/DatePickers/DatePicker/StyledPickerTwoDates';
import { handleHolidayArr } from '../../utils/handleHolidayArr';
import { dateRangeHandler } from '../../utils/date-handling';
import renderDayContents from '../../modules/TimeTracking/DayContentBlock';
import ExpandableTable from '../../components/Tables/ExpandableTable';
import { EditSicknessModal } from '../../components/Modal/EditSicknessModal';
import EditContentModal from '../../modules/Sickness/EditContentModal';
import Loader from '../../components/Loader/Loader';
import { useSicknessPage } from './useSicknessPage';
import { onCancelEditing } from './helpers/OnCancelEditing';
import { EDIT_MODE, SET_TEXT } from '../../store/actions';

const SicknessPage = () => {
  const {
    checkboxState,
    constPartValue,
    currentDate,
    dayAlreadyLogged,
    editDraftSickness,
    editValuesMode,
    editedRowId,
    endDate,
    fileItem,
    fileName,
    handleGlobalEditMode,
    isLoading,
    isOpenEditModal,
    loggedDates,
    setCheckboxState,
    setEditDraftSickness,
    setEditedRowId,
    setHandleValues,
    setIsOpenEditModal,
    sickTodayRequest,
    sicknessColumns,
    sicknessData,
    sortedByDate,
    startDate,
    t,
    tableRef,
    timeLogId,
    timeLogRequest,
    updateSicknessList,
  } = useSicknessPage();

  const onCancelProps = {
    editValuesMode,
    handleGlobalEditMode,
    setEditDraftSickness,
    setEditedRowId,
    setHandleValues,
    setIsOpenEditModal,
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>{t('sickness.title')}</h2>
          <HeaderBlock
            {...{
              currentDate,
              dayAlreadyLogged,
              endDate,
              handleGlobalEditMode,
              setEditDraftSickness,
              setEditedRowId,
              setHandleValues,
              sickTodayRequest,
              startDate,
              updateSicknessList,
            }}
          />
        </div>
        <div className={styles.formData}>
          <FormBlock
            {...{
              checkboxState,
              constPartValue,
              editDraftSickness,
              editValuesMode,
              endDate,
              fileItem,
              fileName,
              handleGlobalEditMode,
              isLoading,
              setCheckboxState,
              setEditDraftSickness,
              setEditedRowId,
              setHandleValues,
              sicknessData,
              startDate,
              timeLogId,
              timeLogRequest,
              updateSicknessList,
            }}
          />
          <div className={styles.datePicker}>
            <StyledPickerTwoDates
              selectsRange={true}
              wrapperClassName='styledCalendar'
              startDate={startDate ? new Date(startDate) : undefined}
              endDate={endDate ? new Date(endDate) : undefined}
              excludeDates={
                loggedDates && [
                  ...loggedDates,
                  ...eachWeekendOfYear(new Date()),
                ]
              }
              highlightDates={handleHolidayArr(sicknessData?.holidays)}
              onChange={(selected) =>
                dateRangeHandler(setHandleValues, selected)
              }
              minDate={subDays(new Date(), 30)}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              onMonthChange={(month) => {
                const formattedMonth = formatCurrentToISO(month)
                  .split('-')
                  .slice(0, 2)
                  .join('-');
                setHandleValues(
                  EDIT_MODE({
                    payload: { editValuesMode: false },
                  }),
                );
                setHandleValues(
                  SET_TEXT({
                    payload: { selectedMonth: formattedMonth },
                  }),
                );
              }}
              renderDayContents={(day, date) =>
                renderDayContents(sicknessData, day, date)
              }
            />
            <div className={styles.datePickerWarning}>
              {t('warning.day_already_logged_selected')}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {sicknessData && (
          <ExpandableTable
            columns={sicknessColumns}
            data={sortedByDate}
            editedRowId={editedRowId}
            onRowClick={() => {}}
            ref={tableRef}
            rowDepth={0}
            setExpandedRowId={() => {}}
            tableName='sickness-list'
          />
        )}
        {!sicknessData?.sicknessPeriods?.length && (
          <p>{t('warning.no_found_data')}</p>
        )}
        <EditSicknessModal
          active={isOpenEditModal && editValuesMode}
          setActive={() => onCancelEditing(onCancelProps)}
        >
          <EditContentModal
            onCancel={() => onCancelEditing(onCancelProps)}
            onContinue={() => setIsOpenEditModal(false)}
          />
        </EditSicknessModal>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default SicknessPage;

import { Dispatch, SetStateAction } from 'react';
import { CreateProjectModalData } from '../components/Modal/CreateProjectModal/types';
import { ProjectData } from '../api/api-types';

const handleInputChange = <T extends object>(
  setProjectState: Dispatch<SetStateAction<T>>,
  key: keyof T,
): ((
  e: React.ChangeEvent<
    HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
  >,
) => void) => {
  return (e) => {
    const value = 'value' in e ? e.value : e.target.value;
    setProjectState((currentState) => ({
      ...currentState,
      [key]: value,
    }));
  };
};

export const createProjectInputChange =
  handleInputChange<CreateProjectModalData>;

export const editProjectInputChange = handleInputChange<ProjectData>;

import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from '../../../../styles/time-tracking.module.scss';
import calendarImage from '../../../../images/Calendar.png';

export interface MultiDatesToolTipProps {
  isTooltipVisible: boolean;
  handleTooltipHover: () => void;
  handleTooltipMouseLeave: () => void;
  handleOkClick: () => void;
  handleDontShowAgainClick: () => void;
  isCommandOrCtrlPressed: boolean;
  canShowTooltip: boolean;
}

const MultiDatesToolTip = ({
  isTooltipVisible,
  handleTooltipHover,
  handleTooltipMouseLeave,
  handleOkClick,
  handleDontShowAgainClick,
  isCommandOrCtrlPressed,
  canShowTooltip,
}: MultiDatesToolTipProps) => {
  const showTooltip =
    canShowTooltip && (isCommandOrCtrlPressed || isTooltipVisible);

  return (
    <Tooltip
      id='multi-time-log'
      className={styles.tooltip_wrapper}
      place='top-start'
      variant='light'
      offset={30}
      clickable={true}
      arrowColor='transparent'
      isOpen={showTooltip}
      data-show={showTooltip}
    >
      <div
        className={styles.tooltip}
        onMouseEnter={handleTooltipHover}
        onMouseLeave={handleTooltipMouseLeave}
      >
        <div className={styles.tooltip_image}>
          <img src={calendarImage} alt='Calendar' />
        </div>
        <div className={styles.tooltip_content}>
          <h4>You can clone time logs!</h4>
          <div className={styles.tooltip_content__list}>
            <p>Fill in the fields to log the time.</p>
            <p>Press Ctrl/Cmd on your keyboard.</p>
            <p>
              Click on the days in the calendar where you want the log to be
              cloned.
            </p>
            <p>Press Submit.</p>
          </div>
          <div className={styles.tooltipButtons}>
            <button
              onClick={handleDontShowAgainClick}
              className={styles.dont_show_button}
            >
              Don’t show again
            </button>
            <button onClick={handleOkClick} className={styles.ok_button}>
              Okay
            </button>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default MultiDatesToolTip;

import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export const useHeaderBlock = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    billable,
    clientId,
    employeeId,
    employeeTaskName,
    endDate,
    pmId,
    projectId,
    startDate,
  } = useParams();

  return {
    billable,
    clientId,
    employeeId,
    employeeTaskName,
    endDate,
    location,
    pmId,
    projectId,
    startDate,
    t,
  };
};

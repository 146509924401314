export const warningEN = {
  confirm_to_delete_report: 'Do you really want to delete a report?',
  day_already_logged: 'This day is already logged',
  day_already_logged_selected: '*The days already logged cannot be selected',
  employee_deactivated: 'Deactivating means setting the end date to today',
  finish_edit:
    'You have not finished editing the log. Would you like to save changes before leaving the editing page?',
  no_available_data: 'No data available.',
  no_clients_data: 'No clients yet.',
  no_clients_projects: "hasn't had any projects yet.",
  no_employee_data: 'No data available for the given employee.',
  no_filled_data: 'Please fill in the data',
  no_filled_location: 'Please fill in the location',
  no_found_data:
    'No data found. Please fill out the form above to display the data.',
  no_period_data: 'No data available for the given period.',
  no_projects_data: 'No projects yet.',
  no_tasks_data: 'No tasks yet.',
  no_team_data: 'No team members yet.',
  task_already_exists: 'Such task already exists',
  logs_from_previous_month:
    'Please pay attention that you cannot edit or delete time logs from previous months.',
  less_than_2_months:
    'Please pay attention: your annulation date is in two months. Unused vacation days for the period will be burnt. ',
  less_than_14_days:
    'Your vacation annulation date is in two weeks! Unused vacation days for the period will be burnt.',
} as const;

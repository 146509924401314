import RobotStandsImage from '../../../images/RobotStandsImage.png';
import BCoinBodyLeftCol from '../components/BCoinBodyLeftCol';

const BCoinHowItWorks = () => (
  <div className='bcoin-body'>
    <BCoinBodyLeftCol
      imageAsset={RobotStandsImage}
      imageClassName='bcoin-body-left-col-img'
      title='How does it work?'
    />
    <div className='bcoin-body-right-col-bg bcoin-how-it-works-right-col'>
      <div className='bcoin-how-it-works'>
        <div className='bcoin-how-it-works-divider' />
        <div className='bcoin-how-it-work-line1' />
        <div className='bcoin-how-it-work-line2' />
        <div className='bcoin-how-it-works-row'>
          <div className='bcoin-how-it-works-key'>1</div>
          <div className='bcoin-how-it-works-dot' />
          <div className='bcoin-how-it-works-value'>Complete tasks</div>
        </div>
        <div className='bcoin-how-it-works-row'>
          <div className='bcoin-how-it-works-key'>2</div>
          <div className='bcoin-how-it-works-dot' />
          <div className='bcoin-how-it-works-value'>Get b-coin</div>
        </div>
        <div className='bcoin-how-it-works-row'>
          <div className='bcoin-how-it-works-key'>3</div>
          <div className='bcoin-how-it-works-dot' />
          <div className='bcoin-how-it-works-value'>Exchange for gifts</div>
        </div>
      </div>
    </div>
  </div>
);

export default BCoinHowItWorks;

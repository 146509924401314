// In fetchTimeLogs.ts

import { getTimeLog } from '../../../api/tracking';
import { setLoaderAction } from '../../../store/actionCreators/loaderActionCreator';
import { getTimeTrackedDataAction } from '../../../store/actionCreators/timeTrackingActionCreators';
import { formatDateYYYYMMDD } from '../../../utils/date-formatting';
import { GetTimeLogsListProps } from '../types';

export const createGetTimeLogsList = ({
  currentDate,
  dispatch,
  setHandleValues,
}: GetTimeLogsListProps) => {
  return async () => {
    setHandleValues(setLoaderAction(true));

    try {
      const data = await getTimeLog({
        date: formatDateYYYYMMDD(new Date(currentDate)),
      });

      setHandleValues(setLoaderAction(false));

      if (!data) return;

      dispatch(getTimeTrackedDataAction(data));
    } catch (error) {
      console.error('Failed to get time logs:', error);
      setHandleValues(setLoaderAction(false));
    }
  };
};

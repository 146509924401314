import { OnCancelEditingProps } from '../types';

export const onCancelEditing = ({
  setHandleValues,
  setEditDraftSickness,
  setEditedRowId,
  setIsOpenEditModal,
  handleGlobalEditMode,
  editValuesMode,
}: OnCancelEditingProps) => {
  setHandleValues({
    type: 'ON_TOGGLE_VIEW',
    payload: {
      editValuesMode: !editValuesMode,
    },
  });
  setEditedRowId(undefined);
  setEditDraftSickness({
    duration: {
      hours: 8,
      minutes: 0,
    },
    eptId: null,
    note: '',
    date: '',
    fileName: '',
    isPartDay: false,
  });
  setIsOpenEditModal(false);
  handleGlobalEditMode(false);
};

import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { reducer, valuesInitialState } from '../reducer/reducer';
import { RootState } from '../store';

const useValues = (dataKey: string) => {
  const { t } = useTranslation();
  const sessionData = sessionStorage.getItem(dataKey);
  const initState: RootState = sessionData
    ? JSON.parse(sessionData)
    : valuesInitialState(t);

  const [handleValues, setHandleValues] = useReducer(reducer, initState);

  const excludeProperty = (values: RootState) => {
    const { ...rest } = values;

    return rest;
  };

  useEffect(() => {
    sessionStorage.setItem(
      dataKey,
      JSON.stringify({
        ...excludeProperty(handleValues),
        toggleEditItem: false,
        editValuesMode: false,
      }),
    );
  }, [handleValues]);

  return {
    handleValues,
    setHandleValues,
  };
};

export default useValues;

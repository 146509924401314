import React from 'react';
import Table from '../../../../components/Tables/Table';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import { useNavigate } from 'react-router-dom';
import { ClientTableProps } from './types';

const ClientTable: React.FC<ClientTableProps> = ({
  clientColumns,
  clientList,
  currentPage,
  setCurrentPage,
  tableRef,
  tableInstance,
  toggleSortParam,
  totalAmount,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Table
        //TODO: add proper typing
        //@ts-ignore
        columns={clientColumns}
        data={clientList}
        onRowClick={(row) => navigate(`/clients/${row.original.id}`)}
        onToggleSortParam={toggleSortParam}
        ref={tableRef}
        sortByApiRequest
        tableInstance={tableInstance}
        tableName='pm-customers-list'
      />
      <TablePagination
        totalRows={totalAmount}
        rowsPerPage={20}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default ClientTable;

import Button from '../../../../components/Button/Button';
import { TranslationFunction } from '../../../../components/type-helpers/translation';

type EditButtonsProps = {
  disabled: boolean;
  removeEditMode: () => void;
  saveEditsHandler: () => void;
  t: TranslationFunction;
};

export const renderEditButtons = ({
  disabled,
  removeEditMode,
  saveEditsHandler,
  t,
}: EditButtonsProps) => (
  <>
    <Button
      label={t('buttons.cancel') as string}
      styleClass='btn__string--normal--grey'
      onClick={removeEditMode}
    />
    <Button
      label={t('buttons.save_edits') as string}
      styleClass='btn__rounded-blue'
      disabled={disabled}
      onClick={saveEditsHandler}
    />
  </>
);

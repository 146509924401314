import React, { forwardRef } from 'react';

//TODO: make proper typing of props
//hint: forwardRef is really hard to type, find better way to pass refs when moving to react-table v8
type TableProps = {
  changeRowColor?: (row: any) => void;
  onRowClick?: (row: any) => void;
  onToggleSortParam?: any;
  sortByApiRequest?: any;
  tableInstance: any;
  tableName: string;
};

const Table = forwardRef<HTMLTableElement, TableProps>(
  (
    {
      changeRowColor,
      onRowClick,
      onToggleSortParam,
      sortByApiRequest,
      tableInstance,
      tableName,
    },
    ref,
  ) => {
    const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
      tableInstance;

    const renderTableHeader = (headerGroup: any) =>
      headerGroup.headers.map((column: any, indx: number) =>
        sortByApiRequest ? (
          <th
            key={indx}
            onClick={
              sortByApiRequest ? () => onToggleSortParam(column.id) : () => {}
            }
          >
            {column.render('Header')}
          </th>
        ) : (
          <th
            key={indx}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {column.render('Header')}
          </th>
        ),
      );

    return (
      <table className={tableName} {...getTableProps()} ref={ref}>
        <thead>
          {headerGroups.map((headerGroup: any, i: number) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {renderTableHeader(headerGroup)}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                onClick={() => (onRowClick ? onRowClick(row) : undefined)}
                {...row.getRowProps(
                  changeRowColor ? changeRowColor(row) : undefined,
                )}
              >
                {row.cells.map((cell: any, indx: number) => (
                  <td key={indx} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);

export default Table;

import ReactDOM from 'react-dom';
import BCoinItemModal from '../BCoinItemModal/BCoinItemModal';
import { useBCoinItemCard } from './useBCoinItemCard';
import { BCoinItemCardProps } from './types';
import MadeInUkraineBadge from './components/MadeInUkraineBadge';
import ItemPrice from './components/ItemPrice';
import { FC } from 'react';

const BCoinItemCard: FC<BCoinItemCardProps> = ({ item }) => {
  const { element, toggleViewItem, toggleViewStoreItem } = useBCoinItemCard();

  if (!element) {
    return null;
  }

  return (
    <>
      <div className='bcoin-item-card' onClick={toggleViewItem}>
        {item.madeInUkraine && <MadeInUkraineBadge />}
        <div className='item-card-img'>
          <img src={item.primaryImage} alt={item.name} />
        </div>
        <div className='item-card-title'>{item.name}</div>
        <div className='item-card-price'>
          <ItemPrice item={item} />
        </div>
      </div>
      {toggleViewStoreItem &&
        ReactDOM.createPortal(
          <BCoinItemModal item={item} onCancelButtonClick={toggleViewItem} />,
          element,
        )}
    </>
  );
};

export default BCoinItemCard;

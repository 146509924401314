import { HoursByProjectAndEmployee } from '../../../api/api-types';

export function filterUniqueProjects(projects: HoursByProjectAndEmployee[]) {
  return projects.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (x) => x.projectNameAndId?.id === value.projectNameAndId?.id,
      ),
  );
}

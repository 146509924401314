import React from 'react';
import './styles/edit-sickness-modal.scss';

type EditSicknessModalProps = {
  active: boolean;
  setActive: (active: boolean) => void;
  children: React.ReactNode;
};

export const EditSicknessModal = ({
  active,
  setActive,
  children,
}: EditSicknessModalProps) => (
  <div
    className={active ? 'modal active' : 'modal'}
    onClick={() => setActive(false)}
  >
    <div
      className={active ? 'modal__content active' : 'modal__content'}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  </div>
);

import React from 'react';
import { ReactComponent as BinariksLogoBlueIcon } from './../icons/BinariksLogoBlueIcon.svg';
import { ReactComponent as BinariksTitleBlueIcon } from './../icons/BinariksTitleBlueIcon.svg';
import { removeEvent } from '../../../utils/ui-handling';

export const LogoSection = React.memo(() => (
  <div className='menu_pm_logo' onClick={removeEvent}>
    <div className='icon_pm_type' style={{ cursor: 'inherit' }}>
      <BinariksLogoBlueIcon />
    </div>
    <div className='binariks_title_icon'>
      <BinariksTitleBlueIcon />
    </div>
  </div>
));

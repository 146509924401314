import React from 'react';
import { ToggleSliderProps } from './types';
import { sliderButtonStyle, sliderLabelStyle } from './getStylesToggleSlider';

const ToggleSlider = ({
  isOn,
  handleToggle,
  toggleSliderTitle,
  id,
  ...props
}: ToggleSliderProps) => (
  <div className='toggle-slider-block'>
    <div className='toggle-slider-wrapper'>
      <input checked={isOn} onChange={handleToggle} id={id} {...props} />
      <label
        style={sliderLabelStyle(isOn)}
        className='slider-label'
        htmlFor={id}
      >
        <span className='slider-button' style={sliderButtonStyle(isOn)} />
      </label>
    </div>
    <div className='toggle-slider-title'>{toggleSliderTitle}</div>
  </div>
);

export default ToggleSlider;

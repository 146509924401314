import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import Loader from '../../components/Loader/Loader';
import PMReportList from '../../components/PMReportList/PMReportList';
import { useReportPM } from './useReportPM';

const ReportPM = () => {
  const { reportPM, isLoading } = useReportPM();

  return (
    <>
      {reportPM ? <PMReportList reportPMAll={reportPM} /> : <EmptyDataBlock />}
      {isLoading && <Loader />}
    </>
  );
};

export default ReportPM;

import { useEffect, useReducer, useState } from 'react';
import { reducer, valuesInitialState } from '../../../../reducer/reducer';
import { useTranslation } from 'react-i18next';
import { ON_TOGGLE_VIEW } from '../../../../store/actions';
import { BCoinItemCardReturn } from './types';

export const useBCoinItemCard = (): BCoinItemCardReturn => {
  const { t } = useTranslation();

  const [element, setElement] = useState<HTMLDivElement>();
  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );
  const { toggleViewStoreItem } = handleValues;

  const toggleViewItem = () =>
    setHandleValues(
      ON_TOGGLE_VIEW({
        payload: {
          toggleViewStoreItem: !toggleViewStoreItem,
        },
      }),
    );

  useEffect(() => {
    const el = document.getElementById('root') as HTMLDivElement;

    if (el) {
      setElement(el);
    }
  }, []);

  return {
    element,
    toggleViewItem,
    toggleViewStoreItem,
  };
};

import React, { useEffect, useRef } from 'react';
import useOnClickOutside from '../../hooks/useClickOutside';
import Button, { ButtonProps } from '../Button/Button';

export type ModalButton = {
  save?: () => void;
  close?: () => void;
  className?: string;
} & ButtonProps;

type ModalProps = {
  buttons?: Array<ModalButton>;
  children: React.ReactNode;
  closeLeft?: boolean;
  closeRight?: boolean;
  onClose?: () => void;
  onTopRight?: () => void;
  styles: any;
  title?: string;
  topRightButton?: React.ReactNode;
};

const Modal = ({
  buttons,
  children,
  closeLeft,
  closeRight,
  onClose,
  onTopRight,
  styles,
  title,
  topRightButton,
}: ModalProps) => {
  const modalRef = useRef(null);

  useOnClickOutside(true, modalRef, () => onClose && onClose());

  useEffect(() => {
    document.body.classList.add('stop-scrolling');
    window.addEventListener('popstate', () => onClose && onClose());
    return () => {
      document.body.classList.remove('stop-scrolling');
      window.removeEventListener('popstate', () => onClose && onClose());
    };
  }, []);

  return (
    <div className={styles.modal} ref={modalRef} onClick={onClose}>
      <div
        className={styles.modal_container}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modal_header}>
          {title && <span className={styles.modal_header_title}>{title}</span>}
          {(closeLeft || closeRight) && (
            <Button onClick={onTopRight} icon={topRightButton} />
          )}
        </div>
        <div className={styles.modal_body}>{children}</div>
        {buttons && (
          <div className={styles.modal_footer}>
            {buttons.map((button) => {
              const { save, close, className, ...restButtonProps } = button;
              return (
                <Button
                  {...restButtonProps}
                  key={button.id}
                  onClick={button.save || button.close}
                  styleClass={button.className}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;

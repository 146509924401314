import React, { FC } from 'react';
import BCoinBadge from '../../../../../images/BCoinBadge.png';
import { Tooltip } from 'react-tooltip';

const MadeInUkraineBadge: FC = () => {
  return (
    <>
      <img src={BCoinBadge} alt='Made in Ukraine' className='item-card-badge' />
      <Tooltip anchorSelect='.item-card-badge' place='bottom-end'>
        <div className='.item-card-badge-content'>Made in Ukraine💙</div>
      </Tooltip>
    </>
  );
};

export default MadeInUkraineBadge;

import { useEffect } from 'react';
import { getTimeLogDataForEmployee } from '../../../api/tracking';
import { getEmployeeDataAction } from '../../../store/actionCreators/employeeActionCreators';
import { FetchEmployeeProjectDataProps } from '../types';

export const useFetchEmployeeProjectData = ({
  projectName,
  selectedProjectName,
  dispatch,
}: FetchEmployeeProjectDataProps) =>
  useEffect(() => {
    const fetchData = async () => {
      selectedProjectName.current = projectName;

      try {
        const data = await getTimeLogDataForEmployee({
          userId: undefined,
        });
        if (!data) return;

        dispatch(getEmployeeDataAction(data));
      } catch (error) {
        console.error('Failed to fetch time log data:', error);
      }
    };

    fetchData();
  }, [projectName]);

import { useEffect, useState } from 'react';
import { TablePaginationProps, UseTablePaginationReturn } from './types';

export const useTablePagination = ({
  totalRows,
  rowsPerPage,
  currentPage,
  setCurrentPage,
}: TablePaginationProps): UseTablePaginationReturn => {
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  const pagesArr = [...new Array(noOfPages)];

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo: number) => setCurrentPage(pageNo);

  useEffect(() => {
    if (noOfPages === currentPage + 1) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 0) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
    if (totalRows <= 20) {
      setCurrentPage(0);
    }
  }, [noOfPages, currentPage]);

  return {
    noOfPages,
    pagesArr,
    canGoBack,
    canGoNext,
    onNextPage,
    onPrevPage,
    onPageSelect,
  };
};

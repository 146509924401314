/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { sortByDefault } from '../../../utils/filtering';
import { TaskListPageDependencies } from '../types';

export const useTableInstance = ({
  taskColumns,
  sortedTaskList,
  sortOrder,
}: TaskListPageDependencies) => {
  return useTable(
    {
      //@ts-ignore
      columns: taskColumns,
      data: sortedTaskList,
      //@ts-ignore
      initialState: { sortBy: sortOrder },
      sortTypes: {
        alphanumeric: (a: Record<string, string>, b: Record<string, string>) =>
          sortByDefault(a, b, 'name'),
      },
      autoResetSortBy: false,
      disableSortRemove: true,
      defaultCanSort: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
  );
};

import { ModalButton } from '../../Modal';
import { UseButtonBlockProps } from '../types';

export const useModalButtons = ({
  closeModal,
  deleteEditedTimelog,
  editMode,
  t,
  timeLogState,
  updateEditedTimelog,
}: UseButtonBlockProps): ModalButton[] => {
  const baseButtons = [
    {
      id: '2',
      label: t('buttons.cancel'),
      className: 'btn__string--normal--grey',
      close: closeModal,
    },
    {
      id: '3',
      label: t('buttons.delete'),
      className: 'btn__rounded-red',
      save: () => deleteEditedTimelog(timeLogState),
      close: closeModal,
    },
  ];

  const buttons = !editMode
    ? baseButtons
    : [
        {
          id: '1',
          label: t('buttons.update'),
          className: 'btn__rounded-blue',
          save: () => updateEditedTimelog(timeLogState),
          type: 'submit' as const,
        },
        ...baseButtons,
      ];

  return buttons;
};

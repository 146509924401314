import { DepartmentDto, DepartmentPreview } from './api-types';
import { DEPARTMENTS, handleError, makeRequest } from './config';

export const getDepartments = async () => {
  try {
    const url = DEPARTMENTS;
    const response = await makeRequest.get<DepartmentDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getDepartmentSubDepartments = async (id: number) => {
  try {
    const url = `${DEPARTMENTS}/${id}`;
    const response = await makeRequest.get<DepartmentPreview[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

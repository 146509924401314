import { useEffect, useMemo, useState } from 'react';
import { ProjectManagerReportItem } from '../../../api/api-types';

export type EmployeeOptionType = {
  id: string | null;
  label: string;
  value: string;
};

const useEmployeeOptions = (pmMemberList: ProjectManagerReportItem[]) => {
  const filterEmployeeOptions = useMemo(() => {
    const optionsMap = new Map<string, EmployeeOptionType>();
    pmMemberList.flatMap((item: ProjectManagerReportItem) =>
      Object.entries(item.employeeHoursMap || {}).forEach(([key, value]) => {
        if (!optionsMap.has(key)) {
          optionsMap.set(key, {
            id: key,
            label: value.name,
            value: value.name,
          });
        }
      }),
    );

    const optionsArray = Array.from(optionsMap.values());

    optionsArray.sort((a, b) => a.value.localeCompare(b.value));

    return optionsArray;
  }, [pmMemberList]);

  const employeeListWithAll = useMemo<EmployeeOptionType[]>(
    () => [{ id: '', label: 'All', value: 'All' }, ...filterEmployeeOptions],
    [filterEmployeeOptions],
  );

  const [employeeOptions, setEmployeeOptions] =
    useState<EmployeeOptionType[]>(employeeListWithAll);

  useEffect(() => {
    setEmployeeOptions(employeeListWithAll);
  }, [pmMemberList]);

  return { employeeOptions, setEmployeeOptions };
};

export default useEmployeeOptions;

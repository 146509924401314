/* eslint-disable react/prop-types */
// TODO: find better way to work with translations
import { format } from 'date-fns';
import { ReactComponent as DocumentIcon } from '../../icons/DocumentIcon.svg';
import { ReactComponent as VectorDown } from '../../icons/VectorDown.svg';
import { ReactComponent as VectorUp } from '../../icons/VectorUp.svg';
import { ReactComponent as CopyIcon } from '../../icons/CopyIcon.svg';
import Button from '../../components/Button/Button';
import { TranslationFunction } from '../../components/type-helpers/translation';
import { toast } from 'react-toastify';

const clientReportColumns = (t: TranslationFunction, rowDepth: number) => [
  {
    id: 'expand',
    Cell: ({ row }: { row: any }) =>
      row.canExpand ? (
        <Button
          icon={row.isExpanded ? <VectorUp /> : <VectorDown />}
          {...row.getToggleRowExpandedProps()}
        />
      ) : null,
  },
  {
    Header: `↑↓${t('columns.client_name')}`,
    accessor: 'name',
    Cell: ({ row }: { row: any }) => {
      if (row.depth === 0 && !row.original.name) return 'No customer';

      if (row.depth > rowDepth) {
        return (
          <div className='copyNameCell'>
            <span>{row.original.name}</span>
            <Button
              icon={<CopyIcon />}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(row.original.name);
                toast('copied successfully', { autoClose: 500 });
              }}
            />
          </div>
        );
      }

      return row.original.name;
    },
  },
  {
    accessor: 'task',
  },
  {
    Header: `↑↓${t('columns.total_duration')}`,
    accessor: 'countHours',
    Cell: ({ row }: { row: any }) =>
      row.original.countHours ? (
        `${`${row.original.countHours}`}`
      ) : (
        <>
          <span className='notBillableHours'>{`${row.original.notBillableHours}`}</span>
          {` / ${row.original.billableHours}`}
        </>
      ),
  },
];

const employeePMReport3Col = (t: TranslationFunction) => [
  {
    Header: `${t('columns.date')}`,
    accessor: (employee: any) =>
      `${employee.date.split('-').reverse().join('.')}`,
  },
  {
    Header: `${t('columns.task')}`,
    accessor: 'taskName',
  },
  {
    Header: `${t('columns.duration')}`,
    accessor: 'duration',
  },
];

const employeePMReport5Col = (t: TranslationFunction) => [
  {
    Header: `${t('columns.date')}`,
    accessor: (employee: any) =>
      `${employee.date.split('-').reverse().join('.')}`,
  },
  {
    Header: `${t('columns.task')}`,
    accessor: 'taskName',
  },
  {
    Header: `${t('columns.payment')}`,
    accessor: 'payment',
  },
  {
    Header: `${t('columns.attachment')}`,
    accessor: 'attachment',
    Cell: ({ row }: { row: any }) => (
      <div className='sickness_btn_wrapper'>
        {row.original.attachment && (
          <Button
            icon={<DocumentIcon />}
            onClick={() => {
              window.open(row.original.attachment, '_blank');
            }}
          />
        )}
      </div>
    ),
  },
  {
    Header: `${t('columns.duration')}`,
    accessor: 'duration',
  },
];

const employeeReport5Col = (t: TranslationFunction) => [
  {
    Header: `${t('columns.date')}`,
    accessor: (employee: any) =>
      `${employee.date.split('-').reverse().join('.')}`,
  },
  {
    Header: `${t('columns.task')}`,
    accessor: 'taskName',
  },
  {
    Header: `${t('columns.note')}`,
    accessor: 'note',
  },
  {
    Header: `${t('columns.time_of_logging')}`,
    accessor: (employee: any) =>
      `${format(new Date(employee.timestamp), 'dd.MM.yyyy, HH:mm')}`,
  },
  {
    Header: `${t('columns.duration')}`,
    accessor: 'duration',
  },
];

const employeeReport6Col = (t: TranslationFunction) => [
  {
    Header: `${t('columns.date')}`,
    accessor: (employee: any) =>
      `${employee.date.split('-').reverse().join('.')}`,
  },
  {
    Header: `${t('columns.task')}`,
    accessor: 'taskName',
  },
  {
    Header: `${t('columns.time_of_logging')}`,
    accessor: (employee: any) =>
      `${format(new Date(employee.timestamp), 'dd.MM.yyyy, HH:mm')}`,
  },
  {
    Header: `${t('columns.payment')}`,
    accessor: 'payment',
  },
  {
    Header: `${t('columns.attachment')}`,
    accessor: 'attachment',
    Cell: ({ row }: { row: any }) => (
      <div className='sickness_btn_wrapper'>
        {row.original.attachment && (
          <Button
            icon={<DocumentIcon />}
            onClick={() => {
              window.open(row.original.attachment, '_blank');
            }}
          />
        )}
      </div>
    ),
  },
  {
    Header: `${t('columns.duration')}`,
    accessor: 'duration',
  },
];

const pmReportColumns = (t: TranslationFunction) => [
  {
    Header: `↑↓${t('columns.employee_name')}`,
    accessor: 'employeeFullName.name',
  },
  {
    Header: `↑↓${t('columns.total_duration')}`,
    accessor: 'countHours',
  },
];

const projectReport3Col = (t: TranslationFunction) => [
  {
    Header: `${t('columns.date')}`,
    accessor: (employee: any) =>
      `${employee.date.split('-').reverse().join('.')}`,
  },
  {
    Header: `${t('columns.note')}`,
    accessor: 'note',
  },
  {
    Header: `${t('columns.duration')}`,
    accessor: 'duration',
  },
];

const projectReport5Col = (t: TranslationFunction) => [
  {
    Header: `${t('columns.date')}`,
    accessor: (employee: any) =>
      `${employee.date.split('-').reverse().join('.')}`,
  },
  {
    Header: `${t('columns.note')}`,
    accessor: 'note',
  },
  {
    Header: `${t('columns.payment')}`,
    accessor: 'payment',
  },
  {
    Header: `${t('columns.attachment')}`,
    accessor: 'attachment',
    Cell: ({ row }: { row: any }) => (
      <div className='sickness_btn_wrapper'>
        {row.original.attachment && (
          <Button
            icon={<DocumentIcon />}
            onClick={() => {
              window.open(row.original.attachment, '_blank');
            }}
          />
        )}
      </div>
    ),
  },
  {
    Header: `${t('columns.duration')}`,
    accessor: 'duration',
  },
];

const totalReportColumns = (t: TranslationFunction) => [
  {
    id: 'expander',
    Cell: ({ row }: { row: any }) =>
      row.canExpand ? (
        <Button
          icon={row.isExpanded ? <VectorUp /> : <VectorDown />}
          {...row.getToggleRowExpandedProps()}
        />
      ) : null,
  },
  {
    Header: `↑↓${t('columns.employee_name')}`,
    accessor: 'name',
  },
  {
    accessor: 'client',
  },
  {
    Header: `↑↓${t('columns.total_duration')}`,
    accessor: 'countHours',
    Cell: ({ row }: { row: any }) =>
      row.original.countHours ? (
        `${`${row.original.countHours}`}`
      ) : (
        <>
          <span className='notBillableHours'>{`${row.original.notBillableHours}`}</span>
          {` / ${row.original.billableHours}`}
        </>
      ),
  },
];

export {
  clientReportColumns,
  employeePMReport3Col,
  employeePMReport5Col,
  employeeReport5Col,
  employeeReport6Col,
  pmReportColumns,
  projectReport3Col,
  projectReport5Col,
  totalReportColumns,
};

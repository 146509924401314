import { SicknessesDto } from '../../../api/api-types';

export const transformSicknessData = (sicknessData: SicknessesDto) => {
  return sicknessData?.sicknessPeriods?.map((period) => {
    const periodDurations = period.logs?.map((log) => log.duration ?? 0) ?? [];
    const days = periodDurations.reduce((a, b) => a + b, 0) / 8;

    const commonData = {
      attachment: period.attachment,
      attachmentName: period.attachmentName,
      days,
      eptId: period.eptId,
      note: period.note,
      startPeriod: period.startPeriod,
    };

    if ((period?.logs?.length ?? 0) > 1) {
      const arrNumbers = period.logs?.map((log) => Number(log.payment));
      const ids = period.logs?.map((log) => Number(log.id));
      const maxNumber = arrNumbers ? Math.max(...arrNumbers) : 0;
      const minNumber = arrNumbers ? Math.min(...arrNumbers) : 0;

      return {
        ...commonData,
        ids,
        payment: `${maxNumber}% / ${minNumber}%`,
        date: `${period.startPeriod} - ${period.endPeriod}`,
        subRows: period.logs,
      };
    }

    return {
      ...commonData,
      date: period.startPeriod,
      ids: [period.logs?.at(0)?.id],
      payment: `${period.logs?.at(0)?.payment} %`,
      subRows: null,
    };
  });
};

import { toast } from 'react-toastify';
import { TASKS, SHORT_LIST, makeRequest, handleError } from './config';
import { EmployeeTask, TaskDto, TaskRequest } from './api-types';

type GetTask = {
  status?: string;
  shortList?: boolean;
};

export const getTask = async ({ status, shortList }: GetTask) => {
  const statusParam = status ?? 'ACTIVE';
  const shortListParam = shortList ?? true;
  try {
    const query = `?status=${statusParam}`;
    const url = `${TASKS}${shortListParam ? SHORT_LIST : ''}${query}`;
    const response = await makeRequest.get<EmployeeTask[]>(url);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Error fetching tasks: ${e.message}`);
    handleError(e);
    return null;
  }
};

type PostTask = {
  body: TaskRequest;
  shortList?: boolean;
};

export const postTask = async ({ body, shortList }: PostTask) => {
  const shortListParam = shortList ?? true;
  try {
    const url = `${TASKS}${shortListParam ? SHORT_LIST : ''}`;
    const response = await makeRequest.post<TaskDto>(url, body);
    toast.success('New task was added successfully');
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PutTask = {
  body: TaskRequest;
  taskId: string | number;
  shortList?: boolean;
};

export const putTask = async ({ body, taskId, shortList }: PutTask) => {
  const shortListParam = shortList ?? true;
  try {
    const url = `${TASKS}${shortListParam ? SHORT_LIST : ''}/${taskId}`;
    const response = await makeRequest.put<TaskDto>(url, body);
    toast.success('Task was edited successfully');
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

import AddEditClientModal from './AddEditClientModal/AddEditClientModal';
import AddEmployeeTimeLogModal from './AddEmployeeTimeLogModal/AddEmployeeTimeLogModal';
import EditProjectModal from './EditProjectModal/EditProjectModal';
import EditProjectReportModal from './EditProjectReportModal/EditProjectReportModal';
import TeamMemberModal from './TeamMemberModal/TeamMemberModal';

const InitModals = () => {
  return (
    <>
      <AddEmployeeTimeLogModal />
      <EditProjectReportModal />
      <AddEditClientModal />
      <EditProjectModal />
      <TeamMemberModal />
    </>
  );
};

export default InitModals;

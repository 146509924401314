import { isDayAlreadyLoggedProps } from '../types';

export const isDayAlreadyLogged = ({
  vacationData,
  startDate,
  endDate,
}: isDayAlreadyLoggedProps) => {
  return vacationData?.dateAndProgressPercentage?.some((log) =>
    log.date
      ? new Date(log.date).getTime() < new Date(startDate).getTime() &&
        new Date(log.date).getTime() > new Date(endDate).getTime()
      : false,
  );
};

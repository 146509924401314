import { putTimeLog } from '../../../../api/tracking';
import { SaveEditsHandlerProps } from '../types';

export const saveEditsHandler = async ({
  getTimeLogsList,
  handleGlobalEditMode,
  removeEditMode,
  setActiveRow,
  setHandleValues,
  timeLogEditRequest,
  timeLogId,
}: SaveEditsHandlerProps) => {
  try {
    await putTimeLog({ id: timeLogId, data: timeLogEditRequest });

    getTimeLogsList();
  } catch (error) {
    console.error('Error updating time log or fetching time logs list:', error);
  } finally {
    removeEditMode({ setHandleValues, setActiveRow, handleGlobalEditMode });
  }
};

export const checkboxes = [
  {
    id: 'one-day',
    title: 'One day',
  },
  {
    id: 'half-a-day',
    title: 'Half a day',
  },
] as const;

export const vacationDataEN = {
  accumulated_days: 'Accumulated days',
  annulation_date: 'Annulation date',
  day_off_type: 'Type of day off',
  days_to_burn: 'Days to burn',
  estimation_period: 'Estimation period',
  last_update: 'Last update',
  paid_vacations: 'Paid Vacations',
  unpaid_day_offs: 'Unpaid Day-offs',
} as const;

/* eslint-disable no-plusplus */
function* generateID() {
  let idNumber = 1;

  while (true) {
    yield idNumber;
    idNumber++;
  }
}

export const generatorObject = generateID();

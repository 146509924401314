import StyledPickerTwoDates from '../../../../../components/DatePickers/DatePicker/StyledPickerTwoDates';

import { handleHolidayArr } from '../../../../../utils/handleHolidayArr';
import renderDayContents from '../../../../TimeTracking/DayContentBlock';
import { DatePickerDoubleDatesProps } from '../../types';
import { useDatePickerDoubleDates } from './useDatePickerDoubleDates';

export const DatePickerDoubleDates = ({
  dayOffType,
  endDate,
  loggedDates,
  onMonthChange,
  registeredEmployee,
  setHandleValues,
  startDate,
  vacationData,
}: DatePickerDoubleDatesProps) => {
  const {
    excludeDates,
    getEndDate,
    getStartDate,
    maxDate,
    minDate,
    onChangeHandler,
  } = useDatePickerDoubleDates({
    dayOffType,
    endDate,
    loggedDates,
    registeredEmployee,
    setHandleValues,
    startDate,
    vacationData,
  });

  return (
    <StyledPickerTwoDates
      selectsRange={true}
      wrapperClassName='styledCalendar'
      startDate={getStartDate}
      endDate={getEndDate}
      excludeDates={excludeDates}
      highlightDates={handleHolidayArr(vacationData?.holidays)}
      onChange={onChangeHandler}
      minDate={minDate}
      maxDate={maxDate}
      onMonthChange={(month) => onMonthChange(month)}
      renderDayContents={(day, date) =>
        renderDayContents(vacationData, day, date)
      }
    />
  );
};

import React, { FC } from 'react';
import ConfirmationPopup from '../../../../../components/ConfirmationPopup/ConfirmationPopup/ConfirmationPopup';
import { sendBCoinTaskCompletion } from '../../../../../api/bcoin';
import { PopupButtonProps } from '../types';

export const PopupButton: FC<PopupButtonProps> = ({
  IconComponent,
  buttonClass,
  buttonText,
  id,
  status,
  text,
}) => (
  <ConfirmationPopup
    onSubmit={() =>
      id
        ? sendBCoinTaskCompletion({
            id,
            status,
          })
        : undefined
    }
    text={text}
  >
    <button className={buttonClass} type='button'>
      <IconComponent className='card-btn-icon' />
      {buttonText}
    </button>
  </ConfirmationPopup>
);

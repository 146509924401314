import { toast } from 'react-toastify';
import {
  API_URL,
  PREVIEW,
  PROJECTS,
  PROJECT_MANAGER,
  REPORTS,
  TEAM_MEMBERS,
  USERS,
  makeRequest,
  handleError,
} from './config';
import { noDeletePM } from '../constants/text-values';
import { sortByNameFn } from '../utils/sortByName';
import {
  PageEmployeeProjectTaskDto,
  EmployeeProjectTaskRequest,
  EmployeeProjectTaskDto,
  UserNameIdDto,
  ProjectManagerReportAllData,
} from './api-types';

export const getTeamMember = async (
  id: number,
  sortParam: string,
  page: number = 0,
  size: number = 10,
  status: string = 'true',
  unpaged: boolean = false,
) => {
  try {
    const query = `/${id}${TEAM_MEMBERS}?isActive=${status}&page=${page}&size=${size}&sort=${sortParam}&unpaged=${unpaged}`;
    const url = `${API_URL}${PROJECTS}${query}`;
    const response = await makeRequest.get<PageEmployeeProjectTaskDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getPMTeamMember = async (
  id: string | number,
  start: string,
  end: string,
) => {
  try {
    const query = `?endPeriod=${end}&pmId=${id}&startPeriod=${start}`;
    const url = `${API_URL}${REPORTS}${PROJECT_MANAGER}${query}`;
    const response = await makeRequest.get<ProjectManagerReportAllData>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const postTeamMember = async (
  id: number,
  data: EmployeeProjectTaskRequest,
) => {
  try {
    const url = `${API_URL}${PROJECTS}/${id}${TEAM_MEMBERS}`;
    const response = await makeRequest.post<EmployeeProjectTaskDto>(url, data);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const putTeamMember = async (
  id: number,
  teamMemberId: string | number,
  data: EmployeeProjectTaskRequest,
) => {
  try {
    const url = `${API_URL}${PROJECTS}/${id}${TEAM_MEMBERS}/${teamMemberId}`;
    const response = await makeRequest.put<EmployeeProjectTaskDto>(url, data);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const deleteTeamMember = async (
  projectId: number,
  teamMemberId: string | number,
) => {
  try {
    const url = `${API_URL}${PROJECTS}/${projectId}${TEAM_MEMBERS}/${teamMemberId}`;
    await makeRequest.delete(url);
    toast.success('End date has been added');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message.includes('409')) {
      toast.warn(noDeletePM);
    }
    handleError(error);
  }
};

export const fetchEmployeesByRole = async (
  role?: string,
  heads?: boolean,
  leads?: boolean,
) => {
  const getFilters = () => {
    return role && role !== 'All'
      ? `?department=${role}${heads ? '&heads=true' : ''}${
          leads ? '&leads=true' : ''
        }`
      : '';
  };

  try {
    const filters = getFilters();
    const url = `${API_URL}${USERS}${PREVIEW}${filters}`;
    const response = await makeRequest.get<UserNameIdDto[]>(url);
    return response.data.sort(sortByNameFn());
  } catch (error) {
    handleError(error);
    return null;
  }
};

import { selectPMModalMain } from '../../../components/Select/selectStyles';
import styles from '../../../components/Modal/styles/project-modal.module.scss';
import dataPairStyles from '../../../components/DataPair/data-pair.module.scss';
import SimpleSelect from '../../../components/Select/SimpleSelect';
import DataPair from '../../../components/DataPair/DataPair';
import { CustomerSelectProps } from '../types';
import { useCustomerSelect } from './useCustomerSelect';

const CustomerSelect = (props: CustomerSelectProps) => {
  const { customerChangeHandler, customerValue, filteredCustomers, t } =
    useCustomerSelect(props);

  return (
    <>
      <div className={styles.project_data}>
        <DataPair
          dataPairTitle={dataPairStyles.data_pair_title}
          title={`${t('modals.fields.customer')}*`}
        />
        <div className={styles.data_input}>
          <SimpleSelect
            options={filteredCustomers}
            onChange={customerChangeHandler}
            value={customerValue}
            styles={selectPMModalMain}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerSelect;

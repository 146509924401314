import { SicknessesDto } from '../../../api/api-types';

export const getLoggedDates = (sicknessData: SicknessesDto) =>
  sicknessData?.dateAndProgressPercentage &&
  sicknessData.dateAndProgressPercentage
    .filter((el) => el.progressPercentage === 100)
    .map((log) =>
      log.date
        ? new Date(
            new Date(log.date).setMinutes(
              new Date(log.date).getTimezoneOffset(),
            ),
          )
        : undefined,
    )
    .filter((date): date is Date => Boolean(date));

import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskType } from '../../../pages/TaskListPage/types';
import { DepartmentDto } from '../../../api/api-types';
import { UseTaskModalReturn } from './types';

export const useTaskModal = (
  data: TaskType,
  endPointData: DepartmentDto[],
): UseTaskModalReturn => {
  const { t } = useTranslation();
  const [taskState, setTaskState] = useState(data);

  const departmentCurrentName = endPointData.find(
    (item) => item.id === taskState?.departmentId,
  );

  const taskOnChangeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setTaskState((state) => ({
      ...state,
      name: e.target.value,
    }));

  const descriptionOnChangeHandler = (e: { target: { value: string } }) =>
    setTaskState((state) => ({
      ...state,
      description: e.target.value,
    }));

  return {
    t,
    taskState,
    setTaskState,
    departmentCurrentName,
    taskOnChangeHandler,
    descriptionOnChangeHandler,
  };
};

import { subDays } from 'date-fns';
import StyledCalendarDatePicker from '../../../../../components/DatePickers/DatePicker/StyledCalendarDatePicker';
import {
  dateReducerHandler,
  filterWeekends,
} from '../../../../../utils/date-handling';
import { handleHolidayArr } from '../../../../../utils/handleHolidayArr';
import renderDayContents from '../../../../TimeTracking/DayContentBlock';
import { DatePickerSingleDateProps } from './types';

export const DatePickerSingleDate = ({
  currentDate,
  setHandleValues,
  vacationData,
  onMonthChange,
}: DatePickerSingleDateProps) => {
  return (
    <StyledCalendarDatePicker
      wrapperClassName='styledCalendar'
      startDate={currentDate ? new Date(currentDate) : undefined}
      minDate={subDays(new Date(), 30)}
      onChange={(selected) => dateReducerHandler(setHandleValues, selected)}
      filterDate={filterWeekends}
      highlightDates={handleHolidayArr(vacationData?.holidays)}
      renderDayContents={(day, date) =>
        renderDayContents(vacationData, day, date)
      }
      onMonthChange={(month) => onMonthChange(month)}
    />
  );
};

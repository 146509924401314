import React from 'react';

type TextFieldProps = {
  labelclassname?: string;
  field?: string;
  title?: string;
} & JSX.IntrinsicElements['input'];

const TextField = ({
  labelclassname,
  field,
  title,
  ...props
}: TextFieldProps) => (
  <label className={labelclassname} htmlFor={field}>
    <input id={field} {...props} />
    <span>{title}</span>
  </label>
);

export default TextField;

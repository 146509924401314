import Button from '../../../components/Button/Button';
import { ReactComponent as PenIcon } from '../../../icons/PenIcon.svg';
import { ReactComponent as PenIconGrey } from '../../../icons/PenIconGrey.svg';
import { ReactComponent as TrashBinIcon } from '../../../icons/TrashBinIcon.svg';

import styles from '../../../styles/time-tracking.module.scss';
import { ColumnEditBlockProps } from './types';
import { useColumnEditBlock } from './useColumnEditBlock';

const ColumnEditBlock = ({
  activeRow,
  editMode,
  handleConfirmPopUp,
  handleGlobalEditMode,
  isEqualMonth,
  row,
  setActiveRow,
  setHandleValues,
}: ColumnEditBlockProps) => {
  const { deleteHandler, editHandler } = useColumnEditBlock({
    editMode,
    handleConfirmPopUp,
    handleGlobalEditMode,
    row,
    setActiveRow,
    setHandleValues,
  });

  return (
    <div className={`${isEqualMonth ? 'table_btn_wrapper' : 'table_btn_none'}`}>
      {row.original.project !== 'Internal Administration' && (
        <div className={styles.buttonArea}>
          <Button
            icon={row.original.id === activeRow ? <PenIconGrey /> : <PenIcon />}
            disabled={row.original.id === activeRow}
            onClick={() => editHandler(row)}
          />
        </div>
      )}
      <div className={styles.buttonArea}>
        <Button icon={<TrashBinIcon />} onClick={deleteHandler} />
      </div>
    </div>
  );
};

export default ColumnEditBlock;
